import { useState, useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Button, Stack } from "@mui/material";
import { LoadingSpinner } from "../../../../components/LoadingSpinner";
import { EmployeeSection } from "../../../../components/Forms/Employer/EmployeeSection";
import { PaperWhite } from "../../../../components/Papers";
import { useUpdateEmployeeProvider } from "../../../../services/hooks/Employee/useUpdateEmployee";
import { ArrowBackOutlined } from "@mui/icons-material";
import wowIcon from "../../../../assets/images/wow.png";
import warning from "../../../../assets/images/warning.svg";
import { Alert } from "../../../../components/Alert";
import { useAtom } from "jotai";
import { replaceEmployeeDataAtom } from "../../../store/requestTracker/replaceRequest.store";

const initialAlert = {
  open: false,
  icon: "",
  title: "",
  messages: [],
  content: "",
};

const initialValue = {
  rut: "",
  name: "",
  lastname1: "",
  lastname2: "",
  phone: "",
  email: "",
};

export const EditEmployeeData = ({ formValues, goBack }) => {
  const { control, handleSubmit, getValues, setValue } = useForm({
    formValues,
  });
  const [alert, setAlert] = useState(initialAlert);
  const [currentValues, setCurrentValues] = useState(formValues);
  const [, setEmployeeData] = useAtom(replaceEmployeeDataAtom);
  const { mutate, isLoading } = useUpdateEmployeeProvider();

  const handleChange = () => {
    setCurrentValues((prev) => ({ ...prev, ...getValues() }));
  };

  useEffect(() => {
    Object.keys(currentValues).forEach((key) => {
      setValue(key, currentValues[key]);
    });
  }, []);

  const onSubmit = useCallback(async (employee) => {
    mutate(
      {
        data: employee,
        employeeId: employee?.id,
      },
      {
        onSuccess: () => {
          setEmployeeData(initialValue);
          setAlert({
            ...alert,
            icon: <img src={wowIcon} alt="Congratulations Icon" />,
            open: true,
            title: "Felicidades",
            messages: ["Empleado actualizado correctamente!"],
          });
        },
        onError: () => {
          setAlert({
            ...alert,
            icon: warning,
            open: true,
            title: "Error",
            messages: [
              "Oh no! se ha producido un error al actualizar el empleado",
            ],
          });
        },
      }
    );
  }, []);

  const handleConfirmation = () => {
    goBack();
    setAlert({
      ...alert,
      open: false,
    });
  };

  return (
    <PaperWhite>
        <Stack
          spacing={2}
          component="form"
          onChange={handleChange}
          onSubmit={handleSubmit(onSubmit)}
        >
          <EmployeeSection
            control={control}
            isEditingProvider={true}
            title={"Editar Empleado"}
          />
          <Stack direction="row" sx={{ justifyContent: "space-between" }}>
            <Button
              variant="goBack"
              disableRipple
              startIcon={<ArrowBackOutlined />}
              onClick={() => goBack()}
              sx={{ height: 40, fontSize: 12, alignSelf: "self-start" }}
            >
              {"Volver atrás"}
            </Button>
            <Button
              color="secondary"
              type="submit"
              sx={{ minWidth: "155px", height: 40 }}
            >
              {isLoading ? <LoadingSpinner open /> : "Guardar"}
            </Button>
          </Stack>
          <Alert
            icon={alert.icon}
            open={alert.open}
            handleConfirmation={handleConfirmation}
            title={alert.title}
            messages={alert.messages}
          />
        </Stack>
      </PaperWhite>
  );
};
