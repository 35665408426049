import { Grid, Stack, Typography } from "@mui/material";
import { ComparisonTable } from "../Replace/ComparisonTable";
import { CommonStatus, VACANCY_TYPE_ENUM } from "../../../../constants/status";
import { replacementFields } from "../helper/entity";
import { formatDate } from "../../../../utils/formatValues";
import { DocumentListUpdate } from "../../../components/Documents/DocumentListUpdate";
import { useGetTemplate } from "../../../../services/hooks/Docs/Templates/useGetTemplates";
import { useUpdateReplacementStatus } from "../../../services/hooks/Request/Replacement/useUpdateReplacementStatus";
import { useState } from "react";
import { AlertSnackBar } from "../../../../components/Alerts";

export const ReplacementRequestInfo = ({ request, isTemporary }) => {
  const entityType = request?.requested?.for?.type === VACANCY_TYPE_ENUM.EMPLOYEE ? request?.requested?.for?.subType : VACANCY_TYPE_ENUM.FLEET;
  const entity = replacementFields(request?.metadata?.entityToReplace, request?.requested?.for?.type);
  const entityToCompare = replacementFields(request?.metadata?.replacement, request?.requested?.for?.type);
  const fromDate = formatDate(request?.metadata?.setting?.fromDate, 'DD-MM-YYYY');
  const toDate = isTemporary ? formatDate(request?.metadata?.setting?.toDate, 'DD-MM-YYYY') : undefined;
  const { data: dataDocumentTemplate } = useGetTemplate(request?.metadata?.replacement?.documentTemplateId);
  const [error, setError] = useState({ isOpen: false });
  const { mutate } = useUpdateReplacementStatus();
  const onUpdateSuccess = () => {
    mutate(
      {
        requestId: request.id,
        status: CommonStatus.PENDING,
      },
      {
        onError: () => {
          setError({
            isOpen: true,
            title: "Error",
            message: "Hubo un error al actualizar la solicitud de reemplazo.",
            handleClose: () => setError({ isOpen: false }),
          });
        }
      }
    );
  }
  return (
    <>
      <Stack marginBottom={3} direction='row' spacing={2} flexGrow={1}>
        <Stack direction='column' spacing={2} flexGrow={2} paddingTop={2}>
          <Stack direction='row' spacing={2} flexGrow={1}>
            <Typography sx={{ fontWeight: 700 }}>
              Fecha de inicio:
            </Typography>
            <Typography>{fromDate}</Typography>
          </Stack>
        </Stack>
        {
          isTemporary &&
          <Stack direction='column' spacing={2} flexGrow={2} paddingTop={2}>
            <Stack direction='row' spacing={2} flexGrow={1}>
              <Typography sx={{ fontWeight: 700 }}>
                Fecha de término:
              </Typography>
              <Typography>{toDate}</Typography>
            </Stack>
          </Stack>
        }
      </Stack>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <ComparisonTable
          entityType={entityType}
          entity={entity}
          entityToCompare={entityToCompare}
        />
      </Grid>
      <DocumentListUpdate
        type={entityType}
        id={request?.metadata?.replacement?.id}
        templateData={dataDocumentTemplate}
        isProviderList={false}
        onUpdateSuccess={onUpdateSuccess}
        status={request?.status}
      />
      <AlertSnackBar
        open={error.isOpen}
        message={error.message}
        handleClose={error.handleClose}
      />
    </>
  )
}