import { useQuery } from "react-query";
import { useApi } from "../../../../services/api";
import { FLEET_URI } from "../../../../constants/api";
import { buildUrlWithQueryParams } from "../../../helper/converters";

export async function getFleetListByProvider(filters) {
  const { providerId, ...restFilters } = filters;

  if (!Object.keys(restFilters).length || !providerId) return;

  const { axiosInstance } = useApi();
  const url = buildUrlWithQueryParams(
    `${FLEET_URI}/fleet/byProvider/${filters.providerId}`,
    restFilters
  );

  const response = await axiosInstance.get(url);
  const { data, metadata } = response.data;

  return { data, metadata };
}

export function useFleetListByProvider(filters) {
  return useQuery(
    ["fleetListByProvider", filters],
    () => getFleetListByProvider(filters),
    {
      staleTime: 1000 * 60 * 10,
    }
  );
}
