import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetEmployee } from "../../../services/hooks/Employee";
import { LoadingSpinner } from "../../../components/LoadingSpinner";
import { EditEmployeeData } from "../ProviderList/components/EditEmployeeData";

export default function EmployeeEdit() {
  const { employeeId } = useParams();
  const navigation = useNavigate();
  const { data: item, isLoading: isEmployeeLoading } = useGetEmployee(employeeId)
  const formValues = useMemo(() => ({
    id: item?.id,
    rut: item?.numberRut,
    name: item?.name ?? "",
    lastname1: item?.lastname1 ?? "",
    lastname2: item?.lastname2 ?? "",
    email: item?.email ?? "",
    phone: item?.phone ?? "",
  }), [item])

  const goBack = () => {
    navigation(-1);
  }

  if ( isEmployeeLoading) return <LoadingSpinner open />;

  return (
    <EditEmployeeData formValues={formValues} goBack={goBack} />
  );
};
