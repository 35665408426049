import { useQuery } from "react-query";
import { useApi } from "../../../api";
import { DOCUMENT_URI } from "../../../../constants/api";

export async function getDocumentsByEntity(id, entity) {
  if (id) {
    try {
      const { axiosInstance } = useApi();

      const response = await axiosInstance.get(`/by-entity/${entity}/${id}`, {
        baseURL: DOCUMENT_URI,
      });

      return response.data;
    } catch (e) {
      return null;
    }
  } else {
    return null;
  }
}

export function useGetDocumentsByEntity(id, entity) {
  return useQuery(
    ["documents", id, entity],
    () => getDocumentsByEntity(id, entity),
    {
      staleTime: 1000 * 60 * 10,
    }
  );
}
