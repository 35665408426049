import { Button, Stack, Typography } from "@mui/material";
import { PaperWhite } from "../../../components/Papers";
import { WarningIcon } from "../../../components/Icons/WarningIcon";
import { useSession } from "@blue-express/bx-lib-universal-frontend";

export default function BlockedProvider({ message }) {
  const { logout } = useSession();
  return (
    <>
      <Stack sx={{ alignItems: 'center', justifyContent: "center", height: "100vh", display: "flex", }}>
        <PaperWhite sx={{ width: "50%", textAlign: "center" }}>
          <WarningIcon sx={{ width: '150px', height: '150px' }} />
          <Typography variant="h5" color="#073E99">
            {message}
          </Typography>
        </PaperWhite>

        <Stack
          direction='row'
          justifyContent='flex-end'
          paddingY={2}
          spacing={10}
        >
          <Button
            onClick={() => logout()}
            size='large'
          >
            Salir
          </Button>
        </Stack>
      </Stack>

    </>
  )
}
