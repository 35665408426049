import { useMutation, useQueryClient } from 'react-query'
import { useApi } from '../../../api'
import { VEHICLE_URI } from '../../../../constants/api'

export function useUpdateVehicleFeature(id) {
  const { axiosInstance } = useApi()
  const queryClient = useQueryClient()

  return useMutation(
    (newFeature) =>
      axiosInstance.put(`/features/${id}`, newFeature, {
        baseURL: VEHICLE_URI,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('listVehiclesFeatures')
        queryClient.invalidateQueries(['vehicleFeature', id])
      },
    }
  )
}
