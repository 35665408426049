import { useMutation } from "react-query";
import { useApi } from "../../../../../services/api";
import { REQUEST_TRACKER_URI } from "../../../../../constants/api";

export const useUpdateReplaceDocumentStatus = () => {
  const { axiosInstance } = useApi();
  return useMutation(({ requestId, status }) =>
    axiosInstance.put(
      `/replaceDocument/${requestId}`,
      { status },
      {
        baseURL: REQUEST_TRACKER_URI,
      }
    )
  );
};
