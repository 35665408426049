import { Stack, Typography } from "@mui/material";

export const InfoHeader = ({ item }) => {
  return (
    <Stack marginBottom={3} direction='row' spacing={2} flexGrow={1}>
      <>
        <Stack direction='column' spacing={2} flexGrow={2} paddingTop={2}>
          <Stack direction='row' spacing={2} flexGrow={1}>
            <Typography sx={{ fontWeight: 700 }}>
              Nombre:
            </Typography>
            <Typography>{`${item?.name} ${item?.lastname1} ${item?.lastname2 || ''}`}</Typography>
          </Stack>
          <Stack direction='row' spacing={2} flexGrow={1}>
            <Typography sx={{ fontWeight: 700 }}>
              Rut:
            </Typography>
            <Typography>{item.numberRut}</Typography>
          </Stack>
        </Stack>
        <Stack direction='column' spacing={2} flexGrow={2} paddingTop={2}>
          <Stack direction='row' spacing={2} flexGrow={1}>
            <Typography sx={{ fontWeight: 700 }}>
              Correo:
            </Typography>
            <Typography>{item?.email}</Typography>
          </Stack>
          <Stack direction='row' spacing={2} flexGrow={1}>
            <Typography sx={{ fontWeight: 700 }}>
              Teléfono:
            </Typography>
            <Typography>{item?.phone}</Typography>
          </Stack>
        </Stack>
      </>
    </Stack>
  )
}
