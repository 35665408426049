import { useQuery } from 'react-query'
import { useApi } from '../../../api'
import { VEHICLE_URI } from '../../../../constants/api'

export async function getVehicleFeature(id) {
  const { axiosInstance } = useApi()

  if (id) {
    try {
      const response = await axiosInstance.get(`/features/${id}`, {
        baseURL: VEHICLE_URI,
      })

      const { data } = response.data

      return data
    } catch (error) {
      return null
    }
  } else {
    return null
  }
}

export function useGetVehicleFeature(id) {
  return useQuery(['vehicleFeature', id], () => getVehicleFeature(id), {
    staleTime: 1000 * 60 * 60 * 24, // 24 horas para não refazer a requisição ao receber foco na tela
  })
}
