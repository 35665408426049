import { useQuery } from 'react-query'
import { useApi } from '../../../api'
import { VEHICLE_URI } from '../../../../constants/api'

export async function getListVehiclesFeatures() {
  const { axiosInstance } = useApi()

  const response = await axiosInstance.get('/features?page=1&limit=1000', {
    baseURL: VEHICLE_URI,
  })

  const { data } = response.data

  return data
}

export function useListVehiclesFeatures() {
  return useQuery('listVehiclesFeatures', () => getListVehiclesFeatures(), {
    staleTime: 1000 * 60 * 60 * 24, // 24 horas para não refazer a requisição ao receber foco na tela
  })
}
