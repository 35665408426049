import { useForm } from "react-hook-form"
import { DocumentsSection } from "../../../components/Forms/Documents/DocumentSection"
import { LoadingSpinner } from "../../../components/LoadingSpinner"
import { Modal } from "../../../components/Modals/Modal"
import { Button, Grid, Stack } from "@mui/material"
import { useCallback, useEffect, useState } from "react"
import { validateGroupedDocuments } from "../../helper/documents"
import { Alert } from "../../../components/Alert"
import { useUpdateDocuments } from "../../../services/hooks/Docs/S3/useUpdateDocument"
import { useGetDocumentsByEntity } from "../../../services/hooks/Docs/S3/useGetDocumentsByEntity"
import wowIcon from "../../../assets/images/wow.png";
import warning from "../../../assets/images/warning.svg"

const initialAlert = {
  open: false,
  icon: '',
  title: '',
  messages: [],
  content: '',
}

const DocumentsModal = ({
  isOpen,
  onClose,
  templateData,
  formValues,
  id,
  ownerType,
  documentUpload,
  onFinish,
}) => {
  const {
    control,
    handleSubmit,
    getValues,
    setValue,
  } = useForm({formValues});
  const [currentValues, setCurrentValues] = useState(formValues)
  const [alert, setAlert] = useState(initialAlert);
  const { mutate: mutateUpdateDocument, isLoading: isLoadingDocuments } = useUpdateDocuments();
  const { data: documentsFromBucket } = useGetDocumentsByEntity(id, ownerType);

  const handleFinishAction = () => {
    Object.keys(currentValues).forEach((key) => {
      setValue(key, '');
    })
  }

  const handleSubmitSend = useCallback(async (data) => {
    const { error: validationGroupedError, groupedDataByDocumentType } =
      validateGroupedDocuments(
        templateData.documentTypes,
        data,
        formValues
      );
    if (validationGroupedError) {
      setAlert({
        ...alert,
        icon: warning,
        open: true,
        title: "Error",
        messages: validationGroupedError.messages,
      });
      return;
    }

    for (let [key, documentToUpload] of Object.entries(
      groupedDataByDocumentType
    )) {
      const beforeUpload = Object.assign({}, documentToUpload);
      beforeUpload["ownerId"] = id;
      beforeUpload["ownerType"] = ownerType;
      beforeUpload.metaData = JSON.stringify(beforeUpload.metaData);
      if (!beforeUpload.file.some((docFile) => docFile === null)) {
        const currentMetadataGroupName = documentToUpload.metaData[0]?.groupName;
        const relatedDocument = documentsFromBucket.find(
          (relDoc) =>
            relDoc.files[0].groupName === currentMetadataGroupName
        );
        const relatedDocumentId = relatedDocument?.id

        mutateUpdateDocument(
          {
            documentId: relatedDocumentId,
            documents: beforeUpload,
          },
          {
            onSuccess: () => {
              setAlert({
                ...alert,
                icon: <img src={wowIcon} alt="Congratulations Icon" />,
                open: true,
                title: "Felicidades",
                messages: [
                  "Documentos cargados exitosamente!",
                ],
              });
              onFinish && onFinish({ documentId: relatedDocumentId });
            },
            onError: () => {
              setAlert({
                ...alert,
                icon: warning,
                open: true,
                title: "Error",
                messages: [
                  "Oh no! se ha producido un error al actualizar los documentos",
                ],
              });
            },
          }
        );

        if (
          key ===
          Object.keys(groupedDataByDocumentType)[
            Object.keys(groupedDataByDocumentType).length - 1
          ]
        ) {
          handleFinishAction();
        }
      }
    }
  }, [documentsFromBucket, templateData])

  useEffect(() => {
    Object.keys(currentValues).forEach((key) => {
      setValue(key, currentValues[key]);
    })
  },[]);

  const handleChange = () => {
    setCurrentValues((prev) => ({...prev, ...getValues()}));
  }

  const handleConfirmation = () => {
    handleFinishAction()
    onClose()
    setAlert({
      ...alert,
      open: false,
    })
  }

  return (
    <Modal open={isOpen} handleClose={onClose}>
      <Stack
        spacing={2}
        component='form'
        onChange={handleChange}
        onSubmit={handleSubmit(handleSubmitSend)}
      >
        {isLoadingDocuments && <LoadingSpinner open />}
        <DocumentsSection control={control} documentStructure={templateData?.documentTypes?.filter((item) => item.id === documentUpload?.documentTypeId)} />
        <Grid item xs={7}>
          <Grid container justifyContent="flex-end">
            <Button
              disabled={false}
              color='secondary'
              type='submit'
              sx={{ minWidth: '155px', height: 40 }}
            >
              {'Guardar'}
            </Button>
          </Grid>
        </Grid>
      </Stack>
      <Alert
        icon={alert.icon}
        open={alert.open}
        handleConfirmation={handleConfirmation}
        title={alert.title}
        messages={alert.messages}
      />
    </Modal>
  )
}

export default DocumentsModal
