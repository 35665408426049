import { useCallback, useState } from "react";
import { Stack, Typography } from "@mui/material";
import { StepperController } from "../../../components/Stepper";
import { stepsReplace } from "../../../../constants/steps";
import { EmployeeForm } from "./EmployeeForm";
import { ProviderReplaceForm } from "./ProviderReplaceForm";
import {
  ENTITY_TYPES,
  ENTITY_TYPES_MAPPING,
  PROVIDER_ASSETS_TYPE,
} from "../../../../constants/entityTypes";
import { LoadingSpinner } from "../../../../components/LoadingSpinner";
import { contractType } from "../../../../constants/contractType";
import { validateGroupedDocuments } from "../../../helper/documents";
import { vacancyType } from "../../../../constants/vacancyType";
import {
  useCreateEmployee,
  useDeleteHardEmployee,
} from "../../../../services/hooks/Employee";
import { useUploadDocuments } from "../../../../services/hooks/Docs/S3/useUploadDocument";
import { Alert } from "../../../../components/Alert";
import { FinishReplace } from "./FinishReplace";
import { EMPLOYEE_TYPE_ENUM, EmployeeStatus } from "../../../../constants/status";
import { useCreateReplacement } from "../../../services/hooks/Request/index";
import { REQUEST_TYPE } from "../../../helper/request";
import {
  replaceDataAtom,
  replaceEmployeeDataAtom,
} from "../../../store/requestTracker/replaceRequest.store";
import { useAtom } from "jotai";

const initialAlertError = {
  open: false,
  title: "",
  messages: [],
  content: "",
};

const initialValue = {
  rut: "",
  name: "",
  lastname1: "",
  lastname2: "",
  phone: "",
  email: "",
};

export const ReplaceEmployee = ({ item, goBack }) => {
  const [step, setStep] = useState(1);
  const [replaceData, setReplaceData] = useAtom(replaceDataAtom);
  const [, setEmployeeData] = useAtom(replaceEmployeeDataAtom);
  const [replaceFinish, setReplaceFinish] = useState(false);
  const [currentTemplate, setCurrentTemplate] = useState(null);
  const [alertError, setAlertError] = useState(initialAlertError);
  const { mutate: createMutate, isLoading: isCreateLoading } =
    useCreateEmployee();
  const { mutate: mutateDeleteEmployee, isLoading: isLoadingDeletingEmployee } =
    useDeleteHardEmployee();
  const { mutate: mutateUploadDocument, isLoading: isLoadingUpload } =
    useUploadDocuments();
  const { mutate: mutateCreateReplacement, isLoading: isLoadingReplacement } =
    useCreateReplacement();

  const handlePrevStep = () => {
    setStep(1);
  };

  const handleSubmit = useCallback(
    async (employee) => {
      const data = {
        name: employee.name,
        lastname1: employee.lastname1,
        lastname2: employee.lastname2,
        numberRut: employee.rut,
        phone: employee.phone,
        email: employee.email,
        contractType: contractType.LABORAL,
        type: item.type,
        subcontract: false,
        providerId: item.providerId,
        fleet: employee.fleet,
        documentTemplateId: item.documentTemplateId,
      };

      const { groupedDataByDocumentType } = validateGroupedDocuments(
        currentTemplate.documentTypes,
        employee
      );
      createMutate(
        {
          data,
          employeeId: employee?.id,
        },
        {
          onSuccess: (createdEmployee) => {
            const groupedDataKeys = Object.keys(groupedDataByDocumentType);
            Object.entries(groupedDataByDocumentType).forEach(
              ([key, documentToUpload]) => {
                const beforeUpload = Object.assign({}, documentToUpload);
                beforeUpload["ownerId"] = createdEmployee?.id;
                beforeUpload["ownerType"] = vacancyType.EMPLOYEE;
                beforeUpload.metaData = JSON.stringify(beforeUpload.metaData);

                const isLastDocumentKey =
                  key === groupedDataKeys[groupedDataKeys.length - 1];
                mutateUploadDocument(beforeUpload, {
                  onSuccess: () => {
                    if (isLastDocumentKey) {
                      const replace = {
                        type: replaceData.isPermanent
                          ? REQUEST_TYPE.PERMANENT_REPLACEMENT
                          : REQUEST_TYPE.TEMPORARY_REPLACEMENT,
                        status: EmployeeStatus.PENDING,
                        requested: {
                          for: {
                            type: ENTITY_TYPES.EMPLOYEE,
                            subType: item.type,
                            plate: item.plate || "",
                            courierDni:
                              item.type === PROVIDER_ASSETS_TYPE.COURIER
                                ? item.numberRut
                                : item.courierDni,
                            peonetaDni:
                              item.type === PROVIDER_ASSETS_TYPE.PEONETA
                                ? item.numberRut
                                : item.peonetaDni,
                          },
                        },
                        metadata: {
                          entityToReplace: item,
                          replacement: createdEmployee,
                          setting: {
                            ...replaceData,
                            isNewEntity: true,
                          },
                        },
                      };
                      mutateCreateReplacement(replace, {
                        onSuccess: () => {
                          onFinish();
                        },
                        onError: () => {
                          mutateDeleteEmployee(
                            {
                              deleteEmployeeId: createdEmployee?.id,
                            },
                            {
                              onSuccess: () => {},
                              onError: () => {
                                setAlertError({
                                  ...alertError,
                                  open: true,
                                  title: "Error",
                                  messages: [
                                    "Oh no! se ha producido un error al eliminar el empleado",
                                  ],
                                });
                              },
                            }
                          );
                          setAlertError({
                            ...alertError,
                            open: true,
                            title: "Error",
                            messages: [
                              "Oh no! se ha producido un error al crear la solicitud de reemplazo",
                            ],
                          });
                        },
                      });
                    }
                  },
                  onError: () => {
                    mutateDeleteEmployee(
                      {
                        deleteEmployeeId: createdEmployee?.id,
                      },
                      {
                        onSuccess: () => {},
                        onError: () => {
                          setAlertError({
                            ...alertError,
                            open: true,
                            title: "Error",
                            messages: [
                              "Oh no! se ha producido un error al eliminar el empleado",
                            ],
                          });
                        },
                      }
                    );
                    setAlertError({
                      ...alertError,
                      open: true,
                      title: "Error",
                      messages: [
                        "Oh no! se ha producido un error al cargar los documentos",
                      ],
                    });
                  },
                });
              }
            );
          },
          onError: () => {
            setAlertError({
              ...alertError,
              open: true,
              title: "Error",
              messages: [
                `Oh no! se ha producido un error al crear ${
                  item.type === PROVIDER_ASSETS_TYPE.PEONETA
                    ? ENTITY_TYPES_MAPPING.peoneta
                    : ENTITY_TYPES_MAPPING.courier
                }`,
              ],
            });
          },
        }
      );
    },
    [currentTemplate, replaceData]
  );

  const handleConfirmation = () => {
    setAlertError({
      ...alertError,
      open: false,
    });
  };

  const submitStepOne = () => {
    setStep(2);
  };

  const onFinish = () => {
    setReplaceData({});
    setEmployeeData(initialValue);
    setReplaceFinish(true);
  };

  if (replaceFinish) {
    return (
      <Stack spacing={4}>
        <Typography variant="h4" align="center">
          {`Reemplazar ${ENTITY_TYPES_MAPPING[item.type]}`}
        </Typography>
        <FinishReplace
          entity={item.type === PROVIDER_ASSETS_TYPE.COURIER ? EMPLOYEE_TYPE_ENUM.COURIER : EMPLOYEE_TYPE_ENUM.PEONETA}
          goBack={goBack}
          goBackTitleButton={`Volver a Mis ${
            item.type === PROVIDER_ASSETS_TYPE.COURIER
              ? "Conductores"
              : "Peonetas"
          }`}
        />
      </Stack>
    );
  }

  return (
    <>
      <Stack spacing={4}>
        <Typography variant="h4" align="center">
          {`Reemplazar ${ENTITY_TYPES_MAPPING[item.type]}`}
        </Typography>
        <StepperController steps={stepsReplace} isCurrentStepActive={step} />
        {step === 1 ? (
          <ProviderReplaceForm
            type={item.type}
            goBack={goBack}
            onSubmit={submitStepOne}
          />
        ) : (
          <>
            <EmployeeForm
              item={item}
              goBack={handlePrevStep}
              onSubmit={handleSubmit}
              setTemplate={setCurrentTemplate}
              setReplaceFinish={setReplaceFinish}
            />
            {(isCreateLoading ||
              isLoadingUpload ||
              isLoadingReplacement ||
              isLoadingDeletingEmployee) && <LoadingSpinner open />}
          </>
        )}
      </Stack>
      <Alert
        icon
        open={alertError.open}
        handleConfirmation={handleConfirmation}
        title={alertError.title}
        messages={alertError.messages}
      />
    </>
  );
};
