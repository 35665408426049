import { useApi } from '../../api'
import { useMutation, useQueryClient } from 'react-query'
import { DOCUMENT_URI } from '../../../constants/api'

export function useApprobationDocuments() {
  const { axiosInstance } = useApi();
  const queryClient = useQueryClient();

  return useMutation((data) => {

    if(data.applyId){
      return axiosInstance.post('/approbation',
      {
        documents: data.documents,
        documentTemplateId: data.documentTemplateId,
        applyId: data.applyId,
      },
      {
        baseURL: DOCUMENT_URI,
      })
    }
    return axiosInstance.post('/approbation/provider',
    {
      documents: data.documents,
      documentTemplateId: data.documentTemplateId,
    },
    {
      baseURL: DOCUMENT_URI,
    })
    
  },
  {
    onSuccess: () => {
      queryClient.invalidateQueries(["documents"]);
      queryClient.invalidateQueries("getDocuments");
    },
  }
)
}