import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Box,
  Button,
  IconButton,
  Stack,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'
import {
  AddCircleOutlined,
  DeleteOutlined,
  EditOutlined,
} from '@mui/icons-material'

import { PaperWhite } from '../../../components/Papers'
import { SearchBar } from '../../../components/SearchBar'
import { Table } from '../../../components/Table'
import { LoadingSpinner } from '../../../components/LoadingSpinner'
import { Dialogs } from '../../../components/Dialogs'
import { AlertSnackBar } from '../../../components/Alerts'
import { getTypeDoc, getRequiredTo } from '../../../utils/formatValues'

import {
  useDeleteDocuments,
  useListDocuments,
} from '../../../services/hooks/Docs'
import { DownloadExportData } from '../../../components/Excel/Excel'

const headCells = [
  {
    id: 'description',
    label: 'Descripción',
    exportExcel: true
  },
  {
    id: 'nameStandard',
    label: 'Nombre estándar',
    exportExcel: true
  },
  {
    id: 'typeDoc',
    label: 'Tipo documento',
    exportExcel: true
  },
  {
    id: 'documentExpired',
    label: 'Documento expirado',
    exportExcel: true
  },
  {
    id: 'requiredTo',
    label: 'Requerido para',
    exportExcel: true
  },
  {
    id: 'actions',
    label: 'Acciones',
    disableSorting: true,
    exportExcel: false
  }
]

const initialAlertError = {
  open: false,
  message: '',
}

export default function Groups() {
  const navigate = useNavigate()

  const [openDialogDelete, setOpenDialogDelete] = useState(false)
  const [deleteIsLoading, setDeleteIsLoading] = useState(false)
  const [deleteId, setDeleteId] = useState('')
  const [alertError, setAlertError] = useState(initialAlertError)
  const [formatToExcel, setFormatToExcel] = useState({})
  const [filterFn, setFilterFn] = useState({
    fn: (items) => items,
  })

  const { data: document, isLoading, error } = useListDocuments()
  const { mutate } = useDeleteDocuments()

  useEffect(() => {
    if(Array.isArray(document) && document.length){
      const format = document.map(item => {
        const obj = {...item,
                      typeDoc: getTypeDoc(item.typeDoc),
                      documentExpired:  item.documentExpired?'SI':'NO',
                      requiredTo: getRequiredTo(item.requiredTo)
                    }

        return obj
      })

      setFormatToExcel(format)
    }

  }, [document])


  const handleCloseAlertError = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setAlertError(initialAlertError)
  }

  const handleCloseDialogDelete = () => {
    setOpenDialogDelete(false)
  }

  const handleDelete = async () => {
    setDeleteIsLoading(true)
    mutate(deleteId, {
      onSuccess: () => {
        setOpenDialogDelete(false)
        setDeleteIsLoading(false)
      },
      onError: () => {
        setOpenDialogDelete(false)
        setAlertError({
          open: true,
          message: 'Oh no! se ha producido un error al deletar un document',
        })
      },
    })
  }

  const handleSearch = (event) => {
    const { value } = event.target

    const newValue = value.toLowerCase()

    setFilterFn({
      fn: (items) => {
        if (value === '') return items
        return items.filter((documents) =>
          documents.description?.toLowerCase().includes(newValue)
        )
      },
    })
  }

  const {
    TableContainer,
    TableHead,
    TablePagination,
    recordsAfterPagingAndSorting,
  } = Table(document, headCells, filterFn)

  if (error) {
    return (
      <PaperWhite
        sx={{
          color: 'primary.main',
          height: '100%',
          display: 'grid',
          placeItems: 'center',
        }}
      >
        <Typography variant='h4'>No se encontraron datos!</Typography>
      </PaperWhite>
    )
  }

  if (isLoading) {
    return <LoadingSpinner open />
  }

  return (
    <Stack spacing={2}>
      <Typography variant='h4' align='center'>
        Documentos
      </Typography>

      <PaperWhite sx={{ p: 0 }}>
        <Stack
          direction='row'
          paddingY={2}
          paddingX={2}
          alignItems='center'
          justifyContent='space-between'
        >
          <Box sx={{display: 'flex'}}>
            <SearchBar
              id='table-searchbar'
              type='text'
              placeholder='buscar'
              onChange={handleSearch}
            />

            <DownloadExportData  
              data={formatToExcel} 
              columns={headCells} 
              nameFile="Documentos"
            />
          </Box>

          <Button
            variant='contained'
            startIcon={<AddCircleOutlined color='inherit' />}
            onClick={() => navigate('/Documents/create', {
                state: {
                  fromButton: true
                },
              })
            }
          >
            Crear Nuevo
          </Button>
        </Stack>

        <TableContainer>
          <TableHead />
          <TableBody>
            {recordsAfterPagingAndSorting().map(({ id, description, nameStandard, documentExpired, typeDoc, requiredTo }) => (
              <TableRow key={id}>
                <TableCell>
                  <Typography>{description}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{nameStandard}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{typeDoc}</Typography>
                </TableCell>
                <TableCell>
                  <Typography>{documentExpired?'SI':'NO'}</Typography>
                </TableCell>                  
                <TableCell>
                  <Typography>{requiredTo}</Typography>
                </TableCell>
                <TableCell sx={{ width: '20%' }}>
                  <Stack direction='row' spacing={2}>
                    <IconButton
                      onClick={() => navigate(`/Documents/update/${id}`, {
                        state: {
                          fromButton: true
                        },
                      })
                    }
                    >
                      <EditOutlined />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        setOpenDialogDelete(true)
                        setDeleteId(id)
                      }}
                    >
                      <DeleteOutlined />
                    </IconButton>
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TableContainer>
        <TablePagination />
      </PaperWhite>

      <Dialogs
        open={openDialogDelete}
        handleClose={handleCloseDialogDelete}
        handleConfirmation={handleDelete}
        isLoading={deleteIsLoading}
        title='¿Estás seguro de que quieres eliminar el documento?'
      />

      <AlertSnackBar
        open={alertError?.open}
        handleClose={handleCloseAlertError}
        message={alertError?.message}
      />
    </Stack>
  )
}
