import reportWebVitals from './reportWebVitals';
import React from 'react'
import ReactDOM from 'react-dom'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

import { CssBaseline, ThemeProvider } from '@mui/material'

import { Buffer } from 'buffer'

import App from './App'
import { CreateOfferProvider } from './contexts/createOfferContext'
import { queryClient } from './services/queryClient'

import { theme } from './styles/theme'

global.Buffer = global.Buffer || Buffer

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <CreateOfferProvider>
          <CssBaseline />
          <App />
          <ReactQueryDevtools />
        </CreateOfferProvider>
      </ThemeProvider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

reportWebVitals();
