import { memo, useEffect, useMemo, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import {
  Divider,
  Stack,
  Button,
  Grid,
  FormControlLabel,
  Switch,
  Box,
  Typography,
  Checkbox,
  Autocomplete,
} from "@mui/material";
import { EmployeeSection } from "./EmployeeSection";
import { DocumentsSection } from "../Documents/DocumentSection";
import { ArrowBackOutlined } from "@mui/icons-material";
import { Controller } from "react-hook-form";
import { useGetTemplate } from "../../../services/hooks/Docs/Templates/useGetTemplates";
import { LoadingSpinner } from "../../LoadingSpinner";
import { templateNames } from "../../../constants/templateNames";
import { Alert } from "../../Alert";
import { Input } from "../../Inputs";
import { EmployeeStatus } from "../../../constants/status";

const initialAlert = {
  open: false,
  title: "",
  messages: [],
};

export const FormCreate = memo(
  ({
    defaultValues,
    onSubmit,
    goBack,
    setTemplate,
    isEditing,
    currentApply,
    isReplacing,
  }) => {
    const { control, handleSubmit, getValues, reset, formState } = useForm({
      defaultValues,
      mode: "onChange",
    });
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const isPeoneta = useWatch({ control, name: "isPeoneta" });
    const selectedFleet = useWatch({ control, name: "fleet" });
    const { data: template, isLoading: isLoadingTemplate } =
      useGetTemplate(selectedTemplate);
    const [alertError, setAlertError] = useState(initialAlert);

    const handleConfirmation = () => {
      const persistValues = {
        id: getValues().id,
        rut: getValues().rut,
        name: getValues().name,
        lastname1: getValues().lastname1,
        lastname2: getValues().lastname2 ?? "",
        email: getValues().email,
        phone: getValues().phone,
        securityOpetation: false,
        fleet: selectedFleet,
        isPeoneta: !isPeoneta,
      };
      reset(persistValues);
      setAlertError({
        ...alertError,
        open: false,
      });
    };

    const handleClose = () => {
      setAlertError({
        ...alertError,
        open: false,
      });
    };

    useEffect(() => {
      if (isEditing || isReplacing) reset(defaultValues);
    }, [defaultValues]);

    useEffect(() => {
      setTemplate(template);
    }, [template]);

    useEffect(() => {
      const selectedVacancy = currentApply.offer.setting?.vacancies.find(
        (vacancy) => vacancy.vehicle.name === selectedFleet?.fleetType
      );
      const template = isPeoneta
        ? templateNames.peoneta
        : selectedVacancy?.vehicle?.courierTemplateId;

      setSelectedTemplate(template);
    }, [selectedFleet]);

    const getFleetsAvailableForLink = useMemo(() => {
      return currentApply?.fleetIds.filter((fleet) => {
        const linkKey = isPeoneta ? "peonetaDni" : "courierDni";
        return !fleet[linkKey] && fleet.status === EmployeeStatus.DRAFT;
      });
    }, [currentApply, isPeoneta]);

    return (
      <Stack spacing={2} component="form" onSubmit={handleSubmit(onSubmit)}>
        <Box gridColumn={{ xs: "span 12", lg: "span 12" }}>
          <Typography variant="h6" sx={{ fontWeight: 700 }}>
            Tipo de empleado y Vehículo
          </Typography>
        </Box>
        <Stack direction="row">
          <Controller
            control={control}
            name="isPeoneta"
            render={({ field: { value } }) => (
              <FormControlLabel
                sx={{ marginTop: "25px" }}
                control={
                  <Switch
                    disabled={isReplacing}
                    color="secondary"
                    checked={value}
                    onChange={() => {
                      setAlertError({
                        ...alertError,
                        open: true,
                        messages: [
                          "Si cambia el estado, tendrá que ingresar nuevamente los documentos",
                        ],
                      });
                    }}
                  />
                }
                label="Peoneta"
              />
            )}
          />
          <Controller
            control={control}
            defaultValue={null}
            name="fleet"
            rules={{
              validate: (value) => {
                return Boolean(value) ? null : "Debe seleccionar un vehículo";
              },
            }}
            render={({
              field: { value, onChange, ...rest },
              fieldState: { error },
            }) => (
              <Autocomplete
                sx={{ flexGrow: 1 }}
                size="small"
                value={value || null}
                noOptionsText="No hay vehículos disponibles"
                required
                disabled={isEditing || isReplacing}
                onChange={(e, newValue) => {
                  onChange(newValue);
                }}
                options={getFleetsAvailableForLink}
                getOptionLabel={(option) => (option ? `${option.plate}` : "")}
                isOptionEqualToValue={(option, value) =>
                  isEditing || isReplacing
                    ? value
                    : option && option?.plate === value?.plate
                }
                renderInput={(params) => (
                  <Input
                    {...params}
                    isRequired
                    label="Vehículo a vincular"
                    error={Boolean(error)}
                    helperText={error?.message}
                  />
                )}
                {...rest}
              />
            )}
          />
        </Stack>
        {selectedFleet?.plate && (
          <>
            <Divider sx={{ pt: 1, pb: 2 }} />
            <EmployeeSection
              control={control}
              getValue={getValues}
              isEditing={isEditing}
              isReplacing={isReplacing}
              title={isEditing ? 'Editar Empleado' : 'Datos del Empleado'}
            />
            {!isLoadingTemplate && template ? (
              <>
                <Divider sx={{ pt: 1, pb: 2 }} />
                <DocumentsSection
                  control={control}
                  documentStructure={template.documentTypes}
                />
              </>
            ) : (
              <LoadingSpinner open />
            )}
            <Box gridColumn={{ xs: "span 12", lg: "span 2" }}>
              <Controller
                control={control}
                name="securityOperation"
                rules={{
                  required: "(*) Campo obligatorio",
                }}
                render={({ field: { value, onChange, ...rest } }) => (
                  <Grid container>
                    <FormControlLabel
                      key="securityOperation"
                      control={
                        <Checkbox
                          checked={value}
                          onChange={(event) => onChange(event.target.checked)}
                          color="secondary"
                          required
                        />
                      }
                    />
                    <Typography
                      variant="body1"
                      sx={{
                        color: "rgba(0, 0, 0, 0.87)",
                        "& span": { color: "orange" },
                        mt: 1,
                      }}
                    >
                      Se compromete el proveedor que sus trabajadores cuenten
                      con chaleco reflectante y zapatos de seguridad
                    </Typography>
                  </Grid>
                )}
              />
            </Box>
          </>
        )}
        <Grid container sx={{ pt: 2 }}>
          <Grid item xs={5}>
            {goBack && (
              <Button
                variant="goBack"
                disableRipple
                startIcon={<ArrowBackOutlined />}
                onClick={goBack}
                sx={{ height: 40, fontSize: 12, alignSelf: "self-start" }}
              >
                {"Volver atrás"}
              </Button>
            )}
          </Grid>
          <Grid item xs={7}>
            <Grid container justifyContent="flex-end">
              <Button
                color="secondary"
                type="submit"
                sx={{ minWidth: "155px", height: 40 }}
                disabled={!formState.isValid}
              >
                {"Guardar"}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Alert
          icon
          open={alertError.open}
          handleConfirmation={handleConfirmation}
          buttonClose={true}
          handleClose={handleClose}
          title={alertError.title}
          messages={alertError.messages}
        />
      </Stack>
    );
  }
);
