import { useMutation, useQueryClient } from "react-query";
import { useApi } from "../../../api";
import { DOCUMENT_TYPE_URI } from "../../../../constants/api";

export function useDeleteDocuments() {
  const { axiosInstance } = useApi();
  const queryClient = useQueryClient();

  return useMutation(
    (id) =>
      axiosInstance.delete(`/${id}`, {
        baseURL: DOCUMENT_TYPE_URI,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("listDocuments");
        queryClient.invalidateQueries("document");
      },
    }
  );
}
