import { Modal as ModalMui, Box, IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'

export function Modal({ open, handleClose, children }) {
  return (
    <ModalMui
      open={open}
      onClose={handleClose}
      sx={{
        p: 5,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
      }}
      BackdropProps={{ style: { backgroundColor: 'rgba(74, 179, 232, 0.75)' } }}
    >
      <Box
        sx={{
          px: 3,
          py: 2,
          backgroundColor: '#FBFBFB',
          borderRadius: '15px',
          position: 'relative',
          maxHeight: '100%',
          overflow: 'auto',
        }}
      >
        <IconButton
          onClick={handleClose}
          sx={{ color: 'black', position: 'absolute', top: '0', right: '0' }}
        >
          <Close />
        </IconButton>
        {children}
      </Box>
    </ModalMui>
  )
}
