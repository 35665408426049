import { Stack, Typography } from "@mui/material";
import AlertModal from "../../../components/Modal/AlertModal";
import { useGetProviderRequestById } from "../../../services/hooks/Request";
import { LoadingSpinner } from "../../../../components/LoadingSpinner";
import { VACANCY_TYPE_ENUM, VACANCY_TYPE_MAPPING } from "../../../../constants/status";
import { PROVIDER_ASSETS_TYPE } from "../../../../constants/entityTypes";
import { requestTypes } from "../../../constants/requests";

export const SecurityApprovalModal = ({
  isOpen,
  onClose,
  onCancel,
  onAccept,
  action,
  requestId,
}) => {
  if (!isOpen) {
    return null;
  }
  const { data: request, isLoading, isSuccess, isError } = useGetProviderRequestById(requestId);
  const entityType = request?.requested?.for?.type === VACANCY_TYPE_ENUM.EMPLOYEE ? request?.requested?.for?.subType : VACANCY_TYPE_ENUM.FLEET;
  const requestType = requestTypes[request?.type];
  const actionText = action === "accept" ? "aprobar" : "rechazar";
  let requestEntityText = '';
  if (entityType === PROVIDER_ASSETS_TYPE.FLEET && request?.requested?.for?.plate) {
    requestEntityText = ` con patente ${request.requested.for.plate}?`;
  } 
  else if (entityType === PROVIDER_ASSETS_TYPE.COURIER && request?.requested?.for?.courierDni) {
    requestEntityText = ` con RUT ${request?.requested?.for.courierDni}`;
  }
  else if (entityType === PROVIDER_ASSETS_TYPE.PEONETA && request?.requested?.for?.peonetaDni) {
    requestEntityText = ` con RUT ${request.requested.for.courierDni}`;
  }

  const handleAccept = () => {
    onAccept({ requestId, type: request.type })
  }
  
  const message = `¿Confirma que desea ${actionText} la solicitud de ${requestType} para el ${VACANCY_TYPE_MAPPING[entityType]}${requestEntityText}?`
  return (
    <AlertModal
      isOpen={isOpen}
      onClose={onClose}
      message={""}
      onCancel={onCancel}
      onAccept={handleAccept}
    >
      {
        isLoading ?
          <LoadingSpinner open={isLoading} />
        : !isSuccess || isError ? 
          <Typography variant="h6">Hubo un error al cargar la solicitud.</Typography>
        :
          <Stack
            mb={2}
            container
            spacing={2}
            width={500}
            style={{ placeItems: 'center' }}
          >
            <Typography
              align='center'
              style={{
              fontWeight: 700,
              width: 440,
              height: 60
            }}>
                {message}
            </Typography>
            <Typography align='center'>
              Esta acción no se puede deshacer
            </Typography>
          </Stack>
      }
    </AlertModal>
  )
};