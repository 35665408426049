import React, { useEffect, useState } from "react";
import {
  IconButton,
  Stack,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Tabs,
  Tab,
  Button,
} from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";

import { MessageBox } from "../../../components/common/MessageBox";
import { SearchBar } from "../../../components/SearchBar";
import { Table } from "../../components/Table";
import { LoadingSpinner } from "../../../components/LoadingSpinner";

import { dynamicHeadCells, getNestedValues } from "./headCells";

import { useListInterested } from "../../../services/hooks/Interested/useListInterested";
import { DocumentList } from "../../components/Documents/DocumentList";
import { EmployeeStatus } from "../../../constants/status";
import { ChipStatus } from "../../components/Chip";
import { useURLfilters } from "../../hooks/filters.hook";

const defaultFilter = {
  type: "employee",
  subType: "courier",
  status: "pending",
  page: 1,
  limit: 10,
};

export default function ListApply() {
  const [currentHeadCells, setCurrentHeadCells] = useState([]);
  const { filters, setFilters, getURLValue, cleanFilters } = useURLfilters(
    defaultFilter,
    {
      persistParamOnClear: ["type", "subType", "status"],
    }
  );
  const { data, isLoading, error } = useListInterested(filters);
  const [dataRecord, setDataRecord] = useState();
  const [isRenderedForm, setIsRenderedForm] = useState(false);

  const { TableContainer, setPage } = Table(
    currentHeadCells,
    {
      ...data?.metadata,
      page: getURLValue("page", 1),
      limit: getURLValue("limit", 10),
    },
    onChangeFilters
  );

  const handleTabChange = (tab) => {
    const newFilter = {
      ...filters,
      type: tab !== "fleet" ? "employee" : tab,
      search: undefined,
      page: 1,
    };
    setPage(1);
    if (tab === "fleet") {
      newFilter.subType = undefined;
    } else {
      newFilter.subType = tab;
    }

    setFilters(newFilter);
    setPage(0);
  };

  const handleSearch = (event) => {
    const { value } = event.target;

    const searchInput = value.toLowerCase();
    if (searchInput !== undefined) {
      setFilters({ page: 1, search: searchInput });
      setPage(0);
    }
  };

  function onChangeFilters(newFilters = {}) {
    setFilters(newFilters);
  }

  const handleGoBack = () => {
    setIsRenderedForm(false);
  };

  useEffect(() => {
    const type = getURLValue("subType") || getURLValue("type");
    setCurrentHeadCells(dynamicHeadCells[type]);
  }, [filters]);

  if (isRenderedForm) {
    return <DocumentList goBack={handleGoBack} dataRecord={dataRecord} />;
  }

  if (error) return <MessageBox>Ups, ocurrio un error!</MessageBox>;

  if (isLoading) return <LoadingSpinner open />;

  const TabsAddon = () => (
    <Stack
      direction="row"
      paddingY={2}
      paddingX={2}
      alignItems="center"
      justifyContent="space-between"
    >
      <Tabs
        value={getURLValue("subType", "courier")}
        onChange={(e, searchInput) => handleTabChange(searchInput)}
      >
        <Tab label="Conductor" value="courier" />
        <Tab label="Proveedor" value="provider" />
        <Tab label="Peoneta" value="peoneta" />
        <Tab label="Vehículo" value="fleet" />
      </Tabs>
      <SearchBar
        id="table-searchbar"
        type="text"
        placeholder="Buscar"
        onSearch={handleSearch}
        search={getURLValue("search", "")}
      />
      <Button
        variant="contained"
        onClick={cleanFilters}
        size="medium"
        sx={{ marginLeft: 3 }}
      >
        Limpiar filtros
      </Button>
    </Stack>
  );

  return (
    <Stack spacing={4}>
      <Typography variant="h4" align="center">
        Lista de postulaciones
      </Typography>
      <TableContainer addons={[TabsAddon]} withPagination>
        <TableBody>
          {data?.data &&
            data?.data.map((record) => {
              return (
                <TableRow key={record?.id}>
                  {currentHeadCells?.map((headCell) => {
                    return (
                      <>
                        {headCell.id === "actions" ? (
                          <TableCell>
                            <Stack direction="row" spacing={1.5}>
                              <IconButton
                                onClick={() => {
                                  setDataRecord(record);
                                  setIsRenderedForm(true);
                                }}
                              >
                                <InfoOutlined />
                              </IconButton>
                            </Stack>
                          </TableCell>
                        ) : (
                          <>
                            {["status", "vacancy.status"].includes(
                              headCell.id
                            ) ? (
                              <TableCell key={headCell.id}>
                                <ChipStatus
                                  label={
                                    EmployeeStatus[
                                      getNestedValues(headCell.id, record)
                                    ]
                                  }
                                  setcolor={getNestedValues(
                                    headCell.id,
                                    record
                                  )}
                                />
                              </TableCell>
                            ) : (
                              <TableCell key={headCell.id}>
                                <Typography>
                                  {getNestedValues(headCell.id, record)}
                                </Typography>
                              </TableCell>
                            )}
                          </>
                        )}
                      </>
                    );
                  })}
                </TableRow>
              );
            })}
        </TableBody>
      </TableContainer>
    </Stack>
  );
}
