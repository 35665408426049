import { useQuery } from "react-query";
import { useApi } from "../../api";
import { APPLY_URI, EMPLOYEE_URI } from "../../../constants/api";
import { buildUrlWithQueryParams } from "../../../v2/helper/converters";

export async function getListInterested(filters) {
  const { axiosInstance } = useApi();

  let path = new URL(`${APPLY_URI}/with-vacancies`);

  if (filters.subType === "provider") {
    path = new URL(`${EMPLOYEE_URI}/backoffice`);
    filters.type = filters.subType;
  }

  const url = buildUrlWithQueryParams(path, filters);
  const response = await axiosInstance.get(url);

  const { data, metadata } = response.data;

  return { data, metadata };
}

export function useListInterested(filters) {
  return useQuery(
    ["listInterested", filters],
    () => getListInterested(filters),
    {
      staleTime: 1000 * 60,
    }
  );
}
