import { translatedStatusRequests } from "../../constants/status";
import { objectToTypeOptionList } from "../helper/converters";

export const requestTypesValues = {
  REPLACEMENT: "REPLACEMENT",
  TEMPORARY_REPLACEMENT: "TEMPORARY_REPLACEMENT",
  PERMANENT_REPLACEMENT: "PERMANENT_REPLACEMENT",
  UNLOCK: "UNLOCK",
  REPLACE_DOCUMENT: "REPLACE_DOCUMENT",
};

export const requestTypes = {
  [requestTypesValues.REPLACEMENT]: "Reemplazo",
  [requestTypesValues.TEMPORARY_REPLACEMENT]: "Reemplazo Temporal",
  [requestTypesValues.PERMANENT_REPLACEMENT]: "Reemplazo Permanente",
  [requestTypesValues.UNLOCK]: "Desbloqueo",
  [requestTypesValues.REPLACE_DOCUMENT]: "Reemplazo de documentación",
}; 

export const requestTypesOptions = objectToTypeOptionList(requestTypes);

export const requestStatusOptions = objectToTypeOptionList(translatedStatusRequests);
