import { useMutation, useQueryClient } from 'react-query'
import { useApi } from '../../api'
import { EMPLOYEE_URI } from '../../../constants/api'

export function useCreateProvider() {
  const { axiosInstance } = useApi()
  const queryClient = useQueryClient()

  return useMutation(async ({data, providerId = null}) => {
    if (providerId) {
      const { data: createdProvider } = await axiosInstance.put(`/${providerId}`, data, {
        baseURL: EMPLOYEE_URI,
      })
      return { ...createdProvider, id: createdProvider?.id ?? createdProvider._id };
    }
    const { data: createdProvider } = await axiosInstance.post('/', data, {
      baseURL: EMPLOYEE_URI,
    })
    return { ...createdProvider, id: createdProvider?.id ?? createdProvider._id }
  }, {
    onSuccess: () => {
      setTimeout(() => {
        queryClient.invalidateQueries('listProviders')
        queryClient.invalidateQueries(['provider'])
      }, 1000)
    },
  })
}
