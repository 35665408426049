import { useMutation, useQueryClient } from 'react-query'
import { useApi } from '../../api'
import { FLEET_URI, APPLY_URI, EMPLOYEE_URI } from '../../../constants/api'

const { axiosInstance } = useApi();

const deleteFleetHard = async ({ idToDelete, linkedEmployees, applyId }) => {
  await axiosInstance.delete(`${FLEET_URI}/fleet/hard/${idToDelete}`);
  Boolean(linkedEmployees?.length) &&
    await Promise.all(linkedEmployees.map((employee) => axiosInstance.delete(`${EMPLOYEE_URI}/hard/${employee.id}`)));

  return await axiosInstance.delete(`/${applyId}/vacancy/fleet/${idToDelete}`, {
    baseURL: APPLY_URI,
  });
}

export function useDeleteHardFleet() {
  const queryClient = useQueryClient();
  return useMutation(deleteFleetHard, {
    onSuccess: () => {
      setTimeout(() => {
        queryClient.refetchQueries('getFleetsByProviderAndOffer');
        queryClient.invalidateQueries('requestsById');
      }, 500);
    },
  });
}