import { useQuery } from "react-query";
import { useApi } from "../../api";
import { OFFER_URI } from "../../../constants/api";

export async function getOfferDetails(offerId) {
  const { axiosInstance } = useApi();

  if (offerId) {
    try {
      const response = await axiosInstance.get(`/${offerId}`, {
        baseURL: OFFER_URI,
      });

      return response.data;
    } catch (error) {
      return null;
    }
  } else {
    return null;
  }
}

export function useOfferDetails(offerId) {
  return useQuery(["offerDetails", offerId], () => getOfferDetails(offerId), {
    staleTime: 1000 * 60 * 10,
  });
}
