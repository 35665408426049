import {
  Avatar,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { getEnabledVehicles } from "../../v2/constants/vehicle-config";
import styles from "./style.module.css";

export default function RadioButtonVehicle({ value, handleChange }) {
  return (
    <FormControl>
      <FormLabel>
        <Typography
          variant="body1"
          sx={{ color: "rgba(0, 0, 0, 0.87)", "& span": { color: "orange" } }}
        >
          Icono <span>*</span>
        </Typography>
      </FormLabel>
      <RadioGroup value={value} onChange={handleChange}>
        <Stack direction="row" className={styles.vehicleTypeSelector}>
          {getEnabledVehicles().map((vehicle) => (
            <Stack>
              <Avatar
                src={vehicle.icon}
                width={100}
                sx={{
                  width: 90,
                  height: 90,
                  backgroundColor: "lightDay",
                  border: "2px solid #FFFFFF",
                }}
              />
              <FormControlLabel
                value={vehicle.value}
                control={<Radio color="secondary" />}
                label={vehicle.label}
              />
            </Stack>
          ))}
        </Stack>
      </RadioGroup>
    </FormControl>
  );
}
