export const rutPattern = () => /^(\d{0,2})\.?(\d{3})\.?(\d{3})-?(\d|k)$/gi
export const companyRutPattern = () =>
  /^([4-9]{1}[0-9]{1})\.?(\d{3})\.?(\d{3})-?(\d|k)$/gi

const fakeRutPattern = () => /^(\d)\1?\.?(\1{3})\.?(\1{3})-?(\d|k)?$/gi

export const isRutValid = (rut) => rutPattern().test(rut)
export const isFakeRut = (rut) => fakeRutPattern().test(rut)
export const isCompanyRut = (rut) => companyRutPattern().test(rut)

export const cleanRut = (rut) =>
  isRutValid(rut) ? rut.replace(/^0+|[^0-9kK]+/g, '').toUpperCase() : ''

export const getRutDigits = (rut) => cleanRut(rut).slice(0, -1)
export const getRutVerifier = (rut) => cleanRut(rut).slice(-1)

export const deconstructRut = (rut) => ({
  digits: getRutDigits(rut),
  verifier: getRutVerifier(rut),
})

export const elevenModule = (num) => (11 - (num % 11)).toString()

export const getVerificationCode = (digits) => {
  let sum = 0
  const secuence = [2, 3, 4, 5, 6, 7, 2, 3]

  // eslint-disable-next-line no-plusplus
  for (let i = digits.length - 1; i >= 0; i--) {
    const d = digits.charAt(i)
    sum += Number(d) * secuence[digits.length - (i + 1)]
  }

  const rest = elevenModule(sum)

  // eslint-disable-next-line no-nested-ternary
  return rest === '11' ? '0' : rest === '10' ? 'k' : rest
}

export const formatRut = (rut) => {
  if (!isRutValid(rut)) return rut

  const rutCleaned = cleanRut(rut)

  let result = `${rutCleaned.slice(-4, -1)}-${rutCleaned.substr(
    rut.length - 1
  )}`

  for (let i = 4; i < rutCleaned.length; i += 3) {
    result = `${rutCleaned.slice(-3 - i, -i)}.${result}`
  }

  return result
}

export const formatRutOnlyScript = (rut) => {
  if (!isRutValid(rut)) return rut

  const rutCleaned = cleanRut(rut)

  let result = `${rutCleaned.slice(-4, -1)}-${rutCleaned.substr(
    rut.length - 1
  )}`

  for (let i = 4; i < rutCleaned.length; i += 3) {
    result = rutCleaned.slice(-3 - i, -i) + result
  }

  return result
}

export const validateRut = (rut, noFakeRut = true) => {
  if (typeof rut !== 'string') return false
  if (!isRutValid(rut)) return false
  if (noFakeRut && isFakeRut(rut)) return false
  return (
    getRutVerifier(rut).toLowerCase() ===
    getVerificationCode(getRutDigits(rut).toLowerCase())
  )
}

export const validateCompanyRut = (rut, noFakeRut = true) => {
  if (typeof rut !== 'string') return false
  if (!isRutValid(rut)) return false
  if (!isCompanyRut(rut)) return false
  if (noFakeRut && isFakeRut(rut)) return false
  return (
    String(getRutVerifier(rut)).toLowerCase() ===
    String(getVerificationCode(getRutDigits(rut))).toLowerCase()
  )
}
