import { useApi } from '../../api'
import { useQuery } from 'react-query'
import { EMPLOYEE_URI } from '../../../constants/api'

export async function getEmployee(employeeId) {
  try {
    const { axiosInstance } = useApi()
    const response = await axiosInstance.get(`/${employeeId}`, {
      baseURL: EMPLOYEE_URI,
    })

    return response.data
  } catch (error) {
    return null
  }
}

export function useGetEmployee(employeeId) {
  return useQuery(['getEmployee', employeeId], () => getEmployee(employeeId), {
    staleTime: 1000 * 60 * 10,
  })
}