import { useQuery } from "react-query";
import { FLEET_URI } from "../../../../constants/api";
import { useApi } from "../../../../services/api";
import { buildUrlWithQueryParams } from "../../../helper/converters";

export async function getFleetList(providerId, filters) {
  const { axiosInstance } = useApi();
  const url = buildUrlWithQueryParams(`${FLEET_URI}/fleet/request/${providerId}`, filters);

  const response = await axiosInstance.get(url);
  const { data, metadata } = response.data;

  return { data, metadata };
}

export function useFleetListRequest(providerId, filters) {
  return useQuery(["fleetListRequest", filters], () => getFleetList(providerId, filters), {
    staleTime: 1000 * 60 * 10,
    retry: 0,
    enabled: false
  })
}