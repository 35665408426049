export const APPLY_PROVIDER_LIST = 'tms.apply-provider-list'

export const APPLY_PROVIDER_ADD = 'tms.apply-provider-add'

export const APPLY_PROVIDER_UPDATE = 'tms.apply-provider-update'

export const OFFER_LIST = 'tms.offer-list'

export const OFFER_ADD = 'tms.offer-add'

export const OFFER_READ = 'tms.offer-read'

export const OFFER_UPDATE = 'tms.offer-update'

export const OFFER_DEL = 'tms.offer-del'

export const OFFER_CLOSE = 'tms.offer-close'

export const APPLIES_LIST = 'tms.applies-list'

export const APPLIES_READ = 'tms.applies-read'

export const APPLIES_APPROVAL = 'tms.applies-approval'

export const APPLIES_DISAPPROVE = 'tms.applies-disapprove'

export const APPLIES_BLOCKED = 'tms.applies-blocked'

export const INTERACTION_ADD = 'tms.interaction-add'

export const INTERACTION_UPDATE = 'tms.interaction-update'

export const INTERACTION_DEL = 'tms.interaction-del'

export const GROUPS_LIST = 'tms.groups-list'

export const GROUPS_ADD = 'tms.groups-add'

export const GROUPS_READ = 'tms.groups-read'
 
export const GROUPS_UPDATE = 'tms.groups-update'

export const GROUPS_DEL = 'tms.groups-del'

export const BRANDS_LIST = 'tms.brands-list'

export const BRANDS_ADD = 'tms.brands-add'

export const BRANDS_READ = 'tms.brands-read'

export const BRANDS_UPDATE = 'tms.brands-update'

export const BRANDS_DEL = 'tms.brands-del'

export const MODELS_LIST = 'tms.models-list'

export const MODELS_ADD = 'tms.models-add'

export const MODELS_READ = 'tms.models-read'

export const MODELS_UPDATE = 'tms.models-update'

export const MODELS_DEL = 'tms.models-del'

export const FEATURES_LIST = 'tms.features-list'

export const FEATURES_ADD = 'tms.features-add'

export const FEATURES_READ = 'tms.features-read'

export const FEATURES_UPDATE = 'tms.features-update'

export const FEATURES_DEL = 'tms.features-del'

export const TYPEVEHICLE_LIST = 'tms.typevehicle-list'

export const TYPEVEHICLE_ADD = 'tms.typevehicle-add'

export const TYPEVEHICLE_READ = 'tms.typevehicle-read'

export const TYPEVEHICLE_UPDATE = 'tms.typevehicle-update'

export const TYPEVEHICLE_DEL = 'tms.typevehicle-del'

export const DOCUMENTS_LIST = 'tms.documents-list'

export const DOCUMENTS_ADD = 'tms.documents-add'

export const DOCUMENTS_READ = 'tms.documents-read'

export const DOCUMENTS_UPDATE = 'tms.documents-update'

export const DOCUMENTS_DEL = 'tms.documents-del'

export const TEMPLATES_LIST = 'tms.templates-list'

export const TEMPLATES_ADD = 'tms.templates-add'

export const TEMPLATES_READ = 'tms.templates-read'

export const TEMPLATES_UPDATE = 'tms.templates-update'

export const TEMPLATES_DEL = 'tms.templates-del'

export const PROVIDER_LIST = 'tms.provider-list'

export const PROVIDER_ADD = 'tms.provider-add'

export const PROVIDER_UPDATE = 'tms.provider-update'

export const PROVIDER_DEL = 'tms.provider-del'

export const PROVIDER_BLOCKED = 'tms.provider-blk'

export const PROVIDER_READ = 'tms.provider-read'

export const BASES_LIST = 'tms.bases-list'

export const BASES_ADD = 'tms.bases-add'

export const BASES_UPDATE = 'tms.bases-update'

export const BASES_DEL = 'tms.bases-del'

export const BASES_READ = 'tms.bases-read'

export const STEPS_LIST = 'tms.steps-list'

export const STEPS_ADD = 'tms.steps-add'

export const STEPS_UPDATE = 'tms.steps-update'

export const STEPS_DEL = 'tms.steps-del'

export const STEPS_READ = 'tms.steps-read'

export const STATUS_LIST = 'tms.status-list'

export const STATUS_ADD = 'tms.status-add'

export const STATUS_UPDATE = 'tms.status-update'

export const STATUS_DEL = 'tms.status-del'

export const STATUS_READ = 'tms.status-read'

export const STANDARD_ANSWER_LIST = 'tms.standard-answer-list'

export const STANDARD_ANSWER_ADD = 'tms.standard-answer-add'

export const STANDARD_ANSWER_UPDATE = 'tms.standard-answer-update'

export const STANDARD_ANSWER_DEL = 'tms.standard-answer-del'

export const STANDARD_ANSWER_READ = 'tms.standard-answer-read'

export const COURIERS_LIST = 'tms.courier-list'

export const COURIERS_ADD = 'tms.courier-add'

export const COURIERS_READ = 'tms.courier-read'

export const COURIERS_UPDATE = 'tms.courier-update' 

export const COURIERS_DEL = 'tms.courier-del'

export const COURIERS_BLOCKED = 'tms.courier-blk'

export const FLEETS_LIST = 'tms.fleets-list'

export const FLEETS_ADD = 'tms.fleets-add'

export const FLEETS_READ = 'tms.fleets-read'

export const FLEETS_UPDATE = 'tms.fleets-update' 

export const FLEETS_DEL = 'tms.fleets-del'

export const FLEETS_BLOCKED = 'tms.fleets-blk'

export const SECURITY_LIST = 'tms.security-module'

export const APPLIES_LIST_SECURITY = 'tms.applies-list-security'

export const MY_COURIERS_LIST = 'tms.my-couriers-list'

export const MY_COURIERS_DETAIL = 'tms.my-couriers-detail'

export const MY_DATA_PROVIDER = 'tms.my-data-provider'

export const MY_FLEETS_LIST = 'tms.my-fleets-list'

export const MY_FLEETS_DETAIL = 'tms.my-fleets-detail'

export const APPLY_PROVIDER_DETAIL = 'tms.apply-provider-detail'

export const APPLY_PROVIDER_APPROVE = 'tms.apply-provider-approve'

export const APPLY_PROVIDER_READ_OFFERS = 'tms.apply-provider-read-offers'

export const APPLY_PROVIDER_READ_AUTO_VALIDATIONS = 'tms.apply-provider-read-auto-validations'

export const APPLY_PROVIDER_READ_INTERACTIONS = 'tms.apply-provider-read-interactions'

export const REPORTS_APPLIES_OFFER = /* 'tms.report-applies-offer' */ FLEETS_LIST

export const REPORTS_DOCUMENTS_EXPIRED = /* 'tms.report-documents-expired' */ FLEETS_LIST
