import { useMutation } from "react-query";
import { useApi } from "../../../../../services/api";
import { REQUEST_TRACKER_URI } from "../../../../../constants/api";

export const useUpdateReplacementStatus = () => {
    const { axiosInstance } = useApi();
    return useMutation(({ requestId, status }) => axiosInstance.put(
      `/replacement/${requestId}`,
      { status },
      {
        baseURL: REQUEST_TRACKER_URI,
      }
    ));
}
