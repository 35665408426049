import { useQuery } from 'react-query'
// import { useApi } from '../../api'

export async function getListApplicationsByOffer(id) {
  /* THIS NEED TO BE CHANGED BY THE NEW APPLIES FLOW
  const { axiosInstance } = useApi()

  const response = await axiosInstance.post('/applications/?action=list', {
    offersId: id,
  })

  const { data } = response.data
  */
  const data = { success: true, data: [], id }
  return data
}

export function useListApplicationsByOffer(id) {
  return useQuery('listApplicationsByOffer', () =>
    getListApplicationsByOffer(id)
  )
}
