import { mainTheme, MainStyles } from "./themes/main.theme";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { TabIndentationPlugin } from "@lexical/react/LexicalTabIndentationPlugin";
import { TableCellNode, TableNode, TableRowNode } from "@lexical/table";
import { ListItemNode, ListNode } from "@lexical/list";
import { CodeHighlightNode, CodeNode } from "@lexical/code";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { TRANSFORMERS } from "@lexical/markdown";

import ListMaxIndentLevelPlugin from "./plugins/ListMaxIndentLevel.plugin";
import AutoLinkPlugin from "./plugins/AutoLink.plugin";
import ToolbarPlugin from "./plugins/ToolbarPlugin";
import { isJsonString } from "./helpers/validateJSON";

function Placeholder({ placeholder }) {
  return <div className="editorPlaceholder">{placeholder}</div>;
}

const editorConfig = {
  theme: mainTheme,
  onError(error) {
    throw error;
  },
  nodes: [
    HeadingNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    CodeNode,
    CodeHighlightNode,
    TableNode,
    TableCellNode,
    TableRowNode,
    AutoLinkNode,
    LinkNode
  ]
};

export function Editor({ customConfig, customStyles, placeholder, onChange, initialState, editable }) {
  const config = customConfig || editorConfig;
  const Styles = customStyles || MainStyles;

  const editorState = isJsonString(initialState) ? initialState : null

  return (
    <>
      <LexicalComposer initialConfig={{ ...config, editorState, editable }}>
        <div className={editable && "editorContainer"}>
         {editable && <ToolbarPlugin />}
          <div className="editorInner">
            <RichTextPlugin
              contentEditable={<ContentEditable className="editorInput" />}
              placeholder={<Placeholder placeholder={editable && placeholder} />}
              ErrorBoundary={LexicalErrorBoundary}
            />
            <OnChangePlugin onChange={editorState => {
              onChange(JSON.stringify(editorState))
            }} />
            <TabIndentationPlugin />
            <HistoryPlugin />
            <AutoFocusPlugin />
            <ListPlugin />
            <LinkPlugin />
            <AutoLinkPlugin />
            <ListMaxIndentLevelPlugin maxDepth={7} />
            <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
          </div>
        </div>
      </LexicalComposer>
      <Styles />
    </>
  );
}

Editor.defaultProps = { 
  customConfig: null,
  customStyles: null,
  placeholder: 'Escriba aqui',
  onChange: () => {},
  initialState: {},
  editable: true,
}