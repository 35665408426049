import { Button, Stack, Typography } from "@mui/material";
import { Modal } from "../../../components/Modals/Modal";
import { useGetProviderRequestById } from "../../services/hooks/Request/index";
import { RequestTypeInfo } from "../../pages/Requests/components/RequestTypeInfo";
import { ChipStatus } from "../Chip";
import { RequestStatus } from "../../../constants/status";
import { LoadingSpinner } from "../../../components/LoadingSpinner";
import { RequestInfoHeader } from "../../pages/Requests/components/RequestInfoHeader";
import { useMemo } from "react";

export const RequestInfoModal = ({ open, requestId, onClose, onAccept }) => {
  if (!requestId) {
    return null;
  }
  const {
    data: request,
    isLoading,
    isSuccess,
    isError,
  } = useGetProviderRequestById(requestId);

  const renderInfoBody = useMemo(() => {
    if (isSuccess && request?.type) {
      return (
        <>
          <Stack
            margin={2}
            direction="row"
            spacing={2}
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography variant="h6">Detalles de la solicitud</Typography>
            <ChipStatus
              label={RequestStatus[request?.status]}
              setcolor={request?.status}
            />
          </Stack>
          <RequestInfoHeader request={request} />
          <RequestTypeInfo request={request} onClose={onClose} />
        </>
      );
    } else if (isError || !request?.type) {
      return (
        <Typography variant="h6">
          Hubo un error al cargar la solicitud.
        </Typography>
      );
    }
  }, [isSuccess, isError, request]);

  if (isLoading) return <LoadingSpinner open={isLoading} />;
  return (
    <Modal open={open} handleClose={onClose}>
      {renderInfoBody}
      <Stack direction="row" sx={{ justifyContent: "center" }}>
        <Button
          color="secondary"
          sx={{ minWidth: "155px", height: 40, marginTop: 4 }}
          onClick={onAccept}
        >
          Aceptar
        </Button>
      </Stack>
    </Modal>
  );
};
