import { Stack, Typography } from "@mui/material";
import { requestTypes } from "../../../constants/requests";
import { VACANCY_TYPE_ENUM, VACANCY_TYPE_MAPPING } from "../../../../constants/status";
import { formatDate } from "../../../../utils/formatValues";

export const RequestInfoHeader = ({ request }) => {
  const { type } = request;
  if (!type) return null; 
  const entityType = request?.requested?.for?.type === VACANCY_TYPE_ENUM.EMPLOYEE ? request?.requested?.for?.subType : VACANCY_TYPE_ENUM.FLEET;
  const requestDate = request.created?.at ? formatDate(request?.created?.at, 'DD-MM-YYYY') : '-';
  const requestResponse = request?.updated?.at ? formatDate(request?.updated?.at, 'DD-MM-YYYY') : '-';
  return (
    <Stack marginBottom={3} direction='row' spacing={2} flexGrow={1}>
      <Stack direction='column' spacing={2} flexGrow={2} paddingTop={2}>
        <Stack direction='row' spacing={2} flexGrow={1}>
          <Typography sx={{ fontWeight: 700 }}>
            Tipo:
          </Typography>
          <Typography>{requestTypes[type]}</Typography>
        </Stack>
        <Stack direction='row' spacing={2} flexGrow={1}>
          <Typography sx={{ fontWeight: 700 }}>
            Categoría:
          </Typography>
          <Typography>{VACANCY_TYPE_MAPPING[entityType]}</Typography>
        </Stack>
      </Stack>
      <Stack direction='column' spacing={2} flexGrow={2} paddingTop={2}>
        <Stack direction='row' spacing={2} flexGrow={1}>
          <Typography sx={{ fontWeight: 700 }}>
            Fecha de solicitud:
          </Typography>
          <Typography>{requestDate}</Typography>
        </Stack>
        <Stack direction='row' spacing={2} flexGrow={1}>
          <Typography sx={{ fontWeight: 700 }}>
            Fecha de respuesta:
          </Typography>
          <Typography>{requestResponse}</Typography>
        </Stack>
      </Stack>
    </Stack>
  )
}
