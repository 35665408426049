/* eslint-disable no-unused-vars */
import { AppBar, Box, Toolbar, IconButton, Badge, Avatar, Tooltip } from '@mui/material'
import {
  SearchOutlined,
  NotificationsOutlined,
  DashboardOutlined,
} from '@mui/icons-material'
import MenuIcon from '@mui/icons-material/Menu'
import { useSession } from '@blue-express/bx-lib-universal-frontend'
import Cookies from 'js-cookie'
import avatarImg from '../../assets/images/avatar.png'
import { SECURITY_LIST } from '../../constants/functionalities'
import { fetchSessionFunctionalities, checkPermission } from '../../utils/permissions'
import { useNotificationsSecurity } from '../../services/hooks/Security'


export function Header({ drawerWidth, handleDrawerToggle }) {
  const { logout, getSession } = useSession()

  const session = getSession()
  const functionalities = fetchSessionFunctionalities(session)

  const { data: dataCount, isLoading, error } = useNotificationsSecurity(checkPermission(functionalities, [SECURITY_LIST]))

  return (
    <AppBar
      position='static'
      sx={{
        gridArea: 'header',
        background:"#0032A0"
      }}
    >
      <Toolbar>
        <IconButton
          color='inherit'
          aria-label='open drawer'
          edge='start'
          onClick={handleDrawerToggle}
          sx={{ display: { md: 'none' } }}
        >
          <MenuIcon />
        </IconButton>
        <Box sx={{ marginLeft: 'auto' }}>
          <Tooltip title="Buscar" arrow>
            <IconButton size='large' aria-label='search' color='inherit'>
              <SearchOutlined />
            </IconButton>
          </Tooltip>
          <Tooltip title="Notificaciones" arrow>
            <IconButton size='large' aria-label='notifications' color='inherit'>
              <Badge badgeContent={(isLoading || error) ? 0 : dataCount.count} color='error'>
                <NotificationsOutlined />
              </Badge>
            </IconButton>
          </Tooltip>
          <Tooltip title="Dashboard" arrow>
            <IconButton size='large' aria-label='dashboard' color='inherit'>
              <DashboardOutlined />
            </IconButton>
          </Tooltip>
          <Tooltip title="Salir" arrow>
            <IconButton
              size='large'
              aria-label='avatar'
              color='inherit'
              onClick={() => {
                Cookies.remove('__sessionSSO', { path: '/' })
                Cookies.remove('__sessionSSOTKN', { path: '/' })
                Cookies.remove('__sessionUser', { path: '/' })
                logout()
              }}
            >
              <Avatar alt='Blue' src={avatarImg} />
            </IconButton>
          </Tooltip>
        </Box>
      </Toolbar>
    </AppBar>
  )
}
