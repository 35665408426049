import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  AddCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  InfoOutlined,
  LockOpenOutlined,
  LockOutlined,
} from '@mui/icons-material'
import {
  Box,
  Button,
  IconButton,
  Stack,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material'

import { LoadingSpinner } from '../../components/LoadingSpinner'
import { SearchBar } from '../../components/SearchBar'
import { Table } from '../../components/Table'
import { ProtectedComponent } from '../../components/ProtectedComponent'
import { PaperWhite } from '../../components/Papers'
import { MessageBox } from '../../components/common/MessageBox'
import { Dialogs } from '../../components/Dialogs'
import { OffersAccordion } from '../../components/Accordions/OffersAccordion'

import {
  OFFER_ADD,
  OFFER_READ,
  OFFER_UPDATE,
  OFFER_DEL,
  OFFER_CLOSE,
} from '../../constants/functionalities'

import {
  useCloseOffer,
  useDeleteOffer,
  useListOffers,
} from '../../services/hooks/Offers'
import { formatedDate, formatDate } from '../../utils/formatValues'
import { DownloadExportData } from '../../components/Excel/Excel'
import { totalVacanciesCounter } from '../../v2/helper/apply'
import { ChipStatus } from '../../v2/components/Chip'

const headCells = [
  {
    id: 'codeOffer',
    label: 'Cód',
    exportExcel: true,
  },
  {
    id: 'status',
    label: 'Status',
    exportExcel: true,
  },
  {
    id: 'title',
    label: 'Título de la oferta',
    exportExcel: true,
  },
  {
    id: 'dateCreate',
    label: 'Fecha Creación',
    exportExcel: true,
  },
  {
    id: 'name',
    label: 'Ciudad',
    exportExcel: true,
  },
  {
    id: 'qtdoffer',
    label: 'Vacantes',
    exportExcel: true,
  },
  {
    id: 'qtdapply',
    label: 'Asignadas',
    exportExcel: true,
  },
  {
    id: 'actions',
    label: 'Acciones',
    disableSorting: true,
    exportExcel: false,
  },
]

export default function ListOffers() {
  const navigate = useNavigate()

  const [openDialogDeleteOffer, setOpenDialogDeleteOffer] = useState(false)
  const [deleteOfferId, setDeleteOfferId] = useState('')

  const [openDialogCerrarOffer, setOpenDialogCerrarOffer] = useState(false)
  const [cerrarOfferId, setCerrarOfferId] = useState('')
  const [formatToExcel, setFormatToExcel] = useState({})
  const [filterFn, setFilterFn] = useState({
    fn: (items) => items,
  })

  const { data, isLoading, error } = useListOffers()

  const { mutate: closeOfferMutate, isLoading: closeOfferIsLoading } =
    useCloseOffer()
  const { mutate: deleteOfferMutate, isLoading: deleteOfferIsLoading } =
    useDeleteOffer()

  const {
    TableContainer,
    TableHead,
    TablePagination,
    recordsAfterPagingAndSorting,
  } = Table(data, headCells, filterFn)

  useEffect(() => {
    if (Array.isArray(data) && data.length) {
      const format = data.map((item) => {
        const vacancies = item?.setting?.vacancies ??
          item.vacancies?.map((v) => (
            {...v, vacancyTotals: {total: Number(v?.total || 0), taken: 0}}
          ));
        const obj = {
          ...item,
          name: item?.setting?.city ?? item?.city,
          dateCreate: formatDate(new Date(item.created.at), 'DD-MM-YYYY'),
          qtdoffer: totalVacanciesCounter(vacancies, 'total'),
          qtdapply: totalVacanciesCounter(vacancies, 'taken'),
        }
        return obj
      })

      setFormatToExcel(format)
    }
  }, [data])

  const handleSearch = (event) => {
    const { value } = event.target

    const newValue = value.toLowerCase()

    setFilterFn({
      fn: (items) => {
        if (value === '') return items
        return items.filter(
          (offer) =>
            offer.codeOffer?.toLowerCase().includes(newValue) ||
            offer.title?.toLowerCase().includes(newValue) ||
            offer.status?.toLowerCase().includes(newValue) ||
            String(offer.created.at).toLowerCase().includes(newValue) ||
            offer.structure?.name?.toLowerCase().includes(newValue)
        )
      },
    })
  }

  const handleCloseDialogDeleteOffer = () => {
    setOpenDialogDeleteOffer(false)
  }

  const handleDeleteOffer = () => {
    if (deleteOfferId) {
      deleteOfferMutate(deleteOfferId, {
        onSuccess: () => {
          setDeleteOfferId('')
          setOpenDialogDeleteOffer(false)
        },
      })
    }
  }

  const handleCloseDialogCerrarOffer = () => {
    setOpenDialogCerrarOffer(false)
  }

  const handleCerrarOffer = () => {
    if (cerrarOfferId) {
      closeOfferMutate(cerrarOfferId, {
        onSuccess: () => {
          setCerrarOfferId('')
          setOpenDialogCerrarOffer(false)
        },
      })
    }
  }

  if (error) return <MessageBox>Ups, ocurrio un error!</MessageBox>

  if (isLoading) return <LoadingSpinner open />

  return (
    <Stack spacing={2}>
      <Typography variant='h4' align='center'>
        Listado de ofertas
      </Typography>

      <PaperWhite sx={{ p: 0 }}>
        <Stack
          direction='row'
          paddingY={2}
          paddingX={2}
          alignItems='center'
          flexWrap='wrap'
          gap='1em'
          justifyContent='center'
        >
          <Box sx={{ display: 'flex', flex: '1 1 350px' }}>
            <SearchBar
              id='table-searchbar'
              type='text'
              placeholder='buscar'
              onChange={handleSearch}
            />

            <DownloadExportData
              data={formatToExcel}
              columns={headCells}
              nameFile='Ofertas'
            />
          </Box>

          <ProtectedComponent functionality={[OFFER_ADD]}>
            <Button
              sx={{maxWidth: 'minmax(150px, 100%)'}}
              variant='contained'
              startIcon={<AddCircleOutlined color='inherit' />}
              onClick={() =>
                navigate('/offers/create', {
                  state: {
                    fromButton: true,
                  },
                })
              }
            >
              Crear Nuevo
            </Button>
          </ProtectedComponent>
        </Stack>

        <>
          <OffersAccordion
            breakPoint='1450px'
            offers={recordsAfterPagingAndSorting()}
            actions={{
              onInfo: (offer) => () => navigate(`/offers/${offer.id}`, { state: {fromButton: true}}),
              onEdit: (offer) => () => navigate(`/offers/update/${offer.id}`, {state: {fromButton: true}}),
              onDelete: (offer) => () => {
                          setOpenDialogDeleteOffer(true)
                          setDeleteOfferId(offer.id)
                        },
              onBlock: (offer) => () => {
                          setOpenDialogCerrarOffer(true)
                          setCerrarOfferId(offer.id)
                        }
            }}
          />
          <TableContainer breakPoint='1450px'>
            <TableHead />
            <TableBody>
              {recordsAfterPagingAndSorting().map((offer) => {
                const vacancies = offer?.setting?.vacancies ??
                  offer.vacancies?.map((v) => (
                    {...v, vacancyTotals: {total: Number(v?.total || 0), taken: 0}}
                  ));
                return (
                  <TableRow hover tabIndex={-1} key={offer.id}>
                    <TableCell
                      sx={{ opacity: offer.status === 'Closed' ? 0.5 : 1 }}
                    >
                      {offer.codeOffer}
                    </TableCell>
                    <TableCell>
                      <ChipStatus label={offer.status} setcolor={offer.status}/>
                    </TableCell>
                    <TableCell
                      sx={{ opacity: offer.status === 'Closed' ? 0.5 : 1 }}
                    >
                      {offer.title}
                    </TableCell>
                    <TableCell
                      sx={{ opacity: offer.status === 'Closed' ? 0.5 : 1 }}
                    >
                      {formatedDate(offer.created.at)}
                    </TableCell>
                    <TableCell
                      sx={{ opacity: offer.status === 'Closed' ? 0.5 : 1 }}
                    >
                      {offer?.setting?.city ?? offer?.city}
                    </TableCell>
                    <TableCell
                      sx={{ opacity: offer.status === 'Closed' ? 0.5 : 1 }}
                    >
                      {
                        totalVacanciesCounter(vacancies, 'total')
                      }
                    </TableCell>
                    <TableCell
                      sx={{ opacity: offer.status === 'Closed' ? 0.5 : 1 }}
                    >
                      {
                        totalVacanciesCounter(vacancies, 'taken')
                      }
                    </TableCell>
                    <TableCell>
                      <Stack direction='row'>
                        <ProtectedComponent functionality={[OFFER_READ]}>
                          <IconButton
                            onClick={() =>
                              navigate(`/offers/${offer.id}`, {
                                state: {
                                  fromButton: true,
                                },
                              })
                            }
                          >
                            <InfoOutlined />
                          </IconButton>
                        </ProtectedComponent>

                        <ProtectedComponent functionality={[OFFER_UPDATE]}>
                          <IconButton
                            disabled={offer.status === 'Closed'}
                            onClick={() =>
                              navigate(`/offers/update/${offer.id}`, {
                                state: {
                                  fromButton: true,
                                },
                              })
                            }
                          >
                            <EditOutlined />
                          </IconButton>
                        </ProtectedComponent>

                        <ProtectedComponent functionality={[OFFER_DEL]}>
                          <IconButton
                            disabled={
                              offer.status === 'Closed' ||
                              offer.status === 'Publicado'
                            }
                            onClick={() => {
                              setOpenDialogDeleteOffer(true)
                              setDeleteOfferId(offer.id)
                            }}
                          >
                            <DeleteOutlined />
                          </IconButton>
                        </ProtectedComponent>

                        <ProtectedComponent functionality={[OFFER_CLOSE]}>
                          <IconButton
                            onClick={() => {
                              setOpenDialogCerrarOffer(true)
                              setCerrarOfferId(offer.id)
                            }}
                            disabled={
                              offer.status === 'Closed' ||
                              offer.status === 'En borrador'
                            }
                          >
                            {offer.status === 'Closed' ? (
                              <LockOutlined color='primary' />
                            ) : (
                              <LockOpenOutlined />
                            )}
                          </IconButton>
                        </ProtectedComponent>
                      </Stack>
                    </TableCell>
                  </TableRow>
                )
              }
            )}
            </TableBody>
          </TableContainer>
          <TablePagination />
        </>
      </PaperWhite>

      <Dialogs
        open={openDialogCerrarOffer}
        handleClose={handleCloseDialogCerrarOffer}
        handleConfirmation={handleCerrarOffer}
        isLoading={closeOfferIsLoading}
        title='¿Estás seguro de que quieres cerrar la oferta?'
      />

      <Dialogs
        open={openDialogDeleteOffer}
        handleClose={handleCloseDialogDeleteOffer}
        handleConfirmation={handleDeleteOffer}
        isLoading={deleteOfferIsLoading}
        title='¿Estás seguro de que quieres eliminar la oferta?'
      />
    </Stack>
  )
}
