import { useQuery } from "react-query";
import { FLEET_URI } from "../../../../constants/api";
import { useApi } from "../../../../services/api";
import { buildUrlWithQueryParams } from "../../../helper/converters";

export async function getListFleets(filters) {
  if (Object.keys(filters || {}).length === 0) return;
  const { axiosInstance } = useApi();
  const url = buildUrlWithQueryParams(`${FLEET_URI}/fleet`, filters);

  const response = await axiosInstance.get(url);

  const { data } = response;

  return data;
}

export function useListFleets(filters) {
  return useQuery(["listFleets", filters], () => getListFleets(filters));
}
