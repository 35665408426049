import { useMutation, useQueryClient } from "react-query";
import { useApi } from "../../../../../services/api";
import { REQUEST_TRACKER_URI } from "../../../../../constants/api";

export const useApprobationReplaceDocument = () => {
  const { axiosInstance } = useApi();
  const queryClient = useQueryClient();

  return useMutation(
    ({ requestId, data }) =>
      axiosInstance.post(`/replaceDocument/${requestId}`, data, {
        baseURL: REQUEST_TRACKER_URI,
      }),
    {
      onSuccess: () => {
        setTimeout(() => {
          queryClient.refetchQueries("listProviderRequests");
          queryClient.refetchQueries("providerRequest");
        }, 500);
      },
    }
  );
};
