import { useCallback, useEffect, useMemo, useState } from "react";
import { LoadingSpinner } from "../../LoadingSpinner";
import { Button, Stack } from "@mui/material";
import { PaperWhite } from "../../Papers";
import { FleetTable } from "./FleetTable";
import { Alert } from "../../Alert";
import { FormCreateVehicle } from "./FormCreate";
import { useCreateFleet } from "../../../services/hooks/Fleets/useCreateFleet";
import { useListFleetsByProviderAndOffer } from "../../../services/hooks/Fleets/useListFleetsByApply";
import { useDeleteHardFleet } from "../../../services/hooks/Fleets/useHardDeleteFleet";
import { Dialogs } from "../../Dialogs";
import { AlertSnackBar } from "../../Alerts";
import wowIcon from "../../../assets/images/wow.png";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../hook/useAuth";
import { useMyProvider } from "../../../services/hooks/Providers/useMyProvider";
import { StepperController } from "../../Stepper";
import { steps } from "../../../constants/steps";
import { useUpdateFleet } from "../../../services/hooks/Fleets/useUpdateFleet";
import { useUpdateDocuments } from "../../../services/hooks/Docs/S3/useUpdateDocument";
import { useUploadDocuments } from "../../../services/hooks/Docs/S3/useUploadDocument";
import { validateGroupedDocuments } from "../../../v2/helper/documents";
import { vacancyType } from "../../../constants/vacancyType";
import { useGetApplyById } from "../../../services/hooks/Apply/useGetApplyById";
import { useOfferDetails } from "../../../services/hooks/Offers";

const initialAlert = {
  open: false,
  isOpen: false,
  messages: [],
};

const initialState = {
  plate: "",
  brand: "",
  model: "",
  typeVehicle: "",
  year: "",
};

export function NewFleetStep() {
  const { email } = useAuth();
  const { step, applyId } = useParams();
  const navigate = useNavigate();
  const [isRenderedForm, setIsRenderedForm] = useState(false);
  const [fleetToDelete, setFleetToDelete] = useState(null);
  const [initialValues, setInitialValues] = useState(initialState);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [alert, setAlert] = useState(initialAlert);
  const [error, setError] = useState({ isOpen: false });
  const [filters, setFilters] = useState({});
  const [isUpdating, setIsUpdating] = useState(false);
  const [initialExpirationDates, setInitialExpirationDates] = useState(null);
  const [currentTemplate, setCurrentTemplate] = useState(null);
  const [documentsFromBucket, setDocumentsFromBucket] = useState([]);

  const { data: provider, isLoading: isProviderLoading } = useMyProvider(email);
  const { data: currentApply, isLoading: isGettingApplies } =
    useGetApplyById(applyId, provider?.id);
  const { mutate: createMutate, isLoading: createIsLoading } = useCreateFleet();
  const { mutate: updateMutate, isLoading: updateIsLoading } = useUpdateFleet();
  const { mutate: deleteMutate, isLoading: deleteIsLoading } =
    useDeleteHardFleet();
  const { mutate: mutateUpdateDocument, isLoading: isLoadingUpdate } =
    useUpdateDocuments();
  const { mutate: mutateUploadDocument, isLoading: isLoadingUpload } =
    useUploadDocuments();
  const {
    data: { data: vehicles, metadata } = {},
    refetch,
    isLoadingFleets,
    isRefetching,
  } = useListFleetsByProviderAndOffer(applyId, filters);
  const { data: offer, isLoading: isLoadingOffer } = useOfferDetails(currentApply?.offer?.id)


  const handleConfirmation = () => {
    setAlert({
      ...alert,
      open: false,
    });
  };

  const handleEdit = (fleet) => {
    setInitialValues(fleet);
    setIsUpdating(true);
    setIsRenderedForm(true);
  };

  const handleDelete = (fleet) => {
    setFleetToDelete(fleet);
    setOpenDeleteDialog(true);
  };

  const confirmDelete = useCallback(() => {
    const linkedEmployees = currentApply.employeeIds.filter(employee => {
      return employee.plate === fleetToDelete.plate;
    })
    deleteMutate(
      { idToDelete: fleetToDelete.id, linkedEmployees, applyId },
      {
        onSuccess: () => {
          setOpenDeleteDialog(false);
          refetch();
        },
      }
    );
  }, [fleetToDelete, applyId, currentApply]);

  const closeDialogDelete = () => {
    setOpenDeleteDialog(false);
  };

  const handleGoBack = () => {
    setInitialValues(initialState);
    setIsRenderedForm(false);
    setTimeout(() => refetch(), 500);
  };

  useEffect(() => {
    if (provider && filters.hasOwnProperty("providerId")) {
      refetch();
    }
  }, [filters, provider]);

  function onChangeFilters(newFilters = {}) {
    setFilters((old) => ({ ...old, ...newFilters, providerId: provider?.id }));
  }

  const handleCreateOrUpdate = useCallback(
    (fleet) => {
      const data = {
        id: fleet.id,
        plate: fleet.plate,
        providerId: provider?.id,
        model: fleet.model.name,
        brand: fleet.brand.name,
        type: fleet.type.vehicle.name,
        documentTemplateId: fleet.type.vehicle.documentTemplateId,
        year: fleet.year,
      };

      const action = data?.id ? updateMutate : createMutate;

      const { error: validationGroupedError, groupedDataByDocumentType } =
        validateGroupedDocuments(
          currentTemplate.documentTypes,
          fleet,
          initialExpirationDates
        );

      if (validationGroupedError) {
        setError({
          isOpen: true,
          title: "Error",
          messages: validationGroupedError.messages[0],
          handleClose: () => setError({ isOpen: false }),
        });
        return;
      }

      action(
        { data, applyId },
        {
          onSuccess: (response) => {
            const createdFleet = response;
            for (let [key, documentToUpload] of Object.entries(
              groupedDataByDocumentType
            )) {
              const beforeUpload = documentToUpload;
              beforeUpload["ownerId"] = createdFleet?.id;
              beforeUpload["ownerType"] = vacancyType.FLEET;
              beforeUpload.metaData = JSON.stringify(beforeUpload.metaData);
              if (isUpdating) {
                if (!beforeUpload.file.some((docFile) => docFile === null)) {
                  const currentMetadataGroupName = JSON.parse(
                    beforeUpload.metaData
                  )[0]?.groupName;
                  const relatedDocumentId = documentsFromBucket.find(
                    (relDoc) =>
                      relDoc.files[0].groupName === currentMetadataGroupName
                  )?.id;

                  mutateUpdateDocument(
                    {
                      documentId: relatedDocumentId,
                      documents: beforeUpload,
                    },
                    {
                      onSuccess: () => { },
                      onError: () => {
                        setError({
                          isOpen: true,
                          messages:
                            "Oh no! se ha producido un error al actualizar los documentos",
                          handleClose: () => setError({ isOpen: false }),
                        });
                      },
                    }
                  );
                }

                if (
                  key ===
                  Object.keys(groupedDataByDocumentType)[
                  Object.keys(groupedDataByDocumentType).length - 1
                  ]
                ) {
                  handleGoBack();
                }
              } else {
                mutateUploadDocument(beforeUpload, {
                  onSuccess: () => {
                    handleGoBack();
                  },
                  onError: () => {
                    deleteMutate(
                      {
                        idToDelete: createdFleet?.id,
                        applyId,
                      },
                      {
                        onSuccess: () => {
                          if (
                            key ===
                            Object.keys(groupedDataByDocumentType)[
                            Object.keys(groupedDataByDocumentType).length - 1
                            ]
                          ) {
                            handleGoBack();
                          }
                        },
                        onError: () => {
                          setError({
                            isOpen: true,
                            messages:
                              "Oh no! se ha producido un error al eliminar el vehículo",
                            handleClose: () => setError({ isOpen: false }),
                          });
                        },
                      }
                    );
                    setError({
                      isOpen: true,
                      messages:
                        "Oh no! se ha producido un error al cargar los documentos",
                      handleClose: () => setError({ isOpen: false }),
                    });
                  },
                });
              }
            }
          },
          onError: () =>
            setError({
              isOpen: true,
              messages: "Oh no! se ha producido un error en el proceso",
              handleClose: () => setError({ isOpen: false }),
            }),
        }
      );
    },
    [vehicles, applyId, isUpdating, currentTemplate, documentsFromBucket]
  );

  const goToNextStep = () => navigate(`/application/${applyId}/employee/2`);

  const handleSearch = (event) => {
    const { value } = event.target;
    const newValue = value.toLowerCase();

    setFilters((old) => ({ ...old, search: newValue }));
  };

  const { filteredVacanciesByAvailability, availability } = useMemo(() => {
    const currentAvalaibiableVacancies = (offer?.setting?.vacancies ?? []).filter(vacancy => Number(vacancy.vacancyTotals.total) > vacancy.vacancyTotals.taken);

    const applyingByType = (vehicles || []).reduce((acc, curr) => {
      acc[curr.fleetType] = acc[curr.fleetType] ? acc[curr.fleetType] + 1 : 1;
      return acc;
    }, {});

    const currentFilter = currentAvalaibiableVacancies.filter(
      (vacancy) => Number(applyingByType[vacancy.vehicle.name] ?? 0) < Number(vacancy.vacancyTotals?.total ?? vacancy.total)
    );

    return {
      filteredVacanciesByAvailability: Object.entries(applyingByType).length ? currentFilter : currentAvalaibiableVacancies,
      availability: currentFilter?.length ? true : false
    };
  }, [vehicles, offer]);

  const textWarnOnDelete = useMemo(() => {
    if (!fleetToDelete) return;
    const baseText = `¿Estás seguro de eliminar este vehiculo?`;
    const fleetWithLinkedCourier = `Si lo eliminas, el conductor RUT ${fleetToDelete.courierDni} se eliminara también.`
    const fleetWithLinkedPeoneta = `Si lo eliminas, el peoneta RUT ${fleetToDelete.peonetaDni} se eliminara también.`

    if (fleetToDelete.courierDni && fleetToDelete.peonetaDni) {
      return `${baseText} Si lo eliminas, el conductor RUT ${fleetToDelete.courierDni} y el peoneta RUT ${fleetToDelete.peonetaDni} se eliminarán también.`;
    }

    return `${baseText} ${fleetToDelete.courierDni ? fleetWithLinkedCourier : fleetWithLinkedPeoneta}`;
  }, [fleetToDelete])

  return (
    <>
      {(
        isLoadingOffer ||
        createIsLoading ||
        deleteIsLoading ||
        isLoadingFleets ||
        isProviderLoading ||
        isRefetching ||
        updateIsLoading ||
        isLoadingUpdate ||
        isLoadingUpload ||
        isGettingApplies) && <LoadingSpinner open />}
      <StepperController steps={steps} isCurrentStepActive={Number(step)} />
      {isRenderedForm ? (
        <PaperWhite sx={{ py: 4, mt: 6 }}>
          <FormCreateVehicle
            formValues={initialValues}
            filteredVacanciesByAvailability={filteredVacanciesByAvailability}
            onSubmit={handleCreateOrUpdate}
            goBack={handleGoBack}
            setCurrentTemplate={setCurrentTemplate}
            setDocumentsFromBucket={setDocumentsFromBucket}
            setInitialExpirationDates={setInitialExpirationDates}
          />
        </PaperWhite>
      ) : (
        <Stack spacing={2} mt={4} component="form">
          <FleetTable
            fleetPaginated={{ vehicles, metadata }}
            onSearch={handleSearch}
            handleCreate={() => setIsRenderedForm(true)}
            handleDelete={handleDelete}
            handleEdit={handleEdit}
            onChangeFilters={onChangeFilters}
            showTable={vehicles?.length && !isLoadingFleets}
            availability={availability}
          />
          <Stack
            direction="row"
            justifyContent="flex-end"
            paddingY={2}
            spacing={10}
          >
            <Button
              onClick={goToNextStep}
              size="large"
              disabled={!vehicles?.length}
            >
              Siguiente
            </Button>
          </Stack>

          <Dialogs
            open={openDeleteDialog}
            handleClose={closeDialogDelete}
            handleConfirmation={confirmDelete}
            isLoading={deleteIsLoading}
            title={textWarnOnDelete}
          />
        </Stack>
      )}
      <Alert
        icon={<img src={wowIcon} alt="Congratulations Icon" />}
        open={alert.open}
        handleConfirmation={handleConfirmation}
        title={alert.title}
        messages={alert.messages}
      />
      <AlertSnackBar
        open={error.isOpen}
        message={error.messages}
        handleClose={error.handleClose}
      />
    </>
  );
}
