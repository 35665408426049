import { useMutation, useQueryClient } from 'react-query'
import { EMPLOYEE_REPORT_URI } from '../../../../constants/api';
import { useApi } from '../../../../services/api';
import { buildUrlWithQueryParams } from '../../../helper/converters';

export async function reportListProviders(filters) {
  const { axiosInstance } = useApi();
  const url = buildUrlWithQueryParams(`${EMPLOYEE_REPORT_URI}/all-providers`, filters);

  const { data } = await axiosInstance.get(url);
  return data;
}

export function useReportProvider() {
  const queryClient = useQueryClient();
  return useMutation(reportListProviders, {
    onSuccess: () => {
      queryClient.refetchQueries("reportListProviders");
    },
  });
}
