import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import React from 'react'
import { ReactComponent as AlertIcon } from '../../assets/images/alert-2.svg';

export function Alert({
  title,
  content,
  open,
  handleClose,
  buttonClose,
  handleConfirmation,
  icon,
  messages
}) {
  return (
    <Dialog
      open={open}
      /* onClose={handleClose} */
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      sx={{ '& .MuiPaper-root': { px: 2, py: 1, borderRadius: '16px', alignItems: 'center' } }}
    >
      {icon? icon : <AlertIcon/> }
      <DialogTitle id='alert-dialog-title' sx={{ '& .MuiPaper-root': { textAlign: 'center' } }}>{title}</DialogTitle>
      <DialogContent>
        {messages.map((message, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <DialogContentText key={index} id='alert-dialog-description' sx={{ '& .MuiPaper-root': { textAlign: 'left' } }}>
          {message}
        </DialogContentText>
        ))}
        <DialogContentText id='alert-dialog-description' sx={{ '& .MuiPaper-root': { textAlign: 'left' } }}>
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {buttonClose &&
          <Button
            onClick={handleClose}
            color='secondary'
            variant='outlined'
            sx={{
              borderRadius: '16px',
              fontWeight: 700,
              textTransform: 'capitalize',
              width: 100,
              height: 40,
            }}
            disableElevation
          >
            Cancelar
          </Button>
        }
        <Button
          onClick={handleConfirmation}
          autoFocus
          color='secondary'
          variant='contained'
          sx={{
            color: 'white',
            borderRadius: '16px',
            fontWeight: 700,
            textTransform: 'capitalize',
            width: 100,
            height: 40,
          }}
          disableElevation
        >
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  )
}
