import { useQuery } from 'react-query'
import { useApi } from '../../api'
import { OFFER_URI } from '../../../constants/api'

const status = {
  'Draft': 'En borrador',
  'Published': 'Publicado',
  'Closed': 'Cerrado',
}

export async function getListOffers() {
  const { axiosInstance } = useApi()

  const response = await axiosInstance.get('?page=1&limit=1000', {
    baseURL: OFFER_URI,
  })

  const { data } = response.data
  const formatedData = data?.map((offer) => ({
    ...offer,
    status: status[offer.status]
  }))

  return formatedData
}

export function useListOffers() {
  return useQuery('listOffers', () => getListOffers(), {
    staleTime: 1000 * 60 * 10, // 10 minutos para não refazer a requisição ao receber foco na tela
  })
}
