import { useApi } from '../../api'
import { useQuery } from 'react-query'
import { DOCUMENT_URI } from '../../../constants/api'

export async function getDocumets(type, id) {
  try {
    const { axiosInstance } = useApi()
    const response = await axiosInstance.get(`/by-entity/${type}/${id}`, {
      baseURL: DOCUMENT_URI,
    })
    return response.data
  } catch (error) {
    return null
  }
}

export function useGetDocuments(type, id) {
  return useQuery(['getDocuments', type, id], () => getDocumets(type, id), {
    staleTime: 1000 * 60 * 10,
  })
}