import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Box, Checkbox, Divider, Grid, FormControlLabel, Stack, Typography, Button } from "@mui/material";
import { Controller } from "react-hook-form";
import { ReactComponent as LeftArrow } from "../../../assets/images/leftarrow.svg"
import { useGetTemplate } from "../../../../services/hooks/Docs/Templates/useGetTemplates";
import { LoadingSpinner } from "../../../../components/LoadingSpinner";
import { PaperWhite } from "../../../../components/Papers";
import { DocumentsSection } from "../../../../components/Forms/Documents/DocumentSection";
import SwitchButton from "../../../components/Button/SwitchButton" 
import { BpIcon, BpCheckedIcon } from "../../../components/Button/CheckedButton";
import { EmployeeSection } from "../../../../components/Forms/Employer/EmployeeSection"
import { ENTITY_TYPES_MAPPING } from "../../../../constants/entityTypes";
import EmployeeTable from "./EmployeeTable";
import { VACANCY_TYPE_MAPPING } from "../../../../constants/status";

export const EmployeeForm = ({ item,  goBack, onSubmit, setTemplate, setReplaceFinish }) => {
  const { control, getValues, formState, handleSubmit } = useForm({
    mode: "onChange",
  });
  const { data: template, isLoading: isLoadingTemplate } =
      useGetTemplate(item.documentTemplateId);
  const [switchLeft, setSwitchLeft] = useState(true);

  useEffect(() => {
    setTemplate(template);
  }, [template]);

  if (isLoadingTemplate) return <LoadingSpinner open />;

  return (
    <>
        <Box sx={{display:"flex", justifyContent:"center"}}>
          <SwitchButton switchLeft={switchLeft} setSwitchLeft={setSwitchLeft} type={VACANCY_TYPE_MAPPING[item.type].toLowerCase()}/>
        </Box>
        <PaperWhite sx={{ py: 4, mt: 3 }}>
        {switchLeft ? 
        <Stack component="form" onSubmit={handleSubmit(onSubmit)}>
          <EmployeeSection control={control} getValue={getValues} title={`Datos del ${ENTITY_TYPES_MAPPING[item.type]}`}/>
          <Divider sx={{ pt: 1, pb: 2 }} />
          <DocumentsSection control={control} documentStructure={template?.documentTypes}/>
          <Box gridColumn={{ xs: "span 12", lg: "span 2" }}>
            <Controller
              control={control}
              name="securityOperation"
              rules={{
                required: "(*) Campo obligatorio",
              }}
              render={({ field: { value, onChange, ...rest } }) => (
                <Grid container>
                  <FormControlLabel
                    key="securityOperation"
                    control={
                      <Checkbox
                        checked={value}
                        onChange={(event) => onChange(event.target.checked)}
                        color="secondary"
                        checkedIcon={<BpCheckedIcon />}
                        icon={<BpIcon />}
                        required
                      />
                    }
                  />
                  <Typography
                    variant="body1"
                    fontFamily="Lato"
                    fontWeight={400}
                    fontSize="14px"
                    lineHeight="18.9px"
                    sx={{
                      color: "rgba(0, 0, 0, 0.87)",
                      "& span": { color: "orange" },
                      mt: 1,
                    }}
                  >
                    Se compromete el proveedor que sus trabajadores cuenten
                    con chaleco reflectante y zapatos de seguridad
                  </Typography>
                </Grid>
              )}
            />
          </Box>
          <Grid container sx={{ pt: 2 }}>
            <Grid item xs={5}>
              {goBack && (
                <Button
                  variant="goBack"
                  disableRipple
                  startIcon={<LeftArrow />}
                  onClick={goBack}
                  sx={{ height: 40, fontSize: 12, alignSelf: "self-start" }}
                >
                  <Typography 
                    fontFamily="Lato"
                    fontWeight={800}
                    fontSize="12px"
                    lineHeight="16.2px"
                  >
                    {"Volver atrás"}
                  </Typography>
                </Button>
              )}
            </Grid>
            <Grid item xs={7}>
              <Grid container justifyContent="flex-end">
                <Button
                  color="secondary"
                  type="submit"
                  sx={{ minWidth: "190px", height: 40 }}
                  disabled={!formState.isValid}
                >
                  <Typography 
                    fontFamily="Lato"
                    fontWeight={700}
                    fontSize="14px"
                    lineHeight="16.8px"
                    color="#FFFFFF"
                  >
                    {"Finalizar"}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Stack>
        :
          <EmployeeTable item={item} goBack={goBack} setReplaceFinish={setReplaceFinish}/>
        }
        </PaperWhite>
    </>
  );
};
