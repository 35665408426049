import { Box, Typography } from '@mui/material'

export function Footer() {
  return (
    <Box
      component='footer'
      sx={{
        gridArea: 'footer',
        height: '56px',
        color: 'lightWell',
        backgroundColor: 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Typography variant='body1'>
        {' '}
        © 2024 Blue Express - Todos los derechos reservados
      </Typography>
    </Box>
  )
}
