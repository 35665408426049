import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Stack,
  Typography,
  Autocomplete,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { ArrowBackOutlined } from "@mui/icons-material";
import { Controller, useForm } from "react-hook-form";

import { PaperWhite } from "../../../components/Papers";
import { Input } from "../../../components/Inputs";
import { AlertSnackBar } from "../../../components/Alerts";
import { LoadingSpinner } from "../../../components/LoadingSpinner";

import {
  useCreateDocuments,
  useGetDocuments,
  useUpdateDocuments,
} from "../../../services/hooks/Docs";

import {
  isComplexTextNumberSpecialValid,
  isComplexTextNumberValid,
  isSimpleNumberValid,
  validateMinLength,
} from "../../../utils/validateValues";

const defaultValues = {
  description: "",
  typeDoc: "",
  requiredTo: "",
  documentExpired: false,
  nameStandard: "",
  daysToNotificationExpires: "",
  blockOperation: false,
  blockPayment: false,
  periodicity: "",
  dateLimiteUpload: "",
};

const initialAlertError = {
  open: false,
  message: "",
};

export default function FormDocuments({ fromButton }) {
  const navigate = useNavigate();
  const { documentId } = useParams();

  const requiredTo = [
    { id: 1, label: "Proveedor" },
    { id: 2, label: "Courier" },
    { id: 3, label: "Flota" },
  ];
  const typeDoc = [
    { id: 1, label: ".pdf" },
    { id: 2, label: ".png" },
    { id: 3, label: ".jpg" },
    { id: 4, label: ".docx" },
    { id: 5, label: ".csv" },
  ];

  const [alertError, setAlertError] = useState(initialAlertError);

  const { control, handleSubmit, setValue } = useForm({ defaultValues });

  const { data: document, isLoading: isLoadingGetDocument } = useGetDocuments(
    documentId || ""
  );

  const { mutate: mutateCreate, isLoading: isLoadingCreate } =
    useCreateDocuments();
  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } =
    useUpdateDocuments(documentId);

  useEffect(() => {
    if (!fromButton) navigate("/404");
  }, []);

  useEffect(() => {
    if (documentId !== "" && documentId !== undefined) {
      setValue("description", document?.description);
      setValue(
        "typeDoc",
        typeDoc?.find((type) => type.label === document?.typeDoc)
      );
      setValue(
        "requiredTo",
        requiredTo?.find((req) => req.label === document?.requiredTo)
      );
      setValue("documentExpired", document?.documentExpired);
      setValue("nameStandard", document?.nameStandard);
      setValue(
        "daysToNotificationExpires",
        Number(document?.daysToNotificationExpires)
      );
      setValue("blockOperation", document?.blockOperation);
      setValue("blockPayment", document?.blockPayment);
      setValue("periodicity", Number(document?.periodicity));
      setValue("dateLimiteUpload", Number(document?.dateLimiteUpload));
    }
  }, [document, documentId]);

  const handleCloseAlertError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertError(initialAlertError);
  };

  const onSubmit = (data) => {
    const obj = {
      blockOperation: data.blockOperation,
      blockPayment: data.blockPayment,
      dateLimiteUpload: data.dateLimiteUpload,
      daysToNotificationExpires: data.daysToNotificationExpires,
      description: data.description,
      documentExpired: data.documentExpired,
      nameStandard: data.nameStandard,
      periodicity: data.periodicity,
      requiredTo: data.requiredTo.label,
      typeDoc: data.typeDoc.label,
    };

    if (documentId) {
      mutateUpdate(obj, {
        onSuccess: () => navigate("/Documents"),
        onError: () =>
          setAlertError({
            open: true,
            message: "Oh no! se ha producido un error al editar un documento",
          }),
      });
    } else {
      mutateCreate(obj, {
        onSuccess: () => navigate("/Documents"),
        onError: () =>
          setAlertError({
            open: true,
            message: "Oh no! se ha producido un error al crear un documento",
          }),
      });
    }
  };

  if (isLoadingGetDocument || isLoadingCreate || isLoadingUpdate) {
    return <LoadingSpinner open />;
  }

  return (
    <Stack spacing={2} component="form" onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h4" align="center">
        {documentId ? "Editando un documento" : "Creando un documento"}
      </Typography>

      <PaperWhite sx={{ pb: 20 }}>
        <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={3}>
          <Box gridColumn={{ xs: "span 12", lg: "span 6" }}>
            <Controller
              control={control}
              name="description"
              rules={{
                required: "(*) Campo obligatorio",
                validate: {
                  minValid: (text) =>
                    validateMinLength(text, 1) ||
                    "Descripción no puede ser menor a 1 caracteres",
                  textIsValid: (text) =>
                    isComplexTextNumberValid(text) ||
                    "Descripción inválida, no se aceptan caracteres extraños",
                },
              }}
              render={({
                field: { value, onChange, ...rest },
                fieldState: { error },
              }) => (
                <Input
                  label="Descripción"
                  fullWidth
                  isRequired
                  value={value}
                  onChange={onChange}
                  error={Boolean(error)}
                  helperText={error?.message}
                  inputProps={{ maxLength: 50 }}
                  {...rest}
                />
              )}
            />
          </Box>

          <Box gridColumn={{ xs: "span 12", lg: "span 6" }}>
            <Controller
              control={control}
              name="typeDoc"
              rules={{
                required: "(*) Campo obligatorio",
              }}
              render={({
                field: { value, onChange, ...rest },
                fieldState: { error },
              }) => (
                <Autocomplete
                  value={value}
                  onChange={(e, newValue) => onChange(newValue)}
                  isOptionEqualToValue={(option, type) =>
                    option.id === Number(type)
                  }
                  getOptionLabel={(option) => option.label || ""}
                  size="small"
                  disablePortal
                  options={typeDoc}
                  renderInput={(params) => (
                    <Input
                      {...params}
                      label="Tipo documento"
                      isRequired
                      error={Boolean(error)}
                      helperText={error?.message}
                      placeholder="Tipo documento"
                      {...rest}
                    />
                  )}
                />
              )}
            />
          </Box>

          <Box gridColumn={{ xs: "span 12", lg: "span 6" }}>
            <Controller
              control={control}
              name="requiredTo"
              rules={{
                required: "(*) Campo obligatorio",
              }}
              render={({
                field: { value, onChange, ...rest },
                fieldState: { error },
              }) => (
                <Autocomplete
                  value={value}
                  onChange={(e, newValue) => onChange(newValue)}
                  isOptionEqualToValue={(option, req) =>
                    option.id === Number(req)
                  }
                  getOptionLabel={(option) => option.label || ""}
                  size="small"
                  disablePortal
                  options={requiredTo}
                  renderInput={(params) => (
                    <Input
                      {...params}
                      label="Requerido para"
                      isRequired
                      error={Boolean(error)}
                      helperText={error?.message}
                      placeholder="Proveedor, Courier, Flota"
                      {...rest}
                    />
                  )}
                />
              )}
            />
          </Box>

          <Box gridColumn={{ xs: "span 12", lg: "span 6" }}>
            <Controller
              control={control}
              name="nameStandard"
              rules={{
                required: "(*) Campo obligatorio",
                validate: {
                  minValid: (text) =>
                    validateMinLength(text, 1) ||
                    "Nombre estándar no puede ser menor a 1 caracteres",
                  textIsValid: (text) =>
                    isComplexTextNumberSpecialValid(text) ||
                    "Nombre estándar inválido, no se aceptan caracteres extraños",
                },
              }}
              render={({
                field: { value, onChange, ...rest },
                fieldState: { error },
              }) => (
                <Input
                  label="Nombre estándar"
                  fullWidth
                  isRequired
                  value={value}
                  onChange={onChange}
                  error={Boolean(error)}
                  helperText={error?.message}
                  inputProps={{ maxLength: 50 }}
                  {...rest}
                />
              )}
            />
          </Box>

          <Box gridColumn={{ xs: "span 12", lg: "span 6" }}>
            <Controller
              control={control}
              name="daysToNotificationExpires"
              rules={{
                required: "(*) Campo obligatorio",
                validate: {
                  minValid: (text) =>
                    validateMinLength(text, 1) ||
                    "Días para notificar expiración no puede ser menor a 1 caracteres",
                  textIsValid: (text) =>
                    isSimpleNumberValid(text) ||
                    "Días para notificar expiración inválido, no se aceptan caracteres extraños",
                },
              }}
              render={({
                field: { value, onChange, ...rest },
                fieldState: { error },
              }) => (
                <Input
                  label="Días para notificar expiración"
                  fullWidth
                  isRequired
                  value={value}
                  onChange={onChange}
                  error={Boolean(error)}
                  helperText={error?.message}
                  inputProps={{ maxLength: 50 }}
                  {...rest}
                />
              )}
            />
          </Box>

          <Box gridColumn={{ xs: "span 12", lg: "span 6" }}>
            <Controller
              control={control}
              name="periodicity"
              rules={{
                required: "(*) Campo obligatorio",
                validate: {
                  minValid: (text) =>
                    validateMinLength(text, 1) ||
                    "Periodicidad no puede ser menor a 1 caracteres",
                  textIsValid: (text) =>
                    isSimpleNumberValid(text) ||
                    "Periodicidad inválida, no se aceptan caracteres extraños",
                },
              }}
              render={({
                field: { value, onChange, ...rest },
                fieldState: { error },
              }) => (
                <Input
                  label="Periodicidad"
                  fullWidth
                  isRequired
                  value={value}
                  onChange={onChange}
                  error={Boolean(error)}
                  helperText={error?.message}
                  inputProps={{ maxLength: 50 }}
                  {...rest}
                />
              )}
            />
          </Box>

          <Box gridColumn={{ xs: "span 12", lg: "span 6" }}>
            <Controller
              control={control}
              name="dateLimiteUpload"
              rules={{
                required: "(*) Campo obligatorio",
                validate: {
                  minValid: (text) =>
                    validateMinLength(text, 1) ||
                    "Día limite de carga no puede ser menor a 1 caracteres",
                  textIsValid: (text) =>
                    isSimpleNumberValid(text) ||
                    "Día limite de carga inválido, no se aceptan caracteres extraños",
                },
              }}
              render={({
                field: { value, onChange, ...rest },
                fieldState: { error },
              }) => (
                <Input
                  label="Día limite de carga"
                  fullWidth
                  isRequired
                  value={value}
                  onChange={onChange}
                  error={Boolean(error)}
                  helperText={error?.message}
                  inputProps={{ maxLength: 50 }}
                  {...rest}
                />
              )}
            />
          </Box>

          <Box gridColumn={{ xs: "span 12", lg: "span 2" }}>
            <Controller
              control={control}
              name="documentExpired"
              rules={{}}
              render={({ field: { value, onChange, ...rest } }) => (
                <>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "rgba(0, 0, 0, 0.87)",
                      "& span": { color: "orange" },
                    }}
                  >
                    Documento expirado
                  </Typography>

                  <FormControlLabel
                    key="documentExpired"
                    control={
                      <Checkbox
                        checked={value}
                        onChange={onChange}
                        color="secondary"
                        {...rest}
                      />
                    }
                  />
                </>
              )}
            />
          </Box>

          <Box gridColumn={{ xs: "span 12", lg: "span 2" }}>
            <Controller
              control={control}
              name="blockOperation"
              rules={{}}
              render={({ field: { value, onChange, ...rest } }) => (
                <>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "rgba(0, 0, 0, 0.87)",
                      "& span": { color: "orange" },
                    }}
                  >
                    Bloquear operación
                  </Typography>

                  <FormControlLabel
                    key="blockOperation"
                    control={
                      <Checkbox
                        checked={value}
                        onChange={onChange}
                        color="secondary"
                        {...rest}
                      />
                    }
                  />
                </>
              )}
            />
          </Box>

          <Box gridColumn={{ xs: "span 12", lg: "span 2" }}>
            <Controller
              control={control}
              name="blockPayment"
              rules={{}}
              render={({ field: { value, onChange, ...rest } }) => (
                <>
                  <Typography
                    variant="body1"
                    sx={{
                      color: "rgba(0, 0, 0, 0.87)",
                      "& span": { color: "orange" },
                    }}
                  >
                    Bloquear pago
                  </Typography>

                  <FormControlLabel
                    key="blockPayment"
                    control={
                      <Checkbox
                        checked={value}
                        onChange={onChange}
                        color="secondary"
                        {...rest}
                      />
                    }
                  />
                </>
              )}
            />
          </Box>
        </Box>
      </PaperWhite>

      <Stack spacing={2} direction="row" justifyContent="flex-end" paddingY={2}>
        <Button
          variant="goBack"
          disableRipple
          startIcon={<ArrowBackOutlined />}
          onClick={() => navigate("/Documents")}
        >
          Volver atrás
        </Button>
        <Button size="large" type="submit">
          {documentId ? "Editar" : "Guardar"}
        </Button>
      </Stack>

      <AlertSnackBar
        open={alertError?.open}
        handleClose={handleCloseAlertError}
        message={alertError?.message}
      />
    </Stack>
  );
}
