import { useQuery } from 'react-query'
import { useApi } from '../../api'

export async function getNotificationsSecurity(hasPermission) {
  if (!hasPermission) return { count: 0 }

  const { axiosInstance } = useApi()
  const response = await axiosInstance.get('/applications/security/notifications')
  const { data } = response.data
  
  return data
}

export function useNotificationsSecurity(hasPermission) {
  return useQuery('notificationsSecurity', () => getNotificationsSecurity(hasPermission), {
    staleTime: 1000 * 60 * 5
  })
}
