import * as React from 'react'
import { Stack, Typography, TextField, IconButton } from '@mui/material'
import UploadIcon from '../../v2/components/Icon/UploadIcon'

export default function FileUploadComponent({
  label,
  value,
  isRequired,
  placeholder,
  onChange,
  error,
  helperText
}) {
  const inputRef = React.createRef()

  return (
    <>
      <Stack spacing={2}>
        <Stack direction='row'>
          <Typography variant='body1' sx={{ '& span': { color: 'orange' }, marginBottom: "10px" }}>
            {label} {isRequired && <span>*</span>}
          </Typography>
        </Stack>
      </Stack>
      <TextField
        fullWidth
        size='small'
        value={value}
        error={error}
        helperText={helperText}
        placeholder={placeholder}
        onClick={() => inputRef.current.click()}
        sx={{
          '& .MuiOutlinedInput-root': {
            borderRadius: '16px',
          },
          '& fieldset': { borderColor: 'borderBlue' },
          '& .MuiButtonBase-root': {
            marginRight: '-12px',
          },
        }}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <IconButton>
              <UploadIcon/>
              <input ref={inputRef} hidden type='file' onChange={onChange} />
            </IconButton>
          ),
        }}
      />
    </>
  )
}