import { useQuery } from "react-query";
import { useApi } from "../../api";
import { LOCALITY_URI } from "../../../constants/api";

export async function getBases(localityId, baseId) {
  const { axiosInstance } = useApi();

  if (localityId && baseId) {
    try {
      const response = await axiosInstance.get(
        `/${localityId}/base/${baseId}`,
        {
          baseURL: LOCALITY_URI,
        }
      );
      return response.data;
    } catch (error) {
      return null;
    }
  } else {
    return null;
  }
}

export function useGetBases(localityId, baseId) {
  return useQuery(
    ["bases", localityId, baseId],
    () => getBases(localityId, baseId),
    {
      staleTime: 1000 * 60 * 60 * 24, // 24 horas para não refazer a requisição ao receber foco na tela
    }
  );
}
