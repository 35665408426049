import { useMutation, useQueryClient } from "react-query";
import { useApi } from "../../../api";
import { DOCUMENT_TEMPLATE_URI } from "../../../../constants/api";

export function useCreateTemplates() {
  const { axiosInstance } = useApi();
  const queryClient = useQueryClient();

  return useMutation(
    (type) =>
      axiosInstance.post("/", type, {
        baseURL: DOCUMENT_TEMPLATE_URI,
      }),
    {
      onSuccess: () => queryClient.invalidateQueries("listTemplates"),
    }
  );
}
