import { useMutation, useQueryClient } from 'react-query'
import { useApi } from '../../api'
import { APPLY_URI } from '../../../constants/api'

export function useUpdateApplyStatus(id) {
    const { axiosInstance } = useApi()
    const queryClient = useQueryClient()

    return useMutation(() => axiosInstance.put(`/${id}/status`, {}, { baseURL: APPLY_URI }), {
        onSuccess: () => {
            setTimeout(() => {
                queryClient.refetchQueries('getFleetsByProviderAndOffer')
                queryClient.refetchQueries('listEmployeeByApply')
                queryClient.invalidateQueries('requestsById')
                queryClient.invalidateQueries('applyById')
                queryClient.refetchQueries('listRequests')
                queryClient.invalidateQueries(['fleet', id])
            }, 500)
        },
    })
}
