import { useQuery } from "react-query";
import { useApi } from "../../../api";
import { DOCUMENT_TYPE_URI } from "../../../../constants/api";
import { buildUrlWithQueryParams } from "../../../../v2/helper/converters";

export async function getListTypes(filters = {}) {
  const { axiosInstance } = useApi();

  const url = buildUrlWithQueryParams(DOCUMENT_TYPE_URI, filters);
  const response = await axiosInstance.get(url);

  const { data, metadata } = response.data;

  return { data, metadata };
}

export function useListTypes(filters = {}) {
  return useQuery(["listDocumentTypes", filters], () => getListTypes(filters), {
    staleTime: 1000 * 60 * 60 * 24,
  });
}
