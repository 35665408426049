import { useNavigate, useParams } from "react-router-dom"
import { LoadingSpinner } from "../../components/LoadingSpinner"
import { Alert, Stack } from "@mui/material"
import { PaperWhite } from "../../components/Papers"
import { FormCreate } from "../../components/Forms/Employer/FormCreateEmployee"
import { useAuth } from "../../hook/useAuth"
import { useGetEmployee, useManageEmployee } from "../../services/hooks/Employee"
import { useCallback, useMemo, useState } from "react"
import { useMyProvider } from "../../services/hooks/Providers/useMyProvider"
import { contractType } from "../../constants/contractType"
import { employeeType } from "../../constants/employeeType"

const ManageEmployee = () => {
  const navigate = useNavigate()
  const handleGoBack = () => {
    navigate('/myrequest')
  }
  const { employeeId } = useParams();
  const { data: employee, isLoading: isEmployeeLoading } = useGetEmployee(employeeId)

  const { email } = useAuth()
  const { mutate, isLoading } = useManageEmployee()
  const [alertError, setAlertError] = useState(false)
  const { data: myProvider, isLoading: isProviderLoading } = useMyProvider(email)

  const handleConfirmation = () => {
    setAlertError({
      ...alertError,
      open: false,
    })
  }
  const formValues = useMemo(() => ({
    rut: employee?.numberRut ?? '',
    name: employee?.name ?? '',
    lastname1: employee?.lastname1 ?? '',
    lastname2: employee?.lastname2 ?? '',
    email: employee?.email ?? '',
    phone: employee?.phone ?? '',
    peoneta: employee?.type === employeeType.PEONETA ?? false,
    securityOpetation: false,
  }), [employee]);

  const handleSubmit = useCallback(async (data) => {
    mutate({
      data: {
        name: data.name,
        lastname1: data.lastname1,
        lastname2: data.lastname2,
        numberRut: data.rut,
        phone: data.phone,
        email: data.email,
        contractType: contractType.LABORAL,
        type: data.peoneta ? employeeType.PEONETA : employeeType.COURIER,
        subcontract: false,
        providerId: myProvider?.id
      },
    }, {
      onSuccess: () => {
        handleGoBack()
      },
      onError: () => {
        setAlertError({
          ...alertError,
          open: true,
          title: 'Error',
          messages:
            ['Oh no! se ha producido un error al crear proveedor'],
        })
      },
    })
  })

  if ( isProviderLoading || isEmployeeLoading ) return <LoadingSpinner open />
  return (
    <>
      <Stack spacing={2} sx={{mt:5}}>
        <PaperWhite>
          <FormCreate
            formValues={formValues}
            onSubmit={handleSubmit}
            goBack={handleGoBack}
          />
        </PaperWhite>
        {isLoading && <LoadingSpinner open />}
      </Stack>
      <Alert
        icon
        open={alertError.open}
        handleConfirmation={handleConfirmation}
        title={alertError.title}
        messages={alertError.messages}
      />
    </>
  )
}

export default ManageEmployee
