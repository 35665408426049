import { useQuery } from "react-query";
import { useApi } from "../../../api";
import { DOCUMENT_TYPE_URI } from "../../../../constants/api";

export async function getTypes(id) {
  if (id) {
    try {
      const { axiosInstance } = useApi();

      const response = await axiosInstance.get(`/${id}`, {
        baseURL: DOCUMENT_TYPE_URI,
      });

      return response.data;
    } catch (e) {
      return null;
    }
  } else {
    return null;
  }
}

export function useGetTypes(id) {
  return useQuery(["documentTypes", id], () => getTypes(id), {
    staleTime: 1000 * 60 * 60 * 24,
  });
}
