import { useParams } from 'react-router-dom'
import { Box, Chip, Stack, Typography } from '@mui/material'

import { useOfferDetails } from '../../../services/hooks/Offers'

import ImgDetail from '../../../assets/images/img-createOffer.png'

export function BoxHeader({ subTitle, content }) {
  const { offerId } = useParams()

  const { data } = useOfferDetails(offerId)

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        px: 5,
        mb: 3,
      }}
    >
      <Stack spacing={2}>
        {offerId && (
          <Stack direction='row' spacing={2} alignItems='center'>
            <Typography variant='subtitle1' style={{ fontWeight: 700 }}>
              Código de esta oferta
            </Typography>
            <Chip
              label={data.codeOffer}
              variant='outlined'
              sx={{
                borderColor: '#155C80',
                px: 3,
                '& .MuiChip-label': {
                  color: 'secondary.main',
                  fontWeight: 700,
                  fontSize: '1rem',
                },
              }}
            />
          </Stack>
        )}

        <Stack spacing={2} sx={{ maxWidth: 520 }}>
          <Typography variant='h6'>{subTitle}</Typography>
          <Typography variant='subtitle1'>{content}</Typography>
        </Stack>
      </Stack>
      <img src={ImgDetail} alt='detail' />
    </Box>
  )
}
