const setExpiration = () => {
  const time = new Date().getTime();
  const expireTime = time + 1000 * 36000;
  const expireDate = new Date(expireTime);
  return expireDate.toUTCString();
};

const encodeJSON = (json) => Buffer.from(JSON.stringify(json)).toString("base64");

export const saveSession = (session, storage = 'cookie') => {
  if (storage !== 'cookie') throw new Error('Save Session in local storage not implemented');
  const { ssoToken, ssoRefresh, idToken, ...userInfo } = session;
  const sessionSSO = { ssoToken, ssoRefresh };
  const sessionToken = { idToken };
  document.cookie = `__sessionUser=${encodeJSON(userInfo)};expires=${setExpiration()};path=/`;
  document.cookie = `__sessionSSO=${encodeJSON(sessionSSO)};expires=${setExpiration()};path=/`;
  document.cookie = `__sessionSSOTKN=${encodeJSON(sessionToken)};expires=${setExpiration()};path=/`; 
}
