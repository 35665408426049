import { Box, Button, Stack, Typography } from "@mui/material"
import { PlusCircleIconOrange } from "../Icons/PlusCircleIconOrange"
import { PaperWhite } from "../Papers"


export const AddNew = ({ handleCreate, title, description, button, image }) => {
  return (
    <PaperWhite sx={{ py: 4, mt: 6 }}>
      <Stack direction='row' spacing={2} justifyContent='space-around'>
        <Stack alignItems='left' justifyContent='center'>
          <Typography fontWeight='800' fontSize='24px' color='#0032A0'>{title}</Typography>
          <Typography variant='subtitle1'>
            {description}
          </Typography>
          <Stack mt={2} direction='row' flexWrap='wrap' justifyContent={{ xs: 'flex-start' }}>
            <Button
              variant='outlined'
              startIcon={<Box mt={2}><PlusCircleIconOrange /></Box>}
              onClick={() => handleCreate()}
              sx={{ width: '190px', height: '40px', fontSize: '13px', borderRadius: '20px' }}
            >
              {button}
            </Button>
          </Stack>
        </Stack>
        <Box
          alignSelf='end'
          component='img'
          alt={button}
          src={image}
          sx={{
            display: { xs: 'none', lg: 'block' }
          }}
        />
      </Stack>
    </PaperWhite>
  )
}