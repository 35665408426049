import { useMutation, useQueryClient } from 'react-query'
import { useApi } from '../../../api'
import { DOCUMENT_TYPE_URI } from '../../../../constants/api'

export function useCreateTypes() {
  const { axiosInstance } = useApi()
  const queryClient = useQueryClient()

  const url = new URL(`${DOCUMENT_TYPE_URI}`)

  return useMutation((type) => axiosInstance.post(url.toString(), type), {
    onSuccess: () => queryClient.invalidateQueries('listDocumentTypes'),
  })
}
