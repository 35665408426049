import React, { useState } from "react";
import {
  Stack,
  Typography,
  Grid,
  Box,
} from "@mui/material";
import { MessageBox } from "../../../../components/common/MessageBox";
import { LoadingSpinner } from "../../../../components/LoadingSpinner";
import { HeaderTabs } from "../../Requests/components/HeaderTabs";
import { ENTITY_TYPES, PROVIDER_ASSETS_TYPE } from "../../../../constants/entityTypes";
import { createSearchParams, useNavigate } from "react-router-dom";
import { useListProviderRequests } from "../../../services/hooks/Request";
import { SecurityRequestTable } from "../../Requests/components/SecurityRequestTable";
import { SecurityApprovalModal } from "./SecurityApprovalModal";
import { useSecurityManageRequest } from "../../../services/hooks/Request/Security/useSecurityAcceptRequest";
import wowIcon from "../../../../assets/images/wow.png";
import warning from "../../../../assets/images/warning.svg";
import { useURLfilters } from "../../../hooks/filters.hook";
import { employeeType } from "../../../../constants/employeeType";
import { RequestStatus } from "../../../../constants/status";
import { Alert } from "../../../../components/Alert";

export const entityfilters = {
  courier: {
    entityType: ENTITY_TYPES.EMPLOYEE,
    subType: PROVIDER_ASSETS_TYPE.COURIER,
    page: 1,
  },
  peoneta: {
    entityType: ENTITY_TYPES.EMPLOYEE,
    subType: PROVIDER_ASSETS_TYPE.PEONETA,
    page: 1,
  },
};

export default function SecurityRequests() {
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState();
  const [approvalModal, setApprovalModal] = useState({ show: false });
  const [alert, setAlert] = useState({
    title: "",
    open: false,
    messages: [],
    icon: "",
    buttonClose: true,
  });

  const defaultFilters = {
    page: 1,
    limit: 10,
    ...entityfilters[currentTab || employeeType.COURIER],
  };
  const { filters, setFilters, cleanFilters, getURLValue } = useURLfilters(
    defaultFilters,
    {
      excludeParams: ["tab"],
      persistParamOnClear: ["tab"],
    }
  );

  const {
    data: { data, metadata, error } = {},
    isLoading,
  } = useListProviderRequests({ ...filters, status: RequestStatus.BACKOFFICEAPPROVED });
  const { mutate: mutateManageRequest, isLoading: isLoadingManageRequest } = useSecurityManageRequest();

  const handleApprove = (request) => {
    setApprovalModal({
      show: true,
      action: "accept",
      requestId: request.id,
    });
  }

  const handleReject = (request) => {
    setApprovalModal({
      show: true,
      action: "reject",
      requestId: request.id
    });
  }

  const handleCloseAlert = () => {
    setAlert({
      ...alert,
      open: false,
    });
  };

  if (error) return <MessageBox>Ups, ocurrio un error!</MessageBox>;

  if (isLoading || isLoadingManageRequest) return <LoadingSpinner open />;

  const tabs = [
    {
      value: PROVIDER_ASSETS_TYPE.COURIER,
      label: "Conductores",
    },
    {
      value: PROVIDER_ASSETS_TYPE.PEONETA,
      label: "Peonetas",
    },
  ]

  const handleAcceptApproval = ({ requestId, type }) => {
    mutateManageRequest(
      {
        requestId,
        type,
        action: approvalModal.action
      },
      {
        onSuccess: () => {
          setAlert({
            ...alert,
            open: true,
            title: "Felicidades",
            buttonClose: false,
            icon: (
              <Box
                component="img"
                alt="icon"
                src={wowIcon}
                sx={{
                  display: { xs: "none", lg: "block" },
                  width: "75px",
                  height: "75px",
                }}
              />
            ),
            messages: [
            `La solicitud ha sido ${approvalModal.action === 'accept' ? 'aceptada' : 'rechazada'} correctamente`,
            ],
          });
          handleCloseApprovalModal();
        },
        onError: () => {
          handleCloseApprovalModal();
          setAlert({
            ...alert,
            icon: warning,
            open: true,
            title: "Error",
            messages: [
              `Error al aprobar la solicitud`,
            ],
          });
        }
      }
    );
    handleCloseApprovalModal();
  }

  const handleCloseApprovalModal = () => {
    setApprovalModal({ showApprove: false, action: undefined });
  }

  const onChangeTab = (tab) => {
    setCurrentTab(tab);
    setFilters(entityfilters[tab]);
  }

  const onCleanFilter = () => {
    cleanFilters();
  };

  return (
    <Stack spacing={4}>
      <Typography variant='h4' align='center'>
        Solicitudes
      </Typography>
      <Grid
        sx={{
          justifyContent: 'center'
        }}
        container
        spacing={2}
      >
        <HeaderTabs
          tabs={tabs}
          xs={12}
          sm={8}
          md={4}
          value={getURLValue("tab") ?? PROVIDER_ASSETS_TYPE.COURIER}
          onChange={(e, searchInput) => {
            onChangeTab(searchInput);
            navigate({
              pathname: '',
              search: `${createSearchParams({
                tab: searchInput,
              })}`
            });
          }}
        />
      </Grid>
      <SecurityRequestTable
        data={data}
        entityType={currentTab ?? getURLValue("tab") ?? "courier"}
        metadata={metadata}
        handleApprove={handleApprove}
        handleReject={handleReject}
        getValue={getURLValue}
        onCleanFilter={onCleanFilter}
        setFilters={setFilters}
      />
      <SecurityApprovalModal
        isOpen={approvalModal.show}
        onClose={handleCloseApprovalModal}
        action={approvalModal.action}
        onCancel={handleCloseApprovalModal}
        onAccept={handleAcceptApproval}
        requestId={approvalModal.requestId}
      />
      <Alert
        open={alert.open}
        title={alert.title}
        messages={alert.messages}
        icon={alert.icon}
        buttonClose={alert.buttonClose}
        onClose={handleCloseAlert}
        handleConfirmation={handleCloseAlert}
      />
    </Stack>
  );
}
