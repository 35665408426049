import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";

import {
  Box,
  Stack,
  Button,
  Typography,
} from "@mui/material";

import { Input } from "../../../../components/Inputs";
import { Modal } from "../../../../components/Modals/Modal";

import {
  validateMinLength,
  isComplexTextNumberValid,
} from "../../../../utils/validateValues";

const typeDoc = [
  { id: 1, label: "pdf" },
  { id: 2, label: "png" },
  { id: 3, label: "jpg" },
  { id: 4, label: "docx" },
  { id: 5, label: "csv" },
];

const initialFormValues = {
  label: "",
  description: "",
  extension: typeDoc,
  isRequired: true,
};

export function UpsertDocumentInput({
  index,
  isEdit,
  selectedDoc,
  setDocuments,
  openModal,
  handleCloseModal,
}) {
  const { control, handleSubmit, setValue, reset } = useForm(initialFormValues);
  const onSubmit = (data) => {
    const dataDoc = { ...initialFormValues, ...data}
    const currentDocType = {
      name: dataDoc.label.toLowerCase().split(" ").join("_"),
      label: dataDoc.label,
      description: dataDoc.description,
      extension: dataDoc.extension.map((ext) => ext.label),
      isRequired: dataDoc.isRequired,
    };

    if (!isEdit) {
      setDocuments((prev) => [...prev, currentDocType]);
    } else {
      setDocuments((prev) => {
        const newDocuments = [...prev];
        newDocuments.splice(index, 1, currentDocType);

        return newDocuments;
      });
    }

    handleCloseAndReset();
  };

  const handleCloseAndReset = () => {
    handleCloseModal();
    reset(initialFormValues);
  };

  useEffect(() => {
    if (isEdit) {
      setValue("name", selectedDoc?.name);
      setValue("label", selectedDoc?.label);
      setValue("description", selectedDoc?.description);
      setValue(
        "extension",
        typeDoc?.filter((pred) => selectedDoc?.extension?.includes(pred.label))
      );
      setValue("isRequired", selectedDoc?.isRequired);
    }
  }, [selectedDoc]);

  return (
    <Modal open={openModal} handleClose={handleCloseAndReset}>
      <Stack spacing={2} component="form">
        <Typography sx={{ marginTop: 2 }} variant="h5" align="center">
          {isEdit ? "Editar documento" : "Crear documento"}
        </Typography>

        <Box>
          <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={3}>
            <Box gridColumn={{ xs: "span 12", lg: "span 6" }}>
              <Controller
                control={control}
                name="label"
                rules={{
                  required: "(*) Campo obligatorio",
                  validate: {
                    minValid: (text) =>
                      validateMinLength(text, 1) ||
                      "El nombre del documento no puede ser menor a 1 caracteres",
                    textIsValid: (text) =>
                      isComplexTextNumberValid(text) ||
                      "Nombre inválido, no se aceptan caracteres extraños",
                  },
                }}
                render={({
                  field: { value, onChange, ...rest },
                  fieldState: { error },
                }) => (
                  <Input
                    label="Nombre"
                    fullWidth
                    isRequired
                    value={value}
                    onChange={onChange}
                    error={Boolean(error)}
                    helperText={error?.message}
                    inputProps={{ maxLength: 50 }}
                    {...rest}
                  />
                )}
              />
            </Box>

            <Box gridColumn={{ xs: "span 12", lg: "span 6" }}>
              <Controller
                control={control}
                name="description"
                rules={{
                  required: "(*) Campo obligatorio",
                }}
                render={({
                  field: { value, onChange, ...rest },
                  fieldState: { error },
                }) => (
                  <Input
                    label="Descripción"
                    fullWidth
                    isRequired
                    value={value}
                    onChange={onChange}
                    error={Boolean(error)}
                    helperText={error?.message}
                    inputProps={{ maxLength: 80 }}
                    {...rest}
                  />
                )}
              />
            </Box>

            {/* 
            <Box gridColumn={{ xs: "span 12", lg: "span 12" }}>
              <Controller
                control={control}
                name="extension"
                rules={{
                  required: "(*) Campo obligatorio",
                }}
                render={({
                  field: { value, onChange, ...rest },
                  fieldState: { error },
                }) => (
                  <Autocomplete
                    value={value}
                    onChange={(e, newValue) => onChange(newValue)}
                    isOptionEqualToValue={(option, type) =>
                      option.id === Number(type)
                    }
                    getOptionLabel={(option) => option.label || ""}
                    size="small"
                    disablePortal
                    options={typeDoc.filter(
                      (option) =>
                        !value?.some((selected) => selected.id === option.id)
                    )}
                    multiple
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          key={option.id}
                          variant="outlined"
                          label={option.label}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <Input
                        {...params}
                        label="Extensión"
                        isRequired
                        error={Boolean(error)}
                        helperText={error?.message}
                        placeholder="Extensión"
                        {...rest}
                      />
                    )}
                  />
                )}
              />
            </Box>
            */}
          </Box>
        </Box>

        <Stack
          spacing={2}
          direction="row"
          justifyContent="flex-end"
          paddingY={2}
        >
          <Button size="large" onClick={handleSubmit(onSubmit)}>
            {isEdit ? "Editar" : "Guardar"}
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
}
