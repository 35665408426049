import { useState } from 'react'
import {
  Table as MuiTable,
  TableHead as MuiTableHead,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  TablePagination as MuiTablePagination,
  TableSortLabel as MuiTableSortLabel,
  Box,
  Stack,
} from '@mui/material'

export const Table = (
  records,
  headCells,
  filterFn = { fn: (filter) => filter }
) => {
  const pages = [5, 10, 25]
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(pages[page])
  const [order, setOrder] = useState('desc')
  const [orderBy, setOrderBy] = useState('')

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array?.map((el, index) => [el, index])
    stabilizedThis?.sort((a, b) => {
      const ordered = comparator(a[0], b[0])
      if (ordered !== 0) {
        return ordered
      }
      return a[1] - b[1]
    })
    return stabilizedThis?.map((el) => el[0])
  }

  const handleSpecialCases = (property) => {
    if (property === 'model') {
      return 'modelId'
    }

    if (property === 'type') {
      return 'vehicleTypeId'
    }

    if (property === 'nameProvider') {
      return 'provider'
    }

    if (property === 'document') {
      return 'description'
    }

    if (property === 'file') {
      return 'nameStandard'
    }
    return property
  }

  const addAditionalProperty = (property) => {
    if (property === 'brand' || property === 'type' || property === 'model') {
      return 'description'
    }
    if (property === 'nameProvider') {
      return 'nameProvider'
    }
    return null
  }

  function getPropValue(obj, property) {
    if (obj === null) {
      return undefined
    }
    const aditional = addAditionalProperty(property)

    property = handleSpecialCases(property)

    if (obj.hasOwnProperty(property)) {
      if (aditional) return obj[property][aditional]
      return obj[property]
    }
    for (const key in obj) {
      if (obj.hasOwnProperty(key) && typeof obj[key] === 'object') {
        const propValue = getPropValue(obj[key], property)
        if (propValue !== undefined && propValue !== null) {
          return propValue
        }
      }
    }
    return undefined
  }

  const combinedComparatorFlow = (a, b, orderByParam) => {
    let array
    if (orderByParam === 'address') {
      array = ['street', 'number']
    }

    const result = array.map((property) => {
      const propValueA = getPropValue(a, property)
      const propValueB = getPropValue(b, property)
      return { propValueA, propValueB }
    })
    const joinedResults = result.reduce((accumulator, currentValue) => {
      Object.keys(currentValue).forEach((key) => {
        if (!accumulator[key]) {
          accumulator[key] = currentValue[key]
        } else {
          accumulator[key] += `, ${currentValue[key]}`
        }
      })
      return accumulator
    }, {})

    const { propValueA, propValueB } = joinedResults
    if (propValueB < propValueA) {
      return -1
    }
    if (propValueB > propValueA) {
      return 1
    }
    return 0
  }

  function descendingComparator(a, b, orderByParam) {
    if (orderByParam === 'address') {
      return combinedComparatorFlow(a, b, orderByParam)
    }

    const propValueA = getPropValue(a, orderByParam)
    const propValueB = getPropValue(b, orderByParam)
    if (propValueB < propValueA) {
      return -1
    }
    if (propValueB > propValueA) {
      return 1
    }
    return 0
  }

  function getComparator(orderParam, orderByParam) {
    return orderParam === 'desc'
      ? (a, b) => descendingComparator(a, b, orderByParam)
      : (a, b) => -descendingComparator(a, b, orderByParam)
  }

  const recordsAfterPagingAndSorting = () =>
    stableSort(filterFn.fn(records), getComparator(order, orderBy))?.slice(
      page * rowsPerPage,
      (page + 1) * rowsPerPage
    )

  const TableContainer = ({ breakPoint, children }) => (
    <Box
      sx={{
        overflowX: 'auto',
        display: 'none',
        width: '100%',
        [`@media (min-width: ${breakPoint ?? '0px'})`]: {
          display: 'table',
        },
      }}
    >
      <MuiTable>{children}</MuiTable>
    </Box>
  )

  const TableHead = () => {
    const handleSortRequest = (cellId) => {
      const isAsc = orderBy === cellId && order === 'asc'
      setOrder(isAsc ? 'desc' : 'asc')
      setOrderBy(cellId)
    }

    return (
      <MuiTableHead>
        <MuiTableRow>
          {headCells.map((headCell) => (
            <MuiTableCell
              key={headCell.id}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {headCell.disableSorting ? (
                headCell.label
              ) : (
                <MuiTableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : 'asc'}
                  onClick={() => {
                    handleSortRequest(headCell.id)
                  }}
                >
                  {headCell.label}
                </MuiTableSortLabel>
              )}
            </MuiTableCell>
          ))}
        </MuiTableRow>
      </MuiTableHead>
    )
  }

  const TablePagination = () => (
    <Stack direction='row' flexWrap='wrap'>
      <Box sx={{ p: 2, '& span': { fontWeight: '700' } }}>
        Total de Registros:{' '}
        <span> {String(records?.length).padStart(2, '0')}</span>
      </Box>
      {records?.length > 5 && (
        <MuiTablePagination
          rowsPerPageOptions={pages}
          component='div'
          count={records.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={<span>Filas por página:</span>}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} de ${count}`
          }
          showFirstButton
          showLastButton
          backIconButtonProps={{ color: 'secondary' }}
          nextIconButtonProps={{ color: 'secondary' }}
          sx={{
            '&': {
              flexGrow: 1,
            },
            '.MuiTablePagination-toolbar': {
              width: '100%',
              flexWrap: 'wrap',
              justifyContent: 'center',
              '@media (min-width: 930px)': {
                justifyContent: 'flex-end',
              },
            },
          }}
        />
      )}
    </Stack>
  )

  return {
    TableContainer,
    TableHead,
    TablePagination,
    setPage,
    recordsAfterPagingAndSorting,
  }
}