import { useCallback, useState, useEffect } from "react";
import { Stack, Typography } from "@mui/material";
import { StepperController } from "../../../components/Stepper";
import { stepsReplace } from "../../../../constants/steps";
import { ProviderReplaceForm } from "../components/ProviderReplaceForm";
import { PROVIDER_ASSETS_TYPE } from "../../../../constants/entityTypes";
import { FleetForm } from "../components/FleetForm";
import { useAtom } from "jotai";
import {
  replaceDataAtom,
  replaceFleetDataAtom,
} from "../../../store/requestTracker/replaceRequest.store";
import { useCreateFleet } from "../../../../services/hooks/Fleets/useCreateFleet";
import { useHardDeleteFleetEntity } from "../../../../services/hooks/Fleets/useHardDeleteFleetEntity";
import { validateGroupedDocuments } from "../../../helper/documents";
import { useUploadDocuments } from "../../../../services/hooks/Docs/S3/useUploadDocument";
import { Alert } from "../../../../components/Alert";
import { FinishReplace } from "./FinishReplace";
import { LoadingSpinner } from "../../../../components/LoadingSpinner";
import {
  ENTITY_TYPES,
  ENTITY_TYPES_MAPPING,
} from "../../../../constants/entityTypes";
import { FleetStatus } from "../../../../constants/status";
import { useCreateReplacement } from "../../../services/hooks/Request/index";
import { REQUEST_TYPE } from "../../../helper/request";

const initialAlertError = {
  open: false,
  title: "",
  messages: [],
  content: "",
};

const initialValue = {
  plate: "",
  brand: "",
  model: "",
  type: "",
};

export const ReplaceFleet = ({ goBack, item, providerId }) => {
  const [step, setStep] = useState(1);
  const [replaceData, setReplaceData] = useAtom(replaceDataAtom);
  const [fleetData, setFleetData] = useAtom(replaceFleetDataAtom);
  const [alertError, setAlertError] = useState(initialAlertError);
  const { mutate: createMutate, isLoading: createIsLoading } = useCreateFleet();
  const [currentTemplate, setCurrentTemplate] = useState(null);
  const { mutate: mutateUploadDocument, isLoading: isLoadingUpload } =
    useUploadDocuments();
  const { mutate: deleteMutateFleet, isLoading: deleteIsLoading } =
    useHardDeleteFleetEntity();
  const [replaceFinish, setReplaceFinish] = useState(false);
  const { mutate: mutateCreateReplacement, isLoading: isLoadingReplacement } =
    useCreateReplacement();

  const submitStepOne = () => {
    setStep(2);
  };

  useEffect(() => {
    setFleetData((prev) => ({
      ...prev,
      year: "",
      validate: { plate: "", isValid: false },
    }));
  }, [fleetData.plate]);

  const handleSubmit = useCallback(
    async (fleet) => {
      const data = {
        plate: fleetData.plate,
        providerId,
        model: fleetData.model,
        brand: fleetData.brand,
        type: fleetData.type,
        documentTemplateId: fleetData.documentTemplateId,
        year: fleetData.year,
      };
      const { groupedDataByDocumentType } = validateGroupedDocuments(
        currentTemplate.documentTypes,
        fleet
      );
      createMutate(
        {
          data,
          fleetId: fleet?.id,
        },
        {
          onSuccess: (createdFleet) => {
            const groupedDataKeys = Object.keys(groupedDataByDocumentType);
            Object.entries(groupedDataByDocumentType).forEach(
              ([key, documentToUpload]) => {
                const beforeUpload = Object.assign({}, documentToUpload);
                beforeUpload["ownerId"] = createdFleet?.id;
                beforeUpload["ownerType"] = ENTITY_TYPES.FLEET;
                beforeUpload.metaData = JSON.stringify(beforeUpload.metaData);
                const isLastDocumentKey =
                  key === groupedDataKeys[groupedDataKeys.length - 1];
                mutateUploadDocument(beforeUpload, {
                  onSuccess: () => {
                    if (isLastDocumentKey) {
                      const replace = {
                        type: replaceData.isPermanent
                          ? REQUEST_TYPE.PERMANENT_REPLACEMENT
                          : REQUEST_TYPE.TEMPORARY_REPLACEMENT,
                        status: FleetStatus.PENDING,
                        requested: {
                          for: {
                            type: ENTITY_TYPES.FLEET,
                            subType: item.type,
                            plate: item.plate || "",
                            courierDni: item.courierDni || "",
                            peonetaDni: item.peonetaDni || "",
                          },
                        },
                        metadata: {
                          entityToReplace: item,
                          replacement: createdFleet,
                          setting: {
                            ...replaceData,
                            isNewEntity: true,
                          },
                        },
                      };
                      mutateCreateReplacement(replace, {
                        onSuccess: () => {
                          onFinish();
                        },
                        onError: () => {
                          deleteMutateFleet(
                            {
                              idToDelete: createdFleet?.id,
                            },
                            {
                              onSuccess: () => {},
                              onError: () => {
                                setAlertError({
                                  ...alertError,
                                  open: true,
                                  title: "Error",
                                  messages: [
                                    "Oh no! se ha producido un error al eliminar el vehículo",
                                  ],
                                });
                              },
                            }
                          );
                          setAlertError({
                            ...alertError,
                            open: true,
                            title: "Error",
                            messages: [
                              "Oh no! se ha producido un error al crear la solicitud de reemplazo",
                            ],
                          });
                        },
                      });
                    }
                  },
                  onError: () => {
                    deleteMutateFleet(
                      {
                        idToDelete: createdFleet?.id,
                      },
                      {
                        onSuccess: () => {},
                        onError: () => {
                          setAlertError({
                            ...alertError,
                            open: true,
                            title: "Error",
                            messages: [
                              "Oh no! se ha producido un error al eliminar el vehículo",
                            ],
                          });
                        },
                      }
                    );
                    setAlertError({
                      ...alertError,
                      open: true,
                      title: "Error",
                      messages: [
                        "Oh no! se ha producido un error al cargar los documentos",
                      ],
                    });
                  },
                });
              }
            );
          },
          onError: () => {
            setAlertError({
              ...alertError,
              open: true,
              title: "Error",
              messages: [
                `Oh no! se ha producido un error al crear ${ENTITY_TYPES_MAPPING.fleet}`,
              ],
            });
          },
        }
      );
    },
    [currentTemplate, fleetData, replaceData]
  );

  const handlePrevStep = () => {
    setStep(1);
  };

  const handleConfirmation = () => {
    setAlertError({
      ...alertError,
      open: false,
    });
  };

  const onFinish = () => {
    setReplaceData({});
    setFleetData(initialValue);
    setReplaceFinish(true);
  };
  if (replaceFinish) {
    return (
      <Stack spacing={4}>
        <Typography variant="h4" align="center">
          {`Reemplazar ${ENTITY_TYPES_MAPPING[item.type]}`}
        </Typography>
        <FinishReplace
          entity={ENTITY_TYPES.FLEET}
          goBack={goBack}
          goBackTitleButton={`Volver a Mis Vehículos`}
        />
      </Stack>
    );
  }


  return (
    <>
      <Stack spacing={4}>
        <Typography variant="h4" align="center">
          {"Reemplazar vehículo"}
        </Typography>
        {(step === 1 || step === 2) && (
          <StepperController steps={stepsReplace} isCurrentStepActive={step} />
        )}

        {step === 1 && (
          <ProviderReplaceForm
            type={PROVIDER_ASSETS_TYPE.FLEET}
            goBack={goBack}
            onSubmit={submitStepOne}
            atom={replaceData}
          />
        )}
        {step === 2 && (
          <>
            <FleetForm
              item={item}
              goBack={handlePrevStep}
              onSubmit={handleSubmit}
              setTemplate={setCurrentTemplate}
              setReplaceFinish={setReplaceFinish}
            ></FleetForm>
            {(createIsLoading ||
              isLoadingUpload ||
              deleteIsLoading ||
              isLoadingReplacement) && <LoadingSpinner open />}
          </>
        )}
      </Stack>
      <Alert
        icon
        open={alertError.open}
        handleConfirmation={handleConfirmation}
        title={alertError.title}
        messages={alertError.messages}
      />
    </>
  );
};
