import {
  Button,
  IconButton,
  Link,
  Stack,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { formatDate } from "../../../utils/formatValues";
import {
  EmployeeStatus,
  MESSAGE_STATUS_MAPPING,
  StatusColor,
} from "../../../constants/status";
import {
  ThumbDownOffAltOutlined,
  ThumbUpOffAltOutlined,
} from "@mui/icons-material";
import { Table } from "../Table";
import { ChipStatus } from "../Chip";
import { formatSnakeCase } from "../../helper/strings";

export const DocumentTable = ({
  addons,
  data,
  dataDocument,
  onThumbUp,
  onThumbDown,
}) => {
  const headCells = [
    {
      id: "title",
      label: "Tipo Documento",
      exportExcel: true,
    },
    {
      id: "file",
      label: "Archivo",
      exportExcel: true,
    },
    {
      id: "expiredDate",
      label: "Fecha vencimiento",
      exportExcel: true,
    },
    {
      id: "status",
      label: "Estado",
      exportExcel: true,
    },
    {
      id: "actions",
      label: "Acciones",
      disableSorting: true,
      exportExcel: false,
    },
  ];
  const { TableContainer } = Table(headCells, data);
  return (
    <TableContainer addons={addons}>
      <TableBody>
        {dataDocument.map((document) => (
          <TableRow hover tabIndex={-1} key={document.id}>
            <TableCell key={document.files[0].groupName}>
              {formatSnakeCase(document.files[0].groupName)}
            </TableCell>
            <TableCell>
              {document.files.map((file, index) => (
                <Stack key={index}>
                  <Button
                    variant="goBack"
                    disableRipple
                    sx={{
                      alignSelf: "self-start",
                    }}
                  >
                    <Link
                      href={file.path}
                      target="_blank"
                      rel="noopener noreferrer"
                      download={file?.name}
                      sx={{
                        color: "#3363FF",
                        fontWeight: 800,
                      }}
                    >
                      {file?.labelName}
                    </Link>
                  </Button>
                </Stack>
              ))}
            </TableCell>
            <TableCell>
              {document.expirationDate
                ? formatDate(document.expirationDate, "DD-MM-YYYY")
                : "-"}
            </TableCell>
            <TableCell>
              {document?.status && (
                <ChipStatus
                  label={EmployeeStatus[document.status]}
                  setcolor={document.status}
                />
              )}
            </TableCell>
            <TableCell>
              <Stack direction="row">
                <IconButton
                  onClick={() => onThumbUp(document)}
                  disabled={
                    document.status === EmployeeStatus.APPROVED ||
                    document.status === EmployeeStatus.REJECTED
                  }
                >
                  <ThumbUpOffAltOutlined
                    color={
                      document.status === MESSAGE_STATUS_MAPPING.APPROVE ||
                      document.status === EmployeeStatus.APPROVED ||
                      document.action === MESSAGE_STATUS_MAPPING.APPROVE
                        ? StatusColor.approve
                        : ""
                    }
                  />
                </IconButton>

                <IconButton
                  onClick={() => onThumbDown(document)}
                  disabled={
                    document.status === EmployeeStatus.APPROVED ||
                    document.status === EmployeeStatus.REJECTED
                  }
                >
                  <ThumbDownOffAltOutlined
                    color={
                      document.status === MESSAGE_STATUS_MAPPING.REJECT ||
                      document.action === MESSAGE_STATUS_MAPPING.REJECT
                        ? StatusColor.reject
                        : ""
                    }
                  />
                </IconButton>
              </Stack>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </TableContainer>
  );
};
