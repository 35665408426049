import { Document } from "./Document";
import { Typography, Box, Stack } from "@mui/material";
import { Controller } from "react-hook-form";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { es } from "date-fns/locale";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { CalendarMonthOutlined } from "@mui/icons-material";
import { Input } from "../../Inputs";

export const GroupedDocuments = ({
  control,
  groupedDocuments,
}) => {
  const { name, documents, hasExpiration, label } = groupedDocuments;

  return (
    <>
      <Box>
      <Typography 
        variant="h6" 
        fontFamily="Lato" 
        fontWeight="800px" 
        fontSize="18px" 
        lineHeight="24.3px"
        sx={{mb:4}}
        >
          {label}
        </Typography>
      </Box>
      <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
        {documents.map((individualDocument) => (
          <Document
            groupedDocuments={groupedDocuments}
            key={individualDocument.id}
            control={control}
            document={individualDocument}
          />
        ))}
        <Box gridColumn="span 4">
        {hasExpiration && (
          <Controller
            control={control}
            name={`${name}_expiration_date`}
            rules={{ required: "(*) Campo obligatorio" }}
            render={({
              field: { value, onChange },
              fieldState: { error },
            }) => (
              <Stack position="relative">
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={es}
                >
                  <span>
                    {"Válido hasta"}{" "}
                    {<span style={{ color: "orange" }}>*</span>}
                  </span>
                  <DesktopDatePicker
                    variant="outlined"
                    value={value || ""}
                    onChange={onChange}
                    minDate={new Date()}
                    popperProps={{
                      placement: "bottom-end",
                    }}
                    components={{
                      OpenPickerIcon: CalendarMonthOutlined,
                    }}
                    renderInput={(params) => (
                      <Input
                        {...params}
                        error={Boolean(error)}
                        helperText={error?.message}
                        {...params.inputProps}
                        ref={params.inputRef}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Stack>
            )}
          />
        )}
        </Box>
      </Box>
    </>
  );
};
