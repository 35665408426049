/* eslint-disable no-param-reassign */
import axios from 'axios'

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    apikey: process.env.REACT_APP_API_KEY,
  },
})

const axiosInstanceFree = axios.create()

export const useApi = () => {
  const configAuthorization = (session) => {
    axiosInstance.defaults.headers.common.Authorization = session?.ssoToken
    axiosInstance.defaults.headers.common.sessionId = session?.sessionid
  }

  const setEmployeeAuthorization = (employee) => {
    const employeeBase64 = employee ? btoa(JSON.stringify(employee)) : null
    axiosInstance.defaults.headers.common['x-employee'] = employeeBase64
  }

  return { axiosInstance, configAuthorization, axiosInstanceFree, setEmployeeAuthorization }
}
