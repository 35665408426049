import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const defaultConfig = {
  excludeParams: [],
  extraParams: {},
  persistParamOnClear: [],
};

const falsyFilters = [undefined, null, ""];

export const useURLfilters = (initialState, config = defaultConfig) => {
  const location = useLocation();
  const [currentSearch, setCurrentSearch] = useState(null);
  const [internalFilters, setFilters] = useState();
  const [onChangeEvents, setOnChangeEvents] = useState([]);

  const getFilters = useCallback(() => {
    const newFilters = {};
    for (const key of Object.keys(internalFilters || {})) {
      if (
        !config.excludeParams?.includes(key) &&
        internalFilters[key] !== undefined
      ) {
        newFilters[key] = internalFilters[key];
      }
    }
    return { ...newFilters, ...(config.extraParams || {}) };
  }, [internalFilters]);

  const getParamsFromURL = (search) => {
    const params = new URLSearchParams(search);
    const newFilters = {};

    for (const [key, value] of params) {
      newFilters[key] = value;
    }

    setFilters((old) => ({ ...old, ...newFilters }));
  };

  const updateURLFilters = () => {
    if (!internalFilters || Object.keys(internalFilters).length === 0) return;

    const searchParams = new URLSearchParams();

    for (const key in internalFilters) {
      if (!falsyFilters.includes(internalFilters[key])) {
        searchParams.append(key, internalFilters[key]);
      }
    }

    const newURL = `${location.pathname}?${searchParams.toString()}`;
    window.history.pushState({}, "", newURL);
    setCurrentSearch(searchParams.toString());
  };

  const cleanFilters = () => {
    const persistentFilters = {};

    for (const key of Object.keys(internalFilters || {})) {
      if (config.persistParamOnClear?.includes(key)) {
        persistentFilters[key] = internalFilters[key];
      }
    }

    setFilters({ ...initialState, ...persistentFilters });
  };

  const addOnChangeEvents = (event = () => {}) => {
    if (typeof event !== "function") {
      throw new Error("Event must be a function");
    }

    setOnChangeEvents([...onChangeEvents, event]);
  };

  const updateFilters = (newFilters) => {
    setFilters((old) => ({ ...old, ...newFilters }));
  };

  const getURLValue = (key, defaultValue) => {
    if (internalFilters && internalFilters.hasOwnProperty(key)) {
      return internalFilters[key];
    }
    return defaultValue;
  };

  useEffect(() => {
    if (location.search !== currentSearch) getParamsFromURL(location.search);
  }, [location.search]);

  useEffect(() => {
    updateURLFilters();
    onChangeEvents.map((event) => event(internalFilters));
  }, [internalFilters]);

  useEffect(() => {
    location.search === ""
      ? updateFilters(initialState)
      : getParamsFromURL(location.search);

    return () => {
      cleanFilters();
    };
  }, []);

  return {
    filters: getFilters(),
    setFilters: updateFilters,
    cleanFilters,
    addOnChangeEvents,
    getURLValue,
  };
};
