import { useQuery } from "react-query";
import { APPLY_URI } from "../../../../constants/api";
import { useApi } from "../../../../services/api";

const useGetApplyByProviderAndOffer = (providerId, offerId) => {
  const fetchApplyByProviderAndOffer = async () => {
    const { axiosInstance } = useApi();
    const response = await axiosInstance.get(
      `/provider/${providerId}/offer/${offerId}`,
      {
        baseURL: APPLY_URI,
      }
    );

    return response.data;
  };

  return useQuery(
    ["apply", providerId, offerId],
    fetchApplyByProviderAndOffer,
    { enabled: false, retry: 0 }
  );
};

export default useGetApplyByProviderAndOffer;
