import { useMutation, useQueryClient } from 'react-query'
import { useApi } from '../../../api'
import { VEHICLE_URI } from '../../../../constants/api'

export function useCreateVehicleType() {
  const { axiosInstance } = useApi()
  const queryClient = useQueryClient()

  return useMutation((type) => axiosInstance.post('/type', type, {
    baseURL: VEHICLE_URI
  }), {
    onSuccess: () => queryClient.invalidateQueries('listVehiclesTypes'),
  })
}
