import { useQuery } from "react-query";
import { useApi } from "../../api";
import { EMPLOYEE_URI } from "../../../constants/api";

export async function getMyProvider(email) {
  try {
    const { axiosInstance } = useApi();
    const response = await axiosInstance.get(`/provider/${email}`, {
      baseURL: EMPLOYEE_URI,
    });

    return response?.data;
  } catch (error) {
    return null;
  }
}

export function useMyProvider(email, enabled = true) {
  return useQuery(["provider", email], () => getMyProvider(email), {
    staleTime: 1000 * 60 * 40,
    enabled,
  });
}
