import { useMutation, useQueryClient } from "react-query";
import { useApi } from "../../api";
import { LOCALITY_URI } from "../../../constants/api";

export function useDeleteBases() {
  const { axiosInstance } = useApi();
  const queryClient = useQueryClient();

  return useMutation(
    ({ id, localityId }) =>
      axiosInstance.delete(`/${localityId}/base/${id}`, {
        baseURL: LOCALITY_URI,
      }),
    {
      onSuccess: () => queryClient.invalidateQueries("listBases"),
    }
  );
}
