import React from 'react'
import * as XLSX from 'xlsx'
import { Stack, Box, Avatar, IconButton, Tooltip } from '@mui/material'
import downloadButton from '../../assets/images/download-button.svg'

export function DownloadExportData({ data, columns, nameFile }) {
  const handleDownloadExcel = () => {
    if (data.length > 0) {
      const datas = []
      data.forEach((dat, index) => {
        const obj = {}
        columns.forEach((column) => {
          if (column.exportExcel === true) {
            obj[column.label] = data[index][column.id]
          }
        })
        datas.push(obj)
      })

      const wb = XLSX.utils.book_new()
      const ws = XLSX.utils.json_to_sheet(datas)
      XLSX.utils.book_append_sheet(wb, ws, nameFile)
      XLSX.writeFile(wb, `${nameFile}.xlsx`)
    }
  }

  return (
    <Stack sx={{ height: '38px', marginTop: '-9px' }}>
      <Box sx={{ paddingRight: '20px', paddingLeft: '20px' }}>
        <IconButton
          size='medium'
          aria-label='avatar'
          color='inherit'
          onClick={handleDownloadExcel}
        >
          <Tooltip title='Descargar Excel'>
            <Avatar src={downloadButton} />
          </Tooltip>
        </IconButton>
      </Box>
    </Stack>
  )
}
