import { Typography } from '@mui/material'
import React from 'react'
import { PaperWhite } from '../../Papers'

const style = {
  height: '100%',
  display: 'grid',
  placeItems: 'center',
}

export function MessageBox({ children }) {
  return (
    <PaperWhite sx={style}>
      <Typography variant='h4'>{children}</Typography>
    </PaperWhite>
  )
}
