import { useState, useEffect } from 'react'
import { useNavigate, useParams,useSearchParams } from 'react-router-dom'
import { Button, Stack, Typography } from '@mui/material'
import { ArrowBackOutlined } from '@mui/icons-material'
import { Controller, useForm } from 'react-hook-form'

import { PaperWhite } from '../../../components/Papers'
import { Input } from '../../../components/Inputs'
import { AlertSnackBar } from '../../../components/Alerts'
import { LoadingSpinner } from '../../../components/LoadingSpinner'
import { ComboBox } from '../../../components/ComboBox'

import {
  useCreateBases,
  useGetBases,
  useUpdateBases,
} from '../../../services/hooks/Bases'

import { useListGeographies } from '../../../services/hooks'

import { 
  isComplexTextNumberValid,
  validateMinLength
} from '../../../utils/validateValues'

const initialAlertError = {
  open: false,
  message: '',
}

const defaultValues = {
  name: '',
  code: '',
  locality: [],
}

export default function FormBases({ fromButton }) {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate()
  const { baseId } = useParams()
  const localityId = searchParams.get('localityId');

  const [alertError, setAlertError] = useState(initialAlertError)
  const [locality, setLocality] = useState(null);
  const { control, handleSubmit, setValue } = useForm({ defaultValues })
  const { data: structuresList, isLoading } = useListGeographies()
  const { data: base, isLoading: isLoadingGetBases } = useGetBases(localityId, baseId)

  const { mutate: mutateCreate, isLoading: isLoadingCreate } = useCreateBases()
  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } =
    useUpdateBases(localityId,baseId)

  useEffect(() => {
    if (!fromButton) navigate('/404')
  }, [])

  useEffect(()=>{
    if(structuresList && localityId) {
      setLocality(structuresList.find((structure) => structure.id === localityId))
    }
  },[structuresList])

  useEffect(() => {
    if (base) {
      setValue('name', base.name)
      setValue('code', base.code)
      setValue('locality', locality ? { name: locality?.name } : null )
    }
  }, [base, locality])

  const handleCloseAlertError = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setAlertError(initialAlertError)
  }

  const renderOptionLabel = (option) => {
    if (Object.entries(option).length === 0) return ''

    return `${option?.name}`
  }

  const onSubmit = (data) => {
    const { name, locality, code } = data

    const newData = {
      name,
      code,
      localityId: locality.id,
    }

    if (baseId) {
      mutateUpdate(newData, {
        onSuccess: () => navigate('/Bases'),
        onError: () =>
          setAlertError({
            open: true,
            message: 'Oh no! se ha producido un error al editar una base',
          }),
      })
    } else {
      mutateCreate(newData, {
        onSuccess: () => navigate('/Bases'),
        onError: () =>
          setAlertError({
            open: true,
            message: 'Oh no! se ha producido un error al crear una nueva base',
          }),
      })
    }
  }

  if (isLoadingGetBases || isLoadingCreate || isLoadingUpdate) {
    return <LoadingSpinner open />
  }

  return (
    <Stack spacing={2} component='form' onSubmit={handleSubmit(onSubmit)}>
      <Typography variant='h4' align='center'>
        {baseId ? 'Editando base' : 'Creando un nueva bases'}
      </Typography>

      <PaperWhite sx={{ pb: 20 }}>
        <Stack spacing={2} sx={{ width: '50%' }}>
          <Controller
            control={control}
            name='name'
            rules={{ 
              required: '(*) Campo obligatorio',
              validate: {
                minValid: (text) => validateMinLength(text, 1) || 'Nombre no puede ser menor a 1 caracteres',
                textIsValid: (text) => isComplexTextNumberValid(text) || 'Nombre inválido, no se aceptan caracteres extraños',
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <Input
                label='Nombre'
                isRequired
                error={Boolean(error)}
                helperText={error?.message}
                inputProps={{ maxLength: 50 }}
                {...field}
              />
            )}
          />

          <Controller
            control={control}
            name='code'
            rules={{ 
              required: '(*) Campo obligatorio',
              validate: {
                minValid: (text) => validateMinLength(text, 1) || 'Sigla no puede ser menor a 1 caracteres',
                textIsValid: (text) => isComplexTextNumberValid(text) || 'Sigla inválida, no se aceptan caracteres extraños',
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <Input
                label='Sigla'
                isRequired
                disabled={Boolean(localityId)}
                error={Boolean(error)}
                helperText={error?.message}
                inputProps={{ maxLength: 5 }}
                {...field}
              />
            )}
          />
          <Controller
            control={control}
            name='locality'
            rules={{ required: '(*) Campo obligatorio' }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <ComboBox
                label='Comunas'
                options={structuresList}
                value={value}
                disabled={Boolean(localityId)}
                renderOptionLabel={renderOptionLabel}
                onChange={onChange}
                error={error}
                loading={isLoading}
                isRequired
                defaultValue='false'
              />
            )}
          />
        </Stack>
      </PaperWhite>

      <Stack spacing={2} direction='row' justifyContent='flex-end' paddingY={2}>
        <Button
          variant='goBack'
          disableRipple
          startIcon={<ArrowBackOutlined />}
          onClick={() => navigate('/Bases')}
        >
          Volver atrás
        </Button>
        <Button size='large' type='submit'>
          {baseId ? 'Editar' : 'Guardar'}
        </Button>
      </Stack>

      <AlertSnackBar
        open={alertError?.open}
        handleClose={handleCloseAlertError}
        message={alertError?.message}
      />
    </Stack>
  )
}
