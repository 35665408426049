/**
 * 
 * @param { Array } vacancies
 * @param { 'total'|'taken' } prop
 */
export const totalVacanciesCounter = (vacancies, prop) => {
  return vacancies?.reduce(
    (acc, vacancy) => acc + Number(vacancy?.vacancyTotals[prop] ?? 0),
    0,
  )
}
