import { Button, Stack, TableBody, TableCell, TableRow } from "@mui/material";
import { ChipStatus } from "../../../components/Chip";
import { formatDate } from "../../../../utils/formatValues";
import {
  EMPLOYEE_TYPE_ENUM,
  VACANCY_TYPE_ENUM,
  RequestStatus,
} from "../../../../constants/status";
import { SearchBar } from "../../../../components/SearchBar";
import { Table } from "../../../components/Table";
import { requestTypes } from "../../../constants/requests";
import { ReactComponent as InfoButton } from "../../../assets/images/infobutton.svg";
import { ReactComponent as TrashButton } from "../../../assets/images/trash.svg";
import { ReactComponent as EditButtonIcon } from "../../../assets/images/editbutton.svg";
import { TableButton } from "../../../components/Button/TableButton";
import { DownloadExportData } from "../../../components/Excel/Excel";

export const ProviderTable = ({
  data = [],
  onInfo,
  onRemove,
  onEdit,
  metadata,
  entityType,
  getValue,
  clearFilters,
  setFilters,
}) => {
  const isPeonetaTable = entityType === EMPLOYEE_TYPE_ENUM.PEONETA;
  const isFleetTable = entityType === VACANCY_TYPE_ENUM.FLEET;
  const fleetCells = [
    {
      id: "fleetPlate",
      label: "Patente",
      disableSorting: true,
      exportExcel: true,
    },
  ];
  const employeeCells = [
    {
      id: "numberRut",
      label: `${isPeonetaTable ? "Rut Peoneta" : "Rut Conductor"}`,
      disableSorting: true,
      exportExcel: true,
    },
  ];
  const headCells = [
    {
      id: "type",
      label: "Tipo de Solicitud",
      disableSorting: true,
      exportExcel: true,
    },
    ...(isFleetTable ? fleetCells : employeeCells),
    {
      id: "requestDate",
      label: "Fecha de Solicitud",
      exportExcel: true,
    },
    {
      id: "responseDate",
      label: "Fecha de Respuesta",
      exportExcel: true,
    },
    {
      id: "statusTranslate",
      label: "Estado",
      disableSorting: true,
      exportExcel: true,
    },
    {
      id: "",
      label: "Acciones",
      disableSorting: true,
    },
  ];

  const allowedDeleteStatus = [
    RequestStatus.OBJECTED,
    RequestStatus.CLOSED,
    RequestStatus.EXPIRED,
    RequestStatus.PENDING,
  ]
  
  const onChangeFilters = (newFilters) => {
    if (!newFilters) return;
    setFilters(newFilters);
  };
  
  const { TableContainer, setPage } = Table(
    headCells,
    {
    ...metadata,
    page: getValue("page", 1),
    limit: getValue("limit", 10),
    },
    onChangeFilters
  );

  const handleSearch = (event) => {
    const { value } = event.target;
    const newValue = value.toLowerCase();

    onChangeFilters({ search: newValue, page: 1 });
    setPage(0);
  };

  const dataRequest = data.map((request) => {
    const dni = isPeonetaTable
      ? request?.requested?.for?.peonetaDni
      : request?.requested?.for?.courierDni;
    const complementaryEmployee = isPeonetaTable
      ? request?.requested?.for?.courierDni
      : request?.requested?.for?.peonetaDni;
    return {
      id: request.id,
      requestType: request.type,
      type: requestTypes[request.type],
      statusTranslate: RequestStatus[request.status],
      status: request.status,
      requestDate: formatDate(request?.created?.at, "DD-MM-YYYY"),
      responseDate: request?.updated
        ? formatDate(request.updated.at, "DD-MM-YYYY")
        : "-",
      ...(isFleetTable
        ? {
            fleetPlate: request?.requested?.for?.plate,
            fleetType: request?.requested?.for?.subType ?? "-",
            courierDni: request?.requested?.for?.courierDni ?? "-",
            peonetaDni: request?.requested?.for?.peonetaDni ?? "-",
          }
        : {
            numberRut: dni,
            [`${isPeonetaTable ? "courier" : "peoneta"}`]:
              complementaryEmployee,
            fleetPlate: request?.requested?.for?.plate ?? "-",
          }),
    };
  });

  const TableAddon = () => (
    <Stack
      sx={{ width: "100%" }}
      direction="row"
      paddingY={2}
      paddingX={2}
      alignItems="center"
      justifyContent="space-between"
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <SearchBar
          id="table-searchbar"
          type="text"
          placeholder="Buscar"
          onSearch={handleSearch}
          search={getValue("search", "")}
      />
        <Stack sx={{ minWidth: 160, marginLeft: "1rem" }}>
          <Button variant="contained" onClick={clearFilters} size="medium">
            Limpiar filtros
          </Button>
        </Stack>
      </Stack>
      <DownloadExportData
        data={dataRequest}
        columns={headCells}
        nameFile={`solicitudes-${entityType}`}
        label="Descargar Archivo"
      />
    </Stack>
  );
  return (
    <TableContainer addons={[TableAddon]} withPagination>
      <TableBody>
        {dataRequest?.map((request) => {
          return (
            <TableRow hover tabIndex={-1} key={request.id}>
              <TableCell>{request.type}</TableCell>
              {isFleetTable ? (
                <>
                  <TableCell>{request?.fleetPlate}</TableCell>
                </>
              ) : (
                <>
                  <TableCell>{request?.numberRut}</TableCell>
                </>
              )}
              <TableCell>{request.requestDate}</TableCell>
              <TableCell>{request.responseDate}</TableCell>
              <TableCell>
                <ChipStatus
                  label={request.statusTranslate}
                  setcolor={request.status}
                />
              </TableCell>
              <TableCell>
                <Stack direction="row">
                  <TableButton
                    onClick={() => onEdit(request.id)}
                    disabled={
                      request.type !== requestTypes.TEMPORARY_REPLACEMENT &&
                      (request.status !== RequestStatus.OBJECTED ||
                        request.status !== RequestStatus.REJECTED)
                    }
                  >
                    <EditButtonIcon />
                  </TableButton>

                  <TableButton onClick={() => onInfo(request)}>
                    <InfoButton />
                  </TableButton>
                  <TableButton
                    onClick={() => onRemove(request)}
                    disabled={!allowedDeleteStatus.includes(request.status)}
                  >
                    <TrashButton />
                  </TableButton>
                </Stack>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </TableContainer>
  );
};
