import { useQuery } from "react-query";
import { useApi } from "../../../../services/api";
import { EMPLOYEE_URI } from "../../../../constants/api";
import { buildUrlWithQueryParams } from "../../../helper/converters";

export async function getCouriersList(filters) {
  if (!filters?.providerId) return;

  const { axiosInstance } = useApi();

  const baseUrl = new URL(`${EMPLOYEE_URI}`);
  const url = buildUrlWithQueryParams(baseUrl, filters);

  const response = await axiosInstance.get(url);
  const { data, metadata } = response.data;

  return { data, metadata };
}

export function useListEmployeesByTypeByProvider(filters) {
  return useQuery(
    ["employeesByProvider", filters],
    () => getCouriersList(filters),
    {
      staleTime: 1000 * 60 * 10,
    }
  );
}
