/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import { useState } from "react";
import { useParams } from "react-router-dom";
import { Controller, useForm, useWatch } from "react-hook-form";

import {
  Box,
  Stack,
  FormControlLabel,
  Switch,
  TableRow,
  TableCell,
  IconButton,
  Typography,
  TableBody,
  Button,
  Container,
  CircularProgress,
} from "@mui/material";

import {
  AddCircleOutlineOutlined,
  EditOutlined,
  DeleteOutlined,
  ArrowBackOutlined,
} from "@mui/icons-material";

import { PaperWhite } from "../../../../components/Papers";
import { Modal } from "../../../../components/Modals/Modal";
import { BoxHeader } from "../BoxHeader";
import { Calendar } from "../Calendar";
import { Input } from "../../../../components/Inputs";
import { SearchBar } from "../../../../components/SearchBar";
import { AlertSnackBar, TimedAlert } from "../../../../components/Alerts";
import { ComboBox } from "../../../../components/ComboBox";
import DateRangePicker from "../DateRangePicker";

import { maskOnlyNumber } from "../../../../utils/masks";
import { getTodayDate } from "../../../../utils/formatValues";

import { useListVehiclesTypes } from "../../../../services/hooks/Vehicles";
import {
  useCreateOffer,
  useUpdateOffer,
} from "../../../../services/hooks/Offers";
import { Dialogs } from "../../../../components/Dialogs";
import { Table } from "../../../../v2/components/Table";

const defaultValues = {
  showTotal: true,
  total: "",
  vehicle: {
    name: "",
    features: [],
  },
  vehicleTypeId: "",
  features: [],
  peonetaRequired: false,
};

const initialAlertError = {
  open: false,
  message: "",
};

const renderOptionLabelDefault =
  (attr = "description") =>
  (option) => {
    if (Object.entries(option)?.length === 0) return "";

    return `${typeof option === "string" ? option : option[attr]}`;
  };

const colsVacancies = (allowedEdit) => {
  const columns = [
    {
      id: "quantity",
      label: "Cantidad",
    },
    {
      id: "showTotal",
      label: "Sítio",
    },
    {
      id: "peonetaRequired",
      label: "Peoneta obligatorio",
    },
    {
      id: "typeVehicle",
      label: "Tipo del vehículo",
    },
    {
      id: "features",
      label: "Características del vehículo",
    },
  ];

  if (!allowedEdit) return columns;

  return [
    ...columns,
    {
      id: "actions",
      label: "Acciones",
      disableSorting: true,
    },
  ];
};

export function Step2({
  onBackStep,
  control,
  handleSubmit,
  setValue,
  setError,
  allowedEdit,
  offerFormState,
}) {
  const { offerId } = useParams();

  const {
    control: controlVacancy,
    handleSubmit: submitVacancy,
    reset: resetVacancy,
    setValue: setValueVacancy,
    getValues: getValuesVacancy,
    formState,
  } = useForm({ defaultValues, mode: "onChange" });

  const vacancies = useWatch({ control, name: "setting.vacancies" });
  const timeCalendar = useWatch({ control, name: "setting.publicationDate" });

  const [showModalVacancies, setShowModalVacancies] = useState(false);
  const [vacancyId, setVacancyId] = useState("");
  const [alertError, setAlertError] = useState(initialAlertError);
  const [, setFilterFn] = useState({
    fn: (items) => items,
  });
  const [alert, setAlert] = useState({
    isOpen: false,
    isTimed: false,
    duration: 3000,
  });

  const handleOpenModalVacancies = (data = defaultValues) => {
    resetVacancy(data);
    setShowModalVacancies(true);
  };
  const handleCloseModalVacancies = () => setShowModalVacancies(false);

  const { data: vehiclesTypes, isLoading: vehiclesTypesIsLoading } =
    useListVehiclesTypes();
  const { mutate: mutateCreate, isLoading: isLoadingCreate } = useCreateOffer();
  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } =
    useUpdateOffer(offerId);

  const [openDialogPublicOffer, setOpenDialogPublicOffer] = useState(false);
  const [publicOffer, setPublicOffer] = useState("");
  const [updating, setUpdating] = useState(false);

  const handleOnClose = () => {
    setUpdating(false);
    setPublicOffer("");
    setOpenDialogPublicOffer(false);
  };

  const callOffer = (newOffer, hasDialog, updating = false) => {
    if (updating) {
      mutateUpdate(newOffer, {
        onSuccess: () => {
          if (hasDialog) handleOnClose();
          setAlert({
            ...alert,
            isOpen: true,
            message: "Acabas de editar la oferta!",
            title: "WOW!",
          });
        },
        onError: () => {
          if (hasDialog) handleOnClose();
          setAlertError({
            open: true,
            message: "Oh no! se ha producido un error al editar la oferta",
          });
        },
      });
    } else {
      mutateCreate(newOffer, {
        onSuccess: () => {
          if (hasDialog) handleOnClose();
          setAlert({
            ...alert,
            isOpen: true,
            message: "Acabas de crear una oferta!",
            title: "WOW!",
          });
        },
        onError: () => {
          if (hasDialog) handleOnClose();
          setAlertError({
            open: true,
            message:
              "Oh no! se ha producido un error al crear una nueva oferta",
          });
        },
      });
    }
  };

  const handlePublicOffer = () => {
    if (publicOffer) {
      publicOffer.status = "Published";
      callOffer(publicOffer, true, updating);
    }
  };

  const handleCreateOffer = () => {
    if (publicOffer) {
      publicOffer.status = "Draft";
      callOffer(publicOffer, true, updating);
    }
  };

  const handleCloseAlertError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertError(initialAlertError);
  };

  const handleEditVacancy = (vacancy) => {
    setVacancyId(vacancy.id);
    handleOpenModalVacancies(vacancy);
  };

  const handleDeleteVacancy = (id) => {
    const copyOfVacancies = [...vacancies];
    const newVacancies = copyOfVacancies?.filter(
      (vacancy) => vacancy.id !== id
    );
    setValue("setting.vacancies", newVacancies);
  };

  const onSubmitVacancy = (data) => {
    const newData = {
      ...data,
      vehicle: { ...data.vehicle, features: data.features },
      vacancyTotals: {
        total: data.total,
        taken: 0,
      },
      vehicleTypeId: data.vehicle?.id,
    };

    const duplicateVacancy = vacancies?.some(
      (vacancy) =>
        vacancy.vehicleTypeId === newData.vehicleTypeId &&
        vacancy.id !== vacancyId
    );

    if (duplicateVacancy) {
      setAlertError({
        open: true,
        message:
          "No se permite registrar una vacante con el mismo tipo vehículo!",
      });
      return;
    }

    const { found, rest } = vacancies.reduce(
      ({ found, rest }, vacancy) => {
        if (vacancy.id === vacancyId) {
          found = vacancy;
        } else {
          rest.push(vacancy);
        }
        return { found, rest };
      },
      { found: {}, rest: [] }
    );

    setValue("setting.vacancies", [
      ...rest,
      {
        ...found,
        ...newData,
        id: vacancyId || String(Math.random().toString(16).slice(2)),
      },
    ]);

    handleCloseModalVacancies();
    resetVacancy(defaultValues);
  };

  const onSubmit = (data) => {
    if (vacancies?.length === 0) {
      setAlertError({
        open: true,
        message: "Registre al menos una vacante!",
      });
      return;
    }

    if (!timeCalendar.from || !timeCalendar.to) {
      setError("dateExibition", true);
      setAlertError({
        open: true,
        message: "Elija los días para mostrar ofertas!",
      });
      return;
    }

    const newOffer = {
      ...data,
      setting: {
        ...data.setting,
        base: data.setting.base,
        city: data.setting.city.name,
      },
    };

    const validateDate = newOffer.setting.publicationDate.from >= getTodayDate();
    setUpdating(!!offerId);

    if (validateDate) {
      setPublicOffer(newOffer);
      setOpenDialogPublicOffer(validateDate);
    } else {
      callOffer(newOffer, false, !!offerId);
    }
  };

  const { TableContainer } = Table(colsVacancies(allowedEdit));

  const handleSearch = (event) => {
    const { value } = event.target;

    const newValue = value.toLowerCase();

    setFilterFn({
      fn: (items) => {
        if (value === "") return items;
        return items.filter(
          (offer) =>
            offer.base.name.toLowerCase().includes(newValue) ||
            String(offer.total).toLowerCase().includes(newValue) ||
            offer.description?.toLowerCase().includes(newValue) ||
            offer.setting?.features?.some((feature) =>
              feature?.name?.toLowerCase().includes(newValue)
            )
        );
      },
    });
  };

  return (
    <>
      <BoxHeader
        subTitle="Configuración de la oferta"
        content="Aquí necesitamos obtener información importante sobre las vacantes, los vehículos y la visibilidad de tu oferta."
      />

      <Container>
        <Stack spacing={2} component="form" onSubmit={handleSubmit(onSubmit)}>
          <PaperWhite>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box>
                <Typography variant="h6">Número de vacantes</Typography>
                <Typography variant="body1">
                  ¿Para qué localidad es la vacante?
                </Typography>
              </Box>
              {vacancies?.length === 0 && (
                <Button
                  startIcon={<AddCircleOutlineOutlined />}
                  onClick={() => {
                    setVacancyId("");
                    handleOpenModalVacancies();
                  }}
                >
                  Crear Nuevo
                </Button>
              )}
            </Stack>

            {vacancies?.length !== 0 && (
              <PaperWhite sx={{ p: 0, my: 2 }}>
                <Stack
                  direction="row"
                  paddingY={2}
                  paddingX={2}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <SearchBar
                    id="table-searchbar"
                    type="text"
                    placeholder="buscar"
                    onChange={handleSearch}
                  />
                  {allowedEdit && (
                    <Button
                      variant="contained"
                      startIcon={<AddCircleOutlineOutlined color="inherit" />}
                      onClick={() => {
                        setVacancyId("");
                        resetVacancy();
                        handleOpenModalVacancies();
                      }}
                    >
                      Crear Nuevo
                    </Button>
                  )}
                </Stack>
                <TableContainer>
                  <TableBody>
                    {vacancies.map((vacancy) => (
                      <TableRow hover tabIndex={-1} key={vacancy.id}>
                        <TableCell>{vacancy.total}</TableCell>
                        <TableCell>
                          {vacancy.showTotal === true ? "Si" : "No"}
                        </TableCell>
                        <TableCell>
                          {vacancy.peonetaRequired === true ? "Si" : "No"}
                        </TableCell>
                        <TableCell>{vacancy.vehicle.name}</TableCell>
                        <TableCell>
                          {vacancy.vehicle.features
                            ?.map((feature) => feature?.name)
                            .join(" | ")}
                        </TableCell>
                        {allowedEdit && (
                          <TableCell>
                            <Box>
                              <IconButton
                                onClick={() => handleEditVacancy(vacancy)}
                              >
                                <EditOutlined />
                              </IconButton>
                              <IconButton
                                onClick={() => handleDeleteVacancy(vacancy.id)}
                              >
                                <DeleteOutlined />
                              </IconButton>
                            </Box>
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </TableContainer>
              </PaperWhite>
            )}
          </PaperWhite>

          <PaperWhite>
            <Stack
              direction="row"
              justifyContent="space-between"
              sx={{ flexWrap: "wrap" }}
            >
              <Box sx={{ width: "50%" }}>
                <Typography variant="h6">Exhibición de la oferta</Typography>
                <Typography variant="body1">
                  ¿Cuánto tiempo te gustaría exhibir esta oferta de trabajo?
                </Typography>
              </Box>

              <Stack
                spacing={2}
                sx={{
                  width: {
                    lg: "50%",
                    xs: "100%",
                  },
                }}
              >
                <Controller
                  control={control}
                  name="setting.publicationDate"
                  rules={{
                    required: "(*) Campo obligatorio",
                  }}
                  render={({
                    field: { value, onChange, ref },
                    fieldState: { error },
                  }) => (
                    <DateRangePicker
                      value={value}
                      onChange={onChange}
                      error={error}
                      ref={ref}
                      disabled={!allowedEdit}
                    />
                  )}
                />
                <Calendar date={timeCalendar} />
              </Stack>
            </Stack>
          </PaperWhite>
          <Stack spacing={2} direction="row" justifyContent="flex-end">
            <Button
              variant="goBack"
              disableRipple
              startIcon={<ArrowBackOutlined />}
              onClick={() => onBackStep()}
            >
              Volver atrás
            </Button>
            <Button
              size="large"
              type="submit"
              disabled={offerId ? false : !offerFormState.isValid}
              startIcon={
                isLoadingCreate || isLoadingUpdate ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null
              }
            >
              {offerId ? "Editar" : "Guardar"}
            </Button>
          </Stack>
        </Stack>
      </Container>

      {/* -------------------------- Modal Vacantes -------------------------- */}
      <Modal open={showModalVacancies} handleClose={handleCloseModalVacancies}>
        <Stack
          spacing={2}
          sx={{ width: 800 }}
          component="form"
          onSubmit={submitVacancy(onSubmitVacancy)}
        >
          <Typography variant="h6" align="center">
            {vacancyId ? "Editar Vacante" : "Crear Vacante"}
          </Typography>

          <Stack spacing={2} direction="row" sx={{ width: "100%" }}>
            <Stack spacing={2} sx={{ width: "50%" }}>
              <Controller
                control={controlVacancy}
                name="vehicle"
                rules={{ required: "(*) Campo obligatorio" }}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <ComboBox
                    placeholder="Elige un tipo de vehículo"
                    label="Tipo de vehículo"
                    options={vehiclesTypes}
                    renderOptionLabel={renderOptionLabelDefault("name")}
                    value={value}
                    onChange={(event) => {
                      onChange(event);
                      setValueVacancy("features", []);
                    }}
                    error={error}
                    loading={vehiclesTypesIsLoading}
                    noOptionsText="No hay tipos de vehículo"
                  />
                )}
              />
              <Controller
                control={controlVacancy}
                name="features"
                rules={{
                  validate: {
                    validateFeatures: (value) =>
                      value.length > 0 ||
                      "Seleccione al menos una característica",
                  },
                }}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <ComboBox
                    placeholder="Elige las características del vehículo"
                    label="Características del vehículo"
                    options={
                      getValuesVacancy("vehicle")?.features.map(({ name }) => ({
                        name,
                        id: name,
                      })) || []
                    }
                    renderOptionLabel={renderOptionLabelDefault("name")}
                    multiple
                    value={value}
                    onChange={onChange}
                    error={error}
                    loading={vehiclesTypesIsLoading}
                    noOptionsText="Seleccione primero el tipo de vehículo"
                  />
                )}
              />
            </Stack>

            <Stack spacing={2} sx={{ width: "50%" }}>
              <Controller
                control={controlVacancy}
                name="total"
                rules={{ required: "(*) Campo obligatorio" }}
                render={({
                  field: { value, onChange, ...rest },
                  fieldState: { error },
                }) => (
                  <Input
                    value={maskOnlyNumber(value)}
                    onChange={onChange}
                    label="Cantidad"
                    isRequired
                    error={Boolean(error)}
                    helperText={error?.message}
                    inputProps={{ maxLength: 5 }}
                    placeholder="0000"
                    {...rest}
                  />
                )}
              />
            </Stack>
          </Stack>

          <Box>
            <Controller
              control={controlVacancy}
              name="showTotal"
              render={({ field: { value, onChange } }) => (
                <FormControlLabel
                  control={
                    <Switch
                      color="secondary"
                      checked={value}
                      onChange={onChange}
                    />
                  }
                  label="¿Mostrar número de vacantes?"
                />
              )}
            />
          </Box>

          <Box>
            <Controller
              control={controlVacancy}
              name="peonetaRequired"
              render={({ field: { value, onChange } }) => {
                return (
                  <FormControlLabel
                    control={
                      <Switch
                        color="secondary"
                        checked={value}
                        onChange={onChange}
                      />
                    }
                    label="¿Es el peoneta obligatorio?"
                  />
                );
              }}
            />
          </Box>

          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <Button
              variant="goBack"
              disableRipple
              startIcon={<ArrowBackOutlined />}
              onClick={handleCloseModalVacancies}
            >
              Volver atrás
            </Button>
            <Button type="submit" disabled={!formState.isValid}>
              {vacancyId ? "Editar" : "Guardar"}
            </Button>
          </Stack>
        </Stack>
      </Modal>
      {/* -------------------------- Modal Vacantes -------------------------- */}

      <Dialogs
        open={openDialogPublicOffer}
        handleClose={handleCreateOffer}
        onClose={handleOnClose}
        handleConfirmation={handlePublicOffer}
        isLoading={isLoadingCreate}
        title="¿Desea publicar la oferta de inmediato?"
      />

      <AlertSnackBar
        open={alertError?.open}
        handleClose={handleCloseAlertError}
        message={alertError?.message}
      />

      <TimedAlert alert={alert} setAlert={setAlert} to="/offers/list" />
    </>
  );
}
