import { VACANCY_TYPE_ENUM } from "../../../../constants/status";
import { requestTypesValues } from "../../../constants/requests";

export const replacementFields = (entity, type) => type === VACANCY_TYPE_ENUM.EMPLOYEE ? {
  RUT: entity?.numberRut ?? '-',
  Email: entity?.email ?? '-',
  Nombre: `${entity?.name} ${entity?.lastname1}`,
  "Teléfono": entity?.phone ?? '-',
} : {
  Patente: entity?.plate ?? '-',
  Marca: entity?.brand ?? '-',
  Modelo: entity?.model ?? '-',
  Tipo: entity?.type ?? '-',
};

export const replacementTypes = [
  requestTypesValues.PERMANENT_REPLACEMENT,
  requestTypesValues.TEMPORARY_REPLACEMENT,
  requestTypesValues.REPLACEMENT,
];
