import { ArrowBack } from "@mui/icons-material";
import { Button, Card, Grid, Stack, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { ComparisonTable } from "./ComparisonTable";
import { DocumentTable } from "../../../components/Documents/DocumentTable";
import {
  EmployeeStatus,
  MESSAGE_STATUS_MAPPING,
  VACANCY_TYPE_ENUM,
  VACANCY_TYPE_MAPPING,
} from "../../../../constants/status";
import { useGetProviderRequestById } from "../../../services/hooks/Request/index";
import { LoadingSpinner } from "../../../../components/LoadingSpinner";
import {
  useApprobationDocuments,
  useGetDocuments,
} from "../../../../services/hooks/Documents";
import { useEffect, useState } from "react";
import { replacementFields } from "../helper/entity";
import { useGetTemplate } from "../../../../services/hooks/Docs/Templates/useGetTemplates";
import { templateNames } from "../../../../constants/templateNames";
import wowIcon from "../../../../assets/images/wow.png";
import { Dialogs } from "../../../../components/Dialogs";
import { useQueryClient } from "react-query";
import { Alert } from "../../../../components/Alert";
import { useManageReplacement } from "../../../services/hooks/Request";
import warningIcon from "../../../assets/images/warning.png";

const initialAlertError = {
  title: "",
  open: false,
  messages: [],
  icon: "",
};

export default function Replace() {
  const navigation = useNavigate();
  const { requestId } = useParams();
  const [dataDocument, setDataDocument] = useState([]);

  const queryClient = useQueryClient();

  const [dataConfirmation, setDataConfirmation] = useState([]);
  const [buttonSelect, setButtonSelect] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogButton, setOpenDialogButton] = useState(false);
  const [documentSelect, setDocumentSelect] = useState();
  const [action, setAction] = useState("");
  const { mutate: manageMutate, isLoading: isAcceptingLoading } =
    useManageReplacement(requestId);

  const handleGoBack = () => {
    navigation(-1);
  };
  const { data: request, isLoading } = useGetProviderRequestById(requestId);
  const entityType =
    request?.requested?.for?.type === VACANCY_TYPE_ENUM.EMPLOYEE
      ? request?.requested?.for?.subType
      : VACANCY_TYPE_ENUM.FLEET;

  const { mutate, isLoading: isLoadingDocuments } = useApprobationDocuments();

  const entity = replacementFields(
    request?.metadata?.entityToReplace,
    request?.requested?.for?.type
  );
  const entityToCompare = replacementFields(
    request?.metadata?.replacement,
    request?.requested?.for?.type
  );
  const { data: documentData, isLoading: isDocumentLoading } = useGetDocuments(
    request?.requested?.for?.type ?? VACANCY_TYPE_ENUM.EMPLOYEE,
    request?.metadata?.replacement?.id
  );
  const { data: dataDocumentTemplate } = useGetTemplate(
    request?.metadata?.replacement?.documentTemplateId ?? templateNames.provider
  );

  const [alert, setAlert] = useState(initialAlertError);

  useEffect(() => {
    if (documentData) {
      setDataDocument(documentData);
      const initialData = documentData
        .filter(
          (document) =>
            document.status === EmployeeStatus.APPROVED ||
            document.status === EmployeeStatus.REJECTED ||
            document.status === MESSAGE_STATUS_MAPPING.APPROVE ||
            document.status === MESSAGE_STATUS_MAPPING.REJECT ||
            document.action === MESSAGE_STATUS_MAPPING.APPROVE ||
            document.action === MESSAGE_STATUS_MAPPING.REJECT
        )
        .map((document) => ({ id: document.id, action: document.status }));
      setDataConfirmation(initialData);
    }
  }, [documentData]);

  const handleCancelDocument = () => {
    setDataConfirmation([]);
    const dataCancel = dataDocument.map((item) => {
      item.status = EmployeeStatus.PENDING;
      return item;
    });
    setDataDocument(dataCancel);
    setOpenDialogButton(false);
    handleGoBack();
  };

  const handleConfirmationAlert = () => {
    handleGoBack();
    setAlert({
      ...alert,
      open: false,
    });
  };

  const handleSend = () => {
    if (buttonSelect === MESSAGE_STATUS_MAPPING.cancel) {
      handleCancelDocument();
      return;
    }

    const documents = dataConfirmation.map((document) => ({
      id: document.id,
      action: document.action,
    }));

    const documentTemplateId = dataDocumentTemplate.id;
    mutate(
      { documents, documentTemplateId },
      {
        onSuccess: () => {
          const hasRejection = documents.some(
            (document) => document.action === MESSAGE_STATUS_MAPPING.REJECT
          );
          const isRejectingRequest =
            buttonSelect === MESSAGE_STATUS_MAPPING.reject;
          let action;
          if (isRejectingRequest) {
            action = MESSAGE_STATUS_MAPPING.REJECT;
          } else {
            action = hasRejection
              ? MESSAGE_STATUS_MAPPING.OBJECT
              : MESSAGE_STATUS_MAPPING.ACCEPT;
          }
          manageMutate(
            { requestId, action },
            {
              onSuccess: () => {
                setOpenDialogButton(false);
                setAlert({
                  ...alert,
                  open: true,
                  title: "Felicidades",
                  Icon: <img src={wowIcon} alt="Congratulations Icon" />,
                  messages: [
                    "Solicitud de reemplazo se gestionó correctamente",
                  ],
                });
                queryClient.refetchQueries("providerRequest");
              },
              onError: () => {
                setOpenDialogButton(false);
                setAlert({
                  ...alert,
                  open: true,
                  title: "Error",
                  messages: [
                    "Oh no! se ha producido un error al gestionar la solicitud",
                  ],
                });
              },
            }
          );
        },
        onError: () => {
          setOpenDialogButton(false);
          setAlert({
            ...alert,
            open: true,
            title: "Error",
            messages: ["Oh no! se ha producido un error al aprobar documentos"],
          });
        },
      }
    );
  };

  const handleConfirmationDocument = (action, document) => {
    setDataConfirmation((prev) => {
      if (prev.some((item) => item.id === document.id)) {
        const dataChange = prev.map((item) =>
          item.id === document.id ? { id: item.id, action } : item
        );
        return dataChange;
      }
      return [...prev, { id: document.id, action }];
    });
    const dataStatusChange = dataDocument.map((item) => {
      if (item.id === document.id) {
        item.action = action;
      }
      return item;
    });
    setDataDocument(dataStatusChange);
    setOpenDialog(false);
  };

  if (
    isLoading ||
    isDocumentLoading ||
    isLoadingDocuments ||
    isAcceptingLoading
  )
    return <LoadingSpinner open />;
  return (
    <Stack spacing={2}>
      <Grid>
        <Button
          variant="goBack"
          startIcon={<ArrowBack />}
          onClick={handleGoBack}
        >
          Volver atrás
        </Button>
      </Grid>
      <Typography variant="h4" align="left">
        Solicitud de reemplazo
      </Typography>
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12} sm={12} md={10} lg={8}>
          <ComparisonTable
            entityType={entityType}
            entity={entity}
            entityToCompare={entityToCompare}
          />
        </Grid>
      </Grid>
      <Card justifyContent="center" spacing={2}>
        <Stack container sx={{ margin: 3 }}>
          <Typography
            variant="h5"
            align="left"
            sx={{
              fontWeight: 700,
              fontSize: "22px",
            }}
          >
            Documentos del {VACANCY_TYPE_MAPPING[entityType]} de reemplazo
          </Typography>
        </Stack>
        <DocumentTable
          dataDocument={dataDocument}
          data={documentData}
          onThumbUp={(doc) => {
            setAction(MESSAGE_STATUS_MAPPING.APPROVE);
            setOpenDialog(true);
            setDocumentSelect(doc);
          }}
          onThumbDown={(doc) => {
            setAction(MESSAGE_STATUS_MAPPING.REJECT);
            setOpenDialog(true);
            setDocumentSelect(doc);
          }}
        />
      </Card>
      {request.status === EmployeeStatus.PENDING && (
        <Grid>
          <Stack
            margin={2}
            direction="row"
            spacing={2}
            justifyContent="flex-end"
          >
            <Button
              color="secondary"
              variant="outlined"
              type="submit"
              sx={{
                minWidth: "200px",
                height: 40,
                backgroundColor: "white",
                border: 2,
              }}
              onClick={() => {
                setButtonSelect(MESSAGE_STATUS_MAPPING.cancel);
                setOpenDialogButton(true);
              }}
            >
              Cancelar
            </Button>
            {entityType === VACANCY_TYPE_ENUM.FLEET && (
              <Button
                color="secondary"
                type="submit"
                sx={{ minWidth: "200px", height: 40 }}
                onClick={() => {
                  setButtonSelect(MESSAGE_STATUS_MAPPING.reject);
                  setOpenDialogButton(true);
                }}
              >
                Vehículo no cumple
              </Button>
            )}
            <Button
              color="secondary"
              disabled={dataConfirmation?.length !== documentData?.length}
              type="submit"
              sx={{ minWidth: "200px", height: 40 }}
              onClick={() => {
                setButtonSelect(MESSAGE_STATUS_MAPPING.confirm);
                setOpenDialogButton(true);
              }}
            >
              Confirmar revisión
            </Button>
          </Stack>
        </Grid>
      )}
      <Alert
        open={alert?.open}
        handleConfirmation={handleConfirmationAlert}
        buttonClose={true}
        title={alert?.title}
        messages={alert?.messages}
      />
      <Dialogs
        icon={warningIcon}
        open={openDialogButton}
        handleClose={() => setOpenDialogButton(false)}
        handleConfirmation={handleSend}
        title={`¿Estás seguro de que quieres ${buttonSelect}?`}
        isLoading={isLoadingDocuments}
      />
      <Dialogs
        icon={warningIcon}
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        handleConfirmation={() =>
          handleConfirmationDocument(action, documentSelect)
        }
        title={`¿Estás seguro de que quieres ${MESSAGE_STATUS_MAPPING[action]} este documento?`}
      />
    </Stack>
  );
}
