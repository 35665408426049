import { useMutation } from "react-query";
import { useApi } from "../../../../../services/api";
import { REQUEST_TRACKER_URI } from "../../../../../constants/api";


export function useCreateReplaceDocument() {
    const { axiosInstance } = useApi();

    return useMutation((data) => axiosInstance.post('/replaceDocument', data, {
        baseURL: REQUEST_TRACKER_URI,
    }));
}
