import { useMutation, useQueryClient } from "react-query";
import { useApi } from "../../../api";
import { DOCUMENT_TYPE_URI } from "../../../../constants/api";

export function useUpdateDocuments(id) {
  const { axiosInstance } = useApi();
  const queryClient = useQueryClient();

  return useMutation(
    (newDocument) =>
      axiosInstance.put(`/${id}`, newDocument, {
        baseURL: DOCUMENT_TYPE_URI,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("listDocuments");
        queryClient.invalidateQueries(["document", id]);
      },
    }
  );
}
