import { useState } from 'react'
import { Box, Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

export default function TMSAccordion({ breakPoint, data, renderHeader, renderBody }) {

  const [expanded, setExpanded] = useState(false)

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <Box
      sx={{
        [`@media (min-width: ${breakPoint ?? '1000px'})`]: {
          display: 'none'
        }
      }}
    >
     { data?.map((item, index) => (
          <Accordion
            key={item.id ?? index}
            expanded={expanded === item.id ?? index} onChange={handleChange(item.id ?? index)}
            disableGutters
            sx={{
              borderTop: '1px solid #7c7c7c',
              '&:last-child': {
                borderBottom: '1px solid #7c7c7c'
              }
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                backgroundColor: '#f5f5f5'
              }}
            >
              {renderHeader(item)}
            </AccordionSummary>
            <AccordionDetails
              sx={{
                borderTop: '1px solid #7c7c7c'
              }}
            >
              {renderBody(item)}
            </AccordionDetails>
          </Accordion>
        )) }
    </Box> 
  );
  
}
