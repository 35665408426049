import { useMutation, useQueryClient } from 'react-query'
import { useApi } from '../../api'
import { OFFER_URI } from '../../../constants/api'

export function useCreateOffer() {
  const { axiosInstance } = useApi()
  const queryClient = useQueryClient()

  return useMutation((offer) => axiosInstance.post('/', offer, {
    baseURL: OFFER_URI,
  }), {
    onSuccess: () => queryClient.invalidateQueries('listOffers'),
  })
}
