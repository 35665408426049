import { Button, Stack, Typography } from "@mui/material";
import { Modal } from "../../../components/Modals/Modal";
import {
  EmployeeStatus,
  VACANCY_TYPE_ENUM,
  VACANCY_TYPE_MAPPING,
  allowedModifyStatus,
} from "../../../constants/status";
import { LoadingSpinner } from "../../../components/LoadingSpinner";
import { useMemo } from "react";
import { ChipStatus } from "../Chip";

const VacancyModal = ({
  isOpen,
  onClose,
  item,
  onEdit,
  setIsRenderedEdit,
  onReplace,
}) => {
  const isReplacing = Boolean(onReplace);
  const isEmployee = item?.vacancy?.type === VACANCY_TYPE_ENUM.EMPLOYEE;

  const vacancy = useMemo(() => {
    const itemType = item?.vacancy?.employeeType
      ? VACANCY_TYPE_MAPPING[item?.vacancy?.employeeType]
      : VACANCY_TYPE_MAPPING.fleet;
    const initialVacancy = { type: itemType, identity: null };

    switch (itemType) {
      case VACANCY_TYPE_MAPPING.fleet:
        initialVacancy.identity = item.vacancy?.plate;
        break;
      case VACANCY_TYPE_MAPPING.courier:
        initialVacancy.identity = item.vacancy?.courierDni;
        break;
      case VACANCY_TYPE_MAPPING.peoneta:
        initialVacancy.identity = item.vacancy?.peonetaDni;
        break;
      default:
        break;
    }

    return initialVacancy;
  }, [item, item?.vacancy?.employeeType]);

  return (
    <Modal open={isOpen} handleClose={onClose}>
      {item.vacancy ? (
        <Stack paddingTop={2} sx={{ width: "auto" }}>
          <Stack
            direction="row"
            spacing={2}
            flexGrow={2}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography variant="h6" px={1}>
              {`${isReplacing ? "Reemplazar al " : ""} ${vacancy.type}`}
            </Typography>
            <ChipStatus label={EmployeeStatus[item?.vacancy?.status]} setcolor={item?.vacancy?.status}/>
          </Stack>
          <Stack direction="row" spacing={2} flexGrow={2} paddingTop={2}>
            <Typography sx={{ fontWeight: 700 }}>
              {isEmployee ? "RUT:" : "PATENTE:"}
            </Typography>
            <Typography>{vacancy?.identity ?? "Sin información"}</Typography>
          </Stack>
          <Stack direction="row" spacing={2} flexGrow={2} paddingTop={2}>
            <Typography sx={{ fontWeight: 700 }}>
              TÍTULO DE LA OFERTA:
            </Typography>
            <Typography>{item.offerTitle}</Typography>
          </Stack>
          <Stack direction="row" spacing={2} flexGrow={2} paddingTop={2}>
            <Typography sx={{ fontWeight: 700 }}>
              CÓDIGO DE LA OFERTA:
            </Typography>
            <Typography>{item.codeOffer}</Typography>
          </Stack>
          <Stack
            direction="row"
            sx={{ justifyContent: "space-between" }}
            pt={3}
          >
            <Button variant="goBack" disableRipple onClick={onClose}>
              Volver
            </Button>
            {!isReplacing &&
              allowedModifyStatus.includes(item.vacancy.status) && (
                <Button disableRipple onClick={() => onEdit(item)}>
                  Editar
                </Button>
              )}
            {!isReplacing &&
              item.vacancy.status === EmployeeStatus.OBJECTED && (
                <Button
                  disableRipple
                  onClick={() => {
                    setIsRenderedEdit(true);
                  }}
                >
                  Ver Documentación
                </Button>
              )}
            {isReplacing && (
              <Button
                disableRipple
                onClick={() => {
                  onReplace(item);
                }}
              >
                Reemplazar
              </Button>
            )}
          </Stack>
        </Stack>
      ) : (
        <LoadingSpinner open />
      )}
    </Modal>
  );
};

export default VacancyModal;
