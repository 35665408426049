import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useGetApplyById } from "../../../services/hooks/Apply/useGetApplyById";
import { useEffect } from "react";
import { LoadingSpinner } from "../../../components/LoadingSpinner";
import { useAuth } from "../../../hook/useAuth";
import { useMyProvider } from "../../../services/hooks/Providers/useMyProvider";
import { useOfferDetails } from "../../../services/hooks/Offers";

const ApplyOutlet = () => {
  const navigate = useNavigate();
  const { applyId } = useParams();
  const { email } = useAuth();
  const { isLoading: isLoadingProvider, data: provider } = useMyProvider(email);
  const { isError, isLoading, data: apply } = useGetApplyById(applyId, provider?.id);
  const { isLoading: isLoadingOffer, data: offer, isError: offerError } = useOfferDetails(apply?.offer?.id)

  useEffect(() => {
    if (!isLoading && isError) {
      navigate("/applyNotAllowed", {
        state: {
          message: "No se ha encontrado la postulación",
        }
      })
    }
  }, [isError])

  useEffect(() => {
    if (offer) {
      const currentAvalaibiableVacancies = offer?.setting?.vacancies?.filter(vacancy => Number(vacancy.vacancyTotals.total) > vacancy.vacancyTotals.taken);

      if (!currentAvalaibiableVacancies?.length) {
        navigate("/applyNotAllowed", {
          state: {
            message: "La oferta a la que estas intentado postular no tiene cupos disponibles!",
          }
        })
      }
    }
    if (!isLoadingOffer && offerError) {
      navigate("/applyNotAllowed", {
        state: {
          message: "Oferta no encontrada!",
        }
      })
    }
    if (offer?.status === "Closed") {
      navigate("/applyNotAllowed", {
        state: {
          message: "Oferta inactiva!",
        }
      })
    }
  }, [offer])

  return isLoading || isLoadingProvider || isLoadingOffer ? <LoadingSpinner open /> : <Outlet />
}

export default ApplyOutlet