export const caseStatus = {
  1: "En validación",
  2: "Aprobado",
  3: "Rechazado",
  4: "Bloqueado",
  5: "Inactivo",
  6: "Preaprobado",
};

export const translatedStatus = {
  draft: "Borrador",
  pending: "Pendiente",
  inProgress: "En Progreso",
  approved: "Aprobado",
  rejected: "Rechazado",
  deleted: "Eliminado",
  inReview: "En revisión",
  objected: "Objetado",
  backofficeApproved: "En revisión",
  closed: "Cerrado",
  expired: "Expirado",
};

export const translatedStatusRequests = {
  pending: translatedStatus.pending,
  approved: translatedStatus.approved,
  rejected: translatedStatus.rejected,
  objected: translatedStatus.objected,
  backofficeApproved: translatedStatus.backofficeApproved,
  closed: translatedStatus.closed,
  expired: translatedStatus.expired,
};

export const CommonStatus = {
  DRAFT: "draft",
  PENDING: "pending",
  INPROGRESS: "inProgress",
  APPROVED: "approved",
  REJECTED: "rejected",
  DELETED: "deleted",
  INREVIEW: "inReview",
  REVIEWED: "reviewed",
  ...translatedStatus,
};

export const FleetStatus = {
  ...CommonStatus,
  blockedByDocuments: 'Bloqueado por documentación',
};

export const EmployeeStatus = {
  ...CommonStatus,
  OBJECTED: "objected",
  BACKOFFICEAPPROVED: "backofficeApproved",
  objected: "Objetado",
  backofficeApproved: "En revisión",
  closed: "Cerrado",
  expired: "Expirado",
  blockedByDocuments: "Bloqueado por documentación",
  reviewed: "Revisado",
};

export const RequestStatus = {
  ...CommonStatus,
  OBJECTED: "objected",
  objected: "Objetado",
  CLOSED: "closed",
  closed: "Cerrado",
  EXPIRED: "expired",
  expired: "Expirado",
  BACKOFFICEAPPROVED: "backofficeApproved",
  backofficeApproved: "En revisión",
};

export const StatusColor = {
  draft: "info",
  pending: "warning",
  inProgress: "warning",
  approve: "success",
  reject: "error",
  approved: "success",
  rejected: "error",
  deleted: "error",
  backofficeApproved: "warning",
  inReview: "info",
  blockedByDocuments: "error",
};

export const allowedModifyStatus = [
  EmployeeStatus.DRAFT,
  EmployeeStatus.PENDING,
];

export const allowedRevokeStatus = [
  EmployeeStatus.PENDING,
  EmployeeStatus.OBJECTED,
  EmployeeStatus.REJECTED,
];

export const STATUS_CODE = {
  NOT_FOUND: 404,
  CONFLICT: 409,
};

export const EMPLOYEE_TYPE_ENUM = {
  PEONETA: "peoneta",
  COURIER: "courier",
};
export const VACANCY_TYPE_ENUM = {
  EMPLOYEE: "employee",
  FLEET: "fleet",
};

export const VACANCY_TYPE_MAPPING = {
  fleet: "Vehículo",
  courier: "Conductor",
  peoneta: "Peoneta",
};

export const MESSAGE_STATUS_MAPPING = {
  APPROVE: "approve",
  REJECT: "reject",
  OBJECT: "object",
  ACCEPT: "accept",
  approve: "aprobar",
  reject: "rechazar",
  cancel: "cancelar",
  confirm: "confirmar",
};

export const ActiveStatus = {
  ACTIVE: "ACTIVO",
  INACTIVE: "INACTIVO",
};
