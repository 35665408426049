import { Chip } from '@mui/material'
import { Circle } from '@mui/icons-material'
import { statusColor } from '../../helper/status'

export function ChipStatus({ label, setcolor }) {
  return (
    <Chip
      size='small'
      label={label?.toUpperCase()}
      sx={{
        px: 1,
        color: 'white',
        backgroundColor: statusColor[setcolor],
        '& .MuiSvgIcon-root': { fontSize: '10px', color: 'white' },
      }}
      icon={<Circle />}
    />
  )
}
