import { Stack, Typography } from "@mui/material";
import { PaperWhite } from "../../../components/Papers";

export const EntityCard = ({ entries }) => (
  <>
    <PaperWhite>
      <Stack
        direction="row"
        gap="2em"
        justifyContent="space-around"
        flexWrap="wrap"
      >
        {entries.map(([columnTitle, values], index) => {
          return (
            <Stack key={index}>
              <Stack
                direction="column"
                spacing={1}
                sx={{
                  alignItems: "start",
                  mb: 3,
                }}
              >
                <Typography variant="h6" sx={{ fontWeight: 700 }}>
                  {columnTitle}
                </Typography>
                {Object.entries(values).map(([key, value]) => {
                  return (
                    <Typography key={key} variant="subtitle1" sx={{ fontWeight: 400 }}>
                      {`${key}`} {value}
                    </Typography>
                  );
                })}
              </Stack>
            </Stack>
          );
        })}
      </Stack>
    </PaperWhite>
  </>
);
