import { useQuery } from 'react-query'
import { useApi } from '../api'
import { LOCALITY_URI } from '../../constants/api'

export async function getListGeographies() {
  const { axiosInstance } = useApi()

  const response = await axiosInstance.get('/?page=1&limit=1000', {
    baseURL: LOCALITY_URI,
  })

  const { data } = response.data

  return data
}

export function useListGeographies() {
  return useQuery('listGeographies', () => getListGeographies(), {
    staleTime: 1000 * 60 * 10, // 10 minutos para não refazer a requisição ao receber foco na tela
  })
}
