export const statusActive = [
  {
    id: "1",
    name: "Activos",
    value: true,
  },
  {
    id: "2",
    name: "Inactivos",
    value: false,
  },
];

export const statusBlocked = [
  {
    id: "1",
    name: "Si",
    value: true,
  },
  {
    id: "2",
    name: "No",
    value: false,
  },
];

export const statusColor = {
  courier: "#2BB9FF",
  peoneta: "#74D497",
  Cerrado: "#FE6767",
  Publicado: "#2BB9FF",
  objected: "#ba68c8",
  pending: "#ff9800",
  inProgress: "#ff9800",
  backofficeApproved: "#ff9800",
  approved: "#4caf50",
  approve: "#4caf50",
  draft: "#03a9f4",
  inReview: "#03a9f4",
  reject: "#ef5350",
  rejected: "#ef5350",
  deleted: "#ef5350",
  closed: "#ef5350",
  expired: "#ef5350",
  blockedByDocuments: "#ef5350",
  reviewed: "#03a9f4",
};
