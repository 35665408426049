import { useState, useCallback } from "react";
import { useForm, Controller } from "react-hook-form";
import { Stack, Typography, Button } from "@mui/material";
import { Modal } from "../../../components/Modals/Modal";
import {
  useUpdateReplacement,
  useGetProviderRequestById,
} from "../../services/hooks/Request";
import { Box } from "@mui/material";
import { DatePicker, Input } from "../../../components/Inputs";
import { formatDate } from "../../../utils/formatValues";
import { Alert } from "../../../components/Alert";
import { LoadingSpinner } from "../../../components/LoadingSpinner";
import { useQueryClient } from "react-query";

const initialAlertError = {
  open: false,
  title: "",
  messages: [],
  content: "",
};

const EditDateModal = ({ open, requestId, onClose }) => {
  if (!requestId) {
    return null;
  }
  const { data: request, isLoading } = useGetProviderRequestById(requestId);

  const [alert, setAlert] = useState(initialAlertError);
  const { control, handleSubmit, formState } = useForm();
  const { mutate: manageMutateUpdate, isLoading: isLoadingUpdate } =
    useUpdateReplacement(requestId);
  const queryClient = useQueryClient();

  const validateEndDate = (value, defaultEndDate) => {
    if (!value) return "La fecha es requerida";
    if (new Date(value) < new Date(defaultEndDate)) {
      return "La fecha término debe ser mayor a la fecha a reemplazar";
    }
    return true;
  };

  const onFinish = () => {
    onClose();
  };

  const onSubmit = useCallback(
    async (data) => {
      const modifiedRequest = {
        ...request,
        metadata: {
          ...request?.metadata,
          setting: {
            ...request?.metadata?.setting,
            toDate: data.toDate,
          },
        },
      };

      manageMutateUpdate(
        { requestId, modifiedRequest },
        {
          onSuccess: () => {
            setAlert({
              ...alert,
              open: true,
              title: "Felicidades",
              messages: ["Se ha extendido la fecha de reemplazo exitosamente"],
            });
            onFinish();
            queryClient.refetchQueries("providerRequest");
          },
          onError: () => {
            setAlert({
              ...alert,
              open: true,
              title: "Error",
              messages: [
                "Oh no! se ha producido un error al extender la fecha de reemplazo",
              ],
            });
          },
        }
      );
    },
    [request]
  );

  const handleConfirmation = () => {
    onClose();
    setAlert({
      ...alert,
      open: false,
    });
  };
  if (isLoading || isLoadingUpdate) return <LoadingSpinner open={isLoading} />;

  return (
    <Modal open={open} handleClose={onClose}>
      <Stack
        spacing={2}
        padding={2}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Typography variant="h6">Extender fecha de reemplazo</Typography>

        <Box display="column" gridTemplateColumns="repeat(12, 1fr)" gap={6}>
          <Box gridColumn={{ xs: "span 12", lg: "span 4" }}>
            <Controller
              control={control}
              name="fromDate"
              defaultValue={request?.metadata?.setting?.fromDate}
              render={({ field }) => (
                <>
                  <Input
                    value={formatDate(field.value, "DD/MM/YYYY")}
                    label="Fecha Inicio Reemplazo"
                    enabled={false}
                  />
                </>
              )}
            />
          </Box>
          <Box gridColumn={{ xs: "span 12", lg: "span 4" }}>
            <Controller
              name="toDate"
              control={control}
              defaultValue={request?.metadata?.setting?.toDate}
              rules={{
                validate: (value) =>
                  validateEndDate(value, request?.metadata?.setting?.toDate),
              }}
              render={({ field, fieldState }) => (
                <>
                  <DatePicker
                    value={field.value}
                    minDate={request?.metadata?.setting?.toDate}
                    onChange={(date) => field.onChange(date)}
                    label="Fecha Término Reemplazo"
                    formatDate="DD/MM/YYYY"
                    error={fieldState.error}
                  />
                  {fieldState.error && (
                    <span style={{ color: "red" }}>
                      {fieldState.error.message}
                    </span>
                  )}
                </>
              )}
            />
          </Box>
        </Box>
        <Button
          color="secondary"
          sx={{ minWidth: "155px", height: 40, marginTop: 4 }}
          type="submit"
          disabled={!formState.isValid}
        >
          Aceptar
        </Button>
      </Stack>
      <Alert
        icon
        open={alert.open}
        handleConfirmation={handleConfirmation}
        title={alert.title}
        messages={alert.messages}
      />
    </Modal>
  );
};

export default EditDateModal;
