export const findFileByLabelName = (
  arr,
  labelName,
  retrieveOnlyDocument = false
) => {
  for (let i = 0; i < arr?.length; i++) {
    const files = arr[i]?.files;
    for (let j = 0; j < files?.length; j++) {
      if (files[j]?.labelName === labelName) {
        if (!retrieveOnlyDocument) {
          return {
            ...files[j],
            owner: {
              id: arr[i]?.owner?.id,
              type: arr[i]?.owner?.type,
            },
          };
        } else {
          return arr[i];
        }
      }
    }
  }
  return null;
};

export const findFileByDocumentTypeId = (
  arr,
  documentTypeId,
  retrieveOnlyDocument = false
) => {
  for (let i = 0; i < arr?.length; i++) {
    const files = arr[i]?.files;
    for (let j = 0; j < files?.length; j++) {
      if (arr[i].documentTypeId === documentTypeId) {
        if (!retrieveOnlyDocument) {
          return {
            ...files[j],
            owner: {
              id: arr[i]?.owner?.id,
              type: arr[i]?.owner?.type,
            },
          };
        } else {
          return arr[i];
        }
      }
    }
  }
  return null;
};
