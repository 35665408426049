import {
  Autocomplete,
  Box,
  Button,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { Input } from "../../Inputs";
import fleetImg from "../../../assets/images/datos-vehiculos.png";
import { maskPlate } from "../../../utils/masks";
import { isNumberValid, validateMinValue } from "../../../utils/validateValues";

export const FleetSection = ({
  filteredVacanciesByAvailability,
  control,
  validatePlate,
  validatePlateError,
  isEditing,
  setValue,
  canFillInputs,
  setTemplateId,
  dataVehicle,
  models,
  setModels,
  isReplacing,
}) => {
  const minLengthAllowed = 4;
  const maxLengthAllowed = 6;

  return (
    <Stack spacing={2}>
      <Box display="grid" gridTemplateColumns="repeat(12, 1fr)">
        <Box gridColumn={{ xs: "span 12" }}>
          <Typography variant="h6" sx={{ fontWeight: 700 }}>
            {isEditing ? "Edita los datos del vehiculo" : "Datos del vehiculo"}
          </Typography>
        </Box>
        <Box gridColumn={{ xs: "span 12", lg: "span 6" }}>
          <Controller
            control={control}
            name="plate"
            rules={{
              required: "(*) Campo obligatorio",
              validate: validatePlateError,
            }}
            render={({ field: { value, onChange, ...rest } }) => (
              <>
                <Input
                  value={value}
                  onChange={(e) => {
                    e.target.value = maskPlate(e.target.value);
                    onChange(e);
                  }}
                  label="Patente"
                  isRequired
                  error={Boolean(validatePlateError)}
                  disabled={isEditing}
                  helperText={
                    Boolean(validatePlateError) &&
                    "Esta patente ya esta registrada en nuestro sistema!"
                  }
                  {...rest}
                />
                {!isEditing && (
                  <Box width={100} mt={1}>
                    <Button
                      disabled={
                        value?.length < minLengthAllowed ||
                        value?.length > maxLengthAllowed
                      }
                      onClick={() => validatePlate(value)}
                    >
                      {"Validar"}
                    </Button>
                  </Box>
                )}
              </>
            )}
          />
        </Box>
        <Box
          gridColumn={{ xs: "span 0", lg: "span 4" }}
          component="img"
          alt="Vehículo"
          src={fleetImg}
          sx={{
            display: { xs: "none", lg: "block" },
            height: "200px",
            marginTop: "-50px",
            marginLeft: "auto",
          }}
        />
      </Box>

      <Box
        display="grid"
        sx={!canFillInputs() ? { display: "none" } : {  gap: { xs: 2}}}
      >
        <Divider sx={{ pt: 1, pb: 2, mb: 2 }} />
        <Box display="grid" gridTemplateColumns="repeat(12, 1fr)"  gap={2}>
          <Box gridColumn="span 8" >
            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)"  gap={2}>
              <Box gridColumn="span 6">
                <Controller
                  control={control}
                  defaultValue={""}
                  name="type"
                  rules={{
                    required: "(*) Campo obligatorio",
                  }}
                  render={({
                    field: { value, onChange, ...rest },
                    fieldState: { error },
                  }) => {
                    return (
                      <Autocomplete
                        size="small"
                        value={value || null}
                        required
                        disabled={isEditing || isReplacing}
                        disableClearable
                        onChange={(e, newValue) => {
                          setTemplateId(newValue?.vehicle?.documentTemplateId);
                          onChange(newValue);
                        }}
                        options={filteredVacanciesByAvailability}
                        getOptionLabel={(option) =>
                          option ? option.vehicle?.name : ""
                        }
                        isOptionEqualToValue={(option, value) =>
                          option && option.id === value.id
                        }
                        renderInput={(params) => (
                          <Input
                            {...params}
                            isRequired
                            label="Tipo de vehículo"
                            error={Boolean(error)}
                            helperText={error?.message}
                          />
                        )}
                        {...rest}
                      />
                    );
                  }}
                />
              </Box>
              <Box gridColumn="span 6">
                <Controller
                  control={control}
                  defaultValue={""}
                  name="brand"
                  rules={{
                    required: "(*) Campo obligatorio",
                  }}
                  render={({
                    field: { value, onChange, ...rest },
                    fieldState: { error },
                  }) => (
                    <Autocomplete
                      size="small"
                      value={value || null}
                      disableClearable
                      required
                      onChange={(e, newValue) => {
                        setValue("model", "");
                        setModels(newValue.models);
                        onChange(newValue);
                      }}
                      options={dataVehicle?.concat([
                        {
                          name: "Otro",
                          models: [],
                        },
                      ])}
                      getOptionLabel={(option) =>
                        option && option?.name ? option?.name : ""
                      }
                      isOptionEqualToValue={(option, value) =>
                        option && option.id === value.id
                      }
                      renderInput={(params) => (
                        <Input
                          {...params}
                          isRequired
                          label="Marca"
                          error={Boolean(error)}
                          helperText={error?.message}
                        />
                      )}
                      {...rest}
                    />
                  )}
                />
              </Box>
              <Box gridColumn="span 6">
                <Controller
                  control={control}
                  defaultValue={""}
                  name="model"
                  rules={{
                    required: "(*) Campo obligatorio",
                  }}
                  render={({
                    field: { value, onChange, ...rest },
                    fieldState: { error },
                  }) => (
                    <Autocomplete
                      size="small"
                      value={value || null}
                      disableClearable
                      required
                      onChange={(e, newValue) => {
                        onChange(newValue);
                      }}
                      options={(models ?? []).concat([
                        {
                          name: "Otro",
                        },
                      ])}
                      getOptionLabel={(option) =>
                        option && option?.name ? option.name : ""
                      }
                      isOptionEqualToValue={(option, value) =>
                        option && option.id === value.id
                      }
                      renderInput={(params) => (
                        <Input
                          {...params}
                          isRequired
                          label="Modelo"
                          error={Boolean(error)}
                          helperText={error?.message}
                        />
                      )}
                      {...rest}
                    />
                  )}
                />
              </Box>
              <Box gridColumn="span 6">
                <Controller
                  control={control}
                  name="year"
                  defaultValue={""}
                  rules={{
                    required: "(*) Campo obligatorio",
                    validate: {
                      textIsValid: (text) =>
                        isNumberValid(text) ||
                        "Año inválido, solo se aceptan números",
                      minValueIsValid: (num) =>
                        validateMinValue(num, 1980) ||
                        `Año inválido, debe ser desde 1980`,
                    },
                  }}
                  render={({
                    field: { value, onChange, ...rest },
                    fieldState: { error },
                  }) => (
                    <>
                      <Input
                        value={value}
                        label="Año"
                        onChange={onChange}
                        isRequired
                        error={Boolean(error)}
                        helperText={error?.message}
                        {...rest}
                      />
                    </>
                  )}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Stack>
  );
};
