/* eslint-disable */
import Checkbox from '@mui/material/Checkbox'
import Autocomplete from '@mui/material/Autocomplete'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'

import { CircularProgress } from '@mui/material'
import { Input } from '../Inputs'

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />
const checkedIcon = <CheckBoxIcon fontSize='small' />

export function ComboBox(props) {
  const {
    label,
    onChange,
    error,
    loading,
    multiple,
    placeholder,
    renderOptionLabel,
    options = [],
    isRequired,
    ...rest
  } = props

  const renderOption = (props, option, { selected }) => (
    <li {...props}>
      <Checkbox
        icon={icon}
        checkedIcon={checkedIcon}
        style={{ marginRight: 8 }}
        checked={selected}
        color='secondary'
      />
      {renderOptionLabel(option)}
    </li>
  )

  return (
    <Autocomplete
      {...rest}
      onChange={(event, newValue, reason, description) => {
        onChange(newValue)
      }}
      options={options}
      multiple={multiple}
      size='small'
      disableCloseOnSelect={multiple}
      defaultValue={multiple ? [] : {}}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => renderOptionLabel(option)}
      renderOption={multiple && renderOption}
      loading={loading}
      loadingText='Cargando...'
      renderInput={(params) => (
        <Input
          {...params}
          placeholder={placeholder}
          label={label}
          isRequired={isRequired === false ? false : true}
          error={Boolean(error)}
          helperText={error?.message}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color='inherit' size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}
