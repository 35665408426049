import { requestTypesValues } from "../../../constants/requests";
import { ReplaceDocumentInfo } from "./ReplaceDocumentInfo";
import { ReplacementRequestInfo } from "./ReplacementRequestInfo";

export const RequestTypeInfo = ({ request }) => {
  const { type } = request;
  switch (type) {
    case requestTypesValues.REPLACE_DOCUMENT:
      return <ReplaceDocumentInfo request={request} />;
    case requestTypesValues.TEMPORARY_REPLACEMENT:
      return <ReplacementRequestInfo request={request} isTemporary={true} />;
    case requestTypesValues.PERMANENT_REPLACEMENT:
      return <ReplacementRequestInfo request={request} />;
    default:
      return null;
  }
}
