import { useMutation, useQueryClient } from "react-query"
import { useApi } from "../../api"
import { EMPLOYEE_URI } from "../../../constants/api"

export const useManageEmployee = () => {
  const { axiosInstance } = useApi()
  const queryClient = useQueryClient()
  const { data, error, mutate } = useMutation(
    async ({employeeId, payload}) => {
      const response = employeeId ?
        await axiosInstance.put(`/${employeeId}`,
          payload,
          { baseURL: EMPLOYEE_URI },
        ) :
        await axiosInstance.post('/',
          payload,
          { baseURL: EMPLOYEE_URI },
        )
      return response
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['listEmployeeByApply'])
      },
    }
  )

  return { data, error, mutate }
}