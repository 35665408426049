import { useMutation, useQueryClient } from "react-query";
import { useApi } from "../../../api";
import { DOCUMENT_URI } from "../../../../constants/api";

export function useUploadDocuments() {
  const { axiosInstance } = useApi();
  const queryClient = useQueryClient();

  return useMutation(
    async (document) => {
      const currentRequestFormData = new FormData();

      Object.entries(document).forEach(([key, value]) => {
        if (!value) return;

        if (key === "file") {
          value.forEach((individualFile) =>
            currentRequestFormData.append("file", individualFile)
          );
        } else {
          currentRequestFormData.append(key, value);
        }
      });

      return axiosInstance.post("/", currentRequestFormData, {
        baseURL: DOCUMENT_URI,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("listInterested");
        queryClient.refetchQueries('getFleetsByProviderAndOffer');
      },
    }
  );
}
