import { useQuery } from 'react-query'
import { useApi } from '../../api'
import { APPLY_URI } from '../../../constants/api'
import { buildUrlWithQueryParams } from '../../../v2/helper/converters';

export async function getFleetsByProviderAndOffer(applyId, filters) {
  const { axiosInstance } = useApi();

  const url = buildUrlWithQueryParams(`${APPLY_URI}/${applyId}/fleets`, filters);
  const response = await axiosInstance.get(url);

  return response?.data;
}

export function useListFleetsByProviderAndOffer(applyId, filters) {
  return useQuery('getFleetsByProviderAndOffer', () => getFleetsByProviderAndOffer(applyId, filters), {
    staleTime: 1000 * 60 * 10,
    retry: 0,
    enabled: false
  })
}
