import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Stack, Typography } from '@mui/material'
import { ArrowBackOutlined } from '@mui/icons-material'
import { Controller, useForm } from 'react-hook-form'

import { PaperWhite } from '../../../components/Papers'
import { Input } from '../../../components/Inputs'
import { AlertSnackBar } from '../../../components/Alerts'
import { LoadingSpinner } from '../../../components/LoadingSpinner'

import {
  useCreateVehicleFeature,
  useGetVehicleFeature,
  useUpdateVehicleFeature,
} from '../../../services/hooks/Vehicles'

import {
  isComplexTextNumberValid,
  validateMinLength,
} from '../../../utils/validateValues'

const initialAlertError = {
  open: false,
  message: '',
}

const defaultValues = {
  name: '',
}

export default function FormFeaturesVehicles({ fromButton }) {
  const navigate = useNavigate()
  const { featureId } = useParams()
  const [alertError, setAlertError] = useState(initialAlertError)

  const { control, handleSubmit, setValue } = useForm({ defaultValues })

  const { data: feature, isLoading: isLoadingGetFeature } =
    useGetVehicleFeature(featureId)

  const { mutate: mutateCreate, isLoading: isLoadingCreate } =
    useCreateVehicleFeature()
  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } =
    useUpdateVehicleFeature(featureId)

  const handleCloseAlertError = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setAlertError(initialAlertError)
  }

  useEffect(() => {
    if (!fromButton) navigate('/404')
  }, [])

  useEffect(() => {
    setValue('name', feature?.name)
  }, [feature])

  const onSubmit = (data) => {
    if (featureId) {
      mutateUpdate(data, {
        onSuccess: () => navigate('/featuresVehicles'),
        onError: () =>
          setAlertError({
            open: true,
            message:
              'Oh no! se ha producido un error al editar la característica del vehículo',
          }),
      })
    } else {
      mutateCreate(data, {
        onSuccess: () => navigate('/featuresVehicles'),
        onError: () =>
          setAlertError({
            open: true,
            message:
              'Oh no! se ha producido un error al crear una nueva característica del vehículo',
          }),
      })
    }
  }

  if (isLoadingGetFeature || isLoadingCreate || isLoadingUpdate) {
    return <LoadingSpinner open />
  }

  return (
    <Stack spacing={2} component='form' onSubmit={handleSubmit(onSubmit)}>
      <Typography variant='h4' align='center'>
        {featureId
          ? 'Editando la característica de vehículo'
          : 'Creando una nueva característica de vehículo'}
      </Typography>

      <PaperWhite sx={{ pb: 20 }}>
        <Stack spacing={2} sx={{ width: '50%' }}>
          <Controller
            control={control}
            name='name'
            rules={{
              required: '(*) Campo obligatorio',
              validate: {
                minValid: (text) =>
                  validateMinLength(text, 1) ||
                  'Descripción no puede ser menor a 1 caracteres',
                textIsValid: (text) =>
                  isComplexTextNumberValid(text) ||
                  'Descripción inválida, no se aceptan caracteres extraños',
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <Input
                label='Descripción'
                isRequired
                error={Boolean(error)}
                helperText={error?.message}
                inputProps={{ maxLength: 50 }}
                {...field}
              />
            )}
          />
        </Stack>
      </PaperWhite>

      <Stack spacing={2} direction='row' justifyContent='flex-end' paddingY={2}>
        <Button
          variant='goBack'
          disableRipple
          startIcon={<ArrowBackOutlined />}
          onClick={() => navigate('/featuresVehicles')}
        >
          Volver atrás
        </Button>
        <Button size='large' type='submit'>
          {featureId ? 'Editar' : 'Guardar'}
        </Button>
      </Stack>

      <AlertSnackBar
        open={alertError?.open}
        handleClose={handleCloseAlertError}
        message={alertError?.message}
      />
    </Stack>
  )
}
