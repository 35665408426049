import { useNavigate } from 'react-router-dom'
import { Box, Button, Stack, Typography } from '@mui/material'
import { ArrowBack } from '@mui/icons-material'

import image from '../../assets/images/Error404.png'

export function Error404() {
  const navigate = useNavigate()

  return (
    <Box
      sx={{
        height: '100%',
        display: 'grid',
        placeItems: 'center',
      }}
    >
      <Stack
        direction='row'
        justifyContent='space-around'
        alignItems='center'
        sx={{ width: '100%' }}
      >
        <img src={image} alt='Error 404' />

        <Stack spacing={4}>
          <Typography variant='h4' sx={{ fontWeight: 700 }}>
            Página no encontrada :(
          </Typography>
          <Typography sx={{ maxWidth: 650 }}>
            No fue posible acceder a la página que estabas buscando ahora. Si lo
            deseas, puedes regresar a la página de inicio.{' '}
          </Typography>
          <Button
            startIcon={<ArrowBack />}
            sx={{ width: 250, height: 50 }}
            onClick={() => navigate('/') }
          >
            Volver al inicio
          </Button>
        </Stack>
      </Stack>
    </Box>
  )
}
