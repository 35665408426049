import { useMutation, useQueryClient } from 'react-query'
import { useApi } from '../../../api'
import { DOCUMENT_TYPE_URI } from '../../../../constants/api'

export function useDeleteTypes() {
  const { axiosInstance } = useApi()
  const queryClient = useQueryClient()

  const url = new URL(`${DOCUMENT_TYPE_URI}`)

  return useMutation((id) => axiosInstance.delete(`${url.toString()}/${id}`), {
    onSuccess: () => {
      queryClient.invalidateQueries('listDocumentTypes');
      queryClient.invalidateQueries('listTemplates');
    },
  })
}
