import { useQuery } from "react-query";
import { useApi } from "../../api";
import { APPLY_URI } from "../../../constants/api";
import { buildUrlWithQueryParams } from "../../../v2/helper/converters";

export async function getListRequests(filters) {
  const { axiosInstance } = useApi();

  const url = buildUrlWithQueryParams(`${APPLY_URI}/with-vacancies`, filters);

  const response = await axiosInstance.get(url);

  const { data } = response;
  return data;
}

export function useListRequests(filters) {
  return useQuery(["listRequests", filters], () => getListRequests(filters), {
    staleTime: 1000 * 60 * 5,
  });
}
