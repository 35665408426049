export const ROLES = {
  PROVIDER_TMS: 'provider',
  FLEET_TMS: 'flota',
  SECURITY_TMS: 'security',
}

export const NAV_ALLOWED_ROLES = [
  ROLES.FLEET_TMS,
  ROLES.SECURITY_TMS,
]

export const BLOCK_ROUTES_STATUS = ["rejected"];
