import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { VACANCY_TYPE_MAPPING } from "../../../../constants/status";

export const ComparisonTable = ({entityType, entity, entityToCompare}) => {
  const entityKeys = Object.keys(entity);
  const entityValues = Object.values(entity);
  const entityToCompareValues = Object.values(entityToCompare);
  const entityName = VACANCY_TYPE_MAPPING[entityType];
  const comparisonMatrix = [entityValues, entityToCompareValues];
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: '10em' }}></TableCell>
            <TableCell
              key={"original"}
            >
              {entityName} original
            </TableCell>
            <TableCell
              key={"toCompare"}
            >
              {entityName} de reemplazo
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {comparisonMatrix[0].map((cell, cellIndex) => (
            <TableRow key={cellIndex}>
              <TableCell
                component="th"
                scope="row"
                sx={{ backgroundColor: '#f5f5f5' }}
              >
                <strong>{entityKeys[cellIndex]}</strong>
              </TableCell>
              {comparisonMatrix.map((row, rowIndex) => (
                <TableCell
                  key={rowIndex}
                >
                  {row[cellIndex]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
