const uppercaseFirstLetter = (str = "") =>
  str.charAt(0).toUpperCase() + str.slice(1);

export const formatSnakeCase = (str = "") => {
  if (!str) return "";

  return uppercaseFirstLetter(
    str?.replace(/([_]\w)/g, (g) => ` ${g[1]}`)?.replace("_", "")
  );
};
