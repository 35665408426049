import { useMutation, useQueryClient } from "react-query";
import { useApi } from "../../../api";
import { DOCUMENT_TEMPLATE_URI } from "../../../../constants/api";

export function useUpdateTemplates(id) {
  const { axiosInstance } = useApi();
  const queryClient = useQueryClient();

  return useMutation(
    (newTemplate) =>
      axiosInstance.put(`/${id}`, newTemplate, {
        baseURL: DOCUMENT_TEMPLATE_URI,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("listTemplates");
        queryClient.invalidateQueries(["templates", id]);
      },
    }
  );
}
