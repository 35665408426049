// TODO: ELiminar este archivo y usar el hook useListTypes

import { useQuery } from "react-query";
import { useApi } from "../../../api";
import { DOCUMENT_TYPE_URI } from "../../../../constants/api";

export async function getListDocuments() {
  const { axiosInstance } = useApi();

  const response = await axiosInstance.get("/?limit=1000", {
    baseURL: DOCUMENT_TYPE_URI,
  });

  const { data } = response.data;

  return data;
}

export function useListDocuments() {
  return useQuery("listDocuments", () => getListDocuments(), {
    staleTime: 1000 * 60 * 60 * 24, // 24 horas para não refazer a requisição ao receber foco na tela
  });
}
