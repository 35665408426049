/* eslint-disable no-param-reassign */
import { Chip } from '@mui/material'
import { Circle } from '@mui/icons-material'

export function ChipStatus({ label, setcolor }) {
  const backgroundColors = {
    'En borrador': '#408D5C',
    courier: '#2BB9FF',
    peoneta: '#74D497',
    Cerrado: '#FE6767',
    Publicado: '#2BB9FF',
    Aprobado: '#74D497',
    Preaprobado: '#74D497',
    Rechazado: '#FF0000',
    Bloqueado: '#FCC400',
    'En validación': '#4169E1',
    'Falta información': '#FF7A00',
    'Completar postulación': '#FF7A00',
    'Dctos. pendientes': '#FF7E44',
    Desaprobado: '#FF0000',
    Inactivo: '#b0b0b0',
    pending: '#FFA500',
    draft: '#6A5ACD',
    approved: '#008000',
    rejected: '#FF4500',
    objected: '#EF6C00',
    Objetado: '#EF6C00',
    Borrador: '#6A5ACD',
    Pendiente: '#FFA500',
  }

  if (!setcolor) {
    setcolor = backgroundColors[label]
  }

  return (
    <Chip
      size='small'
      label={label?.toUpperCase()}
      sx={{
        px: 1,
        color: 'white',
        backgroundColor: setcolor,
        '& .MuiSvgIcon-root': { fontSize: '10px', color: 'white' },
      }}
      icon={<Circle />}
    />
  )
}
