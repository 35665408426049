import { useMutation } from "react-query";
import { useApi } from "../../../../../services/api";
import { REQUEST_TRACKER_URI } from "../../../../../constants/api";

export const useUpdateReplacement = () => {
  const { axiosInstance } = useApi();
  return useMutation(({ requestId, modifiedRequest }) =>
    axiosInstance.put(`/replacement/${requestId}`, modifiedRequest, {
      baseURL: REQUEST_TRACKER_URI,
    })
  );
};
