import { createSvgIcon } from '@mui/material'

export const UserIcon = createSvgIcon(
  <>
    <path
      d='M9 10.01C11.0948 10.01 12.8005 8.30424 12.8005 6.20948C12.8005 4.11471 11.0948 2.40898 9 2.40898C6.90524 2.40898 5.1995 4.11471 5.1995 6.20948C5.1995 8.30424 6.90524 10.01 9 10.01ZM9 3.57606C10.4514 3.57606 11.6334 4.75811 11.6334 6.20948C11.6334 7.66085 10.4514 8.84289 9 8.84289C7.54863 8.84289 6.36658 7.66085 6.36658 6.20948C6.36658 4.75811 7.54863 3.57606 9 3.57606Z'
      fill='#212121'
    />
    <path
      d='M9 0C4.0399 0 0 4.0399 0 9C0 13.9601 4.0399 18 9 18C13.9601 18 18 13.9601 18 9C18 4.0399 13.9601 0 9 0ZM9 1.16708C13.3242 1.16708 16.8329 4.68329 16.8329 9C16.8329 10.616 16.3392 12.1197 15.5012 13.3691C13.9377 11.611 11.4838 10.5337 9 10.5337C6.44888 10.5337 4.05486 11.596 2.51372 13.3915C1.66085 12.1421 1.16708 10.6309 1.16708 9C1.16708 4.67581 4.67581 1.16708 9 1.16708ZM9 16.8329C6.73317 16.8329 4.68329 15.8603 3.25436 14.3192C4.57107 12.6733 6.70324 11.6933 9 11.6933C11.2294 11.6933 13.4289 12.6883 14.7681 14.2968C13.3317 15.8529 11.2818 16.8329 9 16.8329Z'
      fill='#212121'
    />
  </>,
  'UserIcon'
)
