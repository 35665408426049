import { useQuery } from "react-query";
import { REQUEST_TRACKER_URI } from "../../../../constants/api";
import { useApi } from "../../../../services/api";
import { buildUrlWithQueryParams } from '../../../helper/converters';

export async function getListProviderRequests(filters) {
  const { axiosInstance } = useApi();
  const filtersWithoutUndefined = Object.entries(filters)
    .reduce((acc, [key, value]) => {
      if (value !== undefined) {
        acc[key] = value;
      }
      return acc;
    },
    {}
  );
  const url = buildUrlWithQueryParams(`${REQUEST_TRACKER_URI}/request`, filtersWithoutUndefined);

  const response = await axiosInstance.get(url);
  const { data, metadata } = response.data;
  return { data, metadata };
}

export function useListProviderRequests(filters) {
  return useQuery(["listProviderRequests", filters], () => getListProviderRequests(filters));
}
