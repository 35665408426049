import { useMutation } from 'react-query'
import { useApi } from '../../api'
import { FLEET_URI } from '../../../constants/api'

export async function getPlate(plate) {
  if(!plate) return;

  const { axiosInstance } = useApi()
  const response = await axiosInstance.get(`fleet/byPlate/${plate}`, {
    baseURL: FLEET_URI,
  })

  const { data } = response.data

  return data
}

export function useValidatePlate() {
  return useMutation(['getPlate'], (plate) => getPlate(plate), {
    staleTime: 1000 * 60 * 60
  })
}
