export const mainTheme = {
    ltr: "ltr",
    rtl: "rtl",
    placeholder: "editorPlaceholder",
    paragraph: "editorParagraph",
    quote: "editorQuote",
    heading: {
      h1: "editor-heading-h1",
      h2: "editor-heading-h2",
      h3: "editor-heading-h3",
      h4: "editor-heading-h4",
      h5: "editor-heading-h5"
    },
    list: {
      nested: {
        listitem: "editor-nested-listitem"
      },
      ol: "editor-list-ol",
      ul: "editor-list-ul",
      listitem: "editor-listitem"
    },
    image: "editor-image",
    link: "editor-link",
    text: {
      bold: "editor-text-bold",
      italic: "editor-text-italic",
      overflowed: "editor-text-overflowed",
      hashtag: "editor-text-hashtag",
      underline: "editor-text-underline",
      strikethrough: "editor-text-strikethrough",
      underlineStrikethrough: "editor-text-underlineStrikethrough",
      code: "editor-text-code"
    },
    code: "editor-code",
    codeHighlight: {
      atrule: "editor-tokenAttr",
      attr: "editor-tokenAttr",
      boolean: "editor-tokenProperty",
      builtin: "editor-tokenSelector",
      cdata: "editor-tokenComment",
      char: "editor-tokenSelector",
      class: "editor-tokenFunction",
      "class-name": "editor-tokenFunction",
      comment: "editor-tokenComment",
      constant: "editor-tokenProperty",
      deleted: "editor-tokenProperty",
      doctype: "editor-tokenComment",
      entity: "editor-tokenOperator",
      function: "editor-tokenFunction",
      important: "editor-tokenVariable",
      inserted: "editor-tokenSelector",
      keyword: "editor-tokenAttr",
      namespace: "editor-tokenVariable",
      number: "editor-tokenProperty",
      operator: "editor-tokenOperator",
      prolog: "editor-tokenComment",
      property: "editor-tokenProperty",
      punctuation: "editor-tokenPunctuation",
      regex: "editor-tokenVariable",
      selector: "editor-tokenSelector",
      string: "editor-tokenSelector",
      symbol: "editor-tokenProperty",
      tag: "editor-tokenProperty",
      url: "editor-tokenOperator",
      variable: "editor-tokenVariable"
    }
};


export const MainStyles = () => (
  <style>
        {
          `
          .editorContainer {
            color: #000;
            position: relative;
            line-height: 20px;
            font-weight: 400;
            text-align: left;
            border-radius: 10px;
            box-shadow: 1px 1px 8px rgba(0,0,0,.2);
            padding: 1rem;
          }

          .editorInner {
            background: #fff;
            position: relative;
          }
          
          .editorInput {
            min-height: 150px;
            resize: none;
            font-size: 15px;
            caret-color: rgb(5, 5, 5);
            position: relative;
            tab-size: 1;
            outline: 0;
            padding: 15px 10px;
            caret-color: #444;
          }
          
          .editorPlaceholder {
            color: #999;
            overflow: hidden;
            position: absolute;
            text-overflow: ellipsis;
            top: 15px;
            left: 10px;
            font-size: 15px;
            user-select: none;
            display: inline-block;
            pointer-events: none;
          }

          .ltr {
            text-align: left;
          }
          
          .rtl {
            text-align: right;
          }
        
          .editor-text-bold {
            font-weight: bold;
          }
          
          .editor-text-italic {
            font-style: italic;
          }
          
          .editor-text-underline {
            text-decoration: underline;
          }
          
          .editor-text-strikethrough {
            text-decoration: line-through;
          }
          
          .editor-text-underlineStrikethrough {
            text-decoration: underline line-through;
          }
          
          .editor-tokenComment {
            color: slategray;
          }
          
          .editor-tokenPunctuation {
            color: #999;
          }
          
          .editor-tokenProperty {
            color: #905;
          }
          
          .editor-tokenSelector {
            color: #690;
          }
          
          .editor-tokenOperator {
            color: #9a6e3a;
          }
          
          .editor-tokenAttr {
            color: #07a;
          }
          
          .editor-tokenVariable {
            color: #e90;
          }
          
          .editor-tokenFunction {
            color: #dd4a68;
          }
          
          .editorParagraph {
            margin: 0;
            margin-bottom: 8px;
            position: relative;
          }
          
          .editorParagraph:last-child {
            margin-bottom: 0;
          }
          
          .editor-heading-h1 {
            font-size: 24px;
            color: rgb(5, 5, 5);
            font-weight: 400;
            margin: 0;
            margin-bottom: 12px;
            padding: 0;
          }
          
          .editor-heading-h2 {
            font-size: 15px;
            color: rgb(101, 103, 107);
            font-weight: 700;
            margin: 0;
            margin-top: 10px;
            padding: 0;
            text-transform: uppercase;
          }
          
          .editor-quote {
            margin: 0;
            margin-left: 20px;
            font-size: 15px;
            color: rgb(101, 103, 107);
            border-left-color: rgb(206, 208, 212);
            border-left-width: 4px;
            border-left-style: solid;
            padding-left: 16px;
          }
          
          .editor-list-ol {
            padding: 0;
            margin: 0;
            margin-left: 16px;
          }
          
          .editor-list-ul {
            padding: 0;
            margin: 0;
            margin-left: 16px;
          }
          
          .editor-listitem {
            margin: 8px 32px 8px 32px;
          }
          
          .editor-nested-listitem {
            list-style-type: none;
          }
          
          pre::-webkit-scrollbar {
            background: transparent;
            width: 10px;
          }
          
          pre::-webkit-scrollbar-thumb {
            background: #999;
          }

          .editor-link {
            color: rgb(33, 111, 219);
            text-decoration: none;
          }
        `
        }
      </style>
)
    