import { useState, useEffect } from "react";
import { Table } from "../../components/Table";
import { SearchBar } from "../../../components/SearchBar";
import { useMyProvider } from "../../../services/hooks/Providers/useMyProvider";
import { useFleetListByProvider } from "../../services/hooks/Fleets/useFleetListByProvider";
import { UpdateFleetDocsView } from "./components/UpdateFleetDocsView";
import { ReplaceFleet } from "./components/ReplaceFleet";
import { useAuth } from "../../../hook/useAuth";
import {
  Button,
  Stack,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { LoadingSpinner } from "../../../components/LoadingSpinner";
import { MessageBox } from "../../../components/common/MessageBox";
import { FleetStatus } from "../../../constants/status";
import { headCellsProviderFleetList } from "../../helper/headcell";
import { ChipStatus } from "../../components/Chip";
import { TableButton } from "../../components/Button/TableButton";
import { ReactComponent as EditButtonIcon } from "../../assets/images/editbutton.svg";
import { ReactComponent as InfoButton } from "../../assets/images/infobutton.svg";
import { ReactComponent as ReplaceButton } from "../../assets/images/replacebutton.svg";
import ListDocumentsModal from "../../components/Modal/ListDocumentModal";
import { vacancyType } from "../../../constants/vacancyType";
import { useAtom } from "jotai";
import { replaceDataAtom } from "../../store/requestTracker/replaceRequest.store";
import { useURLfilters } from "../../hooks/filters.hook";

const defaultFilters = {
  isProviderList: true,
  page: 1,
  limit: 10,
};

export default function ProviderFleetList() {
  const { email, forceRefreshSession } = useAuth();
  const { data: myProvider, isLoading: providerLoading } = useMyProvider(email);
  const { filters, setFilters, cleanFilters, getURLValue } = useURLfilters(
    defaultFilters,
    {
      excludeParams: ["providerId"],
      extraParams: {
        providerId: myProvider?.id,
      },
    }
  );
  const [showModal, setShowModal] = useState(false);
  const [, setReplaceData] = useAtom(replaceDataAtom);
  const [item, setItem] = useState();
  const [currentItem, setCurrentItem] = useState({});
  const [isRenderedEdit, setIsRenderedEdit] = useState(false);
  const [isReplace, setIsReplace] = useState(false);
  const {
    data: { data, metadata } = {},
    isLoading,
    error,
  } = useFleetListByProvider(filters);
  const { TableContainer } = Table(
    headCellsProviderFleetList,
    {
      ...metadata,
      page: getURLValue("page", 1),
      limit: getURLValue("limit", 10),
    },
    onChangeFilters
  );

  const handleSearch = (event) => {
    const { value } = event.target;
    const newValue = value.toLowerCase();

    setFilters({ search: newValue, page: 1 });
  };

  function onChangeFilters(newFilters = {}) {
    setFilters(newFilters);
  }

  useEffect(() => {
    setReplaceData({});
    forceRefreshSession();
  }, []);

  const onEditDocument = (item) => {
    setCurrentItem(item);
    setIsRenderedEdit(true);
  };
  const handleGoBack = () => {
    setReplaceData({});
    setIsRenderedEdit(false);
    setIsReplace(false);
  };

  if (isRenderedEdit) {
    return <UpdateFleetDocsView goBack={handleGoBack} item={currentItem} />;
  }

  const onReplaceFleet = (item) => {
    setCurrentItem(item);
    setIsReplace(true);
  };

  if (isReplace) {
    return (
      <ReplaceFleet
        providerId={myProvider?.id}
        item={currentItem}
        goBack={handleGoBack}
      />
    );
  }

  if (error) return <MessageBox>Ups, ocurrio un error!</MessageBox>;

  if (isLoading || providerLoading) return <LoadingSpinner open />;

  const TabsAddon = () => (
    <Stack
      direction="row"
      paddingY={2}
      paddingX={2}
      alignItems="center"
      justifyContent="space-between"
    >
      <SearchBar
        id="table-searchbar"
        type="text"
        placeholder="Buscar"
        onSearch={handleSearch}
        search={getURLValue("search", "")}
      />
      <Button
        variant="contained"
        onClick={cleanFilters}
        size="medium"
        sx={{ marginLeft: 3 }}
      >
        Limpiar filtros
      </Button>
    </Stack>
  );

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <Stack spacing={2}>
        <Typography variant="h4" align="center">
          Mis vehículos
        </Typography>
        <TableContainer addons={[TabsAddon]} withPagination>
          <TableBody>
            {data?.map((item) => (
              <TableRow hover tabIndex={-1} key={item.id}>
                <TableCell>
                  <ChipStatus
                    label={FleetStatus[item.status]}
                    setcolor={item.status}
                  />
                </TableCell>
                <TableCell>{item.plate}</TableCell>
                <TableCell>{item.brand}</TableCell>
                <TableCell>{item.model}</TableCell>
                <TableCell>{item.type}</TableCell>
                <TableCell>{item?.active?.isActive ? 'Activo' : 'Inactivo'}</TableCell>
                <TableCell>
                  <TableButton
                    onClick={() => onEditDocument(item)}
                  >
                    <EditButtonIcon />
                  </TableButton>
                  <TableButton
                    onClick={() => {
                      setItem(item);
                      setShowModal(true);
                    }}
                  >
                    <InfoButton />
                  </TableButton>
                  <TableButton
                    disabled={!!item?.requested}
                    onClick={() => onReplaceFleet(item)}
                  >
                    <ReplaceButton />
                  </TableButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TableContainer>
      </Stack>
      <ListDocumentsModal
        isOpen={showModal}
        onClose={handleCloseModal}
        type={vacancyType.FLEET}
        item={item}
      />
    </>
  );
}
