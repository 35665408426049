import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  IconButton,
  Stack,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import {
  AddCircleOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@mui/icons-material";

import { PaperWhite } from "../../../components/Papers";
import { SearchBar } from "../../../components/SearchBar";
import { Dialogs } from "../../../components/Dialogs";
import { AlertSnackBar } from "../../../components/Alerts";
import { LoadingSpinner } from "../../../components/LoadingSpinner";
import { Table } from "../../../v2/components/Table";

import {
  useDeleteTemplates,
  useListTemplates,
} from "../../../services/hooks/Docs";

import styles from "../../../v2/components/TabsAddon/TabsAddon.module.css"

const headCells = [
  {
    id: "description",
    label: "Descripción",
  },
  {
    id: "documents",
    label: "Documentos",
  },
  {
    id: "actions",
    label: "Acciones",
    disableSorting: true,
  },
];

const initialAlertError = {
  open: false,
  message: "",
};

export default function Templates() {
  const navigate = useNavigate();
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [deleteIsLoading, setDeleteIsLoading] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [alertError, setAlertError] = useState(initialAlertError);
  const [filters, setFilters] = useState({});

  const { data: templates, isLoading, error } = useListTemplates(filters);
  const { mutate } = useDeleteTemplates();

  const handleCloseAlertError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertError(initialAlertError);
  };

  const handleCloseDialogDelete = () => {
    setOpenDialogDelete(false);
  };

  const handleDelete = async () => {
    setDeleteIsLoading(true);
    mutate(deleteId, {
      onSuccess: () => {
        setOpenDialogDelete(false);
        setDeleteIsLoading(false);
      },
      onError: () => {
        setOpenDialogDelete(false);
        setAlertError({
          open: true,
          message: "Oh no! se ha producido un error al deletar la plantilla",
        });
      },
    });
  };

  const handleSearch = (event) => {
    const { value } = event.target;
    const newValue = value.toLowerCase();

    if (newValue !== undefined) {
      setFilters((old) => ({ ...old, search: newValue }));
    }
  };

  const onChangeFilters = (newFilters = {}) => {
    setFilters((old) => ({
      ...old,
      ...newFilters,
    }));
  };

  const { TableContainer } = Table(
    headCells,
    templates?.metadata,
    onChangeFilters
  );

  if (error) {
    return (
      <PaperWhite
        sx={{
          color: "primary.main",
          height: "100%",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Typography variant="h4">No se encontraron datos!</Typography>
      </PaperWhite>
    );
  }

  if (isLoading) {
    return <LoadingSpinner open />;
  }

  const TabsAddon = () => (
    <Stack
      direction="row"
      paddingY={2}
      paddingX={2}
      alignItems="center"
      justifyContent="space-between"
      className={styles.tabAddon}
    >
      <Box sx={{ display: "flex" }}>
        <SearchBar
          id="table-searchbar"
          type="text"
          placeholder="buscar"
          onSearch={handleSearch}
        />
      </Box>

      <Button
        variant="contained"
        startIcon={<AddCircleOutlined color="inherit" />}
        onClick={() =>
          navigate("/Templates/create", {
            state: {
              fromButton: true,
            },
          })
        }
      >
        Crear Nuevo
      </Button>
    </Stack>
  );

  return (
    <Stack spacing={2}>
      <Typography variant="h4" align="center">
        Plantilla de documentos
      </Typography>
      {!error && !isLoading && (
        <TableContainer addons={[TabsAddon]} withPagination>
          <TableBody>
            {templates?.data.map(({ id, name, documentTypes }) => (
              <TableRow key={id}>
                <TableCell>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <Typography>{name}</Typography>
                  </Stack>
                </TableCell>
                <TableCell>
                  <Typography>
                    {documentTypes
                      .map((document) => document.label)
                      .join(" | ")}
                  </Typography>
                </TableCell>
                <TableCell sx={{ width: "20%" }}>
                  <Stack direction="row" spacing={2}>
                    <IconButton
                      onClick={() =>
                        navigate(`/Templates/update/${id}`, {
                          state: {
                            fromButton: true,
                          },
                        })
                      }
                    >
                      <EditOutlined />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        setOpenDialogDelete(true);
                        setDeleteId(id);
                      }}
                    >
                      <DeleteOutlined />
                    </IconButton>
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TableContainer>
      )}
      <Dialogs
        open={openDialogDelete}
        handleClose={handleCloseDialogDelete}
        handleConfirmation={handleDelete}
        isLoading={deleteIsLoading}
        title="¿Estás seguro de que quieres eliminar la plantilla?"
      />
      <AlertSnackBar
        open={alertError?.open}
        handleClose={handleCloseAlertError}
        message={alertError?.message}
      />
    </Stack>
  );
}
