import { forwardRef } from "react";
import {
  Stack,
  TextField,
  Typography,
  InputAdornment,
  CircularProgress,
} from "@mui/material";

const InputBase = ({ label, isRequired, sx, isLoading, ...rest }, ref) => (
  <Stack spacing={1}>
    <Stack direction="row">
      <Typography variant="body1" sx={{ "& span": { color: "orange" } }}>
        {label} {isRequired && <span>*</span>}
      </Typography>
    </Stack>
    <TextField
      fullWidth
      size="small"
      sx={{
        ...sx,
        "& .MuiOutlinedInput-root": {
          borderRadius: "16px",
        },
        "& fieldset": { borderColor: "borderBlue" },
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {isLoading && <CircularProgress size={20} />}
          </InputAdornment>
        ),
      }}
      {...rest}
      inputRef={ref}
    />
  </Stack>
);

const Input = forwardRef(InputBase);

export default Input;
