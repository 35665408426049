export const headCellsEmployeeBackOffice = [
  {
    id: 'numberRut',
    label: 'RUT',
    exportExcel: true
  },
  {
    id: 'name',
    label: 'Nombre',
    exportExcel: true
  },
  {
    id: 'email',
    label: 'Correo',
    exportExcel: true
  },
  {
    id: 'numberRutProvider',
    label: 'Rut Proveedor',
    exportExcel: true
  },
  {
    id: 'nameProvider',
    label: 'Nombre Proveedor',
    exportExcel: true
  },
  {
    id: 'active',
    label: 'Activo/Inactivo',
    exportExcel: true
  },
  {
    id: 'blocked',
    label: 'Bloqueado',
    exportExcel: true
  },
  {
    id: 'actions',
    label: 'Acciones',
    disableSorting: true,
  },
]

export const headCellsProviderBackOffice = [
  {
    id: 'numberRut',
    label: 'RUT Razón social',
    exportExcel: true
  },
  {
    id: 'name',
    label: 'Nombre razón social',
    exportExcel: true
  },
  {
    id: 'numberRutLegalRepresentative',
    label: 'Rut Representante legal',
    exportExcel: true
  },
  {
    id: 'nameLegalRepresentative',
    label: 'Nombre Representante legal',
    exportExcel: true
  },
  {
    id: 'email',
    label: 'Correo electrónico',
    exportExcel: true
  },
  {
    id: 'phone',
    label: 'Número de contacto',
    exportExcel: true
  },
  {
    id: 'address',
    label: 'Dirección comercial',
    exportExcel: true
  },
  {
    id: 'commune',
    label: 'Comuna',
    exportExcel: true
  },
  {
    id: 'active',
    label: 'Activo/Inactivo',
    exportExcel: true
  },
  {
    id: 'blocked',
    label: 'Bloqueado',
    exportExcel: true
  },
  {
    id: 'actions',
    label: 'Acciones',
    disableSorting: true,
  },
]


export const headCellsFleets = [
  {
    id: 'plate',
    label: 'Patente',
    exportExcel: true
  },
  {
    id: 'type',
    label: 'Tipo',
    exportExcel: true
  },
  {
    id: 'brand',
    label: 'Marca',
    exportExcel: true
  },
  {
    id: 'model',
    label: 'Modelo',
    exportExcel: true
  },
  {
    id: 'year',
    label: 'Año',
    exportExcel: true
  },
  {
    id: 'dateCreated',
    label: 'Fecha de ingreso',
    exportExcel: true
  },
  {
    id: 'active',
    label: 'Activo/Inactivo',
    exportExcel: true
  },
  {
    id: 'blocked',
    label: 'Bloqueado',
    exportExcel: true
  },
  {
    id: 'dateDeleted',
    label: 'Fecha de desvinculación',
    exportExcel: true
  },
  {
    id: 'actions',
    label: 'Acciones',
    disableSorting: true,
  },
]

export const headCellsProviderFleetList = [
  {
    id: "status",
    label: "Estado",
  },
  {
    id: "plate",
    label: "Patente",
  },
  {
    id: "brand",
    label: "Marca",
  },
  {
    id: "model",
    label: "Modelo",
  },
  {
    id: "type",
    label: "Tipo",
  },
  {
    id: "active",
    label: "Activo/Inactivo",
  },
  {
    id: "actions",
    label: "Acciones",
    disableSorting: true,
  },
];

export const headCellsProviderEmployeeList = [
  {
    id: "status",
    label: "Estado",
  },
  {
    id: "name",
    label: "Nombre",
  },
  {
    id: "numberRut",
    label: "Rut",
  },
  {
    id: "active",
    label: "Activo/Inactivo",
  },
  {
    id: "actions",
    label: "Acciones",
    disableSorting: true,
  },
];