import { useMutation } from "react-query";
import { useApi } from "../../../../../services/api";
import { REQUEST_TRACKER_URI } from "../../../../../constants/api";

export function useSecurityManageRequest() {
  const { axiosInstance } = useApi();
  return useMutation(({ requestId, type, action }) =>
    axiosInstance.post(`/request/${requestId}/${type}/security/${action}`, {}, {
      baseURL: REQUEST_TRACKER_URI,
    })
  );
}
