import React, { useState, useEffect } from "react";

import { useNavigate, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";

import {
  Box,
  Button,
  Chip,
  Stack,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { ArrowBackOutlined } from "@mui/icons-material";

import { PaperWhite } from "../../../../components/Papers";
import { Input } from "../../../../components/Inputs";
import { LoadingSpinner } from "../../../../components/LoadingSpinner";
import { AlertSnackBar } from "../../../../components/Alerts";

import {
  useCreateTypes,
  useUpdateTypes,
  useGetTypes,
} from "../../../../services/hooks/Docs";

import {
  validateMinLength,
  isComplexTextNumberValid,
  isSimpleNumberValid,
} from "../../../../utils/validateValues";
import { UpsertDocumentInput } from "../Documents/UpsertDocumentInput";

const initialFormValues = {
  label: "",
  operation: false,
  daysToNotificationExpires: null,
  hasExpiration: false,
};

export default function FormDocumentTypes() {
  const navigate = useNavigate();
  const { documentTypeId } = useParams();
  const { control, handleSubmit, setValue } = useForm(initialFormValues);
  const [hasExpiration, setHasExpiration] = useState(
    initialFormValues.hasExpiration
  );
  const [documents, setDocuments] = useState([]);
  const [upsertDocumentState, setUpsertDocumentState] = useState({
    index: null,
    isOpen: false,
    isEdit: false,
    selectedDoc: null,
  });
  const [alertError, setAlertError] = useState({
    isOpen: false,
    message: "",
  });

  const { data: documentType, isLoading: isLoadingGetDocument } = useGetTypes(
    documentTypeId || ""
  );

  const { mutate: mutateCreate, isLoading: isLoadingCreate } = useCreateTypes();
  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } =
    useUpdateTypes(documentTypeId);

  const onSubmit = (dataDoc) => {
    const currentDocType = {
      label: dataDoc.label.trim(),
      name: dataDoc.label.replaceAll(" ", "_").toLowerCase(),
      block: {
        operation: dataDoc.operation ?? false,
      },
      notification: {
        daysToNotificationExpires: dataDoc.daysToNotificationExpires,
      },
      hasExpiration: dataDoc.hasExpiration ?? false,
      documents: documents,
    };

    if (documentTypeId !== undefined) {
      mutateUpdate(currentDocType, {
        onSuccess: () => {
          navigate("/Types");
        },
        onError: () =>
          setAlertError({
            open: true,
            message: `Se ha producido un error al editar el tipo documento con ID: ${documentTypeId}`,
          }),
      });
    } else {
      mutateCreate(currentDocType, {
        onSuccess: () => {
          navigate("/Types");
        },
        onError: () =>
          setAlertError({
            open: true,
            message: "Se ha producido un error al crear el tipo de documento",
          }),
      });
    }
  };

  const handleCloseAlertError = () => {
    setAlertError({
      isOpen: false,
      message: "",
    });
  };

  const handleOpenUpsertDocument = ({ selectedDoc = null, index = null }) => {
    setUpsertDocumentState({
      index,
      isOpen: true,
      isEdit: selectedDoc !== null ? true : false,
      selectedDoc,
    });
  };

  const handleCloseUpsertDocument = () => {
    setUpsertDocumentState({
      index: null,
      isOpen: false,
      isEdit: false,
      selectedDoc: null,
    });
  };

  const handleDeleteDocumentInput = (index) => {
    setDocuments((prev) => {
      const newDocuments = [...prev];
      newDocuments.splice(index, 1);

      return newDocuments;
    });
  };

  const hasExpirationOnChange = (event, checked) => {
    setHasExpiration(checked);
    if (!checked) {
      setValue("operation", false);
      setValue("daysToNotificationExpires", null);
    }
  };

  useEffect(() => {
    if (documentTypeId !== "" && documentTypeId !== undefined) {
      setValue("label", documentType?.label);
      setValue("operation", Boolean(documentType?.block?.operation));
      setValue(
        "daysToNotificationExpires",
        Number(documentType?.notification?.daysToNotificationExpires)
      );
      setValue("hasExpiration", Boolean(documentType?.hasExpiration));
      setHasExpiration(Boolean(documentType?.hasExpiration));
      setDocuments(documentType?.documents);
    }
  }, [documentType, documentTypeId]);

  if (isLoadingCreate || isLoadingUpdate || isLoadingGetDocument) {
    return <LoadingSpinner open />;
  }

  return (
    <Stack spacing={4} component="form" onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h4" align="center">
        {documentTypeId
          ? "Edición de tipo de documento"
          : "Creación de tipo de documento"}
      </Typography>

      <PaperWhite sx={{ pb: 20 }}>
        <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={3}>
          <Box
            gridColumn={{ xs: "span 12", lg: "span 6" }}
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            gap={3}
          >
            <Box gridColumn={{ xs: "span 12", lg: "span 12" }}>
              <Controller
                control={control}
                name="label"
                rules={{
                  required: "(*) Campo obligatorio",
                  validate: {
                    minValid: (text) =>
                      validateMinLength(text, 1) ||
                      "El nombre del tipo de documento no puede ser menor a 1 caracteres",
                    textIsValid: (text) =>
                      isComplexTextNumberValid(text) ||
                      "Nombre inválido, no se aceptan caracteres extraños",
                  },
                }}
                render={({
                  field: { value, onChange, ...rest },
                  fieldState: { error },
                }) => (
                  <Input
                    label="Nombre"
                    fullWidth
                    isRequired
                    value={value}
                    onChange={onChange}
                    error={Boolean(error)}
                    helperText={error?.message}
                    inputProps={{ maxLength: 50 }}
                    {...rest}
                  />
                )}
              />
            </Box>
            <Box gridColumn={{ xs: "span 12", lg: "span 12" }}>
              <Button sx={{ width: "100%" }} onClick={handleOpenUpsertDocument}>
                Agregar documento
              </Button>
              {documents?.length > 0 ? (
                <Box marginTop="20px">
                  {documents.map((individualDoc, index) => {
                    return (
                      <Chip
                        sx={{ marginRight: "5px", marginBottom: "5px" }}
                        label={individualDoc.label}
                        onClick={() =>
                          handleOpenUpsertDocument({
                            selectedDoc: individualDoc,
                            index: index,
                          })
                        }
                        onDelete={() => handleDeleteDocumentInput(index)}
                      />
                    );
                  })}
                </Box>
              ) : (
                <Typography textAlign="center" color="gray" marginTop="20px">
                  Lista de documentos vacía
                </Typography>
              )}
            </Box>
          </Box>
          <Box
            gridColumn={{ xs: "span 12", lg: "span 6" }}
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            gap={3}
          >
            <Box gridColumn={{ xs: "span 12", lg: "span 12" }}>
              <Controller
                control={control}
                name="hasExpiration"
                rules={{}}
                render={({ field: { value, onChange, ...rest } }) => {
                  const relatedValue = Boolean(value);

                  return (
                    <>
                      <Typography
                        variant="body1"
                        sx={{
                          color: "rgba(0, 0, 0, 0.87)",
                          "& span": { color: "orange" },
                        }}
                      >
                        Tiene fecha de vencimiento
                      </Typography>

                      <FormControlLabel
                        key="hasExpiration"
                        control={
                          <Checkbox
                            checked={relatedValue}
                            onChange={(...args) => {
                              hasExpirationOnChange(...args);
                              onChange(...args);
                            }}
                            color="secondary"
                            {...rest}
                          />
                        }
                      />
                    </>
                  );
                }}
              />
            </Box>
            {hasExpiration && (
              <Box gridColumn={{ xs: "span 12", lg: "span 12" }}>
                <Controller
                  control={control}
                  name="operation"
                  rules={{}}
                  render={({ field: { value, onChange, ...rest } }) => {
                    const relatedValue = Boolean(value);

                    return (
                      <>
                        <Typography
                          variant="body1"
                          sx={{
                            color: "rgba(0, 0, 0, 0.87)",
                            "& span": { color: "orange" },
                          }}
                        >
                          Bloquear operación
                        </Typography>

                        <FormControlLabel
                          key="operation"
                          control={
                            <Checkbox
                              checked={relatedValue}
                              onChange={onChange}
                              color="secondary"
                              {...rest}
                            />
                          }
                        />
                      </>
                    );
                  }}
                />
              </Box>
            )}
            {hasExpiration && (
              <Box gridColumn={{ xs: "span 12", lg: "span 6" }}>
                <Controller
                  control={control}
                  name="daysToNotificationExpires"
                  rules={{
                    required: "(*) Campo obligatorio",
                    validate: {
                      minValid: (text) =>
                        validateMinLength(text, 1) ||
                        "Días para notificar expiración no puede ser menor a 1 caracteres",
                      textIsValid: (text) =>
                        isSimpleNumberValid(text) ||
                        "Días para notificar expiración inválido, no se aceptan caracteres extraños",
                    },
                  }}
                  render={({
                    field: { value, onChange, ...rest },
                    fieldState: { error },
                  }) => (
                    <Input
                      label="Días para notificar expiración"
                      type="number"
                      fullWidth
                      isRequired
                      value={value}
                      onChange={onChange}
                      error={Boolean(error)}
                      helperText={error?.message}
                      inputProps={{ maxLength: 50 }}
                      {...rest}
                    />
                  )}
                />
              </Box>
            )}
          </Box>
        </Box>
      </PaperWhite>

      <Stack spacing={2} direction="row" justifyContent="flex-end" paddingY={2}>
        <Button
          variant="goBack"
          disableRipple
          startIcon={<ArrowBackOutlined />}
          onClick={() => {
            navigate("/Types");
          }}
        >
          Volver atrás
        </Button>
        <Button size="large" type="submit">
          {documentTypeId ? "Editar" : "Guardar"}
        </Button>
      </Stack>

      <UpsertDocumentInput
        index={upsertDocumentState.index}
        isEdit={upsertDocumentState.isEdit}
        selectedDoc={upsertDocumentState.selectedDoc}
        setDocuments={setDocuments}
        openModal={upsertDocumentState.isOpen}
        handleCloseModal={handleCloseUpsertDocument}
      />

      <AlertSnackBar
        open={alertError?.open}
        handleClose={handleCloseAlertError}
        message={alertError?.message}
      />
    </Stack>
  );
}
