import { useQuery } from "react-query";
import { REQUEST_TRACKER_URI } from "../../../../constants/api";
import { useApi } from "../../../../services/api";

const getProviderRequestById = async (requestId) => {
  const { axiosInstance } = useApi();
  const response = await axiosInstance.get(`/request/${requestId}`, {
    baseURL: REQUEST_TRACKER_URI,
  });

  return response.data;
};

export const useGetProviderRequestById = (requestId) => {
  return useQuery(
    ["providerRequest", requestId],
    () => getProviderRequestById(requestId),
    {
      staleTime: 1000 * 60 * 10,
    }
  );
};
