import { useMutation, useQueryClient } from "react-query";
import { useApi } from "../../api";
import { LOCALITY_URI } from "../../../constants/api";

export function useCreateBases() {
  const { axiosInstance } = useApi();
  const queryClient = useQueryClient();

  return useMutation(
    (type) => {
      const localityId = type.localityId;
      delete type.structureId;
      return axiosInstance.post(`/${localityId}/base`, type, {
        baseURL: LOCALITY_URI,
      });
    },
    {
      onSuccess: () => queryClient.invalidateQueries("listBases"),
    }
  );
}
