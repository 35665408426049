import { useSession } from '@blue-express/bx-lib-universal-frontend'

import {
  fetchSessionFunctionalities,
  checkPermission,
} from '../../utils/permissions'

export const ProtectedComponent = ({ children, functionality }) => {
  const { getSession } = useSession()
  const session = getSession()

  const functionalities = fetchSessionFunctionalities(session)

  if (!checkPermission(functionalities, functionality)) return null

  return children
}
