/* eslint-disable array-callback-return */
/* eslint-disable no-param-reassign */
import { alias, services } from '../constants/access'

export const checkPermission = (functionalities, functionality) =>
  functionalities?.some((func) => functionality.includes(func))

export const fetchFunctionalitiesByService = (authorizations, service) => {
  const functionalities = []
  const foundService = authorizations?.find(
    (serviceWrap) => serviceWrap.service === service
  )
  if (foundService)
    functionalities.push(...(foundService.functionalities ?? []))
  return functionalities
}

export const fetchFunctionalitiesWithoutService = (
  authorizations,
  service,
  alias
) => {
  const functionalities = []

  authorizations?.forEach((serviceWrap) => {
    if (serviceWrap.service === service) {
      serviceWrap?.functionalities?.map((e, i) => {
        serviceWrap.functionalities[i] = e.split('.', 1)[0] === alias ? e : ''
      })
      functionalities.push(...(serviceWrap?.functionalities ?? []))
    }
  })

  return functionalities
}

export const fetchSessionFunctionalities = (session) => {
  if (session?.authorization?.length > 1) {
    return fetchFunctionalitiesWithoutService(
      session.authorization,
      services.TMS,
      alias.TMS
    )
  }
  return fetchFunctionalitiesByService(session.authorization, services.TMS)
}
