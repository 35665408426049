import { useMutation, useQueryClient } from 'react-query'
import { useApi } from '../../api'
import { EMPLOYEE_URI, APPLY_URI } from '../../../constants/api'
import { EMPLOYEE_TYPE_ENUM, EmployeeStatus } from '../../../constants/status'
import { vacancyType } from '../../../constants/vacancyType'

export function useCreateEmployee() {
  const { axiosInstance } = useApi()
  const queryClient = useQueryClient()

  return useMutation(async ({ data, applyId }) => {
    const { fleet, peonetaDni, courierDni, ...employeeData } = data;

    const { data: newEmployee } = await axiosInstance.post('/', employeeData, {
      baseURL: EMPLOYEE_URI,
    })

    const dni = newEmployee.type === EMPLOYEE_TYPE_ENUM.COURIER ? "courierDni" : "peonetaDni";

    if (applyId){
      await axiosInstance.post(`/${applyId}/vacancy/employee`,
        { 
          id: newEmployee._id,
          status: data?.status ?? EmployeeStatus.DRAFT,
          approvation: null,
          type: vacancyType.EMPLOYEE,
          employeeType: newEmployee.type,
          name: newEmployee.name,
          lastname1: newEmployee.lastname1,
          lastname2: newEmployee.lastname2,
          phone: newEmployee.phone,
          email: newEmployee.email,
          plate: fleet.plate,
          documentTemplateId: employeeData.documentTemplateId,
          peonetaDni,
          courierDni,
          [dni]: newEmployee.numberRut,
        },
        { baseURL: APPLY_URI }
      )
    }

    return { ...newEmployee, id: newEmployee._id }
  }, {
    onSuccess: () => {
      setTimeout(() => {
        queryClient.refetchQueries('getFleetsByProviderAndOffer')
        queryClient.refetchQueries('listEmployeeByApply')
        queryClient.invalidateQueries('requestsById')
        queryClient.invalidateQueries('applyById')
      }, 500);
    },
  })
}
