import { createTheme } from '@mui/material'

export const theme = createTheme({
  palette: {
    primary: {
      main: '#3363FF',
    },
    secondary: {
      main: '#FF7E44',
    },
    statusAbierto: {
      light: '#CDFFCD',
      main: '#007F00',
    },
    statusCerrado: {
      light: '#FFE0E0',
      main: '#D30000',
    },
    lightDay: '#EAF8FF',
    blueSoft: '#99B1FF',
    blue70: '#7092FF',
    lightWell: '#1A6F99',
    lightBluePrimary: '#2BB9FF',
    tableHead: '#F6F6F6',
    backdropModal: '#3363ffb0',
    lightText: '#CCCCCC',
  },
  typography: {
    h1: {
      fontFamily: 'Montserrat',
      fontWeight: '900',
    },
    h2: {
      fontFamily: 'Montserrat',
      fontWeight: '900',
    },
    h3: {
      fontFamily: 'Montserrat',
      fontWeight: '900',
    },
    h4: {
      fontFamily: 'Montserrat',
      fontWeight: '900',
    },
    h5: {
      fontFamily: 'Montserrat',
      fontWeight: '900',
    },
    h6: {
      fontFamily: 'Montserrat',
      fontWeight: '900',
    },
    body1: {
      fontFamily: 'Lato',
    },
    body2: {
      fontFamily: 'Lato',
    },
    subtitle1: {
      fontFamily: 'Lato',
    },
    subtitle2: {
      fontFamily: 'Lato',
    },
    caption: {
      fontFamily: 'Lato',
    },
    button: {
      fontFamily: 'Lato',
      fontWeight: 700,
      textTransform: 'none',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
      @font-face {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 300;
        src: url('https://static.blue.cl/fonts/lato/lato-v17-latin-300.eot'); /* IE9 Compat Modes */
        src: local(''),
          url('https://static.blue.cl/fonts/lato/lato-v17-latin-300.eot?#iefix')
            format('embedded-opentype'),
          /* IE6-IE8 */ url('https://static.blue.cl/fonts/lato/lato-v17-latin-300.woff2') format('woff2'),
          /* Super Modern Browsers */ url('https://static.blue.cl/fonts/lato/lato-v17-latin-300.woff')
            format('woff'),
          /* Modern Browsers */ url('https://static.blue.cl/fonts/lato/lato-v17-latin-300.ttf')
            format('truetype'),
          /* Safari, Android, iOS */ url('https://static.blue.cl/fonts/lato/lato-v17-latin-300.svg#Lato')
            format('svg'); /* Legacy iOS */
      }
      /* lato-regular - latin */
      @font-face {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        src: url('https://static.blue.cl/fonts/lato/lato-v17-latin-regular.eot'); /* IE9 Compat Modes */
        src: local(''),
          url('https://static.blue.cl/fonts/lato/lato-v17-latin-regular.eot?#iefix')
            format('embedded-opentype'),
          /* IE6-IE8 */ url('https://static.blue.cl/fonts/lato/lato-v17-latin-regular.woff2')
            format('woff2'),
          /* Super Modern Browsers */ url('https://static.blue.cl/fonts/lato/lato-v17-latin-regular.woff')
            format('woff'),
          /* Modern Browsers */ url('https://static.blue.cl/fonts/lato/lato-v17-latin-regular.ttf')
            format('truetype'),
          /* Safari, Android, iOS */
            url('https://static.blue.cl/fonts/lato/lato-v17-latin-regular.svg#Lato') format('svg'); /* Legacy iOS */
      }
      /* lato-700 - latin */
      @font-face {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        src: url('https://static.blue.cl/fonts/lato/lato-v17-latin-700.eot'); /* IE9 Compat Modes */
        src: local(''),
          url('https://static.blue.cl/fonts/lato/lato-v17-latin-700.eot?#iefix')
            format('embedded-opentype'),
          /* IE6-IE8 */ url('https://static.blue.cl/fonts/lato/lato-v17-latin-700.woff2') format('woff2'),
          /* Super Modern Browsers */ url('https://static.blue.cl/fonts/lato/lato-v17-latin-700.woff')
            format('woff'),
          /* Modern Browsers */ url('https://static.blue.cl/fonts/lato/lato-v17-latin-700.ttf')
            format('truetype'),
          /* Safari, Android, iOS */ url('https://static.blue.cl/fonts/lato/lato-v17-latin-700.svg#Lato')
            format('svg'); /* Legacy iOS */
      }
      /* lato-900 - latin */
      @font-face {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 900;
        src: url('https://static.blue.cl/fonts/lato/lato-v17-latin-900.eot'); /* IE9 Compat Modes */
        src: local(''),
          url('https://static.blue.cl/fonts/lato/lato-v17-latin-900.eot?#iefix')
            format('embedded-opentype'),
          /* IE6-IE8 */ url('https://static.blue.cl/fonts/lato/lato-v17-latin-900.woff2') format('woff2'),
          /* Super Modern Browsers */ url('https://static.blue.cl/fonts/lato/lato-v17-latin-900.woff')
            format('woff'),
          /* Modern Browsers */ url('https://static.blue.cl/fonts/lato/lato-v17-latin-900.ttf')
            format('truetype'),
          /* Safari, Android, iOS */ url('https://static.blue.cl/fonts/lato/lato-v17-latin-900.svg#Lato')
            format('svg'); /* Legacy iOS */
      }
      /* Montserrat extra light */
      @font-face {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 200;
        src: url('https://static.blue.cl/fonts/montserrat/Montserrat-Light.eot'); /* IE9 Compat Modes */
        src: local(''),
          url('https://static.blue.cl/fonts/montserrat/Montserrat-Light.eot?#iefix') format('embedded-opentype'),
          /* IE6-IE8 */ url('https://static.blue.cl/fonts/montserrat/Montserrat-Light.woff2') format('woff2'),
          /* Super Modern Browsers */ url('https://static.blue.cl/fonts/montserrat/Montserrat-Light.woff')
            format('woff'),
          /* Modern Browsers */ url('https://static.blue.cl/fonts/montserrat/Montserrat-Light.ttf')
            format('truetype'),
          /* Safari, Android, iOS */ url('https://static.blue.cl/fonts/montserrat/Montserrat-Light.svg#Montserrat')
            format('svg'); /* Legacy iOS */
      }
      /* Montserrat regular */
      @font-face {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 400;
        src: url('https://static.blue.cl/fonts/montserrat/Montserrat-Regular.eot'); /* IE9 Compat Modes */
        src: local(''),
          url('https://static.blue.cl/fonts/montserrat/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
          /* IE6-IE8 */ url('https://static.blue.cl/fonts/montserrat/Montserrat-Regular.woff2') format('woff2'),
          /* Super Modern Browsers */ url('https://static.blue.cl/fonts/montserrat/Montserrat-Regular.woff')
            format('woff'),
          /* Modern Browsers */ url('https://static.blue.cl/fonts/montserrat/Montserrat-Regular.ttf')
            format('truetype'),
          /* Safari, Android, iOS */ url('https://static.blue.cl/fonts/montserrat/Montserrat-Regular.svg#Montserrat')
            format('svg'); /* Legacy iOS */
      }
      /* Montserrat semi bold */
      @font-face {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        src: url('https://static.blue.cl/fonts/montserrat/Montserrat-SemiBold.eot'); /* IE9 Compat Modes */
        src: local(''),
          url('https://static.blue.cl/fonts/montserrat/Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'),
          /* IE6-IE8 */ url('https://static.blue.cl/fonts/montserrat/Montserrat-SemiBold.woff2') format('woff2'),
          /* Super Modern Browsers */ url('https://static.blue.cl/fonts/montserrat/Montserrat-SemiBold.woff')
            format('woff'),
          /* Modern Browsers */ url('https://static.blue.cl/fonts/montserrat/Montserrat-SemiBold.ttf')
            format('truetype'),
          /* Safari, Android, iOS */ url('https://static.blue.cl/fonts/montserrat/Montserrat-SemiBold.svg#Montserrat')
            format('svg'); /* Legacy iOS */
      }
      /* Montserrat bold */
      @font-face {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        src: url('https://static.blue.cl/fonts/montserrat/Montserrat-Bold.eot'); /* IE9 Compat Modes */
        src: local(''),
          url('https://static.blue.cl/fonts/montserrat/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
          /* IE6-IE8 */ url('https://static.blue.cl/fonts/montserrat/Montserrat-Bold.woff2') format('woff2'),
          /* Super Modern Browsers */ url('https://static.blue.cl/fonts/montserrat/Montserrat-Bold.woff')
            format('woff'),
          /* Modern Browsers */ url('https://static.blue.cl/fonts/montserrat/Montserrat-Bold.ttf') format('truetype'),
          /* Safari, Android, iOS */ url('https://static.blue.cl/fonts/montserrat/Montserrat-Bold.svg#Montserrat')
            format('svg'); /* Legacy iOS */
      }
      /* Montserrat heavy */
      @font-face {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 900;
        src: url('https://static.blue.cl/fonts/montserrat/Montserrat-Black.eot'); /* IE9 Compat Modes */
        src: local(''),
          url('https://static.blue.cl/fonts/montserrat/Montserrat-Black.eot?#iefix') format('embedded-opentype'),
          /* IE6-IE8 */ url('https://static.blue.cl/fonts/montserrat/Montserrat-Black.woff2') format('woff2'),
          /* Super Modern Browsers */ url('https://static.blue.cl/fonts/montserrat/Montserrat-Black.woff')
            format('woff'),
          /* Modern Browsers */ url('https://static.blue.cl/fonts/montserrat/Montserrat-Black.ttf') format('truetype'),
          /* Safari, Android, iOS */ url('https://static.blue.cl/fonts/montserrat/Montserrat-Black.svg#Montserrat')
            format('svg'); /* Legacy iOS */
      }
      `,
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontWeight: 900,
          fontFamily: 'Montserrat',
          color: 'hsla(0, 0%, 40%, 1)',
          borderBottom: 'none',
          backgroundColor: 'hsla(0, 0%, 96%, 1)',
          boxShadow:
            'inset 0px -1px 0px rgba(0, 0, 0, 0.3), inset 0px 1px 0px rgba(0, 0, 0, 0.3)',
        },
        body: {
          borderBottom: 'none',
          boxShadow: 'inset 0px -1px 0px 0px hsla(0, 0%, 0%, 0.08)',
        },
        footer: {
          borderBottom: 'none',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        color: 'secondary',
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          borderRadius: '16px',
          height: '48px',
          minWidth: '190px',
        },
        contained: {
          color: '#fff',
        },
      },
      variants: [
        {
          props: { variant: 'goBack' },
          style: {
            padding: 0,
            color: '#41B6E6',
            height: 'initial',
            minWidth: 'initial',
            backgroundColor: 'transparent',
            '&: hover': { backgroundColor: 'transparent' },
          },
        },
      ],
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          width: '32px',
          height: '32px',
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          '&.MuiStepLabel-alternativeLabel': {
            fontWeight: 900,
            fontSize: '1rem',
          },
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          height: '10px',
          borderRadius: '16px',
          backgroundColor: '#D6E0FF',
        },
        bar1Determinate: {
          borderRadius: '16px',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: 'black',
        },
        underlineAlways: false,
        underlineNone: true,
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#BFEAFF',
          color: '#000',
          borderRadius: '4px',
          padding: '10px',
          fontSize: '0.75rem',
          textAlign: 'center',
        },
        arrow: {
          '&::before': {
            backgroundColor: '#BFEAFF',
          },
        },
      },
    },
  },
})
