import { useCallback, useEffect, useMemo, useState } from "react";
import { FormCreateVehicle } from "../../../components/Forms/Fleet/FormCreate"
import { useNavigate, useParams } from "react-router-dom";
import { useGetApplyById } from "../../../services/hooks/Apply/useGetApplyById";
import { useGetFleetById } from "../../services/hooks/Fleets/useGetFleetById";
import { validateGroupedDocuments } from "../../helper/documents";
import { useUploadDocuments } from "../../../services/hooks/Docs/S3/useUploadDocument";
import { vacancyType } from "../../../constants/vacancyType";
import { PaperWhite } from "../../../components/Papers";
import { LoadingSpinner } from "../../../components/LoadingSpinner";
import { AlertSnackBar } from "../../../components/Alerts";
import { useReplaceFleetVacancy } from "../../services/hooks/Fleets/useReplaceFleetVacancy";

const initialState = {
  plate: "",
  brand: "",
  model: "",
  fleetType: "",
  year: "",
};

export default function ReplaceFleet() {
  const { applyId, fleetId } = useParams();
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState(initialState);
  const [, setDocumentsFromBucket] = useState([]);
  const [error, setError] = useState({ isOpen: false });
  const [currentTemplate, setCurrentTemplate] = useState(null);
  const [initialExpirationDates, setInitialExpirationDates] = useState(null);
  const { mutate: mutateUploadDocument, isLoading: isLoadingUpload } =
    useUploadDocuments();
  const { mutate: replaceFleetMutate, isLoading: replaceLoading } = useReplaceFleetVacancy();
  const {
    data: vehicle,
    isLoading: isGettingFleet,
    isSuccess
  } = useGetFleetById(fleetId);
  const { data: currentApply, isLoading: isGettingApplies } =
    useGetApplyById(applyId, vehicle?.providerId);

  const filteredVacanciesByAvailability = useMemo(() => {
    const applyingByType = vehicle ? { [vehicle.fleetType]: 1 } : {};

    const vacancies = currentApply?.offer?.setting?.vacancies ?? currentApply?.offer?.vacancies ?? [];

    const currentFilter = vacancies.filter(
      (vacancy) => Number(applyingByType[vacancy.vehicle.name] ?? 0) < Number(vacancy.vacancyTotals?.total ?? vacancy.total)
    );
    return Object.entries(applyingByType).length ? currentFilter : vacancies;
  }, [currentApply, vehicle]);

  useEffect(() => {
    if (isSuccess) {
      setInitialValues({
        brand: vehicle.brand,
        model: vehicle.model,
        fleetType: vehicle.type,
        year: vehicle.year,
      });
    }
  }, [vehicle, isSuccess]);

  const handleGoBack = () => {
    navigate('/myrequest')
  };

  const handleReplace = useCallback(
    (fleet) => {
      const data = {
        plate: fleet.plate,
        providerId: vehicle?.providerId,
        model: fleet.model.name,
        brand: fleet.brand.name,
        type: fleet.type.vehicle.name,
        documentTemplateId: fleet.type.vehicle.documentTemplateId,
        year: fleet.year,
      }
      const { error: validationGroupedError, groupedDataByDocumentType } =
        validateGroupedDocuments(
          currentTemplate.documentTypes,
          fleet,
          initialExpirationDates
        );
      if (validationGroupedError) {
        setError({
          isOpen: true,
          title: "Error",
          messages: validationGroupedError.messages[0],
          handleClose: () => setError({ isOpen: false }),
        });
        return;
      }

      replaceFleetMutate(
        {
          applyId,
          idToReplace: fleetId,
          newFleet: data,
        },
        {
          onSuccess: (replacedFleet) => {
            for (let [, documentToUpload] of Object.entries(
              groupedDataByDocumentType
            )) {
              const beforeUpload = documentToUpload;
              beforeUpload["ownerId"] = replacedFleet.newId;
              beforeUpload["ownerType"] = vacancyType.FLEET;
              beforeUpload.metaData = JSON.stringify(beforeUpload.metaData);
              mutateUploadDocument(beforeUpload, {
                onSuccess: () => {
                  handleGoBack();
                },
                onError: () => {
                  setError({
                    isOpen: true,
                    messages:
                      "Oh no! se ha producido un error al cargar los documentos",
                    handleClose: () => setError({ isOpen: false }),
                  });
                },
              });
            }
          },
          onError: () =>
            setError({
              isOpen: true,
              messages: "Oh no! se ha producido un error en el proceso",
              handleClose: () => setError({ isOpen: false }),
            }),
        }
      );
    },
    [vehicle, currentTemplate]
  );
  if (isGettingFleet) {
    return (
      <PaperWhite>
        <LoadingSpinner open />
      </PaperWhite>
    )
  }
  return (
    <PaperWhite sx={{ py: 4, mt: 6 }}>
      {(
          isLoadingUpload ||
          isGettingApplies ||
          replaceLoading
        ) &&
        <LoadingSpinner open />
      }
      <FormCreateVehicle
        formValues={initialValues}
        filteredVacanciesByAvailability={filteredVacanciesByAvailability}
        onSubmit={handleReplace}
        goBack={handleGoBack}
        setCurrentTemplate={setCurrentTemplate}
        setDocumentsFromBucket={setDocumentsFromBucket}
        setInitialExpirationDates={setInitialExpirationDates}
        isReplacing={true}
      />
      <AlertSnackBar
        open={error.isOpen}
        message={error.messages}
        handleClose={error.handleClose}
      />
    </PaperWhite>
  )
};
