import { useMutation, useQueryClient } from "react-query";
import { useApi } from "../../../../services/api";
import { APPLY_URI } from "../../../../constants/api";

const revokeVacancy = async ({
  applyId,
  entityType,
  entityId,
}) => {
  const { axiosInstance } = useApi();
  const response = await axiosInstance.post(
    `/${applyId}/vacancy/${entityType}/${entityId}/revoke`,
    {},
    {
      baseURL: APPLY_URI,
    }
  );
  return response;
};

export function useRevokeVacancy() {
  const queryClient = useQueryClient();
  return useMutation(revokeVacancy, {
    onSuccess: () => {
      queryClient.refetchQueries('listRequests');
    },
  });
}
