import { Box, Button, DialogActions, Stack, Typography } from "@mui/material";
import { Modal } from "../../../components/Modals/Modal";
import  warningIcon from "../../assets/images/low_warning.png";
import { LoadingSpinner } from "../../../components/LoadingSpinner";

const AlertModal = ({
  isOpen,
  onClose,
  isLoading,
  message = 'Hubo un problema',
  children,
  onAccept,
  onCancel,
}) => {
  return (
    <Modal open={isOpen} handleClose={onClose}>
      <Stack
        sx={{
          height: '100%',
          display: 'grid',
          placeItems: 'center',
        }}
      >
        <Box
          mt={3}
          mb={2}
          component="img"
          alt="icon"
          src={warningIcon}
          sx={{
            height: "125px",
          }}
        />
        <Typography variant='h6'>{message}</Typography>
        {children}
      </Stack>
      <Stack
        sx={{
          height: '100%',
          display: 'grid',
          placeItems: 'center',
        }}
      >
        {isLoading && <LoadingSpinner open={isLoading} />}
        <DialogActions>
          {onCancel && (
            <Button
              onClick={onCancel}
              color='secondary'
              variant='outlined'
              sx={{
                borderRadius: '16px',
                fontWeight: 700,
                textTransform: 'capitalize',
                width: 100,
                height: 40,
              }}
              disableElevation
            >
              Cancelar
            </Button>
          )}
          {onAccept && (
            <Button
              color="secondary"
              type="submit"
              sx={{
                borderRadius: '16px',
                fontWeight: 700,
                textTransform: 'capitalize',
                width: 100,
                height: 40,
              }}
              onClick={onAccept}
            >
              Aceptar
            </Button>
          )}
        </DialogActions>
      </Stack>
    </Modal>
)
};

export default AlertModal;
