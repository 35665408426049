import { useEffect, useRef, useState } from "react";
import { $setBlocksType } from "@lexical/selection";
import { $createHeadingNode } from "@lexical/rich-text";
import {
  $getSelection,
  $isRangeSelection,
  $createParagraphNode,
} from "lexical";
import {
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
} from "@lexical/list";

import styles from './styles.module.css';
import toolbarStyles from '../toolbar.module.css';


export const SUPPORTED_BLOCK_TYPES = new Set([
  "paragraph",
  "h1",
  "h2",
  "ul",
  "ol"
]);

export const BLOCK_TYPE_TO_BLOCK_NAME = {
  h1: "Título (grande)",
  h2: "Título (mediano)",
  h3: "Título (pequeño)",
  ol: "Lista numerada",
  paragraph: "Normal",
  ul: "Lista de viñetas"
};


export function BlockOptionsDropdownList({
  editor,
  blockType,
  toolbarRef,
}) {
  const dropDownRef = useRef(null);
  const [showBlockOptionsDropDown, setShowBlockOptionsDropDown] = useState(
    false
  );

  useEffect(() => {
    const dropDown = dropDownRef.current;
    const toolbar = toolbarRef.current;

    if (dropDown !== null && toolbar !== null) {
      const handle = (event) => {
        const target = event.target;

        if (!dropDown.contains(target) && !toolbar.contains(target)) {
          setShowBlockOptionsDropDown(false);
        }
      };
      document.addEventListener("click", handle);

      return () => {
        document.removeEventListener("click", handle);
      };
    }
  }, [dropDownRef, setShowBlockOptionsDropDown, toolbarRef]);

  const formatParagraph = () => {
    if (blockType !== "paragraph") {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $setBlocksType(selection, () => $createParagraphNode());
        }
      });
    }
    setShowBlockOptionsDropDown(false);
  };

  const formatLargeHeading = () => {
    if (blockType !== "h1") {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $setBlocksType(selection, () => $createHeadingNode("h1"));
        }
      });
    }
    setShowBlockOptionsDropDown(false);
  };

  const formatSmallHeading = () => {
    if (blockType !== "h2") {
      editor.update(() => {
        const selection = $getSelection();

        if ($isRangeSelection(selection)) {
          $setBlocksType(selection, () => $createHeadingNode("h2"));
        }
      });
    }
    setShowBlockOptionsDropDown(false);
  };

  const formatBulletList = () => {
    if (blockType !== "ul") {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND);
    }
    setShowBlockOptionsDropDown(false);
  };

  const formatNumberedList = () => {
    if (blockType !== "ol") {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND);
    }
    setShowBlockOptionsDropDown(false);
  };

  return (
    <>
      <button
        className={`${toolbarStyles.toolbarItem} ${styles.blockControls}`}
        onClick={(event) => {
          event.preventDefault();
          setShowBlockOptionsDropDown(!showBlockOptionsDropDown)
        }}
        aria-label="Formatting Options"
      >
        <span className={`${styles.dropdownIcon} ${styles[blockType]}`} />
        <span className={styles.blockType} />
        <span className={styles.textItem}>{BLOCK_TYPE_TO_BLOCK_NAME[blockType]}</span>
        <i className={styles.chevronDown} />
      </button>
      {showBlockOptionsDropDown && (
        <div className={styles.dropdown} ref={dropDownRef}>
          <button className={styles.item} onClick={formatParagraph}>
            <span className={`${styles.dropdownIcon} ${styles.paragraph}`} />
            <span className={styles.textItem}>{BLOCK_TYPE_TO_BLOCK_NAME.paragraph}</span>
            {blockType === "paragraph" && <span className={styles.active} />}
          </button>
          <button  className={styles.item} onClick={formatLargeHeading}>
            <span className={`${styles.dropdownIcon} ${styles.largeHeading}`} />
            <span className={styles.textItem}>{BLOCK_TYPE_TO_BLOCK_NAME.h1}</span>
            {blockType === "h1" && <span className={styles.active} />}
          </button>
          <button  className={styles.item} onClick={formatSmallHeading}>
            <span className={`${styles.dropdownIcon} ${styles.smallHeading}`} />
            <span className={styles.textItem}>{BLOCK_TYPE_TO_BLOCK_NAME.h2}</span>
            {blockType === "h2" && <span className={styles.active} />}
          </button>
          <button  className={styles.item} onClick={formatBulletList}>
            <span className={`${styles.dropdownIcon} ${styles.bulletList}`} />
            <span className={styles.textItem}>{BLOCK_TYPE_TO_BLOCK_NAME.ul}</span>
            {blockType === "ul" && <span className={styles.active} />}
          </button>
          <button  className={styles.item} onClick={formatNumberedList}>
            <span className={`${styles.dropdownIcon} ${styles.numberedList}`} />
            <span className={styles.textItem}>{BLOCK_TYPE_TO_BLOCK_NAME.ol}</span>
            {blockType === "ol" && <span className={styles.active} />}
          </button>
        </div>)}
    </>
  );
}
