import { useQuery } from "react-query";
import { useApi } from "../../../../services/api";
import { EMPLOYEE_URI } from "../../../../constants/api";
import { buildUrlWithQueryParams } from "../../../helper/converters";

export async function getEmployeeList(filters) {
  const { axiosInstance } = useApi();

  const baseUrl = new URL(`${EMPLOYEE_URI}/request`);
  const url = buildUrlWithQueryParams(baseUrl, filters);

  const response = await axiosInstance.get(url);
  const { data, metadata } = response.data;

  return { data, metadata };
}

export function useListEmployeesRequest(filters) {
  return useQuery(
    ["employeesRequest", filters],
    () => getEmployeeList(filters),
    {
      staleTime: 1000 * 60 * 10,
      retry: 0,
      enabled: false,
    }
  );
}
