import { useApi } from '../../api'
import { APPLY_URI } from '../../../constants/api'
import { useQuery } from 'react-query'
import { buildUrlWithQueryParams } from '../../../v2/helper/converters';

export async function getListEmployee(applyId, filters) {
  const { axiosInstance } = useApi();

  const url = buildUrlWithQueryParams(`${APPLY_URI}/${applyId}/employees`, filters); 

  const response = await axiosInstance.get(url)

  const { data } = response;

  return data
}

export function useListEmployeeByApply(applyId, filters = {}) {
  return useQuery('listEmployeeByApply', () => getListEmployee(applyId, filters), {
    staleTime: 1000 * 60 * 10,
    retry: 0,
    enabled: false
  })
}