import { useMutation } from "react-query";
import { useApi } from "../../../../../services/api";
import { REQUEST_TRACKER_URI } from "../../../../../constants/api";

export function useCreateReplacement() {
  const { axiosInstance } = useApi();

  return useMutation((data) =>
    axiosInstance.post("/replacement", data, {
      baseURL: REQUEST_TRACKER_URI,
    })
  );
}
