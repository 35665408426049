import { styled } from "@mui/material/styles";

import FormControl from "@mui/material/FormControl";
import InputBase from "@mui/material/InputBase";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";

export const SearchBar = styled(({ onSearch, minWidth, search, ...props }) => (
  <FormControl
    variant="standard"
    sx={{
      flexGrow: 1,
      minWidth: minWidth ?? "145px",
      maxWidth: "340px",
    }}
  >
    <InputBase
      {...props}
      onKeyUp={(event) => {
        if (event.key === "Enter") onSearch(event);
      }}
      defaultValue={search || ""}
    />
    <SearchOutlinedIcon
      onClick={props.onSearch ?? null}
      sx={{
        position: "absolute",
        right: "10px",
        top: "50%",
        transform: "translateY(-50%)",
        maxWidth: "340px",
      }}
    />
  </FormControl>
))(() => ({
  position: "relative",
  "& .MuiInputBase-input": {
    backgroundColor: "#FFFFFF",
    border: "0.6px solid #1A6F99",
    borderRadius: "10px",
    position: "relative",
    fontSize: 12,
    lineHeight: "14px",
    width: "100%",
    padding: "10px 16px 10px 10px",
    "&::placeholder": {
      fontSize: "12px",
      color: "hsla(0, 0%, 13%, 1)",
    },
  },
}));
