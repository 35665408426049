/* eslint-disable no-unused-vars */
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Dialog, DialogContent, IconButton, Typography } from '@mui/material'
// import Close from '@mui/icons-material/Close'
import wowIcon from '../../assets/images/wow.png'

export default function TimedAlert({ alert, setAlert, to = '/', state, callback }) {
  const {
    isOpen,
    backdropStyles,
    paperProps,
    contentStyles,
    duration,
    message,
    observations,
    newIcon,
    title,
    alertIcon,
    showAlertIcon,
  } = alert
  const navigate = useNavigate()

  useEffect(() => {
    let timer
    if (isOpen) {
      timer = setTimeout(() => {

        if (state) navigate(to, { state })
        else navigate(to)
        setAlert({ ...alert, isOpen: false })
        if (callback) callback()
      }, duration)
    }
    return () => {
      clearTimeout(timer)
    }
  }, [alert])

  /*
  const handleClose = () => {
    setAlert({ ...alert, isOpen: false })
  }
  */
  const defaultBackdropStyles = {
    backgroundColor: 'rgba(74, 179, 232, 0.75)',
    ...backdropStyles,
  }

  const defaultPaperProps = {
    borderRadius: '15px',
    minWidth: 460,
    maxWidth: 500,
    width: '100%',
    ...paperProps,
  }

  const defaultContentStyles = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '15px',
    ...contentStyles,
  }

  return (
    <Dialog
      open={alert.isOpen}
      // onClose={handleClose}
      BackdropProps={{ style: { ...defaultBackdropStyles } }}
      PaperProps={{ style: { ...defaultPaperProps, position: 'relative' } }}
    >
      {/* <IconButton
        aria-label='close'
        sx={{ position: 'absolute', right: 8, top: 8 }}
        onClick={handleClose}
      >
        <Close />
      </IconButton> */}
      <DialogContent sx={defaultContentStyles}>
        {showAlertIcon ? alertIcon : <img src={newIcon || wowIcon} alt='Congratulations Icon' />}
        <Typography variant='h3' sx={{ fontSize: 50, fontWeight: 900 }}>
          {title}
        </Typography>
        <Typography
          variant='subtitle1'
          sx={{ fontSize: 16, fontWeight: 700, textAlign: 'center' }}
        >
          {message}
        </Typography>
        {observations ?
          observations.map((observation, index) =>
            // eslint-disable-next-line react/no-array-index-key
            <Typography key={index} sx={{ color: 'red', textAlign: 'center' }}>
              {observation}
            </Typography>
          ) :
          null}
      </DialogContent>
    </Dialog>
  )
}
