import { useEffect, useState } from "react";
import { InfoOutlined } from "@mui/icons-material";
import {
  IconButton,
  Stack,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { Table } from "../../components/Table";
import { LoadingSpinner } from "../../../components/LoadingSpinner";
import { ActiveStatus, EmployeeStatus } from "../../../constants/status";
import ListDocumentsModal from "../../components/Modal/ListDocumentModal";
import { vacancyType } from "../../../constants/vacancyType";
import { headCellsFleets } from "../../helper/headcell";
import { useListFleets } from "../../services/hooks/Fleets/useListFleets";
import { TabAddonsList } from "../../components/TabsAddon";
import { formatDateApprovalFlow } from "../../../utils/formatValues";
import { useURLfilters } from "../../hooks/filters.hook";
import { useReportFleets } from "../../services/hooks/Fleets/useReportFleets";
import { urlDownload } from "../../helper/files";
import { AlertSnackBar } from "../../../components/Alerts";

const defaultFilters = {
  page: 1,
  limit: 10,
  status: EmployeeStatus.APPROVED,
};

export default function FleetList() {
  const [item, setItem] = useState();
  const [showModal, setShowModal] = useState(false);
  const [downloadData, setDownloadData] = useState([]);
  const [error, setError] = useState({ isOpen: false });

  const { filters, setFilters, getURLValue, cleanFilters } =
    useURLfilters(defaultFilters);

  const { mutate: mutateReportFleets, isLoading: isLoadingReport } = useReportFleets();

  const { data, isLoading } = useListFleets(filters);

  const onChangeFilters = (newFilter = {}) => {
    setFilters({...filters, ...newFilter});
  };

  const { TableContainer, setTableLoading, setPage } = Table(
    headCellsFleets,
    {
      ...data?.metadata,
      page: getURLValue("page", 1),
      limit: getURLValue("limit", 10),
    },
    onChangeFilters
  );

  useEffect(() => {
    setTableLoading(isLoading);
    if (data) {
      setDownloadData(
        data.data.map((item) => {
          return {
            plate: item.plate,
            type: item.type,
            brand: item.brand,
            model: item.model,
            year: item.year,
            dateCreated: item.created.at,
            active: item?.active?.isActive
              ? ActiveStatus.ACTIVE
              : ActiveStatus.INACTIVE,
            blocked: item.blocked.isBlocked ? "SI" : "NO",
            dateDeleted: item.isDeleted ? item.deleted.at : "-",
          };
        })
      );
    }
  }, [data, isLoading]);

  const handleSearch = (event) => {
    const { value } = event.target;
    const newValue = value.toLowerCase();

    onChangeFilters({ search: newValue, page: 1 });
    setPage(0);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const onChangeBlocked = (event) => {
    onChangeFilters({ isBlocked: event.target.value, page: 1 });
  };

  const onChangeActive = (event) => {
    onChangeFilters({ isActive: event.target.value, page: 1 });
  };

  const handleDownloadReport = () => {
    mutateReportFleets(filters, 
      {
        onSuccess: (filePath) => {
          urlDownload(filePath);
        },
        onError: () => {
          setError({
            isOpen: true,
            title: "Error",
            message: "Hubo un error al descargar el reporte de flotas. Inténtelo nuevamente.",
            handleClose: () => setError({ isOpen: false }),
          });
        }
      },
    );
  }

  const TabsAddons = () => (
    <TabAddonsList
      search={getURLValue("search", "")}
      active={getURLValue("isActive", "")}
      blocked={getURLValue("isBlocked", false)}
      downloadData={downloadData}
      handleSearch={handleSearch}
      onChangeActive={onChangeActive}
      onChangeBlocked={onChangeBlocked}
      headCell={headCellsFleets}
      nameFile={vacancyType.FLEET}
      clearFilters={cleanFilters}
      handleDownloadReport={handleDownloadReport}
    />
  );

  if (isLoading || isLoadingReport) return <LoadingSpinner open />;

  return (
    <Stack spacing={2}>
      <TableContainer addons={[TabsAddons]} withPagination>
        <TableBody>
          {data?.data.map((fleet) => (
            <TableRow hover tabIndex={-1} key={fleet.id}>
              <TableCell>{fleet.plate}</TableCell>
              <TableCell>{fleet.type}</TableCell>
              <TableCell sx={{ opacity: 1 }}>{fleet.brand}</TableCell>
              <TableCell sx={{ opacity: 1 }}>{fleet.model}</TableCell>
              <TableCell>{fleet.year}</TableCell>
              <TableCell>{formatDateApprovalFlow(fleet.created.at)}</TableCell>
              <TableCell sx={{ opacity: 1 }}>
                {fleet?.active?.isActive
                  ? ActiveStatus.ACTIVE
                  : ActiveStatus.INACTIVE}
              </TableCell>
              <TableCell sx={{ opacity: 1 }}>
                {fleet.blocked.isBlocked ? "SI" : "NO"}
              </TableCell>
              <TableCell>
                {fleet.isDeleted
                  ? formatDateApprovalFlow(fleet.deleted.at)
                  : "-"}
              </TableCell>
              <TableCell>
                <Stack direction="row">
                  <IconButton
                    onClick={() => {
                      setItem(fleet);
                      setShowModal(true);
                    }}
                  >
                    <InfoOutlined />
                  </IconButton>
                </Stack>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </TableContainer>
      <AlertSnackBar
        open={error.isOpen}
        message={error.message}
        handleClose={error.handleClose}
      />
      <ListDocumentsModal
        isOpen={showModal}
        onClose={handleCloseModal}
        type={vacancyType.FLEET}
        item={item}
      />
    </Stack>
  );
}
