import { Stack, Typography } from "@mui/material";
import { PaperWhite } from "../../../components/Papers";

export const InfoCard = ({ title, entries }) => (
  <>
    <PaperWhite>
      <Typography fontFamily={"Montserrat"} fontWeight={"800"} fontSize={"24px"} lineHeight={"29.26px"} color="#000000" variant="h6" sx={{mb:2}}>
        {title}
      </Typography>
      <Stack
        direction="row"
        gap="2em"
        justifyContent="left"
        flexWrap="wrap"
      >
        <Stack>
          <Stack
            direction="column"
            sx={{
              alignItems: "start",
            }}
          >
            {Object.entries(entries[0]).map(([key, value]) => {

              return (
                <Typography key={key} fontFamily={"Lato"} fontWeight={"400"} fontSize={"14px"} lineHeight={"18.9px"} color="#000000" variant="body1">
                  <b> {`${key}`}</b>{value}
                </Typography>
              );
            })}
          </Stack>
        </Stack>
      </Stack>
    </PaperWhite>
  </>
);
