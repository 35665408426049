import { useMemo, useState } from "react";
import {
  Stack,
  Box,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";

import { SearchBar } from "../../../../components/SearchBar";
import { PaperWhite } from "../../../../components/Papers";
import { Table } from "../../../../components/Table";
import { Modal } from "../../../../components/Modals/Modal";

const headCells = [
  {
    id: "label",
    label: "Nombre",
    exportExcel: false,
  },
  {
    id: "extension",
    label: "Extensión",
    exportExcel: false,
  },
  {
    id: "isRequired",
    label: "Requerido",
    exportExcel: false,
  },
  {
    id: "description",
    label: "Descripción",
    exportExcel: false,
  },
];

export default function RenderDocuments({
  title,
  documents,
  openModal,
  handleCloseModal,
}) {
  const [searchInput, setSearchInput] = useState(null);

  const filteredDocuments = useMemo(() => {
    if (!searchInput) {
      return documents;
    }

    const lowerCaseSearch = searchInput.toLowerCase();
    return documents.filter((doc) =>
      doc.label.toLowerCase().includes(lowerCaseSearch)
    );
  }, [documents, searchInput]);

  const { TableContainer, TableHead } = Table(documents, headCells);

  return (
    <Modal open={openModal} handleClose={handleCloseModal}>
      <Stack spacing={2}>
        <Typography sx={{ marginTop: 2 }} variant="h5" align="center">
          {title}
        </Typography>

        <PaperWhite sx={{ p: 0 }}>
          <Stack
            direction="row"
            paddingY={2}
            paddingX={2}
            alignItems="center"
            justifyContent="space-between"
          >
            <Box sx={{ display: "flex" }}>
              <SearchBar
                id="table-searchbar"
                type="text"
                placeholder="Buscar"
                onChange={(e) => setSearchInput(e.target.value)}
              />
            </Box>
          </Stack>
          <TableContainer>
            <TableHead />
            <TableBody>
              {filteredDocuments.map(
                ({ id, label, extension, isRequired, description }) => (
                  <TableRow key={id}>
                    <TableCell>
                      <Typography>{label}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography fontWeight="bold">
                        {extension.join(", ").toUpperCase()}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography fontWeight="bold">
                        {isRequired ? "SI" : "NO"}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{description}</Typography>
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </TableContainer>
        </PaperWhite>
      </Stack>
    </Modal>
  );
}
