import { useQuery } from 'react-query'
import { FLEET_URI } from '../../../../constants/api'
import { useApi } from '../../../../services/api'

export async function getFleetById(id) {
  if (id === 0) return null
  const { axiosInstance } = useApi()
  const response = await axiosInstance.get(`/fleet/${id}`, {
    baseURL: FLEET_URI,
  })
  const { data } = response
  return data
}

export function useGetFleetById(id) {
  if (id === '') {
    return useQuery(['noData'], () => getFleetById(0), {})
  }
  return useQuery(['fleetById', id], () => getFleetById(id), {
    staleTime: 1000 * 60 * 10,
  })
}
