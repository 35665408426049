import { useMutation, useQueryClient } from "react-query";
import { useApi } from "../../../api";
import { DOCUMENT_TYPE_URI } from "../../../../constants/api";

export function useUpdateTypes(id) {
  const { axiosInstance } = useApi();
  const queryClient = useQueryClient();

  return useMutation(
    (newGroup) =>
      axiosInstance.put(`/${id}`, newGroup, {
        baseURL: DOCUMENT_TYPE_URI,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("listDocumentTypes");
        queryClient.invalidateQueries(["documentTypes", id]);
        queryClient.invalidateQueries("listTemplates");
      },
    }
  );
}
