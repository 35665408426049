import { useMutation, useQueryClient } from "react-query";
import { EMPLOYEE_URI } from "../../../../constants/api";
import { useApi } from "../../../../services/api";

export const updateEmployeeById = async (data) => {
  const { id, ...employeeData } = data;
  const { axiosInstance } = useApi();

  const { data: updatedEmployee } = await axiosInstance.put(
    `/${id}`,
    employeeData,
    {
      baseURL: EMPLOYEE_URI,
    }
  );

  return updatedEmployee;
};

export function useUpdateEmployeeById() {
  const queryClient = useQueryClient();
  return useMutation(updateEmployeeById, {
    onSuccess: () => {
      queryClient.refetchQueries("listRequests");
    },
  });
}
