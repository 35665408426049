import { useMutation, useQueryClient } from 'react-query'
import { useApi } from '../../../api'
import { VEHICLE_URI } from '../../../../constants/api'

export function useCreateVehicleFeature() {
  const { axiosInstance } = useApi()
  const queryClient = useQueryClient()

  return useMutation(
    (feature) => axiosInstance.post('/features', feature, {
      baseURL: VEHICLE_URI,
    }),
    {
      onSuccess: () => queryClient.invalidateQueries('listVehiclesFeatures'),
    }
  )
}
