import { es } from 'date-fns/locale'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import Input from './Input'

export default function DatePickerComponent({
  label,
  value,
  onChange,
  isRequired,
  error,
  helperText,
  disabled,
  minDate,
  required,
  ...rest
}) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
      <span>
        {label} {isRequired && <span style={{ color: 'orange' }}>*</span>}
      </span>
      <DatePicker
        variant='outlined'
        value={value}
        minDate={minDate || new Date()}
        onChange={(newValue) => {
          onChange(newValue)
        }}
        popperProps={{
          placement: "bottom-end"
        }}
        renderInput={(params) => (
          <Input fullWidth {...params} error={error} helperText={helperText} ref={params.inputRef} {...params.inputProps} required={required || false}/>
        )}
        disabled={disabled}
        {...rest}
      />
    </LocalizationProvider>
  )
}
