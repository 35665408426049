import { useMutation, useQueryClient } from "react-query";
import { REQUEST_TRACKER_URI } from "../../../../constants/api";
import { useApi } from "../../../../services/api";
import { requestTypesValues } from "../../../constants/requests";

const deleteRequestById = async ({ requestId, requestType }) => {
  const { axiosInstance } = useApi();
  switch (requestType) {
    case requestTypesValues.REPLACE_DOCUMENT:
      const { data: replaceDocumentData } = await axiosInstance.delete(`/replaceDocument/${requestId}`, {
        baseURL: REQUEST_TRACKER_URI,
      });
      return replaceDocumentData;
    case requestTypesValues.PERMANENT_REPLACEMENT:
      const { data: permantenReplacementData } = await axiosInstance.delete(`/replacement/${requestId}`, {
        baseURL: REQUEST_TRACKER_URI,
      });
      return permantenReplacementData;
    case requestTypesValues.TEMPORARY_REPLACEMENT:
      const { data: temporaryReplacementData } = await axiosInstance.delete(`/replacement/${requestId}`, {
        baseURL: REQUEST_TRACKER_URI,
      });
      return temporaryReplacementData;
    default:
      const { data } = await axiosInstance.delete(`/request/${requestId}`, {
        baseURL: REQUEST_TRACKER_URI,
      });
      return data;
  }
};

export const useDeleteRequestById = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteRequestById, {
    onSuccess: () => {
      queryClient.refetchQueries("listProviderRequests");
    },
  });
};
