import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  IconButton,
  Stack,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import {
  AddCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  FormatListNumbered,
} from "@mui/icons-material";

import { AlertSnackBar } from "../../../components/Alerts";
import { PaperWhite } from "../../../components/Papers";
import { SearchBar } from "../../../components/SearchBar";
import { Table } from "../../../v2/components/Table";
import { DownloadExportData } from "../../../components/Excel/Excel";
import { LoadingSpinner } from "../../../components/LoadingSpinner";
import { Dialogs } from "../../../components/Dialogs";
import RenderDocuments from "./Documents/RenderDocuments";
import { useDeleteTypes, useListTypes } from "../../../services/hooks/Docs";
import styles from "../../../v2/components/TabsAddon/TabsAddon.module.css"

const headCells = [
  {
    id: "name",
    label: "Nombre",
    exportExcel: true,
  },
  {
    id: "expiration",
    label: "Tiene fecha de vencimiento",
    exportExcel: true,
  },
  {
    id: "blockOperation",
    label: "Bloquea operación",
    exportExcel: true,
  },
  {
    id: "actions",
    label: "Acciones",
    disableSorting: true,
    exportExcel: false,
  },
];

export default function Types() {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [alertError, setAlertError] = useState({
    open: false,
    message: "",
  });
  const [deleteId, setDeleteId] = useState("");
  const [renderDocumentTitle, setRenderDocumentTitle] = useState(null);
  const [filters, setFilters] = useState({});
  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [deleteIsLoading, setDeleteIsLoading] = useState(false);
  const [selectedTypeDocuments, setSelectedTypeDocuments] = useState([]);
  const { data, isLoading, error } = useListTypes(filters);
  const { mutate } = useDeleteTypes();

  const handleSearch = (event) => {
    const { value } = event.target;
    const newValue = value.toLowerCase();

    if (newValue !== undefined) {
      setFilters((old) => ({ ...old, search: newValue }));
    }
  };

  const onChangeFilters = (newFilters = {}) => {
    setFilters((old) => ({
      ...old,
      ...newFilters,
    }));
  };

  const { TableContainer } = Table(
    headCells,
    data?.metadata,
    onChangeFilters
  );

  const handleCloseAlertError = () => {
    setAlertError({
      open: false,
      message: "",
    });
  };

  const handleCloseDialogDelete = () => {
    setOpenDialogDelete(false);
  };

  const handleDelete = async () => {
    setDeleteIsLoading(true);
    mutate(deleteId, {
      onSuccess: () => {
        setOpenDialogDelete(false);
        setDeleteIsLoading(false);
      },
      onError: () => {
        setOpenDialogDelete(false);
        setAlertError({
          open: true,
          message: "Oh no! se ha producido un error al deletar la plantilla",
        });
      },
    });
  };

  const handleOpenModal = (documents, title) => {
    setRenderDocumentTitle(title);
    setSelectedTypeDocuments(documents);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setRenderDocumentTitle(null);
    setSelectedTypeDocuments([]);
  };

  if (error) {
    return (
      <PaperWhite
        sx={{
          color: "primary.main",
          height: "100%",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Typography variant="h4">No se encontraron datos!</Typography>
      </PaperWhite>
    );
  }

  if (isLoading) {
    return <LoadingSpinner open />;
  }

  const TabsAddon = () => (
    <Stack
      direction="row"
      paddingY={2}
      paddingX={2}
      alignItems="center"
      justifyContent="space-between"
      className={styles.tabAddon}
    >
      <Box sx={{ display: "flex" }}>
        <SearchBar
          id="table-searchbar"
          type="text"
          placeholder="buscar"
          onSearch={handleSearch}
        />
        <DownloadExportData
          data={data?.data}
          columns={headCells}
          nameFile="Documentos"
        />
      </Box>

      <Button
        variant="contained"
        startIcon={<AddCircleOutlined color="inherit" />}
        onClick={() =>
          navigate("/Types/create", {
            state: {
              fromButton: true,
            },
          })
        }
      >
        Crear Nuevo
      </Button>
    </Stack>
  );

  return (
    <>
      <RenderDocuments
        title={renderDocumentTitle}
        documents={selectedTypeDocuments}
        openModal={openModal}
        handleCloseModal={handleCloseModal}
      />

      <Stack spacing={2}>
        <Typography variant="h4" align="center">
          Tipos de documentos
        </Typography>
          <TableContainer addons={[TabsAddon]} withPagination>
            <TableBody>
              {data?.data.map(
                ({ id, label, hasExpiration, documents, block }) => (
                  <>
                    <TableRow key={id}>
                      <TableCell>
                        <Typography>{label}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography fontWeight="bold">
                          {hasExpiration ? "SI" : "NO"}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography fontWeight="bold">
                          {block?.operation ? "SI" : "NO"}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ width: "20%" }}>
                        <Stack direction="row" spacing={2}>
                          <IconButton
                            onClick={() => navigate(`/Types/update/${id}`)}
                          >
                            <EditOutlined />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              setOpenDialogDelete(true);
                              setDeleteId(id);
                            }}
                          >
                            <DeleteOutlined />
                          </IconButton>
                          {documents.length > 0 && (
                            <IconButton>
                              <FormatListNumbered
                                onClick={() =>
                                  handleOpenModal(documents, label)
                                }
                              />
                            </IconButton>
                          )}
                        </Stack>
                      </TableCell>
                    </TableRow>
                  </>
                )
              )}
            </TableBody>
          </TableContainer>
        <Dialogs
          open={openDialogDelete}
          handleClose={handleCloseDialogDelete}
          handleConfirmation={handleDelete}
          isLoading={deleteIsLoading}
          title="¿Estás seguro de que quieres eliminar el tipo de documento?"
        />
        <AlertSnackBar
          open={alertError?.open}
          handleClose={handleCloseAlertError}
          message={alertError?.message}
        />
      </Stack>
    </>
  );
}
