import { useApi } from "../../api";
import { useQuery } from "react-query";
import { EMPLOYEE_URI } from "../../../constants/api";

export async function validateEmployeeByDni(type, employeeDni) {
  try {
    const { axiosInstance } = useApi();
    const response = await axiosInstance.get(
      `/validate/${type}/dni/${employeeDni}`,
      {
        baseURL: EMPLOYEE_URI,
      }
    );

    return response.data;
  } catch (error) {
    return null;
  }
}

export function useValidateEmployeeByDni(type, Dni) {
  return useQuery(
    ["getEmployeeByDni", Dni],
    () => validateEmployeeByDni(type, Dni),
    {
      staleTime: 1000 * 60 * 10,
    }
  );
}
