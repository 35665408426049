import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import {
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Tooltip,
} from "@mui/material";
import {
  ArrowBackOutlined,
  AssignmentOutlined,
  CalendarTodayOutlined,
  InfoOutlined,
  LocationOnOutlined,
} from "@mui/icons-material";

import { PayIcon } from "../../components/Icons/PayIcon";
import copyIcon from "../../assets/images/copyIcon.png";
import { MessageBox } from "../../components/common/MessageBox";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { PaperWhite } from "../../components/Papers";
import { Table } from "../../components/Table";
import { SearchBar } from "../../components/SearchBar";
import { ChipStatus } from "../../components/Chip";
import { ProtectedComponent } from "../../components/ProtectedComponent";
import { useOfferDetails } from "../../services/hooks/Offers";
import { useListApplicationsByOffer } from "../../services/hooks/Applications";
import { formatedDate } from "../../utils/formatValues";
import { maskRUT } from "../../utils/masks";

import { APPLIES_READ } from "../../constants/functionalities";

import { DownloadExportData } from "../../components/Excel/Excel";
import { Editor } from "../../v2/components/Editor";
import { findVehicleConfig } from "../../v2/constants/vehicle-config";

const headCells = [
  {
    id: "status",
    label: "Status",
    exportExcel: true,
  },
  {
    id: "nameProvider",
    label: "Nombre",
    exportExcel: true,
  },
  {
    id: "rutProvider",
    label: "RUT",
    exportExcel: true,
  },
  {
    id: "emailContact",
    label: "E-mail",
    exportExcel: true,
  },
  {
    id: "dateCreate",
    label: "Inscripción",
    exportExcel: true,
  },
  {
    id: "actions",
    label: "Acciones",
    disableSorting: true,
    exportExcel: false,
  },
];

export default function OfferDetails({ fromButton }) {
  const { offerId } = useParams();
  const navigate = useNavigate();
  const [formatToExcel, setFormatToExcel] = useState({});
  const [filterFn, setFilterFn] = useState({
    fn: (items) => items,
  });

  const { data: offer, isLoading, error } = useOfferDetails(offerId);

  const {
    data: applications,
    isLoading: applicationsIsLoading,
    error: applicationsError,
  } = useListApplicationsByOffer(offerId);

  const [openTooltip, setOpenTooltip] = useState(false);

  useEffect(() => {
    if (Array.isArray(applications) && applications.length) {
      const format = applications.map((item) => {
        const obj = {
          ...item,
          status: item.status.description,
          nameProvider: item.provider.nameProvider,
          rutProvider: maskRUT(item.provider.rutProvider),
          emailContact: item.provider.emailContact,
          dateCreate: formatedDate(item.provider.dateCreate),
        };

        return obj;
      });

      setFormatToExcel(format);
    }
  }, [applications]);

  const handleTooltipClickOpen = () => {
    setOpenTooltip(true);
    navigator.clipboard.writeText(
      `${process.env.REACT_APP_PUBLIC_URL}${offerId}`
    );

    setTimeout(() => {
      setOpenTooltip(false);
    }, 3000);
  };

  const {
    TableContainer,
    TableHead,
    TablePagination,
    recordsAfterPagingAndSorting,
  } = Table(applications, headCells, filterFn);

  useEffect(() => {
    if (!fromButton) navigate("/404");
  }, []);

  const handleSearch = (event) => {
    const { value } = event.target;
    const newValue = value.toLowerCase();

    setFilterFn({
      fn: (items) => {
        if (value === "") return items;
        return items.filter(
          ({ provider, status }) =>
            status.description?.toLowerCase().includes(newValue) ||
            provider.nameProvider?.toLowerCase().includes(newValue) ||
            provider.rutProvider?.toLowerCase().includes(newValue) ||
            provider.emailContact?.toLowerCase().includes(newValue) ||
            String(provider.dateCreate).toLowerCase().includes(newValue)
        );
      },
    });
  };

  const qtfOffer = offer?.setting.vacancies.reduce(
    (acc, vacancy) =>
      acc + Number(vacancy.vacancyTotals?.total ?? vacancy.total),
    0
  );

  const startDateFormated = (value) =>
    new Date(value).toLocaleDateString("es", {
      day: "2-digit",
      month: "long",
    });

  const finishDateFormated = (value) =>
    new Date(value).toLocaleDateString("es", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });

  const formatter = (value) =>
    new Intl.NumberFormat([], {
      style: "decimal",
    }).format(value);

  if (error || applicationsError)
    return <MessageBox>Ups, ocurrio un error!</MessageBox>;

  if (isLoading || applicationsIsLoading) {
    return <LoadingSpinner open />;
  }

  return (
    <>
      <Button
        variant="goBack"
        disableRipple
        startIcon={<ArrowBackOutlined />}
        onClick={() => navigate(-1)}
        sx={{ pb: 2 }}
      >
        Volver atrás
      </Button>

      <PaperWhite sx={{ pb: 4 }}>
        <Stack spacing={4}>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography variant="caption" sx={{ color: "lightText" }}>
              Código oferta
            </Typography>
            <Typography
              sx={{ fontFamily: "Montserrat", fontWeight: 900 }}
              noWrap
            >
              {offer?.codeOffer}
            </Typography>
          </Stack>

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h4" flexWrap>
              {offer?.title}
            </Typography>

            <Stack direction="row" spacing={2}>
              <Tooltip
                title="Copiado"
                open={openTooltip}
                disableFocusListener
                disableHoverListener
                disableTouchListener
              >
                <IconButton
                  onClick={handleTooltipClickOpen}
                  sx={{ with: "40px", height: "40px" }}
                >
                  <img src={copyIcon} alt="Copiar" />
                </IconButton>
              </Tooltip>
            </Stack>
          </Stack>

          <Stack direction={{ xs: "column", lg: "row" }} spacing={4}>
            <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
              <CalendarTodayOutlined />
              <Typography sx={{ color: "lightText" }}>Publicado:</Typography>
              <Typography sx={{ fontWeight: 600 }}>
                {startDateFormated(offer?.setting.publicationDate.from)}
              </Typography>
            </Stack>

            <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
              <LocationOnOutlined />
              <Typography sx={{ color: "lightText" }}>Localidad:</Typography>
              <Typography sx={{ fontWeight: 600 }}>
                {offer?.setting.base.name}
              </Typography>
            </Stack>

            <Stack direction="row" spacing={1} sx={{ alignItems: "center" }}>
              <AssignmentOutlined />
              <Typography sx={{ color: "lightText" }}>Vacantes:</Typography>
              <Typography sx={{ fontWeight: 600 }}>{qtfOffer}</Typography>
            </Stack>

            {!offer?.privateCommercialOffer && (
              <Stack direction="row" spacing={1} alignItems="center">
                <PayIcon />
                <Typography sx={{ color: "lightText" }}>Monto:</Typography>
                <Typography
                  sx={{
                    fontSize: "1.25rem",
                    fontWeight: 600,
                    "& span": {
                      color: "lightText",
                      fontWeight: 400,
                      fontSize: "0.75rem",
                    },
                  }}
                  noWrap
                >
                  ${formatter(offer?.commercialOffer.value)}
                  <span>/ mes</span>
                </Typography>
              </Stack>
            )}
          </Stack>

          <Divider variant="middle" />

          <Box>
            <Typography variant="h6" sx={{ fontWeight: 600 }}>
              Descripción
            </Typography>
            <Editor initialState={offer?.description} editable={false} />
          </Box>

          <Box>
            <Typography variant="h6" sx={{ fontWeight: 600 }}>
              Detalles Oferta
            </Typography>
            <Editor initialState={offer?.details} editable={false} />
          </Box>

          <Box>
            <Typography variant="h6" sx={{ fontWeight: 600, mb: 2 }}>
              Tipo de vehículos
            </Typography>
            <Stack spacing={2} divider={<Divider flexItem />}>
              {offer?.setting?.vacancies.map((vacancy) => (
                <Stack direction="row" spacing={4} alignItems="center">
                  <Typography sx={{ pr: 3 }}>
                    {vacancy.base?.name
                      .concat(" (")
                      .concat(vacancy.base.code)
                      .concat(")")}
                  </Typography>
                  <Typography sx={{ pr: 3 }}>{vacancy.total}</Typography>

                  <Avatar
                    src={findVehicleConfig(vacancy.vehicle.icon)?.icon}
                    sx={{
                      width: 38,
                      height: 38,
                      backgroundColor: "#ECF8FF",
                      mr: 2,
                    }}
                  />
                  <Typography sx={{ pr: 8 }}>{vacancy.vehicle.name}</Typography>

                  <Stack
                    direction={{ xs: "column", lg: "row" }}
                    spacing={2}
                    alignItems="center"
                    divider={<Divider orientation="vertical" flexItem />}
                  >
                    {vacancy.vehicle.features.map(({ name, value }) => (
                      <Typography key={name}>{value}</Typography>
                    ))}
                  </Stack>
                </Stack>
              ))}
            </Stack>
          </Box>

          <Typography>
            La fecha de esta vacante finaliza el{" "}
            <strong>
              {finishDateFormated(offer?.setting?.publicationDate?.to)}
            </strong>
          </Typography>

          {applications?.length > 0 && (
            <>
              <Typography variant="h5" align="center">
                Postulaciones
              </Typography>
              <PaperWhite sx={{ p: 0 }}>
                <Stack
                  direction="row"
                  paddingY={2}
                  paddingX={2}
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box sx={{ display: "flex" }}>
                    <SearchBar
                      id="table-searchbar-postulaciones"
                      type="text"
                      placeholder="buscar"
                      onChange={handleSearch}
                    />

                    <DownloadExportData
                      data={formatToExcel}
                      columns={headCells}
                      nameFile="Mis postulaciones"
                    />
                  </Box>
                </Stack>

                <TableContainer>
                  <TableHead />
                  <TableBody>
                    {recordsAfterPagingAndSorting()?.map(
                      ({ id, provider, status }) => (
                        <TableRow key={provider.id}>
                          <TableCell>
                            <ChipStatus
                              label={status.description}
                              setcolor={status.color}
                            />
                          </TableCell>
                          <TableCell>{provider.nameProvider}</TableCell>
                          <TableCell>{maskRUT(provider.rutProvider)}</TableCell>
                          <TableCell>{provider.emailContact}</TableCell>
                          <TableCell>
                            {formatedDate(provider.dateCreate)}
                          </TableCell>
                          <TableCell>
                            <Stack direction="row" spacing={2}>
                              <ProtectedComponent
                                functionality={[APPLIES_READ]}
                              >
                                <IconButton
                                  onClick={() =>
                                    navigate(`/listApplications/${id}`, {
                                      state: { fromButton: true },
                                    })
                                  }
                                >
                                  <InfoOutlined />
                                </IconButton>
                              </ProtectedComponent>
                            </Stack>
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </TableContainer>
                <TablePagination />
              </PaperWhite>
            </>
          )}
        </Stack>
      </PaperWhite>
    </>
  );
}
