import { useMutation, useQueryClient } from "react-query";
import { useApi } from "../../../api";
import { DOCUMENT_URI } from "../../../../constants/api";

export function useReplaceManyDocument() {
  const { axiosInstance } = useApi();
  const queryClient = useQueryClient();

  return useMutation(
    async ({ documents }) => {
      const currentRequestFormData = new FormData();

      Object.entries(documents).forEach(([key, value]) => {
        if (key === "file") {
          value.forEach((individualFile) =>
            currentRequestFormData.append("file", individualFile)
          );
        } else {
          currentRequestFormData.append(key, value);
        }
      });

      return axiosInstance.post(
        `/replaceManyDocumentation`,
        currentRequestFormData,
        {
          baseURL: DOCUMENT_URI,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["documents"]);
        queryClient.refetchQueries("listRequests");
        queryClient.invalidateQueries("getDocuments");
        queryClient.refetchQueries("getFleetsByProviderAndOffer");
        queryClient.refetchQueries("employeesByProvider");
      },
    }
  );
}
