import { useState, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Stack, Typography } from "@mui/material";
import { LoadingSpinner } from "../../../components/LoadingSpinner";
import { EntityCard } from "../../components/EntityCard";
import {
  EmployeeStatus,
  VACANCY_TYPE_MAPPING,
} from "../../../constants/status";
import { useGetTemplate } from "../../../services/hooks/Docs/Templates/useGetTemplates";
import { maskRUT } from "../../../utils";
import { vacancyType } from "../../../constants/vacancyType";
import { ChipStatus } from "../../components/Chip";
import { ENTITY_TYPES_MAPPING } from "../../../constants/entityTypes";
import { EntityDocumentListUpdateMany } from "../../components/Documents/EntityDocumentListUpdateMany";
import { FinishReplaceDocument } from "./components/FinishReplaceDocument";
import { useGetEmployee } from "../../../services/hooks/Employee";
import { EditEmployeeData } from "./components/EditEmployeeData";

export default function ProviderEmployeeEdit() {
  const { employeeId } = useParams();
  const navigation = useNavigate();
  const { data: item, isLoading: isEmployeeLoading } = useGetEmployee(employeeId)
  const formValues = useMemo(() => ({
    id: item?.id,
    rut: item?.numberRut,
    name: item?.name ?? "",
    lastname1: item?.lastname1 ?? "",
    lastname2: item?.lastname2 ?? "",
    email: item?.email ?? "",
    phone: item?.phone ?? "",
  }), [item])
  const [isRenderedEdit, setIsRenderedEdit] = useState(false);
  const [replaceFinish, setReplaceFinish] = useState(false);
  const { data: template, isLoading: isLoadingTemplate } = useGetTemplate(
    item?.documentTemplateId
  );

  const goBack = () => {
    navigation(-1);
  }

  const goToEditProfile = () => {
    setIsRenderedEdit(true);
  };

  const goToProfile = () => {
    setIsRenderedEdit(false);
  };

  if (isRenderedEdit) {
    return (
      <EditEmployeeData formValues={formValues} goBack={goToProfile} />
    );
  }

  if (isLoadingTemplate || isEmployeeLoading) return <LoadingSpinner open />;

  if (replaceFinish) {
    return (
      <Stack spacing={4}>
        <Typography variant="h4" align="center">
          {`Reemplazar Documentos`}
        </Typography>
        <FinishReplaceDocument
          goBack={goBack}
          goBackTitleButton={`Volver a Mis ${ENTITY_TYPES_MAPPING[item.type]}`}
        />
      </Stack>
    );
  }

  return (
    <>
      <Stack>
        <EntityCard
          entries={[
            [
              `Datos del ${VACANCY_TYPE_MAPPING[item.type]}`,
              {
                "Nombre completo:": (
                  <b>{`${item?.name} ${item?.lastname1} ${item?.lastname2}`}</b>
                ),
                "RUT:": <b>{maskRUT(item?.numberRut)} </b>,
                "Teléfono:": <b> {item?.phone} </b>,
                "E-mail:": <b> {item?.email} </b>,
                "": (
                  <Stack direction="row">
                    <Button
                      onClick={goToEditProfile}
                      autoFocus
                      color="secondary"
                      variant="contained"
                      sx={{
                        color: "white",
                        borderRadius: "16px",
                        fontWeight: 700,
                        textTransform: "capitalize",
                        width: 100,
                        height: 40,
                      }}
                      disableElevation
                    >
                      Editar
                    </Button>
                  </Stack>
                ),
              },
            ],
            [
              "Estado",
              {
                "": (
                  <ChipStatus
                    label={EmployeeStatus[item?.status]}
                    setcolor={item?.status}
                  />
                ),
              },
            ],
          ]}
        />

        <Stack sx={{ mt: 5 }}>
          {
            <EntityDocumentListUpdateMany
              type={vacancyType.EMPLOYEE}
              id={item?.id}
              templateData={template}
              goBack={goBack}
              entity={item}
              setReplaceFinish={setReplaceFinish}
            />
          }
        </Stack>
      </Stack>
    </>
  );
};
