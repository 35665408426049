import { useMutation, useQueryClient } from "react-query";
import { useApi } from "../../../../services/api";
import { APPLY_REPORT_URI } from "../../../../constants/api";
import { buildUrlWithQueryParams } from "../../../helper/converters";

export async function reportEmployeeApplies(filters) {
  const { axiosInstance } = useApi();
  const baseUrl = `${APPLY_REPORT_URI}/all-security-applies`;
  const url = buildUrlWithQueryParams(baseUrl, filters);
  const { data } = await axiosInstance.get(url);
  return data;
}

export function useReportEmployeeApplies() {
  const queryClient = useQueryClient();
  return useMutation(reportEmployeeApplies, {
    onSuccess: () => {
      queryClient.refetchQueries("reportEmployeeApplies");
    },
  });
}
