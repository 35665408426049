import { useMutation, useQueryClient } from 'react-query'
import { useApi } from '../../api'
import { OFFER_URI } from '../../../constants/api'

export function useUpdateOffer(id) {
  const { axiosInstance } = useApi()
  const queryClient = useQueryClient()

  return useMutation((offer) => axiosInstance.put(`/${id}`, offer, {
    baseURL: OFFER_URI,
  }), {
    onSuccess: () => {
      queryClient.invalidateQueries('listOffers')
      setTimeout(() => {
        queryClient.invalidateQueries(['offerDetails', id])
      }, 3000)
    },
  })
}
