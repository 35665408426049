import * as React from 'react'
import { Box } from '@mui/material'
import { ReactComponent as BarIcon } from "../../assets/images/bar.svg"
import { ReactComponent as UpArrowIcon } from "../../assets/images/uparrow.svg"

export default function UploadIcon() {

  return (
    <>
      <Box sx={{left:"8px", position:"relative"}}>
        <UpArrowIcon/>
      </Box>
      <Box sx={{right:"7px", top:"3px", position:"relative"}}>
        <BarIcon/>
      </Box>
    </>
  )
}