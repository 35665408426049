import React from "react";
import {
  Box,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";

import { ReactComponent as LockIcon } from "../../assets/images/lock.svg"

import { styled } from "@mui/material/styles";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";

export const StepperController = ({ isCurrentStepActive, steps }) => {
  const BlueConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 14,
      left: "calc(-50% + 40px)",
      right: "calc(50% + 40px)",
      borderRadius:"8px"
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        border: "3px solid #CCCCCC",
        borderRadius:"8px"
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        border: "3px solid #3363FF",
        borderRadius:"8px"
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      border: `3px solid ${
        theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0"
      }`,
      borderTopWidth: 3,
      borderRadius:"8px"
    },
  }));

  const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 32,
    height: 32,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    paddingTop:"8px",
    paddingBottom:"8px",
    alignItems: "center",
    ...(ownerState.active && {
      backgroundColor: "#CCCCCC",
    }),
    ...(ownerState.completed && {
      backgroundColor: "#3363FF",
    }),
  }));

  function GetInherentIcon(props) {
    const { completed, className, icon } = props;
    const iconStep = completed ? icon : <LockIcon/>;

    return (
      <ColorlibStepIconRoot ownerState={{ completed }} className={className}>
        {iconStep}
      </ColorlibStepIconRoot>
    );
  }

  return (
    <Stack spacing={4} sx={{ alignItems:"center"}}>
      <Box sx={{width:"50%"}}>
        <Stepper
          activeStep={isCurrentStepActive}
          alternativeLabel
          connector={<BlueConnector />}
        >
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel StepIconComponent={GetInherentIcon} >
                <Typography sx={{ color: "#BBBEC8" }} fontWeight="700px" fontSize="10px" lineHeight="12px" letterSpacing="5%">
                  PASO {index + 1}
                </Typography>
                  {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    </Stack>
  );
};
