import { pick } from "../../utils";

export const validateGroupedDocuments = (documentTypes, entity) => {
  const templateRelationshipTags = documentTypes.flatMap(
    (type) => type.documents.map((doc) => doc.name)
  );
  const documentsByStructure = pick(entity, templateRelationshipTags);

  const groupedDataByDocumentType = Object.values(
    documentsByStructure
  ).reduce((result, item) => {
    const documentTypeId = item.documentTypeId;
    result[documentTypeId] = result[documentTypeId] || {
      file: [],
      documentTypeId: item.documentTypeId,
      status: item.status,
      ownerId: item.ownerId,
      ownerType: item.ownerType,
      expirationDate:
        entity[`${item.metaData?.groupName}_expiration_date`] ?? null,
      metaData: [],
    };
    result[documentTypeId].file.push(item?.file ?? null);
    result[documentTypeId].metaData.push(item.metaData);
    return result;
  }, {});

  const entrieWithALeastOneNullFile = Object.entries(
    groupedDataByDocumentType
  ).find(([_, entry]) => {
    return (
      entry.file &&
      entry.file.includes(null) &&
      !entry.file.every((item) => item === null)
    );
  });

  const entrieWithNullFilesAndNewExpDate = Object.entries(
    groupedDataByDocumentType
  ).find(([_, entry]) => {
    const relatedDocType = documentTypes?.find(
      (docType) => docType?.id === entry?.documentTypeId
    );

    return (
      entry.file &&
      entry.file.includes(null) &&
      entry?.expirationDate &&
      entity[`${relatedDocType?.name}_expiration_date`] !==
        entry?.expirationDate
    );
  });

  if (entrieWithNullFilesAndNewExpDate) {
    const relatedDocType = documentTypes?.find(
      (docType) =>
        docType?.id === entrieWithNullFilesAndNewExpDate[1]?.documentTypeId
    );
    return {
      error: {
        messages: [
          `Has modificado la fecha de expiración del grupo ${relatedDocType?.label}, porfavor asegurate de modificar los documentos de igual manera`
        ],
      }
    };
  }

  if (entrieWithALeastOneNullFile) {
    const relatedDocType = documentTypes?.find(
      (docType) =>
        docType?.id === entrieWithALeastOneNullFile[1]?.documentTypeId
    );

    const nullCount = entrieWithALeastOneNullFile[1].file.reduce(
      (count, element) => (element === null ? count + 1 : count),
      0
    );
    const notNullCount =
      entrieWithALeastOneNullFile[1].file.length - nullCount;

    return {
      error: {
        messages: [
          `Has modificado ${notNullCount} documentos del grupo ${relatedDocType?.label}, porfavor asegurate de modificar los ${nullCount} restantes`
        ],
      }
    };
  }
  return { groupedDataByDocumentType, error: null }
}