import { createSvgIcon } from '@mui/material'

export const StoreIcon = createSvgIcon(
  <>
    <path
      d='M9.82098 15.3507H15.4099V10.628H9.82098V15.3507ZM10.9927 11.8029H14.2304V14.1834H10.9927V11.8029Z'
      fill='#212121'
    />
    <path
      d='M16.5662 0H1.37987L0.0154176 3.30973V6.31997H0C0 6.41212 0.00770878 6.49659 0.0154176 6.58874V18L3.82355 17.9846L3.80814 11.8951H7.43126V17.9923H18V3.31741L16.5662 0ZM4.13961 4.00853V6.12799C4.13961 6.39676 4.05482 6.65017 3.91606 6.85751C3.58458 7.33362 3.12206 7.57167 2.53619 7.57167H2.49764C1.77302 7.55631 1.26424 7.11092 1.18715 6.47355V4.00853H4.13961ZM5.31906 6.12799C5.31906 6.12031 5.31906 6.11263 5.31906 6.10495V4.00853H8.31777V6.12799C8.31777 6.39676 8.23298 6.65017 8.09422 6.85751C7.76274 7.32594 7.30021 7.56399 6.71435 7.56399H6.6758C5.92805 7.55631 5.31906 6.94198 5.31906 6.18942V6.12799ZM9.49722 6.12799C9.49722 6.12031 9.49722 6.11263 9.49722 6.10495V4.00853H12.5036V6.11263C12.5036 6.38908 12.4266 6.65017 12.2724 6.86519C11.9409 7.33362 11.4784 7.57167 10.8925 7.57167H10.854C10.1062 7.55631 9.49722 6.94198 9.49722 6.18942V6.12799ZM13.7602 4.00853H16.7666V6.11263C16.7666 6.38908 16.6895 6.65017 16.5353 6.86519C16.2039 7.33362 15.7413 7.57167 15.1555 7.57167H15.1169C14.3692 7.55631 13.7602 6.94198 13.7602 6.18942V4.00853ZM15.7953 1.17491L16.5122 2.8413H1.48009L2.16617 1.17491H15.7953ZM8.603 10.7201H2.62869L2.64411 16.8174L1.18715 16.8251V8.39334C1.55717 8.60836 1.99657 8.73123 2.47452 8.73891H2.53619C3.6 8.73891 4.27837 8.25512 4.69465 7.76365C5.15717 8.34727 5.85867 8.72355 6.65268 8.73891H6.71435C7.57773 8.73891 8.33319 8.39334 8.87281 7.76365C9.33533 8.34727 10.0368 8.72355 10.8308 8.73891H10.8925C11.779 8.73891 12.5499 8.37031 13.0972 7.7099C13.5597 8.32423 14.2844 8.73123 15.1015 8.73891H15.1632C15.7876 8.73891 16.3503 8.55461 16.8206 8.2244V16.8251H8.603V10.7201Z'
      fill='#212121'
    />
  </>,
  'StoreIcon'
)
