import { Paper } from '@mui/material'

export function PaperWhite({ elevation=6, children, sx = null, ...rest }) {
  return (
    <Paper
      elevation={elevation}
      sx={{ px: 4, py: 2, borderRadius: '15px', ...sx }}
      {...rest}
    >
      {children}
    </Paper>
  )
}
