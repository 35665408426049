import { Button, IconButton, Stack, TableBody, TableCell, TableRow } from "@mui/material";
import { ChipStatus } from "../../../components/Chip";
import { formatDate } from "../../../../utils/formatValues";
import { EMPLOYEE_TYPE_ENUM, EmployeeStatus } from "../../../../constants/status";
import { SearchBar } from "../../../../components/SearchBar";
import { Table } from "../../../components/Table";
import { requestTypes } from "../../../constants/requests";
import { DownloadExportData } from "../../../components/Excel/Excel";
import { ThumbDownAltOutlined, ThumbUpAltOutlined } from "@mui/icons-material";

export const SecurityRequestTable = ({
  data = [],
  metadata,
  handleApprove,
  handleReject,
  entityType,
  getValue,
  onCleanFilter,
  setFilters,
}) => {
  const isPeonetaTable = entityType === EMPLOYEE_TYPE_ENUM.PEONETA;
  const headCells = [
    {
      id: "type",
      label: "Tipo de Solicitud",
      disableSorting: true,
      exportExcel: true,
    },
    {
      id: "statusTranslate",
      label: "Estado",
      disableSorting: true,
      exportExcel: true,
    },
    {
      id: "requestDate",
      label: "Fecha de Solicitud",
      sort: {
        id: "created.at",
        order: "asc",
      },
      exportExcel: true,
    },
    {
      id: "numberRut",
      label: "Rut",
      exportExcel: true,
    },
    {
      id: "",
      label: "Acciones",
      disableSorting: true,
    },
  ];
  const onChangeFilters = (newFilters) => {
    if (!newFilters) return;
    setFilters(newFilters);
  };

  const { TableContainer, setPage } = Table(
    headCells,
    {
    ...metadata,
    page: getValue("page", 1),
    limit: getValue("limit", 10),
    },
    onChangeFilters
  );

  const handleSearch = (event) => {
    const { value } = event.target;
    const newValue = value.toLowerCase();

    onChangeFilters({ search: newValue, page: 1 });
    setPage(0);
  };

  const dataRequest = data.map((request) => {
    const dni = isPeonetaTable ? request?.requested?.for?.peonetaDni : request?.requested?.for?.courierDni;
    const complementaryEmployee = isPeonetaTable ? request?.requested?.for?.courierDni : request?.requested?.for?.peonetaDni;
    return {
      id: request.id,
      requestType: request.type,
      type: requestTypes[request.type],
      statusTranslate: EmployeeStatus[request.status],
      status: request.status,
      requestDate: request?.created ? formatDate(request?.created?.at, 'DD-MM-YYYY') : "-",
      ...{
        numberRut: dni,
        [`${isPeonetaTable ? "courier" : "peoneta"}`]: complementaryEmployee,
        fleetPlate: request?.requested?.for?.plate ?? '-',
      },
    }
  });

  const TableAddon = () => (
    <Stack
      sx={{ width: "100%" }}
      direction="row"
      paddingY={2}
      paddingX={2}
      alignItems="center"
      justifyContent="flex-start"
      flex={1}
    >
      <SearchBar
        id="table-searchbar"
        type="text"
        placeholder="Buscar"
        onSearch={handleSearch}
        search={getValue("search", "")}
      />
      <Stack sx={{ minWidth: 160, marginLeft: "1rem" }}>
        <Button variant="contained" onClick={onCleanFilter} size="medium">
          Limpiar filtros
        </Button>
      </Stack>
      <Stack
        flex={1}
        alignItems="end"
      >
        <DownloadExportData
          data={dataRequest}
          columns={headCells}
          nameFile={`solicitudes-${entityType}`}
          label="Descargar Archivo"
        />
      </Stack>
    </Stack>
  );

  return (
    <TableContainer addons={[TableAddon]} withPagination>
      <TableBody>
        {dataRequest?.map((request) => {
          return (
            <TableRow hover tabIndex={-1} key={request.id}>
              <TableCell>{request.type}</TableCell>
              <TableCell>
                <ChipStatus label={request.statusTranslate} setcolor={request.status}/>
              </TableCell>
              <TableCell>
                {request.requestDate}
              </TableCell>
              <TableCell>{request?.numberRut}</TableCell>
              <TableCell>
                <Stack direction="row">
                  <IconButton
                    onClick={() => handleApprove(request)}
                    disabled={document.status === EmployeeStatus.APPROVED}
                  >
                    <ThumbUpAltOutlined />
                  </IconButton>
                  <IconButton
                    onClick={() => handleReject(request)}
                    disabled={document.status === EmployeeStatus.APPROVED}
                  >
                    <ThumbDownAltOutlined />
                  </IconButton>
                </Stack>
              </TableCell>
            </TableRow>
          )}
        )}
      </TableBody>
    </TableContainer>
  )
}
