import auto from "../../assets/images/auto.png";
import moto from "../../assets/images/moto.png";
import camion from "../../assets/images/camion.png";
import troncal from "../../assets/images/troncal.png";
import van from "../../assets/images/van.png";
import peaton from "../../assets/images/peaton.png";
import bicicleta from "../../assets/images/bicicleta.png";

export const VEHICLE_CONFIG = [
  { id: "auto", value: "auto.png", label: "Auto", icon: auto, default: true, enabled: true},
  { id: "moto", value: "moto.png", label: "Moto", icon: moto, enabled: true },
  { id: "camion", value: "camion.png", label: "Camión", icon: camion, enabled: true },
  { id: "troncal", value: "troncal.png", label: "Troncal", icon: troncal, enabled: true },
  { id: "van", value: "van.png", label: "Van", icon: van, enabled: true },
  { id: 'bicicleta', value: 'bicicleta.png', icon: bicicleta, enabled: false },
  { id: 'peaton', value: 'peaton.png', icon: peaton, enabled: false },
];

export const findVehicleConfig = (id) => {
  const vehicleId = id.split(".")[0];

  return VEHICLE_CONFIG.find((vehicle) => vehicle.id === vehicleId && vehicle.enabled);
}

export const getEnabledVehicles = () => {
  return VEHICLE_CONFIG.filter((vehicle) => vehicle.enabled);
}