import { useMutation, useQueryClient } from "react-query";
import { useApi } from "../../api";
import { LOCALITY_URI } from "../../../constants/api";

export function useUpdateBases(localityId, baseId) {
  const { axiosInstance } = useApi();
  const queryClient = useQueryClient();

  return useMutation(
    (newType) =>
      axiosInstance.put(`/${localityId}/base/${baseId}`, newType, {
        baseURL: LOCALITY_URI,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("listBases");
        queryClient.invalidateQueries(["bases", localityId, baseId]);
      },
    }
  );
}
