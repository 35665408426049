import { Button, Stack, Typography } from "@mui/material";
import { Modal } from "../../../components/Modals/Modal";
import { allowedRevokeStatus } from "../../../constants/status";
import { LoadingSpinner } from "../../../components/LoadingSpinner";

const RevokeModal = ({ isOpen, onClose, item, onRevoke }) => (
  <Modal open={isOpen} handleClose={onClose}>
    {item.vacancy ? (
      <Stack paddingTop={2} sx={{ width: "auto" }}>
        <Stack
          direction="row"
          spacing={2}
          flexGrow={2}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Typography variant="h5" px={1}>
            Desistir a la Vacante
          </Typography>
        </Stack>
        <Stack direction="row" spacing={2} flexGrow={2} paddingTop={2}>
          <Typography variant="h6">
            Desistirá a la vacante que contiene:
          </Typography>
        </Stack>
        {item.vacancy.plate && (
          <Stack direction="row" spacing={2} flexGrow={2} paddingTop={2}>
            <Typography sx={{ fontWeight: 700 }}>
              Patente de Vehículo:
            </Typography>
            <Typography>{item.vacancy.plate}</Typography>
          </Stack>
        )}
        {item.vacancy.courierDni && (
          <Stack direction="row" spacing={2} flexGrow={2} paddingTop={2}>
            <Typography sx={{ fontWeight: 700 }}>Rut del Conductor:</Typography>
            <Typography>{item.vacancy.courierDni}</Typography>
          </Stack>
        )}
        {item.vacancy.peonetaDni && (
          <Stack direction="row" spacing={2} flexGrow={2} paddingTop={2}>
            <Typography sx={{ fontWeight: 700 }}>Rut del Peoneta:</Typography>
            <Typography>{item.vacancy.peonetaDni}</Typography>
          </Stack>
        )}
        <Stack direction="row" spacing={2} flexGrow={2} paddingTop={2}>
          <Typography sx={{ fontWeight: 700 }}>
            En la oferta de título:
          </Typography>
          <Typography>{item.offerTitle}</Typography>
        </Stack>
        <Stack direction="row" spacing={2} flexGrow={2} paddingTop={2}>
          <Typography sx={{ fontWeight: 700 }}>Código de la oferta:</Typography>
          <Typography>{item.codeOffer}</Typography>
        </Stack>
        <Stack direction="row" spacing={2} flexGrow={2} paddingTop={2}>
          <Typography>
            Esto eliminará los registros ingresados en dichas vacantes.
          </Typography>
        </Stack>
        <Stack direction="row" sx={{ justifyContent: "space-between" }} pt={3}>
          <Button variant="goBack" disableRipple onClick={onClose}>
            Volver
          </Button>
          {allowedRevokeStatus.includes(item.vacancy.status) && (
            <Button disableRipple onClick={() => onRevoke(item)}>
              Desistir
            </Button>
          )}
        </Stack>
      </Stack>
    ) : (
      <LoadingSpinner open />
    )}
  </Modal>
);

export default RevokeModal;
