import React from "react";
import {
  Box,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";

import CheckIcon from "@mui/icons-material/Check";
import LockIcon from "@mui/icons-material/Lock";

import { styled } from "@mui/material/styles";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";

export const StepperController = ({ title, isCurrentStepActive, steps }) => {
  const BlueConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
      left: "calc(-50% + 40px)",
      right: "calc(50% + 40px)",
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        border: "3px solid #3363FF",
        borderRadius: 20,
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        border: "3px solid #3363FF",
        borderRadius: 20,
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      border: `3px solid ${
        theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0"
      }`,
      borderTopWidth: 3,
      borderRadius: 20,
    },
  }));

  const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    ...(ownerState.active && {
      backgroundColor: "#CCCCCC",
    }),
    ...(ownerState.completed && {
      backgroundColor: "#3363FF",
    }),
  }));

  function GetInherentIcon(props) {
    const { completed, className } = props;

    const icon = completed ? <CheckIcon /> : <LockIcon />;

    return (
      <ColorlibStepIconRoot ownerState={{ completed }} className={className}>
        {icon}
      </ColorlibStepIconRoot>
    );
  }
  return (
    <Stack spacing={4}>
      <Typography variant="h4" align="center">
        {title}
      </Typography>

      <Box sx={{ width: "100%" }}>
        <Stepper
          activeStep={isCurrentStepActive}
          alternativeLabel
          connector={<BlueConnector />}
        >
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel StepIconComponent={GetInherentIcon}>
                <Typography sx={{ color: "#BBBEC8" }}>
                  Paso {index + 1}
                </Typography>
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    </Stack>
  );
};
