import { useQuery } from "react-query";
import { useApi } from "../../api";
import { LOCALITY_URI } from "../../../constants/api";
import { buildUrlWithQueryParams } from "../../../v2/helper/converters";

export async function getListBases(filters = {}) {
  const { axiosInstance } = useApi();
  const url = buildUrlWithQueryParams(LOCALITY_URI, filters);
  const response = await axiosInstance.get(url);

  

  const { data, metadata } = response.data;

  const bases = data.flatMap((locality) =>
    locality.bases.map((base) => ({
      ...base,
      id: base.id,
      localityName: locality.name,
      localityId: locality.id,
    }))
  );

  metadata.total_count = bases.length

  return {bases, metadata};
}

export function useListBases() {
  return useQuery("listBases", () => getListBases(), {
    staleTime: 1000 * 60 * 10, // 10 minutos para não refazer a requisição ao receber foco na tela
  });
}
