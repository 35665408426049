import { useForm } from "react-hook-form";
import { DocumentsSection } from "../../../components/Forms/Documents/DocumentSection";
import { Modal } from "../../../components/Modals/Modal";
import { Button, Grid, Stack } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { validateGroupedDocuments } from "../../helper/documents";
import { Alert } from "../../../components/Alert";
import warning from "../../../assets/images/warning.svg";

const initialAlert = {
  open: false,
  icon: "",
  title: "",
  messages: [],
  content: "",
};

const EntityDocumentsModal = ({
  isOpen,
  onClose,
  templateData,
  formValues,
  documentUpload,
  submitData,
}) => {
  const { control, handleSubmit, getValues, setValue } = useForm({
    formValues,
  });
  const [currentValues, setCurrentValues] = useState(formValues);
  const [alert, setAlert] = useState(initialAlert);

  const handleFinishAction = () => {
    Object.keys(currentValues).forEach((key) => {
      setValue(key, "");
    });
  };

  const handleSubmitSend = useCallback(
    async (data) => {
      const { error: validationGroupedError, groupedDataByDocumentType } =
        validateGroupedDocuments(templateData.documentTypes, data, formValues);
      if (validationGroupedError) {
        setAlert({
          ...alert,
          icon: warning,
          open: true,
          title: "Error",
          messages: validationGroupedError.messages,
        });
        return;
      }

      submitData(groupedDataByDocumentType);
    },
    [templateData]
  );

  useEffect(() => {
    Object.keys(currentValues).forEach((key) => {
      setValue(key, currentValues[key]);
    });
  }, []);

  const handleChange = () => {
    setCurrentValues((prev) => ({ ...prev, ...getValues() }));
  };

  const handleConfirmation = () => {
    handleFinishAction();
    onClose();
    setAlert({
      ...alert,
      open: false,
    });
  };

  return (
    <Modal open={isOpen} handleClose={onClose}>
      <Stack
        spacing={2}
        component="form"
        onChange={handleChange}
        onSubmit={handleSubmit(handleSubmitSend)}
      >
        <DocumentsSection
          control={control}
          documentStructure={templateData?.documentTypes?.filter(
            (item) => item.id === documentUpload?.documentTypeId
          )}
        />
        <Grid item xs={7}>
          <Grid container justifyContent="flex-end">
            <Button
              disabled={false}
              color="secondary"
              type="submit"
              sx={{ minWidth: "155px", height: 40 }}
            >
              {"Guardar"}
            </Button>
          </Grid>
        </Grid>
      </Stack>
      <Alert
        icon={alert.icon}
        open={alert.open}
        handleConfirmation={handleConfirmation}
        title={alert.title}
        messages={alert.messages}
      />
    </Modal>
  );
};

export default EntityDocumentsModal;
