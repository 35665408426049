import { useQuery } from 'react-query'
import { useApi } from '../../api'
import { APPLY_URI } from '../../../constants/api'

export async function getRequestsById(id) {
  const { axiosInstance } = useApi()
  const response = await axiosInstance.get(`/${id}/with-vacancies`, {
    baseURL: APPLY_URI,
  })
  const { data } = response
  return data
}

export function useListRequestsById(id) {
  return useQuery(['requestsById', id], () => getRequestsById(id), {
    cacheTime: 0
  })
}
