/* eslint-disable no-unused-vars */
import { Box, Divider, Stack, Typography } from '@mui/material'

import { PaperBlue } from '../../../components/Papers'
import { TimePicker } from './TimePicker'
import { weekday, month } from '../../../constants/dateStrings'

export function Calendar({
  date,
  initialTime,
  setInitialTime,
  finalTime,
  setFinalTime,
}) {
  const { from, to } = date;

  const fromDate = from ? new Date(from) : null;
  const toDate = to ? new Date(to) : null;

  return ( fromDate != null ?
    <PaperBlue>
      <Stack
        spacing={2}
        divider={<Divider orientation='horizontal' flexItem />}
      >
        <Box>
          <Typography variant='h6' sx={{ fontWeight: '700' }}>
            Fecha inicio él:
          </Typography>
          <Box
            sx={{
              py: 1,
              display: 'flex',
              // justifyContent: 'space-around',
              alignItems: 'center',
            }}
          >
            <Stack
              direction='row'
              spacing={2}
              divider={<Divider orientation='vertical' flexItem />}
            >
              <Typography
                variant='h6'
                sx={{
                  fontSize: '36px',
                  color: 'primary.main',
                }}
              >
                {fromDate?.getDate()}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <Typography variant='h6' sx={{ fontWeight: '700' }}>
                  {month[fromDate?.getMonth()]}
                </Typography>
                <Typography
                  variant='body1'
                  sx={{ fontWeight: '700', color: 'lightWell' }}
                >
                  {weekday[fromDate?.getDay()]}
                </Typography>
              </Box>
            </Stack>
            {/* <TimePicker value={initialTime} onChange={setInitialTime} /> */}
          </Box>
        </Box>
        {toDate != null ? 
        <Box>
          <Typography variant='h6' sx={{ fontWeight: '700' }}>
            Fecha termino él:
          </Typography>
          <Box
            sx={{
              py: 1,
              display: 'flex',
              // justifyContent: 'space-around',
              alignItems: 'center',
            }}
          >
            <Stack
              direction='row'
              spacing={2}
              divider={<Divider orientation='vertical' flexItem />}
            >
              <Typography
                variant='h6'
                sx={{
                  fontSize: '36px',
                  color: 'blue70',
                }}
              >
                {toDate?.getDate()}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <Typography variant='h6' sx={{ fontWeight: '700' }}>
                  {month[toDate?.getMonth()]}
                </Typography>
                <Typography
                  variant='body1'
                  sx={{ fontWeight: '700', color: 'lightWell' }}
                >
                  {weekday[toDate?.getDay()]}
                </Typography>
              </Box>
            </Stack>
            {/* <TimePicker value={finalTime} onChange={setFinalTime} /> */}
          </Box>
        </Box>
        :null}
      </Stack>
    </PaperBlue>
  : null)
}
