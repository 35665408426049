export const DOCUMENT_URI = process.env.REACT_APP_DOCUMENT_URI
export const DOCUMENT_TEMPLATE_URI = process.env.REACT_APP_DOCUMENT_TEMPLATE_URI
export const DOCUMENT_TYPE_URI = process.env.REACT_APP_DOCUMENT_TYPE_URI
export const LOCALITY_URI = process.env.REACT_APP_LOCALITY_URI
export const VEHICLE_URI = process.env.REACT_APP_VEHICLE_URI
export const OFFER_URI = process.env.REACT_APP_OFFER_URI
export const APPLY_URI = process.env.REACT_APP_APPLY_URI
export const APPLY_REPORT_URI = process.env.REACT_APP_APPLY_REPORT_URI
export const EMPLOYEE_URI = process.env.REACT_APP_EMPLOYEE_URI
export const EMPLOYEE_REPORT_URI = process.env.REACT_APP_EMPLOYEE_REPORT_URI
export const FLEET_URI = process.env.REACT_APP_FLEET_URI
export const SETTING_URI = process.env.REACT_APP_SETTING_URI
export const REQUEST_TRACKER_URI = process.env.REACT_APP_REQUEST_TRACKER_URI