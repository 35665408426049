import { useState, useCallback, useEffect } from "react";
import {
  createSearchParams,
  useNavigate,
} from "react-router-dom";
import { Card, Grid, Stack, Tab, Tabs, Typography } from "@mui/material";
import { SelectorInput } from "./components/SelectorInput";
import {
  requestStatusOptions,
  requestTypes,
  requestTypesOptions,
} from "../../constants/requests";
import { LoadingSpinner } from "../../../components/LoadingSpinner";
import { useListProviderRequests } from "../../services/hooks/Request";
import {
  ENTITY_TYPES,
  PROVIDER_ASSETS_TYPE,
} from "../../../constants/entityTypes";
import { getKeyByValue } from "../../helper/object";
import { translatedStatus } from "../../../constants/status";
import { ProviderTable } from "./components/ProviderTable";
import { Alert } from "../../../components/Alert";
import { useSession } from "@blue-express/bx-lib-universal-frontend";
import { useMyProvider } from "../../../services/hooks/Providers/useMyProvider";
import { RequestInfoModal } from "../../components/Modal/RequestInfoModal";
import AlertModal from "../../components/Modal/AlertModal";
import { useDeleteRequestById } from "../../services/hooks/Request";
import EditDateModal from "../../components/Modal/EditDateModal";
import { useURLfilters } from "../../hooks/filters.hook";

export const entityfilters = {
  courier: {
    entityType: ENTITY_TYPES.EMPLOYEE,
    subType: PROVIDER_ASSETS_TYPE.COURIER,
    page: 1,
  },
  peoneta: {
    entityType: ENTITY_TYPES.EMPLOYEE,
    subType: PROVIDER_ASSETS_TYPE.PEONETA,
    page: 1,
  },
  fleet: {
    entityType: ENTITY_TYPES.FLEET,
    subType: undefined,
    page: 1,
  },
};

export default function RequestsProvider() {
  const navigate = useNavigate();
  const { getSession } = useSession();
  const [currentTab, setCurrentTab] = useState();
  const [status, setStatus] = useState([]);
  const [type, setType] = useState();
  const defaultFilters = {
    page: 1,
    limit: 10,
    ...entityfilters[currentTab || "courier"],
  };
  const { filters, setFilters, getURLValue, cleanFilters } = useURLfilters(
    defaultFilters,
    {
      excludeParams: ["tab"],
      persistParamOnClear: ["tab"],
    }
  );

  const [alertError, setAlertError] = useState({
    open: false,
    title: "",
    messages: [],
  });
  const session = getSession();
  const { data: providerData } = useMyProvider(session?.username, false);
  const { mutate: deletedRequestMutate, isLoading: isDeletingLoading } =
    useDeleteRequestById();
  const {
    data: { data, metadata } = {},
    refetch,
    isLoading,
  } = useListProviderRequests({ ...filters, providerId: providerData.id });
  const [requestModal, setRequestModal] = useState({
    open: false,
    requestId: null,
  });
  const [removeRequestModal, setRemoveRequestModal] = useState({
    open: false,
    requestId: null,
    requestType: null,
  });
  const [editDateModal, setEditDateModal] = useState({
    open: false,
    requestId: null,
  });
  const onCloseAlert = () => setAlertError({ ...alertError, open: false });

  const onInfo = (request) => {
    setRequestModal({ open: true, requestId: request.id });
  };

  const onRemove = (request) => {
    setRemoveRequestModal({
      open: true,
      requestId: request.id,
      requestType: request.type,
    });
  };

  const onEdit = (id) => {
    setEditDateModal({ open: true, requestId: id });
  };

  const onChangeTab = (tab) => {
    setCurrentTab(tab);
    setFilters(entityfilters[tab]);
  };

  useEffect(() => {
    onChangeTab(getURLValue("tab") ?? PROVIDER_ASSETS_TYPE.COURIER);
    setStatus();
    setType();
  }, [getURLValue("tab")]);

  const HeaderTabs = () => (
    <Stack direction="row" alignItems="center" justifyContent="center">
      <Tabs
        value={currentTab ?? getURLValue("tab") ?? PROVIDER_ASSETS_TYPE.COURIER}
        onChange={(e, searchInput) => {
          onChangeTab(searchInput);
          navigate({
            pathname: "",
            search: `${createSearchParams({
              tab: searchInput,
            })}`,
          });
        }}
      >
        <Tab label="Vehículos" value={ENTITY_TYPES.FLEET} />
        <Tab label="Conductores" value={PROVIDER_ASSETS_TYPE.COURIER} />
        <Tab label="Peonetas" value={PROVIDER_ASSETS_TYPE.PEONETA} />
      </Tabs>
    </Stack>
  );

  const StatusSelector = useCallback(
    ({ onChange, value }) => (
      <SelectorInput
        onChange={onChange}
        value={value}
        label="Estado de solicitud"
        options={requestStatusOptions}
      />
    ),
    [status]
  );
  const TypeSelector = useCallback(
    ({ onChange, value }) => (
      <SelectorInput
        onChange={onChange}
        value={value}
        label="Tipo de solicitud"
        options={requestTypesOptions}
      />
    ),
    [type]
  );

  const onTypeChange = (e) => {
    setType(e.target.value);
    setFilters({ type: getKeyByValue(requestTypes, e.target.value), page: 1 });
  };

  const onStatusChange = (e) => {
    setStatus(e.target.value);
    setFilters({ status: getKeyByValue(translatedStatus, e.target.value), page: 1 });
  };

  const onCloseInfoModal = () => {
    refetch();
    setRequestModal({ open: false, requestId: null });
  };

  const onCloseDeleteModal = () =>
    setRemoveRequestModal({ open: false, requestId: null });

  const onCleanFilter = () => {
    cleanFilters();
    setStatus();
    setType();
  };

  const onAcceptDeleteRequest = () => {
    deletedRequestMutate(
      {
        requestId: removeRequestModal.requestId,
        requestType: removeRequestModal.requestType,
      },
      {
        onSuccess: () => {
          refetch();
          onCloseDeleteModal();
        },
      },
      {
        onError: () => {
          refetch();
          setAlertError({
            open: true,
            title: "Error",
            messages: ["Error al eliminar la solicitud"],
          });
          onCloseDeleteModal();
        },
      }
    )
  }

  return (
    <Stack spacing={2}>
      <Grid
        sx={{
          justifyContent: "space-between",
        }}
        container
      >
        <Typography variant="h4" align="center">
          Mis Solicitudes
        </Typography>
        <Grid>
          <TypeSelector onChange={onTypeChange} value={type} />
          <StatusSelector onChange={onStatusChange} value={status} />
        </Grid>
      </Grid>
      <Grid
        sx={{
          justifyContent: "center",
        }}
        container
        spacing={2}
      >
        <Grid item xs={12} sm={8} md={6}>
          <Card
            sx={{
              borderRadius: "16px",
              boxShadow: "0 8px 16px 0 #BDC9D7",
            }}
          >
            <HeaderTabs />
          </Card>
        </Grid>
      </Grid>
      {(isLoading) && <LoadingSpinner open />}
      <ProviderTable
        data={data}
        entityType={currentTab ?? getURLValue("tab") ?? "courier"}
        metadata={metadata}
        onInfo={onInfo}
        onRemove={onRemove}
        onEdit={onEdit}
        getValue={getURLValue}
        clearFilters={onCleanFilter}
        setFilters={setFilters}
      />
      <Alert
        icon
        open={alertError.open}
        handleConfirmation={onCloseAlert}
        handleClose={onCloseAlert}
        title={alertError.title}
        messages={alertError.messages}
      />
      <RequestInfoModal
        open={requestModal.open}
        requestId={requestModal.requestId}
        onClose={onCloseInfoModal}
        onAccept={onCloseInfoModal}
      />
      <EditDateModal
        open={editDateModal.open}
        requestId={editDateModal.requestId}
        onClose={() => setEditDateModal({ open: false, requestId: null })}
      />
      <AlertModal
        isOpen={removeRequestModal.open}
        buttonClose={true}
        isLoading={isDeletingLoading}
        onClose={onCloseDeleteModal}
        onCancel={onCloseDeleteModal}
        onAccept={onAcceptDeleteRequest}
        handleConfirmation={() =>
          setRemoveRequestModal({ open: false, requestId: null })
        }
        message="¿Estás seguro de que deseas eliminar esta solicitud?"
      >
        <Stack direction="row" spacing={2}>
          <Typography variant="body1">
            Esta acción no se puede deshacer.
          </Typography>
        </Stack>
      </AlertModal>
    </Stack>
  );
}
