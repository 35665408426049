import {
  Box,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import { SearchBar } from "../../../components/SearchBar";
import { statusActive, statusBlocked } from "../../helper/status";
import DownloadIcon from "@mui/icons-material/Download";

import styles from "./TabsAddon.module.css";

export const TabsAddons = ({ handleSearch, className, children, search }) => (
  <Stack
    direction="row"
    paddingY={2}
    paddingX={2}
    alignItems="center"
    justifyContent="flex-start"
    className={className}
  >
    <Box sx={{ display: "flex" }}>
      <SearchBar
        id="table-searchbar"
        type="text"
        placeholder="buscar"
        onSearch={handleSearch}
        minWidth="200px"
        search={search}
      />
    </Box>
    {children}
  </Stack>
);

export const TabAddonsList = ({
  handleSearch,
  active,
  onChangeActive,
  blocked,
  onChangeBlocked,
  downloadData,
  headCell,
  nameFile,
  search,
  clearFilters,
  handleDownloadReport,
}) => (
  <TabsAddons
    handleSearch={handleSearch}
    className={styles.tabAddon}
    search={search}
  >
    <Stack sx={{ minWidth: 160, marginLeft: "1rem" }}>
      <FormControl fullWidth size="small">
        <InputLabel id="active-label">Estado</InputLabel>
        <Select
          labelId="active-label"
          size="small"
          label="Estado"
          sx={{ borderRadius: "15px", backgroundColor: "white" }}
          value={active}
          onChange={onChangeActive}
        >
          {statusActive.map((item) => (
            <MenuItem key={item.id} value={item.value}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
    <Stack sx={{ minWidth: 160, marginLeft: "1rem" }}>
      <FormControl fullWidth size="small">
        <InputLabel id="blocked-label">Bloqueado</InputLabel>
        <Select
          labelId="blocked-label"
          size="small"
          label="Bloqueado"
          sx={{ borderRadius: "15px", backgroundColor: "white" }}
          value={blocked}
          onChange={onChangeBlocked}
        >
          {statusBlocked.map((item) => (
            <MenuItem key={item.id} value={item.value}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
    <Stack sx={{ minWidth: 160, marginLeft: "1rem" }}>
      <Button variant="contained" onClick={clearFilters} size="medium">
        Limpiar filtros
      </Button>
    </Stack>
    <Stack className={styles.downLoadBtn}>
      <Box sx={{ paddingLeft: "1rem" }}>
        <Button
          variant="contained"
          startIcon={<DownloadIcon color="inherit" />}
          onClick={handleDownloadReport}
          size="medium"
        >
          Descargar
        </Button>
      </Box>
    </Stack>
  </TabsAddons>
);
