import { Alert, Snackbar } from '@mui/material'
import React from 'react'

export default function AlertSnackBar({
  open,
  handleClose,
  message,
  severity = 'error',
  position = { vertical: 'top', horizontal: 'center' },
}) {
  return (
    <Snackbar
      open={open}
      autoHideDuration={5000}
      onClose={handleClose}
      anchorOrigin={position}
    >
      <Alert
        onClose={handleClose}
        severity={severity}
        sx={{ width: '100%', borderRadius: '15px' }}
        elevation={6}
        variant='filled'
      >
        {message}
      </Alert>
    </Snackbar>
  )
}
