/* eslint-disable no-undef */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Avatar,
  Button,
  IconButton,
  Stack,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import {
  AddCircleOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@mui/icons-material";

import { PaperWhite } from "../../../components/Papers";
import { SearchBar } from "../../../components/SearchBar";
import { Table } from "../../../components/Table";
import { Dialogs } from "../../../components/Dialogs";
import { AlertSnackBar } from "../../../components/Alerts";
import { LoadingSpinner } from "../../../components/LoadingSpinner";

import {
  useDeleteVehicleType,
  useListVehiclesTypes,
} from "../../../services/hooks/Vehicles";

import { DownloadExportData } from "../../../components/Excel/Excel";
import { findVehicleConfig } from "../../../v2/constants/vehicle-config";

const headCells = [
  {
    id: "name",
    label: "Descripción",
    exportExcel: true,
  },
  {
    id: "features",
    label: "Características",
    exportExcel: true,
  },
  {
    id: "actions",
    label: "Acciones",
    disableSorting: true,
    exportExcel: false,
  },
];

const initialAlertError = {
  open: false,
  message: "",
};

export default function TypeVehicles() {
  const navigate = useNavigate();

  const [openDialogDelete, setOpenDialogDelete] = useState(false);
  const [deleteIsLoading, setDeleteIsLoading] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [alertError, setAlertError] = useState(initialAlertError);
  const [formatToExcel, setFormatToExcel] = useState({});
  const [filterFn, setFilterFn] = useState({
    fn: (items) => items,
  });

  const { data: vehiclesTypes, isLoading, error } = useListVehiclesTypes();
  const { mutate } = useDeleteVehicleType();

  useEffect(() => {
    if (Array.isArray(vehiclesTypes) && vehiclesTypes.length) {
      const format = vehiclesTypes.map((item) => {
        const obj = {
          ...item,
          features: item.features.map((feature) => feature.name).join(" | "),
        };

        return obj;
      });

      setFormatToExcel(format);
    }
  }, [vehiclesTypes]);

  const handleCloseAlertError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertError(initialAlertError);
  };

  const handleCloseDialogDelete = () => {
    setOpenDialogDelete(false);
  };

  const handleDelete = async () => {
    setDeleteIsLoading(true);
    mutate(deleteId, {
      onSuccess: () => {
        setOpenDialogDelete(false);
        setDeleteIsLoading(false);
      },
      onError: () => {
        setOpenDialogDelete(false);
        setAlertError({
          open: true,
          message:
            "Oh no! se ha producido un error al deletar el tipo de vehículo",
        });
      },
    });
  };

  const handleSearch = (event) => {
    const { value } = event.target;

    setFilterFn({
      fn: (items) => {
        if (value === "") return items;
        return items.filter(
          (type) =>
            type.name?.toLowerCase().includes(value) ||
            type.features?.some((feature) =>
              feature?.name?.toLowerCase().includes(value)
            )
        );
      },
    });
  };

  const {
    TableContainer,
    TableHead,
    TablePagination,
    recordsAfterPagingAndSorting,
  } = Table(vehiclesTypes, headCells, filterFn);

  if (error) {
    return (
      <PaperWhite
        sx={{
          color: "primary.main",
          height: "100%",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Typography variant="h4">No se encontraron datos!</Typography>
      </PaperWhite>
    );
  }

  if (isLoading) {
    return <LoadingSpinner open />;
  }

  return (
    <Stack spacing={2}>
      <Typography variant="h4" align="center">
        Tipo de vehículos
      </Typography>

      {!error && !isLoading && (
        <PaperWhite sx={{ p: 0 }}>
          <Stack
            direction="row"
            paddingY={2}
            paddingX={2}
            alignItems="center"
            justifyContent="space-between"
          >
            <Box sx={{ display: "flex" }}>
              <SearchBar
                id="table-searchbar"
                type="text"
                placeholder="buscar"
                onChange={handleSearch}
              />

              <DownloadExportData
                data={formatToExcel}
                columns={headCells}
                nameFile="Tipo de vehículos"
              />
            </Box>

            <Button
              variant="contained"
              startIcon={<AddCircleOutlined color="inherit" />}
              onClick={() =>
                navigate("/typeVehicles/create", {
                  state: {
                    fromButton: true,
                  },
                })
              }
            >
              Crear Nuevo
            </Button>
          </Stack>

          <TableContainer>
            <TableHead />
            <TableBody>
              {recordsAfterPagingAndSorting().map(
                ({ id, icon, name, features }) => (
                  <TableRow key={id}>
                    <TableCell>
                      <Stack direction="row" spacing={2} alignItems="center">
                        <Avatar
                          src={findVehicleConfig(icon)?.icon}
                          sx={{
                            backgroundColor: "lightDay",
                            width: 48,
                            height: 48,
                            border: "2px solid #FFFFFF",
                          }}
                        />
                        <Typography>{name}</Typography>
                      </Stack>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {features.map((feature) => feature.name).join(" | ")}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ width: "20%" }}>
                      <Stack direction="row" spacing={2}>
                        <IconButton
                          onClick={() =>
                            navigate(`/typeVehicles/update/${id}`, {
                              state: {
                                fromButton: true,
                              },
                            })
                          }
                        >
                          <EditOutlined />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            setOpenDialogDelete(true);
                            setDeleteId(id);
                          }}
                        >
                          <DeleteOutlined />
                        </IconButton>
                      </Stack>
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </TableContainer>
          <TablePagination />
        </PaperWhite>
      )}

      <Dialogs
        open={openDialogDelete}
        handleClose={handleCloseDialogDelete}
        handleConfirmation={handleDelete}
        isLoading={deleteIsLoading}
        title="¿Estás seguro de que quieres eliminar el tipo de vehículo?"
      />

      <AlertSnackBar
        open={alertError?.open}
        handleClose={handleCloseAlertError}
        message={alertError?.message}
      />
    </Stack>
  );
}
