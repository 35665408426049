import {
  IconButton,
  Stack,
  Typography,
} from '@mui/material'
import {
  DeleteOutlined,
  EditOutlined,
  InfoOutlined,
  LockOpenOutlined,
  LockOutlined,
} from '@mui/icons-material'
import { ProtectedComponent } from '../ProtectedComponent'
import { formatedDate } from '../../utils/formatValues'
import {
  OFFER_READ,
  OFFER_UPDATE,
  OFFER_DEL,
  OFFER_CLOSE,
} from '../../constants/functionalities'
import TMSAccordion from './TMSAccordion'
import { totalVacanciesCounter } from '../../v2/helper/apply'
import { ChipStatus } from '../../v2/components/Chip'

function Header({ offer }) {
  const sx = { opacity: offer.status === 'Closed' ? 0.5 : 1, flexShrink: 0, flexGrow: 1  }
  return (
    <Stack
      direction='row'
      sx={{
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        mr: '1em'
      }}
    >
      <Typography sx={sx}>
        { offer.codeOffer }
      </Typography>
      <Typography sx={sx}>
        { offer.title }
      </Typography>
      <ChipStatus label={offer.status} setcolor={offer.status}/>
    </Stack>
  )
}

function Body({ offer, actions }) {
  const { onInfo, onEdit, onDelete, onBlock } = actions
  const vacancies = offer?.setting?.vacancies ??
    offer.vacancies?.map((v) => (
      {...v, vacancyTotals: {total: Number(v?.total || 0), taken: 0}}
    ));
  return (
    <Stack spacing={1}>
      <Typography>
        <Typography component='span' mr={1}>Fecha Creación:</Typography>
        <Typography component='span'><strong>{ formatedDate(offer.created.at) }</strong></Typography>
      </Typography>
      <Typography>
        <Typography component='span' mr={1}>Ciudad:</Typography>
        <Typography component='span'><strong>{ offer?.setting?.city ?? offer.city }</strong></Typography>
      </Typography>
      <Typography>
        <Typography component='span' mr={1}>Vacantes:</Typography>
        <Typography component='span'><strong>
          {
            totalVacanciesCounter(vacancies, 'total')
          }
        </strong></Typography>
      </Typography>
      <Typography>
        <Typography component='span' mr={1}>Asignadas:</Typography>
        <Typography component='span'><strong>
          {
            totalVacanciesCounter(vacancies, 'taken')
          }
        </strong></Typography>
      </Typography>
      <Stack direction='row' justifyContent='center'>
        <ProtectedComponent functionality={[OFFER_READ]}>
          <IconButton onClick={onInfo(offer)}>
            <InfoOutlined />
          </IconButton>
        </ProtectedComponent>
        <ProtectedComponent functionality={[OFFER_UPDATE]}>
          <IconButton
            disabled={offer.status === 'Closed'}
            onClick={onEdit(offer)}
          >
            <EditOutlined />
          </IconButton>
        </ProtectedComponent>
        <ProtectedComponent functionality={[OFFER_DEL]}>
          <IconButton
            disabled={
              offer.status === 'Closed' ||
              offer.status === 'Published'
            }
            onClick={onDelete(offer)}
          >
            <DeleteOutlined />
          </IconButton>
        </ProtectedComponent>
        <ProtectedComponent functionality={[OFFER_CLOSE]}>
          <IconButton
            onClick={onBlock(offer)}
            disabled={
              offer.status === 'Closed' ||
              offer.status === 'Draft'
            }
          >
            {offer.status === 'Closed' ? (
              <LockOutlined color='primary' />
            ) : (
              <LockOpenOutlined />
            )}
          </IconButton>
        </ProtectedComponent>
      </Stack>
    </Stack>
  )
}

export function OffersAccordion({ breakPoint, offers, actions }) {
  return (
    <TMSAccordion
      breakPoint={breakPoint}
      data={offers}
      renderHeader={(offer) => <Header offer={offer}/>}
      renderBody={(offer) => <Body offer={offer} actions={actions}/>}
    />
  )
}
