import { useMutation, useQueryClient } from 'react-query'
import { useApi } from '../../../api'
import { VEHICLE_URI } from '../../../../constants/api'

export function useDeleteVehicleFeature() {
  const { axiosInstance } = useApi()
  const queryClient = useQueryClient()

  return useMutation((id) => axiosInstance.delete(`/features/${id}`, {
    baseURL: VEHICLE_URI,
  }), {
    onSuccess: () => queryClient.invalidateQueries('listVehiclesFeatures'),
  })
}
