import { useMutation } from 'react-query'
import { useApi } from '../../api'
import { APPLY_URI } from '../../../constants/api'

export function useCreateApply() {
    const { axiosInstance } = useApi();

    return useMutation((apply) => axiosInstance.post('/', apply, {
        baseURL: APPLY_URI,
    }));
}
