import { useMutation, useQueryClient } from 'react-query'
import { useApi } from '../../api'
import { OFFER_URI } from '../../../constants/api'

export function useDeleteOffer() {
  const { axiosInstance } = useApi()
  const queryClient = useQueryClient()

  return useMutation((id) => axiosInstance.delete(`/${id}`, {
    baseURL: OFFER_URI,
  }), {
    onSuccess: () => {
      queryClient.invalidateQueries('listOffers')
    },
  })
}
