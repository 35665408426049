import { memo, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Divider, Stack, Button, Grid } from "@mui/material"
import { EmployeeSection } from "./ProviderSection"
import { CompanySection } from "./CompanySection"
import { DocumentsSection } from "../Documents/DocumentSection"
import { ArrowBackOutlined } from "@mui/icons-material"
import { AddressSectionProvider } from "./AddressSectionProvider"
import { useGetTemplateByName } from "../../../services/hooks/Docs/Templates/useGetTemplates"
import { LoadingSpinner } from "../../LoadingSpinner"
import { templateNames } from "../../../constants/templateNames"

export const FormCreate = memo(({formValues, onSubmit, goBack, setTemplate, isEditing}) => {
  const {
    control,
    handleSubmit,
    getValues,
    setValue,
  } = useForm({formValues});
  const [currentValues, setCurrentValues] = useState(formValues);
  const handleChange = () => {
    setCurrentValues((prev) => ({...prev, ...getValues()}));
  }
  const { data, isLoading } = useGetTemplateByName(templateNames.provider)

  useEffect(() => {
    if (data) {
      setTemplate(data);
    }
  }, [data]);

  useEffect(() => {
    Object.keys(currentValues).forEach((key) => {
      setValue(key, currentValues[key]);
    })
  },[]);

  const completeAddress = ({ geoReference, street, number, city, commune, zipCode, region }, results, coordinates) => {
    if (coordinates) {
      setValue(geoReference, coordinates)
    }
    results[0]?.address_components.forEach((item) => {
      if (street && item.types.includes('route')) {
        setValue(street, item.long_name)
      }
      if (number && item.types.includes('street_number')) {
        setValue(number, item.long_name)
      }
      if (city && item.types.includes('administrative_area_level_2')) {
        setValue(city, item.long_name)
      }
      if (commune && (item.types.includes('sublocality') || item.types.includes('locality'))) {
        setValue(commune, item.long_name)
      }
      if (zipCode && item.types.includes('postal_code')) {
        setValue(zipCode, item.long_name)
      }
      if (region && item.types.includes('administrative_area_level_1')) {
        setValue(region, item.long_name)
      }
    })
  }

  const completeAddressProvider = (values) => {
    const { results, coordinates } = values
    completeAddress({
      geoReference: 'geoReferenceProvider',
      street: 'streetProvider',
      number: 'numberProvider',
      city: 'cityProvider',
      commune: 'communeProvider',
      zipCode: 'zipCodeProvider',
      region: 'regionProvider',
    }, results, coordinates)
  }

  if (isLoading ) return <LoadingSpinner open />

  return (
    <Stack
      spacing={2}
      component='form'
      onChange={handleChange}
      onSubmit={handleSubmit(onSubmit)}
    >
      {!isEditing && 
        <>
          <EmployeeSection control={control} getValue={getValues} isEditing={isEditing}/>
          <Divider sx={{ pt: 1, pb: 2 }} />
          <AddressSectionProvider control={control} completeDirection={completeAddressProvider} title={'Dirección del proveedor'} isEditing={isEditing}/>
          <Divider sx={{ pt: 1, pb: 2 }} />
        </>
      }
      <CompanySection control={control} getValue={getValues} isEditing={isEditing}/>
      {!isEditing && 
        <>
          <Divider sx={{ pt: 1, pb: 2 }} />
          <DocumentsSection control={control} documentStructure={data.documentTypes} />
        </>
      }
      <Grid container sx={{ pt: 2}} >
        <Grid item xs={5}>
          { goBack &&
            <Button
              variant='goBack'
              disableRipple
              startIcon={<ArrowBackOutlined />}
              onClick={goBack}
              sx={{ height: 40, fontSize: 12, alignSelf: 'self-start' }}
            >
              { "Volver atrás" }
            </Button>
          }
        </Grid>
        <Grid item xs={7}>
          <Grid container justifyContent="flex-end">
            <Button
              disabled={false}
              color='secondary'
              type='submit'
              sx={{ minWidth: '155px', height: 40 }}
            >
              { "Guardar" }
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Stack>
  )
})