import {
  Typography,
  Stack,
  FormControlLabel,
  Box,
  Button,
  Grid,
} from "@mui/material";
import { ArrowBackOutlined } from "@mui/icons-material";
import { Input } from "../../../../components/Inputs";
import { Controller } from "react-hook-form";
import Switch from "@mui/material/Switch";
import { PaperWhite } from "../../../../components/Papers";
import { useForm } from "react-hook-form";
import {
  PROVIDER_ASSETS_TYPE,
  ENTITY_TYPES_MAPPING,
} from "../../../../constants/entityTypes";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { CalendarMonthOutlined } from "@mui/icons-material";
import { es } from "date-fns/locale";
import { useAtom } from "jotai";
import { replaceDataAtom } from "../../../store/requestTracker/replaceRequest.store";



const formTitle = (type) =>
  type === PROVIDER_ASSETS_TYPE.FLEET
    ? ENTITY_TYPES_MAPPING.fleet
    : type === PROVIDER_ASSETS_TYPE.COURIER
    ? ENTITY_TYPES_MAPPING.courier
    : type === PROVIDER_ASSETS_TYPE.PEONETA
    ? ENTITY_TYPES_MAPPING.peoneta
    : null;

const MINDATE = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);

export const ProviderReplaceForm = ({ onSubmit, goBack, type }) => {
  const [replaceData, setReplaceData] = useAtom(replaceDataAtom);
  const { control, handleSubmit, formState } = useForm({
    defaultValue:replaceData,
    mode: "onChange",
  });
  const title = formTitle(type);

  const updateFormField = (field, onChange) => (event) => {
    let value;
    
    if(field === "isPermanent"){
      value = event.target.checked;
    }
    if(field === "fromDate" || field === "toDate"){
      value = event;
    }
    if(field === "observations" || field === "replaceReason"){
      value = event.target.value;
    }

    setReplaceData((prevState) => ({ ...prevState, [field]: value }));
    onChange && onChange(event)
  }

  return (
    <PaperWhite sx={{ p: 3, mt: 5 }}>
      <Stack spacing={2} component="form" onSubmit={handleSubmit(onSubmit)}>
        <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={6}>
          <Box gridColumn={{ xs: "span 12", lg: "span 12" }}>
            <Typography variant="h6" sx={{ fontWeight: 700 }}>
              Datos del {title} de reemplazo
            </Typography>
            <FormControlLabel
              control={
                <Switch
                  color="secondary"
                  checked={replaceData.isPermanent}
                  onChange={updateFormField("isPermanent")}
                />
              }
              label="Reemplazo Permanente"
            />
          </Box>
          <Box gridColumn={{ xs: "span 12", lg: "span 4" }}>
            <Controller
              control={control}
              name="replaceReason"
              defaultValue={replaceData.replaceReason}
              rules={{
                required: "(*) Campo obligatorio",
              }}
              render={({
                field: { value, onChange, ...rest },
                fieldState: { error },
              }) => (
                <>
                  <Input
                    value={value}
                    label="Motivo del reemplazo"
                    onChange={updateFormField("replaceReason", onChange)}
                    isRequired
                    error={Boolean(error)}
                    helperText={error?.message}
                    {...rest}
                  />
                </>
              )}
            />
          </Box>
          <Box gridColumn={{ xs: "span 12", lg: "span 4" }}>
            <Controller
              control={control}
              name="from_date"
              rules={{ required: "(*) Campo obligatorio" }}
              defaultValue={replaceData.fromDate}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <Stack position="relative">
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    adapterLocale={es}
                  >
                    <span>
                      {"Inicio del reemplazo"}
                      {<span style={{ color: "red" }}>*</span>}
                    </span>
                    <DesktopDatePicker
                      variant="outlined"
                      value={value || ""}
                      onChange={updateFormField("fromDate", onChange)}
                      minDate={MINDATE}
                      popperProps={{
                        placement: "bottom-end",
                      }}
                      components={{
                        OpenPickerIcon: CalendarMonthOutlined,
                      }}
                      renderInput={(params) => (
                        <Input
                          {...params}
                          error={Boolean(error)}
                          helperText={error?.message}
                          {...params.inputProps}
                          ref={params.inputRef}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Stack>
              )}
            />
          </Box>
          {!replaceData.isPermanent && (
            <Box gridColumn={{ xs: "span 12", lg: "span 4" }}>
              <Controller
                control={control}
                name="to_date"
                rules={{ required: "(*) Campo obligatorio" }}
                defaultValue={replaceData.toDate}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <Stack position="relative">
                    <LocalizationProvider
                      dateAdapter={AdapterDateFns}
                      adapterLocale={es}
                    >
                      <span>
                        {"Fin del reemplazo"}
                        {<span style={{ color: "red" }}>*</span>}
                      </span>
                      <DesktopDatePicker
                        variant="outlined"
                        value={value || ""}
                        onChange={updateFormField("toDate", onChange)}
                        minDate={replaceData.fromDate ? replaceData.fromDate : MINDATE}
                        popperProps={{
                          placement: "bottom-end",
                        }}
                        components={{
                          OpenPickerIcon: CalendarMonthOutlined,
                        }}
                        renderInput={(params) => (
                          <Input
                            {...params}
                            error={Boolean(error)}
                            helperText={error?.message}
                            {...params.inputProps}
                            ref={params.inputRef}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Stack>
                )}
              />
            </Box>
          )}
        </Box>
        <Box gridColumn={{ xs: "span 12", lg: "span 12" }}>
          <Controller
            control={control}
            name="observations"
            defaultValue={replaceData.observations}
            rules={{
              required: "(*) Campo obligatorio",
            }}
            render={({
              field: { value, onChange, ...rest },
              fieldState: { error },
            }) => (
              <>
                <Input
                  value={value}
                  label="Observaciones"
                  onChange={updateFormField("observations", onChange)}
                  isRequired
                  error={Boolean(error)}
                  helperText={error?.message}
                  {...rest}
                />
              </>
            )}
          />
        </Box>
        <Grid container sx={{ pt: 2 }}>
          <Grid item xs={5}>
            {goBack && (
              <Button
                variant="goBack"
                disableRipple
                startIcon={<ArrowBackOutlined />}
                onClick={goBack}
                sx={{ height: 40, fontSize: 12, alignSelf: "self-start" }}
              >
                Volver atrás
              </Button>
            )}
          </Grid>
          <Grid item xs={7}>
            <Grid container justifyContent="flex-end">
              <Button
                disabled={!formState.isValid}
                color="secondary"
                type="submit"
                sx={{ minWidth: "155px", height: 40 }}
              >
                Siguiente
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Stack>
    </PaperWhite>
  );
};
