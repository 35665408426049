import { useEffect, useState } from "react";
import {
  Table as MuiTable,
  TableHead as MuiTableHead,
  TableRow as MuiTableRow,
  TableCell as MuiTableCell,
  TablePagination as MuiTablePagination,
  TableSortLabel as MuiTableSortLabel,
  Box,
  Stack,
} from "@mui/material";
import { LoadingSpinner } from "../../../components/LoadingSpinner";
import { PaperWhite } from "../../../components/Papers";

export const Table = (
  headCells = [],
  metadata = {},
  onChangeFilters = (newFilter) => newFilter
) => {
  const pages = [10, 20, 30];
  const [page, setPage] = useState(metadata?.page - 1 || 0);
  const [rowsPerPage, setRowsPerPage] = useState(metadata?.limit || 10);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("");
  const [loading, setTableLoading] = useState(false);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
    onChangeFilters({ page: newPage + 1, limit: rowsPerPage });
  };

  const handleChangeRowsPerPage = (event) => {
    onChangeFilters({ page: 1, limit: parseInt(event.target.value, 10) });
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const initialConfig = () => {
    let filters = {};
    const hasDefaultSort = headCells.find(
      (cell) => cell.hasOwnProperty("sort") && cell.sort.default
    );
    if (hasDefaultSort) {
      setOrder(hasDefaultSort.id);
      filters.sortBy = hasDefaultSort.sort.id;
      filters.orderBy = hasDefaultSort.sort.order;
    }
    onChangeFilters(filters);
  };

  useEffect(() => {
    initialConfig();
  }, []);

  useEffect(() => {
    setPage(metadata.page - 1 || 0);
    setRowsPerPage(metadata.page_size || 10);
  }, [metadata]);

  const TableContainer = ({ children, addons, withPagination }) => {
    return (
      <PaperWhite sx={{ p: 0, mt: 5 }}>
        {Array.isArray(addons) && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              flex: 1,
              overflowX: "auto",
            }}
          >
            {addons.map((Addon, index) => (
              <Addon key={index} />
            ))}
          </Box>
        )}

        <Box sx={{ overflowX: "auto" }}>
          <MuiTable>
            <TableHead />
            {children}
          </MuiTable>
        </Box>

        {withPagination && <TablePagination />}
      </PaperWhite>
    );
  };

  const paginationData = { page, rowsPerPage };

  const TableHead = () => {
    const handleSortRequest = (cellId) => {
      const newOrder = orderBy === cellId && order === "asc" ? "desc" : "asc";
      setOrder(newOrder);
      setOrderBy(cellId);
      onChangeFilters({ sortBy: cellId, orderBy: newOrder });
    };

    return (
      <MuiTableHead>
        <MuiTableRow>
          {headCells.map((headCell) => (
            <MuiTableCell
              key={headCell.id}
              sx={{ fontWeight: "700" }}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              {headCell.disableSorting ? (
                headCell.label
              ) : (
                <MuiTableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={() => {
                    handleSortRequest(headCell.sort?.id || headCell.id);
                  }}
                >
                  {headCell.label}
                </MuiTableSortLabel>
              )}
            </MuiTableCell>
          ))}
        </MuiTableRow>
      </MuiTableHead>
    );
  };

  const TablePagination = () => (
    <>
      {loading && <LoadingSpinner open />}
      <Stack direction="row" flexWrap="wrap">
        <Box sx={{ p: 2, "& span": { fontWeight: "700" } }}>
          Total de Registros:{" "}
          <span>
            {" "}
            {metadata?.total_count
              ? String(metadata?.total_count).padStart(2, "0")
              : "0"}
          </span>
        </Box>
        <MuiTablePagination
          rowsPerPageOptions={pages}
          component="div"
          count={metadata.total_count || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={<span>Filas por página:</span>}
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} de ${count}`
          }
          showFirstButton
          showLastButton
          backIconButtonProps={{ color: "secondary" }}
          nextIconButtonProps={{ color: "secondary" }}
          sx={{
            "&": {
              flexGrow: 1,
            },
            ".MuiTablePagination-toolbar": {
              width: "100%",
              flexWrap: "wrap",
              justifyContent: "center",
              "@media (min-width: 930px)": {
                justifyContent: "flex-end",
              },
            },
          }}
        />
      </Stack>
    </>
  );

  return {
    TableContainer,
    TableHead,
    TablePagination,
    setPage,
    setTableLoading,
    paginationData,
  };
};
