import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Stack, Typography } from "@mui/material";
import { ArrowBackOutlined } from "@mui/icons-material";
import { Controller, useForm } from "react-hook-form";

import { PaperWhite } from "../../../components/Papers";
import { Input } from "../../../components/Inputs";
import { AlertSnackBar } from "../../../components/Alerts";
import { LoadingSpinner } from "../../../components/LoadingSpinner";
import { ComboBox } from "../../../components/ComboBox";

import {
  useCreateTemplates,
  useGetTemplates,
  useUpdateTemplates,
} from "../../../services/hooks/Docs";

import {
  isComplexTextNumberValid,
  validateMinLength,
} from "../../../utils/validateValues";
import { useListTypes } from "../../../services/hooks/Docs/Types/useListTypes";

const initialAlertError = {
  open: false,
  message: "",
};

const defaultValues = {
  name: "",
  documentTypes: [],
};

export default function FormTemplate({ fromButton }) {
  const navigate = useNavigate();
  const { templateId } = useParams();
  const [alertError, setAlertError] = useState(initialAlertError);
  const { control, handleSubmit, setValue } = useForm({ defaultValues });
  const { data: { data: listDocs } = {}, isLoading } = useListTypes({
    page: 1,
    limit: 1000,
  });
  const { data: template, isLoading: isLoadingGetType } =
    useGetTemplates(templateId);
  const { mutate: mutateCreate, isLoading: isLoadingCreate } =
    useCreateTemplates();
  const { mutate: mutateUpdate, isLoading: isLoadingUpdate } =
    useUpdateTemplates(templateId);

  useEffect(() => {
    if (!fromButton) navigate("/404");
  }, []);

  useEffect(() => {
    if (template) {
      setValue("name", template.name);
      setValue("documentTypes", template.documentTypes);
    }
  }, [template]);

  const handleCloseAlertError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertError(initialAlertError);
  };

  const renderOptionLabel = (option) => {
    if (Object.entries(option).length === 0) return "";

    return `${option?.label}`;
  };

  const onSubmit = (data) => {
    const { name, documentTypes } = data;

    const formatedDocs = documentTypes.map(({ id }) => id);

    const newData = {
      name: name,
      documentTypeIds: formatedDocs,
    };

    if (templateId) {
      mutateUpdate(newData, {
        onSuccess: () => navigate("/Templates"),
        onError: () =>
          setAlertError({
            open: true,
            message: "Oh no! se ha producido un error al editar la plantilla",
          }),
      });
    } else {
      mutateCreate(newData, {
        onSuccess: () => navigate("/Templates"),
        onError: () =>
          setAlertError({
            open: true,
            message:
              "Oh no! se ha producido un error al crear un nueva plantilla",
          }),
      });
    }
  };

  if (isLoadingGetType || isLoadingCreate || isLoadingUpdate) {
    return <LoadingSpinner open />;
  }

  return (
    <Stack spacing={2} component="form" onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h4" align="center">
        {templateId
          ? "Editando la plantilla de documento"
          : "Creando un nueva plantilla de documento"}
      </Typography>

      <PaperWhite sx={{ pb: 20 }}>
        <Stack spacing={2} sx={{ width: "50%" }}>
          <Controller
            control={control}
            name="name"
            rules={{
              required: "(*) Campo obligatorio",
              validate: {
                minValid: (text) =>
                  validateMinLength(text, 1) ||
                  "Nombre no puede ser menor a 1 caracteres",
                textIsValid: (text) =>
                  isComplexTextNumberValid(text) ||
                  "Nombre inválido, no se aceptan caracteres extraños",
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <Input
                label="Nombre"
                isRequired
                error={Boolean(error)}
                helperText={error?.message}
                inputProps={{ maxLength: 50 }}
                {...field}
              />
            )}
          />

          <Controller
            control={control}
            name="documentTypes"
            rules={{ required: "(*) Campo obligatorio" }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <ComboBox
                label="Documentos"
                options={listDocs || []}
                value={value}
                optionLabel="label"
                renderOptionLabel={renderOptionLabel}
                multiple
                onChange={onChange}
                error={error}
                loading={isLoading}
              />
            )}
          />
        </Stack>
      </PaperWhite>

      <Stack spacing={2} direction="row" justifyContent="flex-end" paddingY={2}>
        <Button
          variant="goBack"
          disableRipple
          startIcon={<ArrowBackOutlined />}
          onClick={() => navigate("/Templates")}
        >
          Volver atrás
        </Button>
        <Button size="large" type="submit">
          {templateId ? "Editar" : "Guardar"}
        </Button>
      </Stack>

      <AlertSnackBar
        open={alertError?.open}
        handleClose={handleCloseAlertError}
        message={alertError?.message}
      />
    </Stack>
  );
}
