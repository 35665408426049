import { useState, forwardRef } from 'react'
import { Button } from '@mui/material'
import { DateRangePicker as Calendar, LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import { DateRangeOutlined, ArrowDownwardOutlined } from '@mui/icons-material'
import { es } from 'date-fns/locale'

export function DateRange({ value, onChange, error, disabled }, ref) {
  const [calendarOpen, setCalendarOpen] = useState(false)

  const handleOpenCalendar = () => setCalendarOpen(true)
  const handleCloseCalendar = () => setCalendarOpen(false)

  const dateFrom = value.from ? new Date(value.from) : new Date();
  const dateTo = value.to ? new Date(value.to) : new Date();

  if(!value.to) dateTo.setDate(dateFrom.getDate()+ 1);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
      <Calendar
        inputRef={ref}
        value={[dateFrom, dateTo]}
        onChange={(event) => onChange({ from: event[0], to: event[1] })}
        onClose={handleCloseCalendar}
        open={calendarOpen}
        disablePast
        showToolbar
        toolbarTitle={false}
        renderInput={() => (
          <Button
            fullWidth
            startIcon={<DateRangeOutlined />}
            endIcon={<ArrowDownwardOutlined />}
            onClick={handleOpenCalendar}
            color={error ? 'error' : 'inherit'}
            variant='outlined'
            disabled={disabled}
            sx={{
              borderRadius: '15px',
              textTransform: 'capitalize',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            Elija los días para mostrar ofertas
          </Button>
        )}
      />
    </LocalizationProvider>
  )
}

const DateRangePicker = forwardRef(DateRange)

export default DateRangePicker
