import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useCallback, useEffect, useRef, useState } from "react";
import {
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  REDO_COMMAND,
  UNDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  FORMAT_TEXT_COMMAND,
  FORMAT_ELEMENT_COMMAND,
  $getSelection,
  $isRangeSelection,
} from "lexical";
import { $isLinkNode, TOGGLE_LINK_COMMAND } from "@lexical/link";
import { $getNearestNodeOfType, mergeRegister } from "@lexical/utils";
import {
  $isListNode,
  ListNode
} from "@lexical/list";
import { createPortal } from "react-dom";
import {
  $isHeadingNode
} from "@lexical/rich-text";


import styles from './toolbar.module.css';
import { BlockOptionsDropdownList } from "./TextOptionDropdown/TextOptionDropdown";
import { FloatingLinkEditor } from "./FloatingLinkEditor";
import { getSelectedNode } from "../../helpers/getSelectedNode";

export const LowPriority = 1;

export function Divider({ position }) {
  const classname = position === 'vertical' ? styles.dividerVertical : styles.dividerHorizontal;
  return <div className={classname} />;
}

export default function ToolbarPlugin() {
  const [editor] = useLexicalComposerContext();
  const toolbarRef = useRef(null);
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  const [blockType, setBlockType] = useState("paragraph");
  const [isLink, setIsLink] = useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isStrikethrough, setIsStrikethrough] = useState(false);

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element =
        anchorNode.getKey() === "root"
          ? anchorNode
          : anchorNode.getTopLevelElementOrThrow();
      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);
      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode);
          const type = parentList ? parentList.getTag() : element.getTag();
          setBlockType(type);
        } else {
          const type = $isHeadingNode(element)
            ? element.getTag()
            : element.getType();
          type !== 'root' && setBlockType(type);
        }
      }

      // Update text format
      setIsBold(selection.hasFormat("bold"));
      setIsItalic(selection.hasFormat("italic"));
      setIsUnderline(selection.hasFormat("underline"));
      setIsStrikethrough(selection.hasFormat("strikethrough"));

      // Update links
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      setIsLink($isLinkNode(parent) || $isLinkNode(node))
    }
  }, [editor]);
  
  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(updateToolbar);
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        (_payload, newEditor) => {
          updateToolbar();
          return false;
        },
        LowPriority
      ),
      editor.registerCommand(
        CAN_UNDO_COMMAND,
        (payload) => {
          setCanUndo(payload);
          return false;
        },
        LowPriority
      ),
      editor.registerCommand(
        CAN_REDO_COMMAND,
        (payload) => {
          setCanRedo(payload);
          return false;
        },
        LowPriority
      )
    );
  }, [editor, updateToolbar]);

  const insertLink = useCallback((event) => {
    event.preventDefault();

    if (!isLink) {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, "https://");
    } else {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    }
  }, [editor, isLink]);

  const selectTextFormat = (event, command, action) => {
    event.preventDefault();
    editor.dispatchCommand(command, action);
    editor.focus();
  }

  return (
    <>
      <div className={styles.toolbar} ref={toolbarRef}>
        <button
          disabled={!canUndo}
          onClick={(event) => {
            event.preventDefault();
            editor.dispatchCommand(UNDO_COMMAND);
          }}
          className={`${styles.toolbarItem} ${styles.spaced}`}
          aria-label="Undo"
        >
          <i className={`${styles.format} ${styles.undo}`} style={!canUndo ? { opacity: 0.4, cursor: 'not-allowed' } : {}} />
        </button>
        <button
          disabled={!canRedo}
          onClick={(event) => {
            event.preventDefault();
            editor.dispatchCommand(REDO_COMMAND);
          }}
          className={styles.toolbarItem}
          aria-label="Redo"
        >
          <i className={`${styles.format} ${styles.redo}`} style={!canRedo ? { opacity: 0.4, cursor: 'not-allowed' } : {}} />
        </button>
        <Divider position="vertical" />

        <BlockOptionsDropdownList
          editor={editor}
          blockType={blockType}
          toolbarRef={toolbarRef}
        />
        <button
          onClick={(event) => selectTextFormat(event, FORMAT_TEXT_COMMAND, "bold")}
          className={`${styles.toolbarItem} ${styles.spaced} ${isBold ? styles.active : ""}`}
          aria-label="Format Bold"
          title="Negrita"
        >
          <i className={`${styles.format} ${styles.bold}`} />
        </button>
        <button
          onClick={(event) => selectTextFormat(event, FORMAT_TEXT_COMMAND, "italic")}
          className={`${styles.toolbarItem} ${styles.spaced} ${isItalic ? styles.active : ""}`}
          aria-label="Format Italics"
          title="Cursiva"
        >
          <i className={`${styles.format} ${styles.italic}`} />
        </button>
        <button
          onClick={(event) => selectTextFormat(event, FORMAT_TEXT_COMMAND, "underline")}
          className={`${styles.toolbarItem} ${styles.spaced} ${isUnderline ? styles.active : ""}`}
          aria-label="Format Underline"
          title="Subrayado"
        >
          <i className={`${styles.format} ${styles.underline}`} />
        </button>
        <button
          onClick={(event) => selectTextFormat(event, FORMAT_TEXT_COMMAND, "strikethrough")}
          className={`${styles.toolbarItem} ${styles.spaced} ${isStrikethrough ? styles.active : ""}`}
          aria-label="Format Strikethrough"
          title="Tachado"
        >
          <i className={`${styles.format} ${styles.strikethrough}`} />
        </button>
        <button
          onClick={(event) => insertLink(event)}
          className={`${styles.toolbarItem} ${styles.spaced} ${isLink ? styles.active : ""}`}
          aria-label="Insert Link"
          title="Hipervínculo"
        >
          <i className={`${styles.format} ${styles.link}`} />
        </button>
        {isLink && createPortal(<FloatingLinkEditor editor={editor} />, document.body)}
        <Divider position="vertical" />
        <button
          onClick={(event) => selectTextFormat(event, FORMAT_ELEMENT_COMMAND, "left")}
          className={`${styles.toolbarItem} ${styles.spaced}`}
          aria-label="Left Align"
        >
          <i className={`${styles.format} ${styles.leftAlign}`} />
        </button>
        <button
          onClick={(event) => selectTextFormat(event, FORMAT_ELEMENT_COMMAND, "center")}
          className={`${styles.toolbarItem} ${styles.spaced}`}
          aria-label="Center Align"
        >
          <i className={`${styles.format} ${styles.centerAlign}`} />
        </button>
        <button
          onClick={(event) => selectTextFormat(event, FORMAT_ELEMENT_COMMAND, "right")}
          className={`${styles.toolbarItem} ${styles.spaced}`}
          aria-label="Right Align"
        >
          <i className={`${styles.format} ${styles.rightAlign}`} />
        </button>
        <button
          onClick={(event) => selectTextFormat(event, FORMAT_ELEMENT_COMMAND, "justify")}
          className={`${styles.toolbarItem}`}
          aria-label="Justify Align"
        >
          <i className={`${styles.format} ${styles.justifyAlign}`} />
        </button>
      </div >
      <Divider position="horizontal" />
    </>
  );
}
