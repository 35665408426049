import { useMutation, useQueryClient } from 'react-query'
import { useApi } from '../../../api'
import { VEHICLE_URI } from '../../../../constants/api'

export function useUpdateVehicleType(id) {
  const { axiosInstance } = useApi()
  const queryClient = useQueryClient()

  return useMutation(
    (newType) =>
      axiosInstance.put(`/type/${id}`, newType, {
        baseURL: VEHICLE_URI,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('listVehiclesTypes')
        queryClient.invalidateQueries(['vehicleType', id])
      },
    }
  )
}
