/* eslint-disable react/no-array-index-key */
import { useState } from 'react'
import { SearchOutlined } from '@mui/icons-material'
import { InputAdornment } from '@mui/material'
import GooglePlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-google-places-autocomplete';

const DropdownIndicator = () => (
  <InputAdornment disabled sx={{paddingRight: '13px'}} position={"start"}>
    <SearchOutlined />
  </InputAdornment>
);

const NoOptionsMessage = () => (
  <center>
    <span style={{ color: 'grey' }}>Ingrese su dirección</span>
  </center>
);

export default function SearchAddress({ onSelected, ...rest }) {
  const [openMenu, setOpenMenu] = useState(false)
  const [address, setAddress] = useState('')
  const [coordinates, setCoordinates] = useState({
    lat: null,
    lng: null
  })

  const handleSelect = async (value) => {
    if(value){
      try {
        if (openMenu) {
          setOpenMenu(false)
        }
        
        const results = await geocodeByAddress(value.label)
        const latLng = await getLatLng(results[0])
        setAddress(value.label)
        setCoordinates(latLng)
        onSelected({ results, coordinates })
      } catch (error) {
        console.log(error)
      }
    }
  }

  const onKeyDown = () => {
    if (!openMenu) {
      setOpenMenu(true)
    }
  };

  const onBlur = () => {
    if (openMenu) {
      setOpenMenu(false)
    }
  };

  return (
    <div>
      <GooglePlacesAutocomplete
        {...rest}
        selectProps={{
          address,
          onKeyDown,
          onChange: handleSelect,
          onBlur,
          isClearable: true,
          placeholder: 'Ingrese su dirección',
          menuIsOpen: openMenu,
          components: { DropdownIndicator, NoOptionsMessage, IndicatorSeparator:() => null } 
        }}
        autocompletionRequest= {{componentRestrictions: { country: 'cl' }}}
        apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
      />
    </div>
  )
}
