import { useQuery } from "react-query";
import { useApi } from "../../../api";
import { DOCUMENT_TYPE_URI } from "../../../../constants/api";

export async function getDocuments(id) {
  if (id) {
    try {
      const { axiosInstance } = useApi();

      const response = await axiosInstance.get(`/${id}`, {
        baseURL: DOCUMENT_TYPE_URI,
      });

      const { data } = response.data;

      return data;
    } catch (e) {
      return null;
    }
  } else {
    return null;
  }
}

export function useGetDocuments(id) {
  return useQuery(["document", id], () => getDocuments(id), {
    staleTime: 1000 * 60 * 60 * 24, // 24 horas para não refazer a requisição ao receber foco na tela
  });
}
