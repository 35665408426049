import { useQuery } from "react-query";
import { useApi } from "../../../api";
import { DOCUMENT_TEMPLATE_URI } from "../../../../constants/api";
import { templateNames } from "../../../../constants/templateNames";

export async function getTemplates(id) {
  const { axiosInstance } = useApi();

  if (id){
    const response = await axiosInstance.get(`/${id}`, {
      baseURL: DOCUMENT_TEMPLATE_URI,
    });

    const { data } = response;

    return data;
  }
  return null
}

async function getTemplateByName(name) {
  const { axiosInstance } = useApi();

  if (name){
    const response = await axiosInstance.get(`/by-name/${name}`, {
      baseURL: DOCUMENT_TEMPLATE_URI,
    });

    const { data } = response;

    return data;
  }
  return null
}

async function getTemplate(value) {
  if(!value) return null;
  const staticTemplateFound = Object.entries(templateNames).some(([_,templateName]) => templateName === value);

  const getTemplateFn = staticTemplateFound ? getTemplateByName : getTemplates;

  return await getTemplateFn(value);
}

export function useGetTemplates(id) {
  return useQuery(["templates", id], () => getTemplates(id), {
    staleTime: 1000 * 60 * 60 * 24,
  });
}

export function useGetTemplateByName(name) {
  return useQuery(["template", name], () => getTemplateByName(name), {
    staleTime: 1000 * 60 * 60 * 24,
  })
}

export function useGetTemplate(value) {
  return useQuery(["selectedTemplate", value], () => getTemplate(value), {
    staleTime: 0
  });
}
