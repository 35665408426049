export function renameFile(file, newName) {
  const blob = new Blob([file], { type: file.type });
  const newFile = new File([blob], newName, { type: file.type });
  return newFile;
}

export function urlDownload(fileUrl) {
  const link = document.createElement('a');
  link.href = fileUrl;
  document.body.appendChild(link);
  link.click();
}
