import { useQuery } from 'react-query'
import { useApi } from '../../api'
import { APPLY_URI } from '../../../constants/api'

export async function getApplyById(applyId, providerId) {
  if(!applyId || !providerId) return null;
  const { axiosInstance } = useApi()
  const response = await axiosInstance.get(`/${applyId}/provider/${providerId}`, {
    baseURL: APPLY_URI,
  })
  const { data } = response
  return data
}

export function useGetApplyById(applyId, providerId) {
  return useQuery(['applyById', applyId, providerId], () => getApplyById(applyId, providerId), {
    staleTime: 1000 * 60 * 5,
    retry: 0,
  })
}
