import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material'
import React from 'react'

export function Dialogs({
  title,
  content,
  open,
  handleClose,
  handleConfirmation,
  isLoading,
  onClose,
  buttonConfirmationTitle,
  buttonCancelTitle,
  icon,
}) {
  return (
    <Dialog
      open={open}
      onClose={onClose || handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      sx={{ '& .MuiPaper-root': { px: 2, py: 1, borderRadius: '16px' } }}
    >
      <Stack sx={{  alignItems:'center'}}>
        {icon && 
        <Box
          component="img"
          alt="icon"
          src={icon}
          sx={{
            display: { xs: "none", lg: "block" },
            width: "75px",
            height: "75px",
          }}
        />
        }
        <DialogTitle id='alert-dialog-title'>
          <Typography fontFamily={'Montserrat'} fontWeight={"700"} fontSize={"16px"} lineHeight={"19.5px"} color="#000000" variant="h6" align='center'>
            {title}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
          <Typography fontFamily={'Lato'} fontWeight={"400"} fontSize={"14px"} lineHeight={"18.9px"} color="#000000" variant="body1">
            {content}
          </Typography>
          </DialogContentText>
        </DialogContent>
      </Stack>
      <DialogActions sx={{display:'flex', justifyContent:'space-around'}}>
        <Button
          onClick={handleClose}
          disabled={isLoading}
          color='secondary'
          variant='outlined'
          sx={{
            borderRadius: '16px',
            fontWeight: 700,
            textTransform: 'capitalize',
            width: 100,
            height: 40,
          }}
          disableElevation
          startIcon={
            isLoading ? <CircularProgress color='inherit' size={20} /> : null
          }
        >
          {buttonCancelTitle || 'No'}
        </Button>
        <Button
          disabled={isLoading}
          onClick={handleConfirmation}
          autoFocus
          color='secondary'
          variant='contained'
          sx={{
            color: 'white',
            borderRadius: '16px',
            fontWeight: 700,
            textTransform: 'capitalize',
            width: 100,
            height: 40,
          }}
          disableElevation
          startIcon={
            isLoading ? <CircularProgress color='inherit' size={20} /> : null
          }
        >
          {buttonConfirmationTitle || 'Si'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
