import { useMutation, useQueryClient } from "react-query";
import { useApi } from "../../../../services/api";
import { APPLY_URI } from "../../../../constants/api";
import { CommonStatus } from "../../../../constants/status";

export function useRejectVacancy() {
  const { axiosInstance } = useApi();
  const queryClient = useQueryClient();

  return useMutation(
    ({ applyID, vacancyID }) =>
      axiosInstance.put(
        `/vacancy/${CommonStatus.REJECTED}/apply/${applyID}/${vacancyID}`,
        {},
        {
          baseURL: APPLY_URI,
        }
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("listInterested");
      },
    }
  );
}
