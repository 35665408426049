import { useState, useEffect } from "react";
import { useSession } from "@blue-express/bx-lib-universal-frontend";
import {
  Button,
  Stack,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { SearchBar } from "../../../components/SearchBar";
import { Table } from "../../../v2/components/Table";
import { LoadingSpinner } from "../../../components/LoadingSpinner";
import { MessageBox } from "../../../components/common/MessageBox";
import { ProtectedComponent } from "../../../components/ProtectedComponent";
import { useListRequests } from "../../../services/hooks/Apply";
import { useMyProvider } from "../../../services/hooks/Providers/useMyProvider";

import { MY_DATA_PROVIDER } from "../../../constants/functionalities";
import { formatDateApprovalFlow } from "../../../utils/formatValues";
import {
  EMPLOYEE_TYPE_ENUM,
  EmployeeStatus,
  VACANCY_TYPE_ENUM,
  VACANCY_TYPE_MAPPING,
  allowedRevokeStatus,
} from "../../../constants/status";
import VacancyModal from "../../components/Modal/VacancyModal";
import { useNavigate } from "react-router-dom";
import { EditRequests } from "./components/EditRequests";
import RevokeModal from "../../components/Modal/RevokeModal";
import { useRevokeVacancy } from "../../services/hooks/apply/useRevokeVacancy";
import { Alert } from "../../../components/Alert";
import { ChipStatus } from "../../components/Chip";
import { useURLfilters } from "../../hooks/filters.hook";
import { TableButton } from "../../components/Button/TableButton";
import { ReactComponent as RemoveIcon } from "../../assets/images/trash.svg";
import { ReactComponent as InfoIcon } from "../../assets/images/infobutton.svg";
import { ReactComponent as ReplaceIcon } from "../../assets/images/replacebutton.svg";

const headCells = [
  {
    id: "codeOffer",
    label: "Cod. Oferta",
    sort: {
      id: "codeOffer",
      default: true,
      order: "asc",
    },
  },
  {
    id: "offerTitle",
    label: "Título Oferta",
    sort: {
      id: "offerTitle",
    },
  },
  {
    id: "type",
    label: "Tipo",
    disableSorting: true,
  },
  {
    id: "plateOrNumberRut",
    label: "Rut/Patente",
    disableSorting: true,
  },
  {
    id: "dateApply",
    label: "Fecha de Postulación",
    sort: {
      id: "created.at",
    },
  },
  {
    id: "itemStatus",
    label: "Estado",
    disableSorting: true,
  },
  {
    id: "actions",
    label: "Acciones",
    disableSorting: true,
  },
];

export default function ProvidersRequests({ fromMyData }) {
  const { forceRefreshSession, getSession } = useSession();
  const session = getSession();
  const navigate = useNavigate();
  const [currentItem, setCurrentItem] = useState({});
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showRevokeModal, setShowRevokeModal] = useState(false);
  const [showReplaceModal, setShowReplaceModal] = useState(false);
  const [alertError, setAlertError] = useState({
    open: false,
    title: "",
    messages: [],
  });
  const [isRenderedEdit, setIsRenderedEdit] = useState(false);
  const providerEmail = session?.username;
  const { data: myProvider, isLoading: providerLoading } =
    useMyProvider(providerEmail);
  const { filters, setFilters, cleanFilters, getURLValue } = useURLfilters(
    {
      page: 1,
      limit: 10,
    },
    {
      excludeParams: ["providerId"],
      extraParams: {
        providerId: myProvider?.id,
      },
    }
  );

  const {
    data: { data, metadata } = {},
    isLoading,
    error,
  } = useListRequests(filters);
  const { mutate: revokeMutate, isLoading: revokeLoading } = useRevokeVacancy();
  const onCloseAlert = () => setAlertError({ ...alertError, open: false });

  const { TableContainer } = Table(
    headCells,
    {
      ...metadata,
      page: getURLValue("page", 1),
      limit: getURLValue("limit", 10),
    },
    onChangeFilters
  );

  const handleSearch = (event) => {
    const { value } = event.target;
    const newValue = value.toLowerCase();

    setFilters({ search: newValue, page: 1 });
  };

  const onInfo = (apply) => {
    setCurrentItem(apply);
    setShowInfoModal(true);
  };

  const onRevoke = (item) => {
    setCurrentItem(item);
    setShowRevokeModal(true);
  };

  const onReplace = (item) => {
    setCurrentItem(item);
    setShowReplaceModal(true);
  };

  const handleRevokeVacancy = (item) => {
    revokeMutate(
      {
        applyId: item.applyId,
        entityType: item.vacancy.type,
        entityId: item.vacancy.id,
      },
      {
        onError: () => {
          setAlertError({
            ...alertError,
            open: true,
            title: "Error",
            messages: ["Error al desistir la postulación."],
          });
        },
      }
    );
    setShowRevokeModal(false);
  };

  const handleEdit = (item) => {
    if (item.vacancy.type === "employee") {
      navigate(`/application/${item?.applyId}/employee/2`);
      return;
    }
    navigate(`/application/${item?.applyId}/fleet/1`);
  };

  const handleReplace = (item) => {
    if (item?.vacancy?.type === "employee") {
      navigate(`/employee/replace/${item?.applyId}/${item?.vacancy?.id}`);
      return;
    }

    navigate(`/vehicle/replace/${item?.applyId}/${item?.vacancy?.id}`);
  };

  const handleCloseInfoModal = () => {
    setShowInfoModal(false);
  };

  const handleCloseRevokeModal = () => {
    setShowRevokeModal(false);
  };

  const handleCloseReplaceModal = () => {
    setShowReplaceModal(false);
  };

  function onChangeFilters(newFilters = {}) {
    setFilters(newFilters);
  }

  useEffect(() => {
    forceRefreshSession();
  }, []);

  const getPlateOrDNIFromVacancy = (vacancy) => {
    if (vacancy?.type === VACANCY_TYPE_ENUM.FLEET) {
      return vacancy?.plate;
    }
    return vacancy.employeeType === EMPLOYEE_TYPE_ENUM.COURIER
      ? vacancy?.courierDni
      : vacancy?.peonetaDni;
  };

  const getVacancyType = (vacancy) => {
    if (vacancy?.type === VACANCY_TYPE_ENUM.FLEET) {
      return `${VACANCY_TYPE_MAPPING[VACANCY_TYPE_ENUM.FLEET]} (${
        vacancy?.fleetType
      })`;
    }
    return VACANCY_TYPE_MAPPING[vacancy?.employeeType];
  };

  const handleGoBack = () => {
    handleCloseInfoModal();
    setIsRenderedEdit(false);
  };

  if (isRenderedEdit) {
    return <EditRequests goBack={handleGoBack} item={currentItem} />;
  }

  if (error) return <MessageBox>Ups, ocurrio un error!</MessageBox>;

  if (isLoading || providerLoading || revokeLoading)
    return <LoadingSpinner open />;

  const TabsAddon = () => (
    <Stack
      direction="row"
      paddingY={2}
      paddingX={2}
      alignItems="center"
      justifyContent="space-between"
    >
      <SearchBar
        id="table-searchbar"
        type="text"
        placeholder="Buscar"
        onSearch={handleSearch}
        search={getURLValue("search", "")}
      />
      <Button
        variant="contained"
        onClick={cleanFilters}
        size="medium"
        sx={{ marginLeft: 3 }}
      >
        Limpiar filtros
      </Button>
    </Stack>
  );

  return (
    <>
      <Stack spacing={2}>
        {fromMyData ? null : (
          <Typography variant="h4" align="center">
            Mis Postulaciones
          </Typography>
        )}
        <TableContainer addons={[TabsAddon]} withPagination>
          <TableBody>
            {data?.map((item) => (
              <TableRow hover tabIndex={-1} key={item.vacancy.id}>
                <TableCell>{item.codeOffer}</TableCell>
                <TableCell>{item.offerTitle}</TableCell>
                <TableCell>{getVacancyType(item.vacancy)}</TableCell>
                <TableCell>{getPlateOrDNIFromVacancy(item.vacancy)}</TableCell>
                <TableCell>
                  {formatDateApprovalFlow(new Date(item.dateApply))}
                </TableCell>
                <TableCell>
                  <ChipStatus
                    label={EmployeeStatus[item.vacancy?.status]}
                    setcolor={item.vacancy?.status}
                  />
                </TableCell>
                <TableCell>
                  <Stack direction="row">
                    <ProtectedComponent functionality={[MY_DATA_PROVIDER]}>
                      {(item.vacancy?.status === EmployeeStatus.DRAFT ||
                        item.vacancy?.status === EmployeeStatus.OBJECTED) && (
                        <TableButton onClick={() => onInfo(item)}>
                          <InfoIcon />
                        </TableButton>
                      )}
                      {(item.vacancy?.status === EmployeeStatus.REJECTED ||
                        (item.vacancy?.type === VACANCY_TYPE_ENUM.FLEET &&
                          item.vacancy?.status ===
                            EmployeeStatus.OBJECTED)) && (
                        <TableButton onClick={() => onReplace(item)} >
                          <ReplaceIcon />
                        </TableButton>
                      )}
                      {allowedRevokeStatus.includes(item.vacancy?.status) && (
                        <TableButton onClick={() => onRevoke(item)}>
                          <RemoveIcon />
                        </TableButton>
                      )}
                    </ProtectedComponent>
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TableContainer>
        <VacancyModal
          isOpen={showInfoModal}
          onClose={handleCloseInfoModal}
          item={currentItem}
          onEdit={handleEdit}
          setIsRenderedEdit={setIsRenderedEdit}
        />
        <VacancyModal
          isOpen={showReplaceModal}
          onClose={handleCloseReplaceModal}
          item={currentItem}
          setIsRenderedEdit={setIsRenderedEdit}
          onReplace={handleReplace}
        />
        <RevokeModal
          isOpen={showRevokeModal}
          onClose={handleCloseRevokeModal}
          item={currentItem}
          onRevoke={handleRevokeVacancy}
        />
        <Alert
          icon
          open={alertError.open}
          handleConfirmation={onCloseAlert}
          handleClose={onCloseAlert}
          title={alertError.title}
          messages={alertError.messages}
        />
      </Stack>
    </>
  );
}
