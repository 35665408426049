import { useMutation } from 'react-query'
import { useApi } from '../../api'
import { FLEET_URI } from '../../../constants/api'

const { axiosInstance } = useApi();

const hardDeleteFleetEntity = async ({ idToDelete}) => {
  return await axiosInstance.delete(`${FLEET_URI}/fleet/hard/${idToDelete}`);
}

export function useHardDeleteFleetEntity() {
  return useMutation(hardDeleteFleetEntity);
}