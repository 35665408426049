import { ArrowBack } from "@mui/icons-material";
import { Box, Button, Card, Grid, Stack, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { DocumentTable } from "../../../components/Documents/DocumentTable";
import {
  EmployeeStatus,
  MESSAGE_STATUS_MAPPING,
} from "../../../../constants/status";
import {
  useGetProviderRequestById,
  useApprobationReplaceDocument,
} from "../../../services/hooks/Request";
import { LoadingSpinner } from "../../../../components/LoadingSpinner";
import { useGetDocuments } from "../../../../services/hooks/Documents";
import { useEffect, useState } from "react";
import { Dialogs } from "../../../../components/Dialogs";
import { Alert } from "../../../../components/Alert";
import { InfoCard } from "../../../components/InfoCard";
import { vacancyType } from "../../../../constants/vacancyType";
import { maskRUT } from "../../../../utils";
import warningIcon from "../../../assets/images/warning.png";
import wowIcon from "../../../../assets/images/wow.png";
import { formatSnakeCase } from "../../../helper/strings";

const initialAlertError = {
  title: "",
  open: false,
  messages: [],
  icon: "",
  buttonClose: true,
};

export default function ReplaceDocument() {
  const navigation = useNavigate();
  const { requestId } = useParams();
  const [dataDocument, setDataDocument] = useState([]);
  const [dataConfirmation, setDataConfirmation] = useState([]);
  const [buttonSelect, setButtonSelect] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogButton, setOpenDialogButton] = useState(false);
  const [documentSelect, setDocumentSelect] = useState();
  const [action, setAction] = useState("");

  const handleGoBack = () => {
    navigation(-1);
  };
  const { data: request, isLoading } = useGetProviderRequestById(requestId);

  const { data: documentData, isLoading: isDocumentLoading } = useGetDocuments(
    "requests",
    request?.id
  );
  const {
    mutate: manageApprovation,
    isLoading: isLoadingApprobationDocuments,
  } = useApprobationReplaceDocument();

  const [alert, setAlert] = useState(initialAlertError);

  useEffect(() => {
    if (documentData) {
      setDataDocument(documentData);
      const initialData = documentData.reduce((accumulator, document) => {
        if (
          document.status === EmployeeStatus.APPROVED ||
          document.status === EmployeeStatus.REJECTED ||
          document.status === MESSAGE_STATUS_MAPPING.APPROVE ||
          document.status === MESSAGE_STATUS_MAPPING.REJECT ||
          document.action === MESSAGE_STATUS_MAPPING.APPROVE ||
          document.action === MESSAGE_STATUS_MAPPING.REJECT
        ) {
          accumulator.push({ id: document.id, action: document.status });
        }
        return accumulator;
      }, []);
      setDataConfirmation(initialData);
    }
  }, [documentData, dataDocument]);

  const handleCancelDocument = () => {
    setDataConfirmation([]);
    const dataCancel = dataDocument.map((item) => {
      item.action = EmployeeStatus.PENDING;
      item.status = EmployeeStatus.PENDING;
      return item;
    });
    setDataDocument(dataCancel);
    setOpenDialogButton(false);
    handleGoBack();
  };

  const handleConfirmationAlert = () => {
    handleCancelDocument();
    setAlert({
      ...alert,
      open: false,
    });
    handleGoBack();
  };

  const handleSend = () => {
    setOpenDialogButton(false);
    if (buttonSelect === MESSAGE_STATUS_MAPPING.cancel) {
      handleCancelDocument();
      return;
    }
    const data = dataDocument.map((document) => ({
      idDocument: document.id,
      action: document.action,
    }));

    manageApprovation(
      { requestId, data },
      {
        onSuccess: () => {
          setAlert({
            ...alert,
            open: true,
            title: "Felicidades",
            buttonClose: false,
            icon: (
              <Box
                component="img"
                alt="icon"
                src={wowIcon}
                sx={{
                  display: { xs: "none", lg: "block" },
                  width: "75px",
                  height: "75px",
                }}
              />
            ),
            messages: [
              `Solicitud de ${MESSAGE_STATUS_MAPPING[action]} documento se realizó correctamente`,
            ],
          });
        },
      },
      {
        onError: () => {
          setAlert({
            ...alert,
            open: true,
            title: "Error",
            messages: [
              `Error al ${MESSAGE_STATUS_MAPPING[action]} el documento`,
            ],
          });
        },
      }
    );
  };

  const handleConfirmationDocument = (action, document) => {
    setDataConfirmation((prev) => {
      if (prev.some((item) => item.id === document.id)) {
        const dataChange = prev.map((item) =>
          item.id === document.id ? { id: item.id, action } : item
        );
        return dataChange;
      }
      return [...prev, { id: document.id, action }];
    });
    const dataStatusChange = dataDocument.map((item) => {
      if (item.id === document.id) {
        item.action = action;
      }
      return item;
    });
    setDataDocument(dataStatusChange);
    setOpenDialog(false);
  };

  if (isLoading || isDocumentLoading || isLoadingApprobationDocuments)
    return <LoadingSpinner open />;
  return (
    <Stack spacing={2}>
      <Grid>
        <Button
          variant="goBack"
          startIcon={<ArrowBack />}
          onClick={handleGoBack}
        >
          Volver atrás
        </Button>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <InfoCard
          title={"Solicitud de reemplazo de documentos"}
          entries={
            request?.requested?.for?.type === vacancyType.EMPLOYEE
              ? [
                  {
                    "Nombre completo: ": `${request?.metadata?.entity?.name} ${request?.metadata?.entity?.lastname1} ${request?.metadata?.entity?.lastname2 ?? ""}`,
                    "RUT: ": maskRUT(request?.metadata?.entity?.numberRut),
                    "Teléfono: ": request?.metadata?.entity?.phone,
                    "E-mail: ": request?.metadata?.entity?.email,
                  },
                ]
              : [
                  {
                    "Patente: ": request?.metadata?.entity?.plate,
                    "Marca: ": request?.metadata?.entity?.brand,
                    "Modelo: ": request?.metadata?.entity?.model,
                    "Tipo Vehículo: ": request?.metadata?.entity?.type,
                  },
                ]
          }
        />
      </Grid>
      {request.status === EmployeeStatus.PENDING && (
        <>
          <Card spacing={2}>
            <Stack sx={{ margin: 3 }}>
              <Typography
                variant="h5"
                align="left"
                sx={{
                  fontWeight: 700,
                  fontSize: "22px",
                }}
              >
                Documentos de reemplazo
              </Typography>
            </Stack>
            <DocumentTable
              dataDocument={dataDocument}
              data={documentData}
              onThumbUp={(doc) => {
                setAction(MESSAGE_STATUS_MAPPING.APPROVE);
                setOpenDialog(true);
                setDocumentSelect(doc);
              }}
              onThumbDown={(doc) => {
                setAction(MESSAGE_STATUS_MAPPING.REJECT);
                setOpenDialog(true);
                setDocumentSelect(doc);
              }}
            />
          </Card>
          <Grid>
            <Stack
              margin={2}
              direction="row"
              spacing={2}
              justifyContent="flex-end"
            >
              <Button
                color="secondary"
                variant="outlined"
                type="submit"
                sx={{
                  minWidth: "200px",
                  height: 40,
                  backgroundColor: "white",
                  border: 2,
                }}
                onClick={() => {
                  setButtonSelect(MESSAGE_STATUS_MAPPING.cancel);
                  setOpenDialogButton(true);
                }}
              >
                Cancelar
              </Button>
              <Button
                color="secondary"
                disabled={dataConfirmation?.length !== documentData?.length}
                type="submit"
                sx={{ minWidth: "200px", height: 40 }}
                onClick={() => {
                  setButtonSelect(MESSAGE_STATUS_MAPPING.confirm);
                  setOpenDialogButton(true);
                }}
              >
                Confirmar revisión
              </Button>
            </Stack>
          </Grid>
        </>
      )}
      <Alert
        open={alert?.open}
        handleConfirmation={handleConfirmationAlert}
        buttonClose={alert?.buttonClose}
        title={alert?.title}
        messages={alert?.messages}
        icon={alert?.icon}
      />
      <Dialogs
        icon={warningIcon}
        open={openDialogButton}
        handleClose={() => setOpenDialogButton(false)}
        handleConfirmation={handleSend}
        title={`¿Estás seguro de que quieres ${buttonSelect}?`}
      />
      <Dialogs
        icon={warningIcon}
        open={openDialog}
        handleClose={() => setOpenDialog(false)}
        handleConfirmation={() =>
          handleConfirmationDocument(action, documentSelect)
        }
        title={`Confirma que deseas ${
          MESSAGE_STATUS_MAPPING[action]
        } documentación ${formatSnakeCase(
          documentSelect?.files[0]?.groupName
        )}`}
        content={"Esta acción no se puede deshacer"}
        buttonCancelTitle={"Cancelar"}
        buttonConfirmationTitle={MESSAGE_STATUS_MAPPING[action]}
      />
    </Stack>
  );
}
