import { LoadingSpinner } from "../../../components/LoadingSpinner"
import { Modal } from "../../../components/Modals/Modal"
import { Button, Link, Stack, TableBody, TableCell, TableRow, Typography } from "@mui/material"
import { Table } from "../Table"
import { useGetDocuments } from "../../../services/hooks/Documents"
import VisibilityIcon from "@mui/icons-material/Visibility";
import { formatDate } from "../../../utils/formatValues"
import { ChipStatus } from "../Chip"
import { EmployeeStatus } from "../../../constants/status"
import { ENTITY_TYPES, ENTITY_TYPES_MAPPING } from "../../../constants/entityTypes"
import { InfoHeader } from "../InfoHeader/InfoHeader"

const headCells = [
  {
    id: "title",
    label: "Documento",
    exportExcel: true,
  },
  {
    id: "file",
    label: "Archivo",
    exportExcel: true,
  },
  {
    id: "expiredDate",
    label: "Fecha vencimiento",
    exportExcel: true,
  },
  {
    id: "status",
    label: "Estado",
    exportExcel: true,
  }
];

const ListDocumentsModal = ({ isOpen, onClose, type, item }) => {
  const { data, isLoading } = useGetDocuments( type, item?.id);
  const { TableContainer } = Table(headCells, data);

  if (isLoading) return <LoadingSpinner open />

  return (
    <Modal open={isOpen} handleClose={onClose}>
      {item?.type === ENTITY_TYPES.PROVIDER ? 
      <>
        <Stack
          direction="row"
          spacing={2}
          justifyContent="flex-start"
          alignItems="center"
        >
          <Typography variant="h6">
            Detalles del proveedor
          </Typography>
          <ChipStatus label={EmployeeStatus[item?.status]} setcolor={item?.status}/>
        </Stack>
        <InfoHeader item={item} />
      </>
      :
      <Stack
          direction="row"
          spacing={2}
          justifyContent="flex-start"
          alignItems="center"
        >
          <Typography variant="h6">
            Documentos del {ENTITY_TYPES_MAPPING[item?.type] ? 
            `${ENTITY_TYPES_MAPPING[item?.type]} ${item?.numberRut}` 
            : 
            `${ENTITY_TYPES_MAPPING[ENTITY_TYPES.FLEET]} ${item?.plate}`}
          </Typography>
          <ChipStatus label={EmployeeStatus[item?.status]} setcolor={item?.status}/>
        </Stack>
      }
      <Stack
        spacing={2}
      >
        <TableContainer>
          <TableBody>
            {data.map((document) => (
              <TableRow hover tabIndex={-1} key={document.id}>
                <TableCell >{document.files[0].groupName}</TableCell>
                <TableCell>
                  {document.files.map((file) => (
                    <Stack>
                      <Button
                        variant="goBack"
                        disableRipple
                        startIcon={<VisibilityIcon />}
                        sx={{
                          height: 40,
                          fontSize: 14,
                          alignSelf: "self-start",
                        }}
                      >
                        <Link
                          href={file.path}
                          target="_blank"
                          rel="noopener noreferrer"
                          download={file?.name}
                        >
                          {file?.labelName}
                        </Link>
                      </Button>
                    </Stack>
                  ))}
                </TableCell>
                <TableCell>
                  {document.expirationDate ? formatDate(document.expirationDate, "DD-MM-YYYY") :
                    "-"}
                </TableCell>
                <TableCell>
                  <ChipStatus
                    label={EmployeeStatus[document.status]}
                    setcolor={document.status}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </TableContainer>
      </Stack>
      <Stack direction="row" sx={{ justifyContent: "center" }}>
        <Button
          color="secondary"
          sx={{ minWidth: "155px", height: 40, marginTop: 4 }}
          onClick={onClose}
        >
          Aceptar
        </Button>
      </Stack>
    </Modal>
  )
}

export default ListDocumentsModal
