import { useState, useEffect } from "react";
import { useSession } from "@blue-express/bx-lib-universal-frontend";
import {
  Button,
  Grid,
  Radio,
  Stack,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { useMyProvider } from "../../../../services/hooks/Providers/useMyProvider";
import { Table } from "../../../components/Table";
import { MessageBox } from "../../../../components/common/MessageBox";
import { LoadingSpinner } from "../../../../components/LoadingSpinner";
import { SearchBar } from "../../../../components/SearchBar";
import { EmployeeStatus, FleetStatus, VACANCY_TYPE_ENUM, VACANCY_TYPE_MAPPING } from "../../../../constants/status";
import { ChipStatus } from "../../../components/Chip";
import { REQUEST_TYPE } from "../../../helper/request";
import { ENTITY_TYPES } from "../../../../constants/entityTypes";
import { useAtom } from "jotai";
import { replaceDataAtom } from "../../../store/requestTracker/replaceRequest.store";
import { Alert } from "../../../../components/Alert";
import { ReactComponent as LeftArrow } from "../../../assets/images/leftarrow.svg"
import { useCreateReplacement } from "../../../services/hooks/Request";
import { useFleetListRequest } from "../../../services/hooks/Provider/useListFleetRequest";

const initialAlertError = {
  open: false,
  title: "",
  messages: [],
  content: "",
};

const headCells = [
  {
    id: "status",
    label: "Estado Documentación",
  },
  {
    id: "plate",
    label: "Patente",
  },
  {
    id: "brand",
    label: "Marca",
  },
  {
    id: "model",
    label: "Modelo",
  },
  {
    id: "type",
    label: "Tipo",
  },
];

export default function Fleetable({item, goBack, setReplaceFinish}) {
  const defaultFilters = {
    page: 1,
    limit: 10,
  };
  const { forceRefreshSession, getSession } = useSession();
  const session = getSession();
  const providerEmail = session?.username;
  const { data: myProvider, isLoading: providerLoading } = useMyProvider(providerEmail);
  const [filters, setFilters] = useState(defaultFilters);
  const [selectedItem, setSelectedItem] = useState();
  const [alertError, setAlertError] = useState(initialAlertError);
  const [replaceData, setReplaceData] = useAtom(replaceDataAtom);
  const { mutate: mutateCreateReplacement, isLoading: isLoadingCreateReplacement } =
    useCreateReplacement();
  const {
    data: { data, metadata } = {},
    refetch,
    isLoading,
    error,
  } = useFleetListRequest(myProvider?.id, filters);

  const { TableContainer } = Table(
    headCells,
    metadata,
    onChangeFilters
  );

  const handleSearch = (event) => {
    const { value } = event.target;
    setFilters((old) => ({ ...old, search: value }));
  };

  function onChangeFilters(newFilters = {}) {
    setFilters((old) => ({
      ...old,
      ...newFilters,
    }));
  }

  const onFinish = () => {
    setReplaceData({});
    setReplaceFinish(true);
  };

  const handleSend = () => {
    const replace = {
      type: replaceData.isPermanent
        ? REQUEST_TYPE.PERMANENT_REPLACEMENT
        : REQUEST_TYPE.TEMPORARY_REPLACEMENT,
      status: FleetStatus.PENDING,
      requested: {
        for: {
          type: ENTITY_TYPES.FLEET,
          subType: item.type,
          plate: item.plate || "",
          courierDni: item.courierDni || "",
          peonetaDni: item.peonetaDni || "",
        },
      },
      metadata: {
        entityToReplace: item,
        replacement: selectedItem,
        setting: {
          ...replaceData,
          isNewEntity: true,
        },
      },
    };
    mutateCreateReplacement(replace, {
      onSuccess: () => {
        onFinish();
      },
      onError: () => {
        setAlertError({
          ...alertError,
          open: true,
          title: "Error",
          messages: [
            "Oh no! se ha producido un error al crear la solicitud de reemplazo",
          ],
        });
      },
    });
  }

  useEffect(() => {
    if (myProvider) {
      refetch();
    }
  }, [filters, myProvider]);

  useEffect(() => {
    forceRefreshSession();
  }, []);

  const handleConfirmation = () => {
    setAlertError({
      ...alertError,
      open: false,
    });
  };

  const TabsAddon = () => (
    <Stack
      direction="row"
      paddingY={2}
      paddingX={2}
      alignItems="center"
      justifyContent="space-between"
    >
      <SearchBar
        id="table-searchbar"
        type="text"
        placeholder="Buscar"
        onSearch={handleSearch}
      />
    </Stack>
  );

  if (error) return <MessageBox>Ups, ocurrio un error!</MessageBox>;

  if (isLoading || providerLoading || isLoadingCreateReplacement) return <LoadingSpinner open />;

  return (
    <>
      <Stack spacing={2}>
        <Typography align="left" fontFamily={"Montserrat"} fontWeight={700} fontSize={"18px"} >
          Asignar un {VACANCY_TYPE_MAPPING[VACANCY_TYPE_ENUM.FLEET].toLowerCase()} de reemplazo
        </Typography>
        <Typography align="left" fontFamily={"Lato"} fontWeight={400} fontSize={"14px"}>
          Solo podrás seleccionar un {VACANCY_TYPE_MAPPING[VACANCY_TYPE_ENUM.FLEET].toLowerCase()} cuya documentación  ha sido aprobada, pero no están activos
        </Typography>
          <TableContainer addons={[TabsAddon]} withPagination>
            <TableBody>
              {data?.map((item) => (
                <TableRow hover tabIndex={-1} key={item.id}>
                  <TableCell>
                    <Radio
                      checked={selectedItem?.plate === item.plate}
                      onChange={() => setSelectedItem(item)}
                      defaultValue={item?.plate || ''}
                      name="radio-buttons"
                    />    
                    <ChipStatus
                      label={EmployeeStatus[item.status]}
                      setcolor={item.status}
                    />
                  </TableCell>
                  <TableCell>
                    {item.plate}
                  </TableCell>
                  <TableCell>
                    {item.brand}
                  </TableCell>
                  <TableCell>
                    {item.model}
                  </TableCell>
                  <TableCell>
                    {item.type}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </TableContainer>
          <Grid container sx={{ pt: 2, mt: 4 }}>
            <Grid item xs={5}>
                {goBack && (
                  <Button
                    variant="goBack"
                    disableRipple
                    startIcon={<LeftArrow />}
                    onClick={goBack}
                    sx={{ height: 40, fontSize: 12, alignSelf: "self-start" }}
                  >
                    <Typography 
                      fontFamily="Lato"
                      fontWeight={800}
                      fontSize="12px"
                      lineHeight="16.2px"
                    >
                      {"Volver atrás"}
                    </Typography>
                  </Button>
                )}
            </Grid>
            <Grid item xs={7}>
              <Grid container justifyContent="flex-end">
                <Button
                  disabled={!selectedItem}
                  color="secondary"
                  type="submit"
                  onClick={() => {
                    handleSend()
                  }}
                  sx={{ minWidth: "190px", height: 40 }}
                >
                  Siguiente
                </Button>
              </Grid>
            </Grid>
          </Grid>
      </Stack>
      <Alert
        icon
        open={alertError.open}
        handleConfirmation={handleConfirmation}
        title={alertError.title}
        messages={alertError.messages}
      />
    </>
  );
}
