import { Button, Stack, Typography } from "@mui/material";
import { PaperWhite } from "../../../components/Papers";
import { useNavigate } from "react-router-dom";
import { WarningIcon } from "../../../components/Icons/WarningIcon";

export default function RejectedApply({ message }) {
  const navigate = useNavigate()
  return (
    <>
      <PaperWhite sx={{ py: 4, mt: 6 }}>
        <Stack direction="column" spacing={2} sx={{alignItems: 'center'}}>
          <WarningIcon sx={{width: '150px', height: '150px'}}/>
          <Typography variant="h5" color="#073E99">
              {message}
          </Typography>
        </Stack>
      </PaperWhite>
      <Stack
          direction='row'
          justifyContent='flex-end'
          paddingY={2}
          spacing={10}
      >
          <Button
              onClick={() => navigate('/myrequest')}
              size='large'
          >
              Volver
          </Button>
      </Stack>
    </>
  )
}
