import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Stack } from "@mui/material";

import { PaperWhite } from "../../components/Papers";
import { Step1 } from "./components/Steps/Step1";
import { Step2 } from "./components/Steps/Step2";

import { useOfferDetails } from "../../services/hooks/Offers";
import { LoadingSpinner } from "../../components/LoadingSpinner";
import { StepperController } from "../../components/Stepper";

const steps = ["Descripción", "Configuración de la oferta"];

const defaultValues = {
  title: "",
  description: "",
  details: "",
  priority: 3,
  codeOffer: "",
  commercialOffer: {
    value: "",
    isPrivate: false,
  },
  setting: {
    vacancies: [],
    city: "",
    showTotal: false,
    publicationDate: {
      from: null,
      to: null,
    },
    isCyber: false,
    base: { code: "", name: "", address: "", id: "" },
  },
  company: {
    id: "1",
    name: "Blue",
  },
};

export default function FormOffer({ fromButton }) {
  const navigate = useNavigate();
  const { offerId } = useParams();

  const { data: offer, isLoading } = useOfferDetails(offerId);

  const [activeStep, setActiveStep] = useState(offerId ? 0 : 1);

  const [allowedEdit, setAllowedEdit] = useState(offer?.status !== 2);

  const [status, setStatus] = useState(offer?.status);

  const {
    control,
    handleSubmit,
    setValue,
    setError,
    trigger,
    reset: resetOffer,
    formState,
  } = useForm({
    defaultValues,
    mode: "onChange",
  });

  useEffect(() => {
    if (!fromButton) navigate("/404");
  }, []);

  useEffect(() => {
    if (offer) {
      resetOffer(offer);
      setAllowedEdit(offer?.status !== "Published");
      setStatus(offer?.status || "Draft");
      setActiveStep(1);
    }
  }, [offer]);

  const renderForm = useMemo(() => {
    const form = {
      0: <LoadingSpinner open />,
      1: (
        <Step1
          onNextStep={() => setActiveStep(2)}
          allowedEdit={allowedEdit}
          control={control}
          handleSubmit={handleSubmit}
          setValue={setValue}
          trigger={trigger}
          offer={offer}
        />
      ),
      2: (
        <Step2
          onBackStep={() => setActiveStep(1)}
          allowedEdit={allowedEdit}
          status={status}
          {...{
            control,
            handleSubmit,
            setValue,
            setError,
            offerFormState: formState,
          }}
        />
      ),
    };
    return form[activeStep];
  }, [activeStep, formState.isValid]);

  if (isLoading) return <LoadingSpinner open />;

  return (
    <Stack spacing={4}>
      <StepperController
        steps={steps}
        isCurrentStepActive={activeStep}
        title={offerId ? "Editando la oferta" : "Creando una nueva oferta"}
      />
      <PaperWhite>{renderForm}</PaperWhite>
    </Stack>
  );
}
