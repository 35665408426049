// REACT
import { useEffect, useState } from 'react'

// SERVICES
import withUniversalSession, {
  useSession,
} from '@blue-express/bx-lib-universal-frontend'
import Cookies from 'js-cookie'
import { useApi } from './services/api'

// COMPONENTS
import { Layout } from './components/Layout'
import { LoadingSpinner } from './components/LoadingSpinner'
import { Routes } from './routes'
import { saveSession } from './utils/saveSession'
import { useMyProvider } from './services/hooks/Providers/useMyProvider'
import { ROLES, BLOCK_ROUTES_STATUS } from './constants/roles'
import BlockedProvider from './v2/pages/BlockedProvider'
import { hasRole } from './utils/roles'

function App() {
  const { configAuthorization, setEmployeeAuthorization } = useApi()
  const { getSession, hasStarted } = useSession()
  const session = getSession()
  const [authorizationOk, setAuthorizationOk] = useState(false)
  const [employeeWasSetted, setEmployeeWasSetted] = useState(false)
  const [firstNavigation, setFirstNavigation] = useState(null)
  const { data, refetch, isSuccess } = useMyProvider(session?.username, false)

  const isProviderRole = hasRole(session, ROLES.PROVIDER_TMS);


  useEffect(() => {
    if (session) {
      if (session.ssoToken) {
        configAuthorization(session)
        saveSession(session)
      }
    }
  }, [session])

  useEffect(() => {
    if (session && !hasStarted) {
      refetch()
    }
    if (isSuccess && !employeeWasSetted) {
      setEmployeeAuthorization({
        id: data?.id,
        name: session?.firstname,
        lastname1: session?.lastname,
        email: session?.username,
        numberRut: data?.numberRut,
        status: data?.status,
        phone: data?.phone
      })
      setAuthorizationOk(true)
      saveSession(session)
      setEmployeeWasSetted(true)
    }
  }, [session, data, isSuccess])

  useEffect(() => {
    if (!hasStarted) {
      const preStoredSession = session?.username
      Cookies.set("__prevUser", preStoredSession)
    }

    if (session && hasStarted) {
      const pathName = window.location.pathname.split("/")
      pathName.shift()
      setFirstNavigation(`${pathName.join("/")}${window.location.search}`);
      return
    }
  }, [hasStarted]);
   
  if(isProviderRole && BLOCK_ROUTES_STATUS.includes(data?.status)) {
    return <BlockedProvider message="Tu postulación como proveedor ha sido rechazada, gracias por querer ser parte de Blue Express." />
  }

  return session && authorizationOk && firstNavigation !== null ? (
    <Layout provider={data}>
      <Routes nav={firstNavigation} />
    </Layout>
  ) : (
    <LoadingSpinner open />
  )
}

export default withUniversalSession(App, {
  env: process.env.REACT_APP_UNIVERSAL_ENV,
  service: 'tms',
  storage: 'cookie',
  locale: 'es',
  client_secret: process.env.REACT_APP_UNIVERSAL_CLIENT_SECRET,
  client_id: process.env.REACT_APP_UNIVERSAL_CLIENT_ID,
  ttl: 60,
})
