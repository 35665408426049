import { Stack } from "@mui/material";
import { DocumentListUpdate } from "../../../components/Documents/DocumentListUpdate";
import { vacancyType } from "../../../../constants/vacancyType";
import { LoadingSpinner } from "../../../../components/LoadingSpinner";
import { employeeType } from "../../../../constants/employeeType";
import { EntityCard } from "../../../components/EntityCard";
import { maskRUT } from "../../../../utils";
import {
  EmployeeStatus,
  VACANCY_TYPE_MAPPING,
} from "../../../../constants/status";
import { useGetTemplate } from "../../../../services/hooks/Docs/Templates/useGetTemplates";
import { ChipStatus } from "../../../components/Chip";

export const EditRequests = ({ item, goBack }) => {
  const { data: template, isLoading: isLoadingTemplate } = useGetTemplate(
    item?.vacancy?.documentTemplateId
  );

  if (isLoadingTemplate) return <LoadingSpinner open />;

  return (
    <>
      <Stack>
        <EntityCard
          entries={
            item.vacancy.type === vacancyType.EMPLOYEE
              ? [
                  [
                    `Datos del ${
                      VACANCY_TYPE_MAPPING[item.vacancy.employeeType]
                    }`,
                    {
                      "Nombre completo:": (
                        <b>{`${item?.vacancy?.name} ${item?.vacancy?.lastname1} ${item?.vacancy?.lastname2 ?? ""}`}</b>
                      ),
                      "RUT:":
                        item?.vacancy?.employeeType === employeeType.COURIER ? (
                          <b> {maskRUT(item?.vacancy?.courierDni)} </b>
                        ) : (
                          <b>{maskRUT(item?.vacancy?.peonetaDni)} </b>
                        ),
                      "Teléfono:": <b> {item?.vacancy?.phone} </b>,
                      "E-mail:": <b> {item?.vacancy?.email} </b>,
                    },
                  ],
                  [
                    "Estado",
                    {
                      "": (
                        <ChipStatus
                          label={EmployeeStatus[item?.vacancy?.status]}
                          setcolor={item?.vacancy?.status}
                        />
                      ),
                    },
                  ],
                ]
              : [
                  [
                    "Datos del vehículo",
                    {
                      "Tipo de vehículo:": <b>{item?.vacancy?.fleetType}</b>,
                      "Patente:": <b> {item?.vacancy?.plate} </b>,
                      "Marca:": <b> {item?.vacancy?.brand} </b>,
                      "Modelo:": <b> {item?.vacancy?.model} </b>,
                      Año: <b> {item?.vacancy?.year} </b>,
                    },
                  ],
                  [
                    "Estado",
                    {
                      "": (
                        <ChipStatus
                          label={EmployeeStatus[item?.vacancy?.status]}
                          setcolor={item?.vacancy?.status}
                        />
                      ),
                    },
                  ],
                ]
          }
        />

        <Stack sx={{ mt: 10 }}>
          <DocumentListUpdate
            type={item?.vacancy?.type}
            id={item?.vacancy?.id}
            templateData={template}
            goBack={goBack}
            status={item?.status} 
          />
        </Stack>
      </Stack>
    </>
  );
};
