import { useNavigate, useParams } from "react-router-dom";
import { StepperController } from "../../Stepper"
import { steps } from "../../../constants/steps";
import { PaperWhite } from "../../Papers";
import { Box, Button, Stack, Typography } from "@mui/material";
import applySuccess from "../../../assets/images/apply-success.png";
import { ArrowBack } from "@mui/icons-material";

export const FinishApply = () => {
    const { step } = useParams();
    const navigate = useNavigate();

    return (
        <>
            <StepperController
                steps={steps}
                isCurrentStepActive={step}
                title={"Postulación finalizada"}
            />
            <PaperWhite sx={{ py: 4, mt: 6 }}>
                <Typography variant="h3" color="#45ADE6" align="center">
                    ¡Buen trabajo!
                </Typography>
                <Typography variant="h5" color="#073E99" align="center">
                    Nos alegra que estés interesado en nuestra oferta
                </Typography>
                <Box
                    alignSelf='center'
                    component='img'
                    alt="Postulación finalizada"
                    src={applySuccess}
                    sx={{
                        display: "block"
                    }}
                    mx="auto"
                    my={2}
                />
            </PaperWhite>
            <Stack
                direction='row'
                justifyContent='flex-end'
                paddingY={2}
                spacing={10}
            >
                <Button variant='goBack' startIcon={<ArrowBack />} onClick={() => navigate(-1)}>
                    Volver atrás
                </Button>

                <Button
                    onClick={() => navigate('/myrequest')}
                    size='large'
                >
                    Finalizar
                </Button>
            </Stack>
        </>
    )
}