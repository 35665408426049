import { useMutation } from "react-query";
import { useApi } from "../../../../../services/api";
import { REQUEST_TRACKER_URI } from "../../../../../constants/api";

export const useManageReplacement = () => {
    const { axiosInstance } = useApi();
    return useMutation(({ requestId, action }) => axiosInstance.post(`/replacement/${requestId}/${action}`, null, {
        baseURL: REQUEST_TRACKER_URI,
    }));
}
