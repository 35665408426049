import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { maskRUT } from "../../../../utils";
import { CommonStatus, VACANCY_TYPE_ENUM, VACANCY_TYPE_MAPPING } from "../../../../constants/status";
import { DocumentListUpdate } from "../../../components/Documents/DocumentListUpdate";
import { useState } from "react";
import { AlertSnackBar } from "../../../../components/Alerts";
import { useGetTemplate } from "../../../../services/hooks/Docs/Templates/useGetTemplates";
import { ENTITY_TYPES } from "../../../../constants/entityTypes";
import { useUpdateReplaceDocumentStatus } from "../../../services/hooks/Request/ReplaceDocument/useUpdateReplaceDocumentStatus";
import { LoadingSpinner } from "../../../../components/LoadingSpinner";

export const ReplaceDocumentInfo = ({ request }) => {
  const entity = request?.requested?.for?.type === VACANCY_TYPE_ENUM.EMPLOYEE ? {
    "Nombre: ": `${request?.metadata?.entity?.name ?? ''} ${request?.metadata?.entity?.lastname1 ?? ''} ${request?.metadata?.entity?.lastname2 ?? ''}`,
    "RUT: ": maskRUT(request?.metadata?.entity?.numberRut ?? ''),
    "Teléfono: ": request?.metadata?.entity?.phone,
    "E-mail: ": request?.metadata?.entity?.email,
  } : {
    "Patente: ": request?.metadata?.entity?.plate,
    "Marca: ": request?.metadata?.entity?.brand,
    "Modelo: ": request?.metadata?.entity?.model,
    "Tipo Vehículo: ": request?.metadata?.entity?.type,
  };
  const { data: dataDocumentTemplate } = useGetTemplate(request?.metadata?.entity?.documentTemplateId);
  const entityType = request?.requested?.for?.type === VACANCY_TYPE_ENUM.EMPLOYEE ? request?.requested?.for?.subType : VACANCY_TYPE_ENUM.FLEET;
  const [error, setError] = useState({ isOpen: false });

  const { mutate, isLoading } = useUpdateReplaceDocumentStatus();
  const onUpdateSuccess = () => {
    mutate(
      {
        requestId: request.id,
        status: CommonStatus.PENDING,
      },
      {
        onError: () => {
          setError({
            isOpen: true,
            title: "Error",
            message: "Hubo un error al actualizar la solicitud de reemplazo de documento.",
            handleClose: () => setError({ isOpen: false }),
          });
        }
      }
    );
  }
  const entityValues = Object.values(entity);
  const entityKeys = Object.keys(entity);
  if (isLoading) {
    return <LoadingSpinner open={isLoading} />
  }
  return (
    <Grid item xs={12} sm={12} md={12} lg={12}>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: '10em' }}></TableCell>
              <TableCell
                key={"type"}
              >
                {VACANCY_TYPE_MAPPING[entityType]}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {entityValues.map((cell, cellIndex) => (
              <TableRow key={cellIndex}>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ backgroundColor: '#f5f5f5' }}
                >
                  <strong>{entityKeys[cellIndex]}</strong>
                </TableCell>
                <TableCell>
                  {entityValues[cellIndex]}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <DocumentListUpdate
        type={ENTITY_TYPES.REQUESTS}
        id={request.id}
        templateData={dataDocumentTemplate}
        isProviderList={false}
        onUpdateSuccess={onUpdateSuccess}
        status={request.status}
      />
      <AlertSnackBar
        open={error.isOpen}
        message={error.message}
        handleClose={error.handleClose}
      />
    </Grid>
  )
}
