const simpleTextPattern = () => /[^a-zA-Z-ñÑ ]/gi
const simpleTextPatternFirstEnd = () => /[^a-zA-Z-ñÑ]/gi
const simpleTextNumberPattern = () => /[^a-zA-Z0-9 ]/gi
const simpleTextNumberPatternFirstEnd = () => /[^a-zA-Z0-9 ]/gi
const simpleNumberPattern = () => /[^0-9]/gi
const complexTextPattern = () => /[^ÀàÁáÂâÃãÄäÈèÉéÊêËëÌìÍíÎîÏïÑñÒòÓóÔôÕõÖöÙùÚúÛûÜüÝýŸÿ a-zA-Z,./-]/gi
const complexTextPatternFirstEnd = () => /[^ÀàÁáÂâÃãÄäÈèÉéÊêËëÌìÍíÎîÏïÑñÒòÓóÔôÕõÖöÙùÚúÛûÜüÝýŸÿa-zA-Z ,./-]/gi
const complexTextPatternSpecial = () => /[^ÀàÁáÂâÃãÄäÈèÉéÊêËëÌìÍíÎîÏïÑñÒòÓóÔôÕõÖöÙùÚúÛûÜüÝýŸÿ a-zA-Z -/.]/gi
const complexTextPatternSpecialFirstEnd = () => /[^ÀàÁáÂâÃãÄäÈèÉéÊêËëÌìÍíÎîÏïÑñÒòÓóÔôÕõÖöÙùÚúÛûÜüÝýŸÿa-zA-Z ,./-]/gi
const complexTextNumberPattern = () => /[^ÀàÁáÂâÃãÄäÈèÉéÊêËëÌìÍíÎîÏïÑñÒòÓóÔôÕõÖöÙùÚúÛûÜüÝýŸÿ a-zA-Z0-9.,/-]/gi
const complexTextNumberSpecialPattern = () => /[^ÀàÁáÂâÃãÄäÈèÉéÊêËëÌìÍíÎîÏïÑñÒòÓóÔôÕõÖöÙùÚúÛûÜüÝýŸÿ_a-zA-Z0-9 ,./-]/gi
const complexTextNumberPatternFirstEnd = () => /[^ÀàÁáÂâÃãÄäÈèÉéÊêËëÌìÍíÎîÏïÑñÒòÓóÔôÕõÖöÙùÚúÛûÜüÝýŸÿa-zA-Z0-9 ,./-]/gi
const complexTextNumberPatternSpecialFirstEnd = () => /[^ÀàÁáÂâÃãÄäÈèÉéÊêËëÌìÍíÎîÏïÑñÒòÓóÔôÕõÖöÙùÚúÛûÜüÝýŸÿa-zA-Z0-9_ ,./-]/gi
const numberPattern = () => /\d/

export const isSimpleTextValid = (text) => {
    if (text.length === 0) return true

    const primerCaracter = text.charAt(0)
    const ultimoCaracter = text.charAt(text.length - 1)

    return (!simpleTextPatternFirstEnd().test(primerCaracter) && !simpleTextPattern().test(text)
        && !simpleTextPatternFirstEnd().test(ultimoCaracter))
}

export const isComplexTextValid = (text) => {
    if (text.length === 0) return true

    const primerCaracter = text.charAt(0)
    const ultimoCaracter = text.charAt(text.length - 1)

    return (!complexTextPatternFirstEnd().test(primerCaracter) && !complexTextPattern().test(text)
        && !complexTextPatternFirstEnd().test(ultimoCaracter))
}

export const isComplexTextSpecialValid = (text) => {
    if (text.length === 0) return true

    const primerCaracter = text.charAt(0)
    const ultimoCaracter = text.charAt(text.length - 1)

    return (!complexTextPatternSpecialFirstEnd().test(primerCaracter) && !complexTextPatternSpecial().test(text)
        && !complexTextPatternSpecialFirstEnd().test(ultimoCaracter))
}

export const isComplexTextNumberValid = (text) => {
    if (text.length === 0) return true

    const primerCaracter = text.charAt(0)
    const ultimoCaracter = text.charAt(text.length - 1)

    return (!complexTextNumberPatternFirstEnd().test(primerCaracter) && !complexTextNumberPattern().test(text)
        && !complexTextNumberPatternFirstEnd().test(ultimoCaracter))
}

export const isComplexTextNumberSpecialValid = (text) => {
    if (text.length === 0) return true

    const primerCaracter = text.charAt(0)
    const ultimoCaracter = text.charAt(text.length - 1)

    return (!complexTextNumberPatternSpecialFirstEnd().test(primerCaracter) && !complexTextNumberSpecialPattern().test(text)
        && !complexTextNumberPatternSpecialFirstEnd().test(ultimoCaracter))
}

export const isSimpleTextNumberValid = (text) => {
    if (text.length === 0) return true

    const primerCaracter = text.charAt(0)
    const ultimoCaracter = text.charAt(text.length - 1)

    return (!simpleTextNumberPatternFirstEnd().test(primerCaracter) && !simpleTextNumberPattern().test(text)
        && !simpleTextNumberPatternFirstEnd().test(ultimoCaracter))
}

export const isSimpleNumberValid = (text) => {
    if (text.length === 0) return true
    const primerCaracter = text.toString().charAt(0)
    const ultimoCaracter = text.toString().charAt(text.length - 1)

    return (!simpleNumberPattern().test(primerCaracter) && !simpleNumberPattern().test(text)
        && !simpleNumberPattern().test(ultimoCaracter))
}

export const isCustomTextValid = (text, pattern) => (text.length > 0) ? pattern.test(text) : true
export const validateMinLength = (text, length) => (text.toString().length >= Number(length))
export const validateMaxLength = (text, length) => (Number(length) >= text.length)
export const validateMinValue = (value, min) => (Number(value) >= Number(min))
export const validateMaxValue = (value, max) => (Number(max) >= Number(value))
export const isNumberValid = (num) => (num.length > 0) ? numberPattern().test(num) : true

export function isVehicleColorValid(text) {
    return !/[^a-z0-9\s/\-()\u00E0-\u00FC]/i.test(text);
}
