import { memo, useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { Divider, Stack, Button, Grid } from "@mui/material";
import { DocumentsSection } from "../Documents/DocumentSection";
import { ArrowBackOutlined } from "@mui/icons-material";
import { FleetSection } from "./FleetSection";
import { useValidatePlate } from "../../../services/hooks/Fleets/useValidatePlate";
import { LoadingSpinner } from "../../LoadingSpinner";
import { STATUS_CODE } from "../../../constants/status";
import { useGetTemplates } from "../../../services/hooks/Docs/Templates/useGetTemplates";
import { useListRequestsById } from "../../../services/hooks/Apply";
import { useListVehiclesBrand } from "../../../services/hooks/Vehicles/Brands/useListVehiclesBrand";
import { findFileByDocumentTypeId, findFileByLabelName } from "../../../utils/documents";
import { useGetDocumentsByEntity } from "../../../services/hooks/Docs/S3/useGetDocumentsByEntity";

export const FormCreateVehicle = memo(
  ({
    formValues,
    filteredVacanciesByAvailability,
    onSubmit,
    goBack,
    setCurrentTemplate,
    setDocumentsFromBucket,
    setInitialExpirationDates,
    isReplacing,
  }) => {
    const { control, handleSubmit, setValue, getValues, formState } = useForm({
      formValues,
      mode: "onChange",
    });
    const { applyId } = useParams();
    const [templateId, setTemplateId] = useState(null);
    const [models, setModels] = useState([]);
    const { data: dataApply, isLoading: isLoadingApply } =
      useListRequestsById(applyId);
    const { data: dataVehicle, isLoading: isLoadingVehicle } =
      useListVehiclesBrand();
    const {
      mutate: getPlate,
      isLoading,
      isError,
      error,
      isSuccess,
    } = useValidatePlate();
    const { data: template, isLoading: isLoadingTemplate } = useGetTemplates(templateId);
    const { data: documentsFromBucket, isLoading: isGettingDocuments } =
      useGetDocumentsByEntity(formValues?.id, "fleet");

    useEffect(() => {
      let expirationDates = {};
      const isEditing = Boolean(formValues.id)
      if (isEditing) {
        expirationDates = {
          ...template?.documentTypes.reduce((result, documentType) => {
            if (documentType?.hasExpiration) {
              result[`${documentType?.name}_expiration_date`] =
                findFileByDocumentTypeId(
                  documentsFromBucket,
                  documentType?.id,
                  true
                )?.expirationDate ?? null;
            }

            documentType.documents.forEach((docResult) => {
              const relatedDocumentFromBucket = findFileByLabelName(
                documentsFromBucket,
                docResult?.label
              );
              result[docResult.name] = {
                documentTypeId: documentType?.id,
                status: "pending",
                ownerId: relatedDocumentFromBucket?.owner?.id,
                ownerType: relatedDocumentFromBucket?.owner?.type,
                metaData: {
                  id: docResult?.id,
                  name: relatedDocumentFromBucket?.name,
                  labelName: relatedDocumentFromBucket?.labelName,
                  groupName: relatedDocumentFromBucket?.groupName,
                },
              };
            });

            return result;
          }, {}),
        };
      }
      if (isEditing || isReplacing) {
        const formValuesWithDocuments = {
          ...formValues,
          ...expirationDates,
        };
        Object.keys(formValuesWithDocuments).forEach((key) => {
          setValue(key, formValuesWithDocuments[key]);
        });
        isEditing && setInitialExpirationDates(expirationDates);
        if (dataApply) {
          const vacancySelected = dataApply?.offer.setting?.vacancies.find(
            (vacancy) => vacancy.vehicle?.name === formValues.fleetType
          )

          setValue("type", vacancySelected);
          setValue('template', vacancySelected?.vehicle?.documentTemplateId);
          
          setTemplateId(vacancySelected?.vehicle?.documentTemplateId);
        }
        if (dataVehicle) {
          const vehicleBrandSelected = dataVehicle.find(
            (brand) => brand?.name === formValues.brand && brand
          );
          const vehicleModelSelected = vehicleBrandSelected?.models.find(
            (model) => model?.name === formValues.model && model
          )

          setValue("brand", vehicleBrandSelected ?? { name: "Otro" });
          setValue("model", vehicleModelSelected ?? { name: "Otro" });
          setModels(vehicleBrandSelected?.models);
        }
      }
    }, [formValues, dataApply, dataVehicle, template, documentsFromBucket]);

    useEffect(() => {
      setCurrentTemplate(template);
      setDocumentsFromBucket(documentsFromBucket);
    }, [template, documentsFromBucket]);

    const isEditing = Boolean(formValues.id);
    const validatePlate = useCallback(
      (plate) => !isEditing && getPlate(plate),
      [isEditing]
    );
    const plateHasError = useCallback(
      () =>
        (isError && error?.response?.status !== STATUS_CODE.NOT_FOUND) ||
        isSuccess,
      [isError, error, isSuccess]
    );
    const canFillDocuments = useCallback(
      () => (!isLoadingTemplate && templateId && template && !isLoading && isError) || isEditing,
      [isEditing, isLoadingTemplate, templateId, template, isLoading, isError]
    );
    const canFillInputs = useCallback(
      () => (!isLoading && isError) || isEditing,
      [isLoading, isError, isEditing]
    );

    if (isLoadingApply || isLoadingVehicle || isGettingDocuments)
      return <LoadingSpinner open />;

    return (
      <>
        <Stack spacing={2} component="form" onSubmit={handleSubmit(onSubmit)}>
          <FleetSection
            control={control}
            validatePlate={validatePlate}
            filteredVacanciesByAvailability={filteredVacanciesByAvailability}
            validatePlateError={plateHasError()}
            isLoading={isLoading}
            isEditing={isEditing}
            setValue={setValue}
            getValues={getValues}
            canFillInputs={canFillInputs}
            setTemplateId={setTemplateId}
            data={dataApply}
            dataVehicle={dataVehicle}
            models={models}
            setModels={setModels}
            isReplacing={isReplacing}
          />
          <Divider sx={{ pt: 1, pb: 2 }} />
          {canFillDocuments() && template && (
            <DocumentsSection
              control={control}
              documentStructure={template.documentTypes}
            />
          )}
          <Grid container sx={{ pt: 2 }}>
            <Grid item xs={5}>
              {goBack && (
                <Button
                  variant="goBack"
                  disableRipple
                  startIcon={<ArrowBackOutlined />}
                  onClick={goBack}
                  sx={{ height: 40, fontSize: 12, alignSelf: "self-start" }}
                >
                  Volver atrás
                </Button>
              )}
            </Grid>
            <Grid item xs={7}>
              <Grid container justifyContent="flex-end">
                <Button
                  disabled={!formState.isValid}
                  color="secondary"
                  type="submit"
                  sx={{ minWidth: "155px", height: 40 }}
                >
                  Guardar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Stack>
        {isLoading && <LoadingSpinner open />}
      </>
    );
  }
);
