import { Card, Grid, Stack, Tab, Tabs } from "@mui/material";

export const HeaderTabs = ({ tabs, value, onChange, xs=12, sm=8, md=6 }) => (
  <Grid item xs={xs} sm={sm} md={md}>
    <Card
      sx={{
        borderRadius: '16px',
        boxShadow: '0 8px 16px 0 #BDC9D7',
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <Tabs
          value={ value }
          onChange={ onChange }
        >
          {
            tabs.map(tab => (
              <Tab key={ tab.value } label={ tab.label } value={ tab.value } />
            ))
          }
        </Tabs>
      </Stack>
    </Card>
  </Grid>
);