import { Paper } from '@mui/material'

export function PaperBlue({ children, sx = null }) {
  return (
    <Paper
      elevation={0}
      sx={{
        borderRadius: '15px',
        backgroundColor: 'lightDay',
        py: 2,
        px: 4,
        ...sx,
      }}
    >
      {children}
    </Paper>
  )
}
