import { useQuery } from "react-query";
import { useApi } from "../../../api";
import { DOCUMENT_TEMPLATE_URI } from "../../../../constants/api";
import { buildUrlWithQueryParams } from "../../../../v2/helper/converters";

export async function getListTemplates(filters = {}) {
  const { axiosInstance } = useApi();

  const url = buildUrlWithQueryParams(DOCUMENT_TEMPLATE_URI, filters);

  const response = await axiosInstance.get(url);

  const { data, metadata } = response.data;

  return { data, metadata };
}

export function useListTemplates(search) {
  return useQuery(["listTemplates", search], () => getListTemplates(search), {
    staleTime: 1000 * 60 * 60 * 24,
  });
}
