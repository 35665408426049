import { useMutation, useQueryClient } from 'react-query'
import { FLEET_URI } from '../../../../constants/api';
import { useApi } from '../../../../services/api';
import { buildUrlWithQueryParams } from '../../../helper/converters';

export async function reportListFleets(filters) {
  const { axiosInstance } = useApi();
  const url = buildUrlWithQueryParams(`${FLEET_URI}/report/all-fleets`, filters);
  const { data } = await axiosInstance.get(url);
  return data;
}

export function useReportFleets() {
  const queryClient = useQueryClient();
  return useMutation(reportListFleets, {
    onSuccess: () => {
      queryClient.refetchQueries("reportListFleets");
    },
  });
}
