export const dynamicHeadCells = {
  courier: [
    {
      id: "codeOffer",
      label: "Código de oferta",
    },
    {
      id: "vacancy.status",
      label: "Estado",
    },
    {
      id: "vacancy.name",
      label: "Nombre",
    },
    {
      id: "vacancy.courierDni",
      label: "RUT conductor",
    },
    {
      id: "vacancy.peonetaDni",
      label: "RUT peoneta",
    },
    {
      id: "vacancy.plate",
      label: "patente",
    },
    {
      id: "actions",
      label: "Acciones",
      disableSorting: true,
    },
  ],
  peoneta: [
    {
      id: "codeOffer",
      label: "Código de oferta",
    },
    {
      id: "vacancy.status",
      label: "Estado",
    },
    {
      id: "vacancy.name",
      label: "Nombre",
    },
    {
      id: "vacancy.peonetaDni",
      label: "RUT peoneta",
    },
    {
      id: "vacancy.courierDni",
      label: "RUT conductor",
    },
    {
      id: "vacancy.plate",
      label: "Patente",
    },
    {
      id: "actions",
      label: "Acciones",
      disableSorting: true,
    },
  ],
  fleet: [
    {
      id: "codeOffer",
      label: "Código de oferta",
    },
    {
      id: "vacancy.status",
      label: "Estado",
    },
    {
      id: "vacancy.plate",
      label: "Patente",
    },
    {
      id: "vacancy.type",
      label: "Tipo",
    },
    {
      id: "vacancy.courierDni",
      label: "Rut conductor",
    },
    {
      id: "vacancy.peonetaDni",
      label: "Rut peoneta",
    },
    {
      id: "actions",
      label: "Acciones",
      disableSorting: true,
    },
  ],
  provider: [
    {
      id: "status",
      label: "Estado",
    },
    {
      id: "numberRut",
      label: "RUT Empresa",
    },
    {
      id: "name",
      label: "Nombre Empresa",
    },
    {
      id: "legalRepresentative.dni",
      label: "RUT Representante legal",
    },
    {
      id: "legalRepresentative.name",
      label: "Nombre representante legal",
    },
    {
      id: "actions",
      label: "Acciones",
      disableSorting: true,
    },
  ],
};

export const getNestedValues = (path, value = {}) => {
  if(path.includes('.')) {
    const routes = path.split('.');
    let currentValue = JSON.parse(JSON.stringify(value));

    for(let route of routes) {             
      if(!currentValue.hasOwnProperty(route)) {
        currentValue = null;
        break;
      }
      currentValue = currentValue[route];
    }

    return currentValue;
  }
  return value[path];
}