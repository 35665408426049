import React from "react";
import * as XLSX from "xlsx";
import { Stack, Box, Button } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";

export function DownloadExportData({
  data,
  columns,
  nameFile,
  className = "",
  label = "Descargar",
}) {
  const handleDownloadExcel = () => {
    if (data.length > 0) {
      const datas = [];
      data.forEach((dat, index) => {
        const obj = {};
        columns.forEach((column) => {
          if (column.exportExcel === true) {
            obj[column.label] = data[index][column.id];
          }
        });
        datas.push(obj);
      });
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.json_to_sheet(datas);
      XLSX.utils.book_append_sheet(wb, ws, nameFile);
      XLSX.writeFile(wb, `${nameFile}.xlsx`);
    }
  };

  return (
    <Stack className={className}>
      <Box sx={{ paddingLeft: "1rem" }}>
        <Button
          variant="contained"
          startIcon={<DownloadIcon color="inherit" />}
          onClick={handleDownloadExcel}
          size="medium"
        >
          {label}
        </Button>
      </Box>
    </Stack>
  );
}
