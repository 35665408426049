import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";

export const TableButton = styled(Button) ({
  maxWidth: '32px', 
  maxHeight: '32px', 
  minWidth: '32px', 
  minHeight: '36px', 
  boxShadow:"0px 2px 4px -2px #18274B33", 
  borderRadius:"8px", 
  padding:"0px", 
  background:"#FFFFFF",
  margin:"0px 6px",
  '&:hover': {
    backgroundColor: '#CCCCCC',
    borderColor: '#0062cc',
    boxShadow: 'none',
  },
  '&:active': {
    boxShadow: 'none',
    backgroundColor: '#0062cc',
    borderColor: '#005cbf',
  },
});