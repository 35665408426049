import { useMutation, useQueryClient } from "react-query";
import { useApi } from "../../../api";
import { DOCUMENT_TYPE_URI } from "../../../../constants/api";

export function useCreateDocuments() {
  const { axiosInstance } = useApi();
  const queryClient = useQueryClient();

  return useMutation(
    (document) =>
      axiosInstance.post("/", document, {
        baseURL: DOCUMENT_TYPE_URI,
      }),
    {
      onSuccess: () => queryClient.invalidateQueries("listDocuments"),
    }
  );
}
