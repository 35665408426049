import React, { useState } from "react";
import { MessageBox } from "../../../components/common/MessageBox";
import { LoadingSpinner } from "../../../components/LoadingSpinner";
import { useGetDocuments } from "../../../services/hooks/Documents";

import {
  Button,
  Grid,
  IconButton,
  Link,
  Stack,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";

import VisibilityIcon from "@mui/icons-material/Visibility";

import { Table } from "../Table";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import DocumentsModal from "../Modal/DocumentsModal";
import { CommonStatus, EmployeeStatus } from "../../../constants/status";
import { formatDateApprovalFlow } from "../../../utils/formatValues";
import { ArrowBackOutlined } from "@mui/icons-material";
import { ChipStatus } from "../Chip";

const headCells = [
  {
    id: "title",
    label: "Documento",
    exportExcel: true,
  },
  {
    id: "file",
    label: "Archivo",
    exportExcel: true,
  },
  {
    id: "expiredDate",
    label: "Fecha vencimiento",
    exportExcel: true,
  },
  {
    id: "state",
    label: "Estado",
    exportExcel: true,
  },
  {
    id: "actions",
    label: "Acciones",
    disableSorting: true,
    exportExcel: false,
  },
];

const formValues = {};

export function DocumentListUpdate({ type, id, templateData, goBack, isProviderList, onUpdateSuccess, status }) {
  const { data, isLoading, error } = useGetDocuments(type, id);
  const [showModal, setShowModal] = useState(false);
  const [documentUpload, setDocumentUpload] = useState();

  const { TableContainer } = Table(headCells, data);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const disableUpload = (document) => {
    if (isProviderList)
      return false
    else{
      return  document.status === EmployeeStatus.APPROVED ||
      document.status === EmployeeStatus.PENDING
    }
  }

  if (error) return <MessageBox>Ups, ocurrio un error!</MessageBox>;

  if (isLoading) return <LoadingSpinner open />;
  const TabsAddon = () => (
    <Stack
      direction="row"
      paddingY={2}
      paddingX={2}
      alignItems="center"
      justifyContent="space-between"
    ></Stack>
  );

  return (
    <Stack spacing={4}>
      {data.length ? (
        <>
          <TableContainer addons={[TabsAddon]}>
            <TableBody>
              {data.map((document) => (
                <TableRow hover tabIndex={-1} key={document.id}>
                  <TableCell>{document.files[0].groupName}</TableCell>
                  <TableCell>
                    {document.files.map((file) => (
                      <Stack key={file.name}>
                        <Button
                          variant="goBack"
                          disableRipple
                          startIcon={<VisibilityIcon />}
                          sx={{
                            height: 40,
                            fontSize: 14,
                            alignSelf: "self-start",
                          }}
                        >
                          <Link
                            href={file.path}
                            target="_blank"
                            rel="noopener noreferrer"
                            download={file?.name}
                          >
                            {file?.labelName}
                          </Link>
                        </Button>
                      </Stack>
                    ))}
                  </TableCell>
                  <TableCell>
                    {document.expirationDate
                      ? formatDateApprovalFlow(
                          new Date(document.expirationDate)
                        )
                      : "-"}
                  </TableCell>
                  <TableCell>
                    <ChipStatus 
                      label={EmployeeStatus[document.status === CommonStatus.APPROVED && status === CommonStatus.backofficeApproved
                       ? CommonStatus.REVIEWED : document.status ]}
                      setcolor={document.status === CommonStatus.APPROVED && status === CommonStatus.backofficeApproved
                        ? CommonStatus.REVIEWED : document.status}
                    />
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={() => {
                        setDocumentUpload(document);
                        setShowModal(true);
                      }}
                      disabled={disableUpload(document)}
                    >
                      <Stack
                        sx={{ height: 40, fontSize: 14, alignItems: "center" }}
                      >
                        <FileUploadIcon />
                        {'Cargar Archivo'} 
                      </Stack>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </TableContainer>
          <DocumentsModal
            isOpen={showModal}
            onClose={handleCloseModal}
            templateData={templateData}
            formValues={formValues}
            id={id}
            ownerType={type}
            documentUpload={documentUpload}
            onFinish={onUpdateSuccess}
          />
        </>
      ) : null}
      <Grid item xs={5} sx={{ mt: 5 }}>
        {goBack && (
          <Button
            variant="goBack"
            disableRipple
            startIcon={<ArrowBackOutlined />}
            onClick={goBack}
            sx={{ height: 40, fontSize: 12, alignSelf: "self-start" }}
          >
            {"Volver atrás"}
          </Button>
        )}
      </Grid>
    </Stack>
  );
}
