import { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, CircularProgress, Stack, Typography } from '@mui/material'
import { ArrowBackOutlined } from '@mui/icons-material'
import { Controller, useForm } from 'react-hook-form'

import { PaperWhite } from '../../../components/Papers'
import { Input, RadioButtonVehicle } from '../../../components/Inputs'
import { AlertSnackBar } from '../../../components/Alerts'
import { LoadingSpinner } from '../../../components/LoadingSpinner'
import { ComboBox } from '../../../components/ComboBox'

import {
  useCreateVehicleType,
  useGetVehicleType,
  useListVehiclesFeatures,
  useUpdateVehicleType,
} from '../../../services/hooks/Vehicles'

import {
  isComplexTextNumberValid,
  validateMinLength,
} from '../../../utils/validateValues'
import { useListTemplates } from '../../../services/hooks/Docs/Templates/useListTemplates'
import { getEnabledVehicles } from '../../../v2/constants/vehicle-config'

const initialAlertError = {
  open: false,
  message: '',
}

const defaultValues = {
  name: '',
  features: [],
  documentTemplate: {},
  courierTemplate: {}
}

export default function FormTypeVehicles({ fromButton }) {
  const navigate = useNavigate()
  const { typeId } = useParams()

  const [alertError, setAlertError] = useState(initialAlertError)
  const [icon, setIcon] = useState('')

  const { control, handleSubmit, setValue } = useForm({ defaultValues })
  const { data: vehiclesFeatures, isLoading } = useListVehiclesFeatures()
  const { data: type, isLoading: isLoadingGetType } = useGetVehicleType(typeId)
  const {
    mutate: mutateCreate,
    isLoading: isLoadingCreate
  } = useCreateVehicleType()
  const {
    mutate: mutateUpdate,
    isLoading: isLoadingUpdate
  } = useUpdateVehicleType(typeId)
  const { data: documentTemplates, isLoading: isLoadingTemplates } = useListTemplates({
    page: 1,
    limit: 1000,
  });

  useEffect(() => {
    if (!fromButton) navigate('/404')
  }, [])

  useEffect(() => {
    if (type && documentTemplates) {
      setValue('name', type.name)
      setValue('features', type.features)
      setValue('courierTemplate', documentTemplates.data?.find(document => document.id === type.courierTemplateId));
      setValue('documentTemplate', documentTemplates.data?.find(document => document.id === type.documentTemplateId));
      setIcon(type.icon)
    } else {
      setIcon(getEnabledVehicles().find((vehicle) => vehicle.default)?.icon)
    }
  }, [type, documentTemplates])

  const handleIcon = (event) => {
    setIcon(event.target.value)
  }

  const handleCloseAlertError = (_, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setAlertError(initialAlertError)
  }

  const renderOptionLabel = (path = '') => (option) => {
    if (Object.entries(option).length === 0) return ''

    return `${option[path]}`
  }

  const onSubmit = (data) => {
    const { name, features, documentTemplate, courierTemplate } = data
    const newData = {
      name,
      icon,
      features: features.map((fet) => ({
        name: fet.name,
      })),
      documentTemplateId: documentTemplate.id,
      courierTemplateId: courierTemplate.id
    }

    if (typeId) {
      mutateUpdate(newData, {
        onSuccess: () => navigate('/typeVehicles'),
        onError: () =>
          setAlertError({
            open: true,
            message:
              'Oh no! se ha producido un error al editar el tipo de vehículo',
          }),
      })
    } else {
      mutateCreate(newData, {
        onSuccess: () => navigate('/typeVehicles'),
        onError: () =>
          setAlertError({
            open: true,
            message:
              'Oh no! se ha producido un error al crear un nuevo tipo de vehículo',
          }),
      })
    }
  }

  if (isLoadingGetType || isLoadingCreate || isLoadingUpdate) {
    return <LoadingSpinner open />
  }

  return (
    <Stack spacing={2} component='form' onSubmit={handleSubmit(onSubmit)}>
      <Typography variant='h4' align='center'>
        {typeId
          ? 'Editando el tipo de vehículo'
          : 'Creando un nuevo tipo de vehículo'}
      </Typography>

      <PaperWhite sx={{ pb: 20 }}>
        <Stack spacing={2} sx={{ width: '50%' }}>
          <Controller
            control={control}
            name='name'
            rules={{
              required: '(*) Campo obligatorio',
              validate: {
                minValid: (text) =>
                  validateMinLength(text, 1) ||
                  'Descripción no puede ser menor a 1 caracteres',
                textIsValid: (text) =>
                  isComplexTextNumberValid(text) ||
                  'Descripción inválida, no se aceptan caracteres extraños',
              },
            }}
            render={({ field, fieldState: { error } }) => (
              <Input
                label='Descripción'
                isRequired
                error={Boolean(error)}
                helperText={error?.message}
                inputProps={{ maxLength: 50 }}
                {...field}
              />
            )}
          />

          <Controller
            control={control}
            name='features'
            rules={{ required: '(*) Campo obligatorio' }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <ComboBox
                multiple
                label='Características'
                options={vehiclesFeatures || []}
                renderOptionLabel={renderOptionLabel('name')}
                value={value}
                onChange={onChange}
                error={error}
                loading={isLoading}
                noOptionsText='No hay características'
              />
            )}
          />

          {
            isLoadingTemplates ? <CircularProgress /> : <Controller
              control={control}
              name='documentTemplate'
              rules={{ required: '(*) Campo obligatorio' }}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <ComboBox
                  label='Selecciona la documentación requerida'
                  options={documentTemplates?.data || []}
                  renderOptionLabel={renderOptionLabel('name')}
                  value={value}
                  onChange={onChange}
                  error={error}
                  loading={isLoading}
                  noOptionsText='No hay características'
                />
              )}
            />
          }
          {
            isLoadingTemplates ? <CircularProgress /> : <Controller
              control={control}
              name='courierTemplate'
              rules={{ required: '(*) Campo obligatorio' }}
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <ComboBox
                  label='Selecciona la documentación requerida por el conductor'
                  options={documentTemplates?.data || []}
                  renderOptionLabel={renderOptionLabel('name')}
                  value={value}
                  onChange={onChange}
                  error={error}
                  loading={isLoading}
                  noOptionsText='No hay documentos'
                />
              )}
            />
          }
          <RadioButtonVehicle value={icon} handleChange={handleIcon} />
        </Stack>
      </PaperWhite>

      <Stack spacing={2} direction='row' justifyContent='flex-end' paddingY={2}>
        <Button
          variant='goBack'
          disableRipple
          startIcon={<ArrowBackOutlined />}
          onClick={() => navigate('/typeVehicles')}
        >
          Volver atrás
        </Button>
        <Button size='large' type='submit'>
          {typeId ? 'Editar' : 'Guardar'}
        </Button>
      </Stack>

      <AlertSnackBar
        open={alertError?.open}
        handleClose={handleCloseAlertError}
        message={alertError?.message}
      />
    </Stack>
  )
}
