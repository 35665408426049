import { Stack, Typography } from "@mui/material";
import { LoadingSpinner } from "../../../../components/LoadingSpinner";
import { EntityCard } from "../../../components/EntityCard";
import {
  EmployeeStatus,
  VACANCY_TYPE_ENUM,
} from "../../../../constants/status";
import { useGetTemplate } from "../../../../services/hooks/Docs/Templates/useGetTemplates";
import { ChipStatus } from "../../../components/Chip";
import { ENTITY_TYPES_MAPPING } from "../../../../constants/entityTypes";
import { FinishReplaceDocument } from "./FinishReplaceDocument";
import { useState } from "react";
import { EntityDocumentListUpdateMany } from "../../../components/Documents/EntityDocumentListUpdateMany";

export const UpdateFleetDocsView = ({ item, goBack }) => {
  const { data: template, isLoading: isLoadingTemplate } = useGetTemplate(
    item?.documentTemplateId
  );
  const [replaceFinish, setReplaceFinish] = useState(false);

  if (isLoadingTemplate) return <LoadingSpinner open />;

  if (replaceFinish) {
    return (
      <Stack spacing={4}>
        <Typography variant="h4" align="center">
          {`Reemplazar Documentos`}
        </Typography>
        <FinishReplaceDocument
          goBack={goBack}
          goBackTitleButton={`Volver a Mis ${ENTITY_TYPES_MAPPING.fleet}`}
        />
      </Stack>
    );
  }

  return (
    <>
      <Stack>
        <EntityCard
          entries={[
            [
              "Datos del vehículo",
              {
                "Tipo de vehículo:": <b>{item?.type}</b>,
                "Patente:": <b> {item?.plate} </b>,
                "Marca:": <b> {item?.brand} </b>,
                "Modelo:": <b> {item?.model} </b>,
                Año: <b> {item?.year} </b>,
              },
            ],
            [
              "Estado",
              {
                "": (
                  <ChipStatus
                    label={EmployeeStatus[item?.status]}
                    setcolor={item?.status}
                  />
                ),
              },
            ],
          ]}
        />

        <Stack sx={{ mt: 5 }}>
          {
            <EntityDocumentListUpdateMany
              type={VACANCY_TYPE_ENUM.FLEET}
              id={item?.id}
              templateData={template}
              goBack={goBack}
              entity={item}
              setReplaceFinish={setReplaceFinish}
            />
          }
        </Stack>
      </Stack>
    </>
  );
};
