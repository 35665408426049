import { useCallback, useEffect, useRef, useState } from 'react';
import { $getSelection, $isRangeSelection, SELECTION_CHANGE_COMMAND } from 'lexical';
import { $isLinkNode, TOGGLE_LINK_COMMAND } from "@lexical/link";
import { mergeRegister } from "@lexical/utils";
import { LowPriority } from '..';
import { getSelectedNode } from '../../../helpers/getSelectedNode';
import styles from './styles.module.css';

function positionEditorElement(editor, rect) {
    if (rect === null) {
        editor.style.opacity = "0";
        editor.style.top = "-1000px";
        editor.style.left = "-1000px";
    } else {
        editor.style.opacity = "1";
        editor.style.top = `${rect.top + rect.height + window.pageYOffset + 10}px`;
        editor.style.left = `${(rect.left + 110) + window.pageXOffset - editor.offsetWidth / 2 + rect.width / 2
            }px`;
    }
}

const escapeRiskURLCharacters = (url) => url.replace(/[^A-Za-z0-9\-._~:/?#[\]@!$&'()*+,;=]/g, '');

export function FloatingLinkEditor({ editor }) {
    const editorRef = useRef(null);
    const inputRef = useRef(null);
    const mouseDownRef = useRef(false);
    const [linkUrl, setLinkUrl] = useState("");
    const [isEditMode, setEditMode] = useState(false);
    const [lastSelection, setLastSelection] = useState(null);

    const updateLinkEditor = useCallback(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
            const node = getSelectedNode(selection);
            const parent = node.getParent();
            if ($isLinkNode(parent)) {
                setLinkUrl(parent.getURL());
            } else if ($isLinkNode(node)) {
                setLinkUrl(node.getURL());
            } else {
                setLinkUrl("");
            }
        }
        const editorElem = editorRef.current;
        const nativeSelection = window.getSelection();
        const activeElement = document.activeElement;

        if (editorElem === null) {
            return;
        }

        const rootElement = editor.getRootElement();
        if (
            selection !== null &&
            !nativeSelection.isCollapsed &&
            rootElement !== null &&
            rootElement.contains(nativeSelection.anchorNode)
        ) {
            const domRange = nativeSelection.getRangeAt(0);
            let rect;
            if (nativeSelection.anchorNode === rootElement) {
                let inner = rootElement;
                while (inner.firstElementChild != null) {
                    inner = inner.firstElementChild;
                }
                rect = inner.getBoundingClientRect();
            } else {
                rect = domRange.getBoundingClientRect();
            }

            if (!mouseDownRef.current) {
                positionEditorElement(editorElem, rect);
            }
            setLastSelection(selection);
        } else if (!activeElement || activeElement.className !== "linkInput") {
            positionEditorElement(editorElem, null);
            setLastSelection(null);
            setEditMode(false);
            setLinkUrl("");
        }

        return true;
    }, [editor]);

    useEffect(() => {
        editor.getEditorState().read(() => {
            updateLinkEditor();
        });

        return mergeRegister(
            editor.registerUpdateListener(({ editorState }) => {
                editorState.read(() => {
                    updateLinkEditor();
                });
            }),

            editor.registerCommand(
                SELECTION_CHANGE_COMMAND,
                () => {
                    updateLinkEditor();
                    return true;
                },
                LowPriority
            )
        );
    }, [editor, updateLinkEditor]);

    useEffect(() => {
        if (isEditMode && inputRef.current) {
            inputRef.current.focus();
        }
    }, [isEditMode]);

    return (
        <>
            <div ref={editorRef} className={styles.linkEditor}>
                {isEditMode ? (
                    <input
                        ref={inputRef}
                        className={styles.linkInput}
                        value={escapeRiskURLCharacters(linkUrl)}
                        onChange={(event) => {
                            setLinkUrl(event.target.value);
                        }}
                        onKeyDown={(event) => {
                            if (event.key === "Enter") {
                                event.preventDefault();
                                if (lastSelection !== null) {
                                    if (linkUrl !== "") {
                                        editor.dispatchCommand(TOGGLE_LINK_COMMAND, linkUrl);
                                    }
                                    setEditMode(false);
                                }
                            } else if (event.key === "Escape") {
                                event.preventDefault();
                                setEditMode(false);
                            }
                        }}
                    />
                ) : (
                    <>
                        <div className={styles.linkInput}>
                            <a className={styles.linkInputAnchor} href={escapeRiskURLCharacters(linkUrl)} target="_blank" rel="noopener noreferrer">
                                {linkUrl}
                            </a>
                            <div
                                className={`${styles.linkEdit} ${styles.linkEditIcon}`}
                                role="button"
                                tabIndex={0}
                                onMouseDown={(event) => event.preventDefault()}
                                onClick={() => setEditMode(true)}
                            />
                        </div>
                    </>
                )}
            </div>
        </>
    );
}